import React, { useState, useContext, useRef, useEffect } from "react";
import { FirebaseContext } from "../../firebase";
import Swal from "sweetalert2";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import ReactTimeAgo from "react-time-ago";
import { useReactToPrint } from "react-to-print";
import PedidoPrint from "./PedidoPrint";
import QRCode from "react-qr-code";

const Pedido = ({ pedido }) => {
  //print
  const componentRef = useRef();

  //const [print, setPrint] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  /*   const printFunction = () => {
    setPrint(true)
    handlePrint()

  } */

  //Tiempo que transcurrio desde la notificacion
  //TimeAgo.addDefaultLocale(es);

  //context de firebase

  const [tiempoentrega, guardarTiempoEntrega] = useState(0);

  const { firebase } = useContext(FirebaseContext);

  const [datos, guardarDatos] = useState([]);

  //existencia ref para acceder a un valor directamente en el DOM
  const completadoRef = useRef(pedido.completado);

  //anteriormente asi formateaba el numero de telefono pero no es necesario con los inputs que dan formato
  /* 
  const telefono_format =
    "(" +
    pedido.telefono.substring(0, 3) +
    ") " +
    pedido.telefono.substring(3, 6) +
    "-" +
    pedido.telefono.substring(6, 11); */

  //define el tiempo de entrega
  const definirTiempo = (id) => {
    try {
      firebase.db.collection("ordenes").doc(id).update({ tiempoentrega });
    } catch (error) {
      console.log(error);
    }
  };

  //modificar el estado de disponibilidad en firebase
  const actualizarDisponibilidad = () => {
    const completado = completadoRef.current.value;

    try {
      firebase.db.collection("ordenes").doc(pedido.id).update({
        completado,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const mostrarDetalle = () => {
    switch (pedido.plataforma) {
      case "stripe":
        Swal.fire({
          title: "<p style='color:#22c55e'>" + "Detalles desde Stripe" + "</p>",
          html:
            '<p style="text-align:left;color:#fff;"><b>Stripe Id: </b>' +
            pedido.session.id +
            "</p> " +
            '<p style="text-align:left;color:#fff;"><b>Cantidad: </b>' +
            (pedido.session.amount_total / 100).toFixed(2) +
            "</p> " +
            '<p style="text-align:left;color:#fff;"><b>Created: </b>' +
            pedido.session.created +
            "</p> " +
            '<p style="text-align:left;color:#fff;"><b>Currency: </b>' +
            pedido.session.currency +
            "</p> " +
            '<p style="text-align:left;color:#fff;"><b>Status: </b>' +
            pedido.session.status.toString() +
            "</p> " +
            '<p style="text-align:left;color:#fff;"><b>Payment status: </b>' +
            pedido.session.payment_status +
            "</p> " +
            '<p style="text-align:left;color:#fff;"><b>Live Mode: </b>' +
            pedido.session.livemode.toString() +
            "</p> ",
          color: "#fff",
          background: "#18181b",
          confirmButtonColor: "#581c87",

        });
        break;
      case "paypal":
        Swal.fire({
          title: "Detalles desde Paypal",
          html:
            '<p style="text-align:left;"><b>Paypal Id: </b>' +
            pedido.response.id +
            '<p style="text-align:left;"><b>Status: </b>' +
            pedido.response.status.toString() +
            "</p> " +
            '<p style="text-align:left;"><b>Created: </b>' +
            pedido.response.create_time,
          color: "#fff",
          background: "#18181b",
          confirmButtonColor: "#581c87",

        });
        break;
      default:

      case "paypal":
        Swal.fire({
          title: "No hay una plataforma definida para este pedido",
        });
    }
  };

  const handleRecibido = () => {
    Swal.fire({
      title:
        "<p style='color:#22c55e'>" +
        "Cambiaste el estado de este pedido a RECIBIDO" +
        "</p>",
      html: '<p style="color:#fff">¿Deseas enviar una notificación por correo al cliente?</p>',
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#581c87",
      cancelButtonColor: "#d33",
      confirmButtonText: "Enviar",
      color: "#fff",
      background: "#18181b",
      position: "center",
      iconColor: "#22c55e",
    }).then((result) => {
      if (result.isConfirmed) {
        firebase.db.collection("mail").add({
          to: pedido.correo,
          template: {
            name: "confirmacion_pedido",
            data: {
              nombre: pedido.nombre.split(" ")[0],
              nombreCompleto: pedido.nombre,
              productos: pedido.orden,
              envio: pedido.envio,
              costo_envio: pedido.costo_envio,
              total: pedido.total,
              id: pedido.id,
              calle: pedido.calle,
              colonia: pedido.colonia,
              ciudad: pedido.ciudad,
              estado: pedido.estado,
              codigoPostal: pedido.codigoPostal,
              creado:Date(pedido.creado).toLocaleString(),
            },
          },
        });

        Swal.fire({
          title: "<p style='color:#22c55e'>" + "Enviado" + "</p>",
          html: '<p style="color:#fff">Se envió un correo al cliente para hacerle saber sobre el estado del pedido</p>',
          icon: "success",
          confirmButtonColor: "#581c87",
          color: "#fff",
          background: "#18181b",
          position: "center",
          iconColor: "#22c55e",
        });
      }
    });
  };

  const handlePreparando = () => {
    Swal.fire({
      title:
        "<p style='color:#22c55e'>" +
        "Cambiaste el estado de este pedido a PREPARANDO" +
        "</p>",
      html: '<p style="color:#fff">¿Deseas enviar una notificación por correo al cliente?</p>',
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#581c87",
      cancelButtonColor: "#d33",
      confirmButtonText: "Enviar",
      color: "#fff",
      background: "#18181b",
      position: "center",
      iconColor: "#22c55e",
    }).then((result) => {
      if (result.isConfirmed) {
        firebase.db.collection("mail").add({
          to: pedido.correo,
          template: {
            name: "preparacion_pedido",
            data: {
              nombre: pedido.nombre.split(" ")[0],
              nombreCompleto: pedido.nombre,
              productos: pedido.orden,
              envio: pedido.envio,
              costo_envio: pedido.costo_envio,
              total: pedido.total,
              id: pedido.id,
              calle: pedido.calle,
              colonia: pedido.colonia,
              ciudad: pedido.ciudad,
              estado: pedido.estado,
              codigoPostal: pedido.codigoPostal,
              creado:Date(pedido.creado).toLocaleString(),
            },
          },
        });

        Swal.fire({
          title: "<p style='color:#22c55e'>" + "Enviado" + "</p>",
          html: '<p style="color:#fff">Se envió un correo al cliente para hacerle saber sobre el estado del pedido</p>',
          icon: "success",
          confirmButtonColor: "#581c87",
          color: "#fff",
          background: "#18181b",
          position: "center",
          iconColor: "#22c55e",
        });
      }
    });
  };

  const handleEnviado = () => {
    Swal.fire({
      title:
        "<p style='color:#22c55e'>" +
        "Cambiaste el estado de este pedido a ENVIADO" +
        "</p>",
      html: '<p style="color:#fff">¿Deseas enviar una notificación por correo al cliente?</p>',
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#581c87",
      cancelButtonColor: "#d33",
      confirmButtonText: "Enviar",
      color: "#fff",
      background: "#18181b",
      position: "center",
      iconColor: "#22c55e",
    }).then((result) => {
      if (result.isConfirmed) {
        firebase.db.collection("mail").add({
          to: pedido.correo,
          template: {
            name: "enviado_pedido",
            data: {
              nombre: pedido.nombre.split(" ")[0],
              nombreCompleto: pedido.nombre,
              productos: pedido.orden,
              envio: pedido.envio,
              costo_envio: pedido.costo_envio,
              total: pedido.total,
              id: pedido.id,
              calle: pedido.calle,
              colonia: pedido.colonia,
              ciudad: pedido.ciudad,
              estado: pedido.estado,
              codigoPostal: pedido.codigoPostal,
              creado:Date(pedido.creado).toLocaleString(),
            },
          },
        });

        Swal.fire({
          title: "<p style='color:#22c55e'>" + "Enviado" + "</p>",
          html: '<p style="color:#fff">Se envió un correo al cliente para hacerle saber sobre el estado del pedido</p>',
          icon: "success",
          confirmButtonColor: "#581c87",
          color: "#fff",
          background: "#18181b",
          position: "center",
          iconColor: "#22c55e",
        });
      }
    });
  };

  const handleCompletado = () => {
    Swal.fire({
      title:
        "<p style='color:#22c55e'>" +
        "Cambiaste el estado de este pedido a COMPLETADO" +
        "</p>",
      html: '<p style="color:#fff">¿Deseas enviar una notificación por correo al cliente?</p>',
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#581c87",
      cancelButtonColor: "#d33",
      confirmButtonText: "Enviar",
      color: "#fff",
      background: "#18181b",
      position: "center",
      iconColor: "#22c55e",
    }).then((result) => {
      if (result.isConfirmed) {
        firebase.db.collection("mail").add({
          to: pedido.correo,
          template: {
            name: "completado_pedido",
            data: {
              nombre: pedido.nombre.split(" ")[0],
              nombreCompleto: pedido.nombre,
              productos: pedido.orden,
              envio: pedido.envio,
              costo_envio: pedido.costo_envio,
              total: pedido.total,
              id: pedido.id,
              calle: pedido.calle,
              colonia: pedido.colonia,
              ciudad: pedido.ciudad,
              estado: pedido.estado,
              codigoPostal: pedido.codigoPostal,
              creado:Date(pedido.creado).toLocaleString(),
            },
          },
        });

        Swal.fire({
          title: "<p style='color:#22c55e'>" + "Enviado" + "</p>",
          html: '<p style="color:#fff">Se envió un correo al cliente para hacerle saber sobre el estado del pedido</p>',
          icon: "success",
          confirmButtonColor: "#581c87",
          color: "#fff",
          background: "#18181b",
          position: "center",
          iconColor: "#22c55e",
        });
      }
    });
  };

  const handleCancelado = () => {
    Swal.fire({
      title:
        "<p style='color:#22c55e'>" +
        "Cambiaste el estado de este pedido a CANCELADO" +
        "</p>",
      html: '<p style="color:#fff">¿Deseas enviar una notificación por correo al cliente?</p>',
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#581c87",
      cancelButtonColor: "#d33",
      confirmButtonText: "Enviar",
      color: "#fff",
      background: "#18181b",
      position: "center",
      iconColor: "#22c55e",
    }).then((result) => {
      if (result.isConfirmed) {
        firebase.db.collection("mail").add({
          to: pedido.correo,
          template: {
            name: "cancelado_pedido",
            data: {
              nombre: pedido.nombre.split(" ")[0],
              nombreCompleto: pedido.nombre,
              productos: pedido.orden,
              envio: pedido.envio,
              costo_envio: pedido.costo_envio,
              total: pedido.total,
              id: pedido.id,
              calle: pedido.calle,
              colonia: pedido.colonia,
              ciudad: pedido.ciudad,
              estado: pedido.estado,
              codigoPostal: pedido.codigoPostal,
              creado:Date(pedido.creado).toLocaleString(),
            },
          },
        });

        Swal.fire({
          title: "<p style='color:#22c55e'>" + "Enviado" + "</p>",
          html: '<p style="color:#fff">Se envió un correo al cliente para hacerle saber sobre el estado del pedido</p>',
          icon: "success",
          confirmButtonColor: "#581c87",
          color: "#fff",
          background: "#18181b",
          position: "center",
          iconColor: "#22c55e",
        });
      }
    });
  };

  return (
    <>
      <div className="w-full mb-8">
        <div className="mx-auto bg-gray-800 to-black rounded-xl shadow-lg overflow-hidden max-w-screen-2xl">
          <div className="lg:flex flex-row-reverse">
            <div className="lg:w-6/12 xl:w-6/12">
              <div className="bg-gradient-to-l from-green-600 to-green-400 p-2 xs:rounded-t-none sm:rounded-t-none md:rounded-b lg:rounded-b-none lg:rounded-r lg:rounded-t-none">
                <h1 className="font-bold ml-5 xs:text-sm sm:text-sm md:text-base lg:text-lg xl:text-lg text-white">
                  Información del cliente
                </h1>
              </div>

              <div className="flex xs:flex-col sm:flex-row w-full justify-between ">
                <div className="rounded-md mt-4 mb-2 ml-5 p-2 bg-gray-700 shadow-inner col-span-2 w-2/3 xs:w-auto xs:mr-4 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-2/3">
                  <div className="p-2 text-white xs:text-xs sm:text-sm lg:text-base">
                    <p className="pl-2 font-semibold mt-2">
                      Nombre:{" "}
                      <span className=" font-normal ">{pedido.nombre}</span>
                    </p>
                    <p className=" pl-2 font-semibold ">
                      Calle:{" "}
                      <span className=" font-normal ">{pedido.calle}</span>
                    </p>
                    <p className=" pl-2 font-semibold ">
                      Colonia:{" "}
                      <span className=" font-normal">{pedido.colonia}</span>
                    </p>
                    <p className="pl-2 font-semibold">
                      Ciudad:{" "}
                      <span className=" font-normal ">{pedido.ciudad}</span>
                    </p>
                    <p className=" pl-2 font-semibold">
                      Estado:{" "}
                      <span className=" font-normal ">{pedido.estado}</span>
                    </p>
                    <p className="pl-2 font-semibold">
                      Codigo Postal:{" "}
                      <span className="font-normal">{pedido.codigoPostal}</span>
                    </p>
                    <p className="pl-2 font-semibold">
                      Telefono:{" "}
                      <span className="font-normal">{pedido.telefono}</span>
                    </p>
                    <p className="pl-2 font-semibold  mb-2 ">
                      Correo:{" "}
                      <span className=" font-normal ">{pedido.correo}</span>
                    </p>
                  </div>
                </div>
                <div className="md:m-2 md:p-2 flex self-center">
                  <div className=" border border-green-400 p-4 m-4 rounded-lg">
                    <div
                      style={{
                        height: "auto",
                        margin: "0 auto",
                        maxWidth: 150,
                        width: "100%",
                      }}
                    >
                      <QRCode
                        size={256}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "100%",
                        }}
                        value={pedido.id}
                        viewBox={`0 0 256 256`}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {window.location.pathname !== "/mispedidos" ? (
                <button
                  onClick={handlePrint}
                  className="ml-5 mb-4 mt-2 rounded-lg text-white bg-purple-900 hover:bg-purple-700 p-2 text-sm inline-flex items-center shadow-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                    />
                  </svg>
                  Imprimir etiqueta
                </button>
              ) : null}
            </div>
            <div className="lg:w-6/12 xl:w-6/12 ring-2 ring-gray-700">
              <div className="bg-gradient-to-l from-yellow-400 to-yellow-500 p-2 ">
                <h1 className="font-bold ml-5 xs:text-sm sm:text-sm md:text-base lg:text-lg xl:text-lg text-white">
                  PEDIDO ID: {pedido.id}
                </h1>
              </div>

              <div className="flex-wrap lg:flex justify-between m-4">
                <div className="bg-gray-700 self-center p-2 rounded-lg ">
                  <p className="xs:text-sm sm:text-sm text-white rounded-lg  self-center ml-2 mr-2">
                    Fecha de pedido:{" "}
                    {new Date(pedido.creado).toLocaleString() + ","}
                    <ReactTimeAgo
                      className="ml-1 xs:text-sm sm:text-sm text-green-500 font-semibold"
                      date={pedido.creado}
                      locale="es-MX"
                    />
                  </p>
                </div>

                {window.location.pathname === "/mispedidos" ? (
                  <>
                    {pedido.completado === "recibido" ? (
                      <p className="ml-4 xs:text-sm sm:text-sm bg-yellow-500 rounded-md m-2 p-2 flex justify-center text-white">
                        Estado del pedido: Recibido
                      </p>
                    ) : null}
                    {pedido.completado === "preparando" ? (
                      <p className="ml-4 xs:text-sm sm:text-sm bg-yellow-500 rounded-md m-2 p-2 flex justify-center text-white">
                        Estado del pedido: Preparando
                      </p>
                    ) : null}
                    {pedido.completado === "enviado" ? (
                      <p className="ml-4 xs:text-sm sm:text-sm bg-yellow-500 rounded-md m-2 p-2 flex justify-center text-white">
                        Estado del pedido: Enviado
                      </p>
                    ) : null}
                    {pedido.completado === "completado" ? (
                      <p className="ml-4 xs:text-sm sm:text-sm bg-green-500 rounded-md m-2 p-2 flex justify-center text-white">
                        Estado del pedido: Completado
                      </p>
                    ) : null}
                    {pedido.completado === "cancelado" ? (
                      <p className="ml-4 xs:text-sm sm:text-sm bg-red-500 rounded-md m-2 p-2 flex justify-center text-white">
                        Estado del pedido: Cancelado
                      </p>
                    ) : null}
                  </>
                ) : (
                  <div className="flex mt-2 xl:mt-0">
                    <select
                      className="bg-purple-900 hover:bg-pruple-700 text-white text-center rounded-lg p-2 leading-tight focus:outline-none focus:shadow-outline xs:text-sm sm:text-sm shadow-lg mr-2"
                      value={pedido.completado}
                      ref={completadoRef}
                      onChange={() => actualizarDisponibilidad()}
                    >
                      <option
                        className=""
                        value="recibido"
                        onClick={handleRecibido}
                      >
                        Recibido
                      </option>
                      <option
                        className=""
                        value="preparando"
                        onClick={handlePreparando}
                      >
                        Preparando
                      </option>
                      <option
                        className=""
                        value="enviado"
                        onClick={handleEnviado}
                      >
                        Enviado
                      </option>
                      <option
                        className=""
                        value="completado"
                        onClick={handleCompletado}
                      >
                        Completado
                      </option>
                      <option
                        className=""
                        value="cancelado"
                        onClick={handleCancelado}
                      >
                        Cancelado
                      </option>
                    </select>
                    <button
                      onClick={mostrarDetalle}
                      className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 p-2 text-sm inline-flex items-center shadow-lg "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                      Ver detalles
                    </button>
                  </div>
                )}
              </div>

              <div className="bg-gray-700 m-4 rounded border-gray-600 divide-y divide-gray-600 shadow-inner ">
                {pedido.orden.map((productos, i) => (
                  <div
                    key={pedido.id + i}
                    className="flex py-2 justify-between "
                  >
                    <div className="flex ml-2  w-2/3 ">
                      <img
                        className="rounded-lg h-10 w-10 shadow-lg"
                        alt=""
                        src={productos.imagen}
                      />

                      <div className="flex flex-col">
                        {productos.sku ? (
                          <p className="text-xs ml-2 text-gray-500">
                            SKU: {productos.sku}
                          </p>
                        ) : (
                          <p className="text-xs ml-2 mt-2 text-gray-500"></p>
                        )}

                        <p className="pl-2 mr-2 xs:text-xs sm:text-sm text-white">
                          {productos.cantidad} x{" "}
                          {new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                          }).format(productos.precio)}{" "}
                          {productos.descuento
                            ? "(" + "-" + productos.descuento + "%" + ")"
                            : null}{" "}
                          {productos.nombre}
                        </p>
                      </div>
                    </div>

                    <p className=" mr-2 mt-2 justify-end xs:text-xs sm:text-sm font-semibold text-white">
                      {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(
                        productos.cantidad *
                          (
                            productos.precio -
                            productos.precio * (productos.descuento / 100)
                          ).toFixed(2)
                      )}
                    </p>
                  </div>
                ))}
              </div>

              {pedido.envio == "gratis" ? (
                <div className="flex justify-end m-4">
                  <div className=" bg-gray-700 p-2 rounded-lg text-sm text-gray-300 ">
                    Gastos de envío: Gratis
                  </div>
                </div>
              ) : null}

              {pedido.envio == "costo" ? (
                <div className="flex justify-end m-4">
                  <div className=" bg-gray-700 p-2 rounded-lg text-sm text-gray-300 ">
                    Gastos de envío: ${pedido.costo_envio.toFixed(2)}
                  </div>
                </div>
              ) : null}

              {pedido.envio == "posicionamiento" ? (
                <div className="flex justify-end m-4">
                  <div className=" bg-gray-700 p-2 rounded-lg text-sm text-gray-300 ">
                    Envío por geoposicionamiento
                  </div>
                </div>
              ) : null}

              {pedido.envio == "noenvio" && Object.keys(pedido).length > 0 ? (
                <div className="flex justify-end m-4">
                  <div className=" bg-gray-700 p-2 rounded-lg text-sm text-gray-300 ">
                    {" "}
                    Entrega física en tienda
                  </div>
                </div>
              ) : null}

              <div className="flex justify-end">
                <div></div>
                <p className="font-bold mr-4 justify-end xs:text-base sm:text-base md:text-base lg:text-lg xl:text-lg bg-green-500 rounded-lg p-2 text-white shadow-xl mb-4">
                  Total MXN{""}
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(pedido.total)}{" "}
                </p>
              </div>

              <div className="sm:flex sm:-mx-2 pl-2"></div>
            </div>
          </div>
        </div>
        {window.location.pathname !== "/mispedidos" ? (
          <iframe className="w-0 h-0">
            <div className=" " ref={componentRef}>
              {" "}
              <PedidoPrint pedido={pedido} />
            </div>
          </iframe>
        ) : null}
      </div>

      {/*              <div className="mb-3">
                  <div className="p-3 shadow-md bg-white">            
                     <h1 className="font-bold mb-2 mt-4 ">Pedido ID: {pedido.id}</h1>  
                     <p className="text-sm">Fecha de pedido: { (new Date(pedido.creado)).toLocaleString() }</p>  
                     <p className="text-sm mb-2">Estado: </p>  


                     {pedido.orden.map( productos => (
                         <p className="text-sm">{productos.cantidad} x {productos.nombre} = MXN {new Intl.NumberFormat("es-MX", { style: 'currency', currency: 'MXN'}).format(productos.total)}</p>
                     ))}

                     <p className="font-bold mt-2">Total: MXN {new Intl.NumberFormat("es-MX", { style: 'currency', currency: 'MXN'}).format(pedido.total)} </p>

                        <button onClick={console.log('Ver detalles')} className="rounded-lg text-white bg-yellow-500 hover:bg-yellow-600 p-2 text-sm inline-flex items-center mt-4">
                        <svg className="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white"><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.21 0-4 1.791-4 4s1.79 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4zm-.004 3.999c-.564.564-1.479.564-2.044 0s-.565-1.48 0-2.044c.564-.564 1.479-.564 2.044 0s.565 1.479 0 2.044z"/></svg>
                            Ver detalles                    
                        </button>   */}

      {/*  TODO PARA DEFINIR EL TIEMPO DE ENTREGA
                    
                    {pedido.tiempoentrega === 0 && (
                         <div>
                             <label className="block text-sm text-gray">Tiempo de entrega</label>
                             <input
                                type="number"
                                className="shadow appearance-none border rounded pl-10 py-2 text-gray-700 leading-tight focus:outline-none focus: shadow-outline"
                                placeholder="0"
                                value={tiempoentrega}
                                onChange={ e => guardarTiempoEntrega( parseInt(e.target.value) )}
                             />
                             <button
                                 onClick={ () => definirTiempo(pedido.id) }
                                 type="submit"
                                 className="rounded-lg text-white bg-blue-500 hover:bg-blue-800 p-3 text-sm mt-3 mb-5 inline-flex items-center ml-5">
                                     Definir tiempo
                             </button>
                         </div>
                     )}
                        {pedido.tiempoentrega > 0 && (<p>Tiempo de entrega: <span>{pedido.tiempoentrega} minutos</span></p>)} */}

      {/*                   </div>    
              </div>   */}
    </>
  );
};

export default Pedido;
