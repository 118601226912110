import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const Redirect = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    console.log(id)    


     useEffect(() => {
        navigate("/producto/"+id)    
     }, [])
    
  return (
    <></>
  )
}

export default Redirect