import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon, ShoppingCartIcon } from "@heroicons/react/outline";
import React, { useState, useContext, Fragment, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FirebaseContext } from "../../firebase";
import Notifications from "../ui/Notifications";
import MenuCategories from "../ui/MenuCategories";
import { useAuth } from "../../context/AuthContext";
import cart from "../../images/cart.png";
import user from "../../images/user.png";
import PedidoContext from "../../context/pedidos/pedidosContext";
import { PEDIDO_ORDENADO } from "../../types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const FrontHeader = ({ toggleDrawer }) => {
  //CONTEXT DEL PEDIDO

  const { pedido, total, mostrarResumen, eliminarProducto, pedidoRealizado } =
    useContext(PedidoContext);

  const [role, setRole] = useState("");

  const { usuario } = useAuth();

  if (usuario) {
    usuario.getIdTokenResult().then((idTokenResult) => {
      //  console.log("ESTÁ HABILITADO", idTokenResult.claims.habilitado);
      //  console.log("SU ROL ES", idTokenResult.claims.role);
      setRole(idTokenResult.claims.role);
    });
  }

  const [cartCount, guardarCartCount] = useState(0);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //    const [setSearchTerm]= useState("");

  const navigate = useNavigate();

  useEffect(() => {
    //Muestra el número de articulos añadidos en el carrito
    let nuevoTotal = 0;

    nuevoTotal = pedido.reduce(
      (nuevoTotal, articulo) => nuevoTotal + articulo.cantidad,
      0
    );

    guardarCartCount(nuevoTotal);

    //nota, se utiliza el JSON.stringify para detectar cambios internos en el arreglo, en este caso el cambio de la cantidad
  }, [JSON.stringify(pedido)]);

  const navigation = [
    { name: "Tienda", href: "#", ruta: "/", current: true },
    { name: "Nosotros", href: "#", ruta: "/nosotros", current: false },
    //  { name: "Ofertas", href: "#", ruta: "/ofertas", current: false },
    { name: "Contacto", href: "#", ruta: "/contacto", current: false },
  ];

  const Logout = () => {
    const activityObj = {
      uidUsuario: usuario.uid,
      activity: "El usuario salió del sistema",
      fecha: Date.now(),
      code: "02",
    };

    //guarda el pedido en el carrito
    firebase.db
      .collection("cart")
      .doc(usuario.uid)
      .set({ ...pedido });

    firebase.auth.signOut().then(() => {
      //registro de actividad

      firebase.db.collection("usuarios_log").add(activityObj);

      //limpiamos el carro de comparas

      pedidoRealizado();

      //redireccionar con useNavigate

      navigate("/login");
    });
  };

  return (
    <div>
      <Disclosure
        as="nav"
        className="bg-gradient-to-tl from-gray-800 via-gray-900 to-black"
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Menu principal</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>

                <div className="flex-shrink-0 flex items-center xs:ml-14">
                  <img
                    onClick={() => {
                      navigate("/");
                    }}
                    className="block lg:hidden h-8 w-auto cursor-pointer"
                    src={cart}
                    alt="Revdev Store"
                  />
                  <img
                    onClick={() => {
                      navigate("/");
                    }}
                    className="hidden lg:block h-8 w-auto cursor-pointer"
                    src={cart}
                    alt="Revdev Store"
                  />
                  <p
                    onClick={() => {
                      navigate("/");
                    }}
                    className="cursor-pointer text-2xl text-white xs:text-base xs:ml-2 md:text-lg xs:hidden"
                  >
                    The Revdev Store
                  </p>
                </div>

                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="hidden sm:block sm:ml-6">
                    <nav className="flex space-x-4">
                      {navigation.map((item, id) => (
                        <div key={id} className="mt-3">
                          {/*                            <a
                                key={item.name}
                                href={item.href}
                                className={classNames(
                                item.current ? 'bg-yellow-500 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'px-3 py-2 rounded-md text-base font-medium'
                                )}
                                aria-current={item.current ? 'page' : undefined}
                            >
                                {item.name}
                            </a>  */}

                          <NavLink
                            key={item.name}
                            to={item.ruta}
                            onClick={() => navigate(item.ruta)}
                            activeClassName="text-white bg-yellow-500"
                            className={classNames(
                              item.current
                                ? "text-white bg-yellow-500"
                                : "text-white hover:bg-yellow-500 bg-gray-800",
                              "p-3 rounded-md text-base"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </NavLink>
                        </div>
                      ))}

                      <MenuCategories mobile={false} />
                    </nav>
                  </div>
                </div>

                {usuario ? (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    {window.location.pathname === "/payment" ||
                    window.location.pathname === "/checkout" ? null : (
                      <>
                        <button
                          onClick={toggleDrawer}
                          className="bg-gray-800 mr-1 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-green-500"
                        >
                          <span className="sr-only">Carro de compras</span>
                          <ShoppingCartIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </button>

                        {cartCount === 0 ? null : (
                          <button
                            className="absolute top-3 ml-5 z-10 bg-green-500 text-xs font-bold px-1  rounded-sm animate-pulse"
                            onClick={toggleDrawer}
                          >
                            {cartCount}
                          </button>
                        )}
                      </>
                    )}

                    <Notifications />

                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative z-50">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                              <span className="sr-only">Open user menu</span>
                              {usuario.photoURL ? (
                                <img
                                  className="h-8 w-8 rounded-full"
                                  alt=""
                                  src={usuario.photoURL}
                                />
                              ) : (
                                <img
                                  className="h-8 w-8 rounded-full"
                                  alt=""
                                  src={user}
                                />
                              )}
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none px-1"
                            >
                              {role === "admin" || role === "owner" ? (
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      onClick={() => navigate("/admin")}
                                      className={classNames(
                                        active
                                          ? "bg-yellow-500 rounded-lg"
                                          : "",
                                        "block px-4 py-2 text-sm text-white hover:text-white cursor-pointer mb-1"
                                      )}
                                    >
                                      Administrar
                                    </a>
                                  )}
                                </Menu.Item>
                              ) : null}
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    onClick={() => navigate("/UserAccount")}
                                    className={classNames(
                                      active ? "bg-yellow-500 rounded-lg" : "",
                                      "block px-4 py-2 text-sm text-gray-300 hover:text-white cursor-pointer"
                                    )}
                                  >
                                    Tu perfil
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    onClick={() => navigate("/mispedidos")}
                                    className={classNames(
                                      active ? "bg-yellow-500 rounded-lg" : "",
                                      "block px-4 py-2 text-sm text-gray-300 hover:text-white cursor-pointer"
                                    )}
                                  >
                                    Mis pedidos
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    onClick={Logout}
                                    className={classNames(
                                      active ? "bg-yellow-500 rounded-lg" : "",
                                      "block px-4 py-2 text-sm text-gray-300 hover:text-white cursor-pointer"
                                    )}
                                  >
                                    Salir
                                  </a>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                ) : (
                  <div className="flex text-white mr-4 ">
                    <p
                      className="cursor-pointer hover:text-yellow-500 text-base"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </p>
                    <p
                      className="cursor-pointer hover:text-yellow-500 ml-4 text-base"
                      onClick={() => navigate("/registro")}
                    >
                      Regístrate
                    </p>
                  </div>
                )}
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 flex-col">
                {navigation.map((item, id) => (
                  /*                   <div key={id}>
                        <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                            item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-yellow-500 hover:text-white',
                            'block px-3 py-2 rounded-md text-base font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                        >
                        {item.name}
                        </a> 

                    <button
                      onClick={() => navigate(item.ruta)}
                      activeClassName="text-white bg-yellow-500"
                      className={classNames(
                        item.current
                          ? "  w-full bg-gray-900 text-white"
                          : " w-full text-gray-300 hover:bg-yellow-500 hover:text-white",
                        "block px-3 py-2 rounded-md text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </button>
                  </div> */

                  <NavLink
                    key={item.name}
                    to={item.ruta}
                    onClick={() => navigate(item.ruta)}
                    activeClassName="text-white bg-yellow-500"
                    className={classNames(
                      item.current
                        ? "  w-full bg-gray-900 text-white"
                        : " w-full text-gray-300 hover:bg-yellow-500 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </NavLink>
                ))}
                <div className="pt-2">
                  <MenuCategories />
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default FrontHeader;
