import React, { useContext, useRef } from "react";
import { FirebaseContext } from "../../firebase";
import { useNavigate, useHistory } from "react-router-dom";
import ProductosContext from "../../context/ProductosContext";
import Swal from "sweetalert2";

const Producto = ({ producto }) => {
  const { productoState, setProductoState } =
    React.useContext(ProductosContext);

  //Tiempo que transcurrio desde el comentario
  // TimeAgo.addDefaultLocale(es);

  //hook para redireccionar
  const navigate = useNavigate();

  //existencia ref para acceder a un valor directamente en el DOM
  const existenciaRef = useRef(producto.existencia);

  //context de firebase para cambio en la db
  const { firebase } = useContext(FirebaseContext);

  const {
    id,
    nombre,
    imagen,
    existencia,
    categoria,
    precio,
    descripcion,
    descuento,
    stock,
    sku,
  } = producto;

  //modificar el estado de disponibilidad en firebase
  const actualizarDisponibilidad = () => {
    const existencia = existenciaRef.current.value === "true";

    try {
      firebase.db.collection("productos").doc(id).update({
        existencia,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditar = () => {
    setProductoState(producto);

    navigate("/editar-producto");
  };

  const handleEliminar = () => {
    Swal.fire({
      title:
        "<p style='color:#fff'>" +
        "¿Estás seguro de eliminar este producto?" +
        "</p>",
      html: '<p style="color:#fff">Los cambios no podrán ser revertidos</p>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#581c87",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar",
      color: "#fff",
      background: "#18181b",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        firebase.db
          .collection("productos")
          .doc(producto.id)
          .delete()
          .then(() => {
            Swal.fire({
              title: "<p style='color:#22c55e'>" + "Eliminado" + "</p>",
              html: '<p style="color:#fff">El producto ha sido eliminado</p>',
              icon: "success",
              color: "#fff",
              background: "#18181b",
            });
          })
          .catch((error) => {
            console.error("Error al eliminar el producto: ", error);
          });
      }
    });
  };

  return (
    <div className="w-full px-3 mt-6 ">
      <div className="mx-auto bg-gray-700 rounded-xl shadow-md hover:shadow-lg overflow-hidden  lg:max-w-screen-xl hover:bg-gray-600 cursor-pointer">
        <div className="lg:flex">
          <div className="lg:w-5/12 xl:w-3/12">
            <img
              className="w-full h-full object-cover lg:v-screen shadow-lg"
              src={imagen}
              alt={nombre}
              onClick={handleEditar}
            />
          </div>
          <div className="lg:w-7/12 xl:w-9/12 pl-5 mb-5">
            <div onClick={handleEditar}>
              <p className="font-bold text-2xl  mb-2 mt-4 lg:ml-5 md:ml-5 xs:ml-0 sm:ml-1 text-white">
                {nombre}
              </p>
              <div className="flex text-gray-300">
                <p className="text-sm font-bold mt-2 lg:ml-5 md:ml-5 xs:ml-0 sm:ml-1">
                  SKU: <span className=" font-normal">{sku}</span>
                </p>
                <p className="text-gray-300  text-sm font-bold mt-2 ml-4">
                  CATEGORÍA:{" "}
                  <span className="text-gray-300 font-normal">{categoria}</span>
                </p>
              </div>

              <p className="text-gray-200 text-sm mt-4 mr-2 lg:ml-5 md:ml-5 xs:ml-0 sm:ml-1">
                {descripcion}
              </p>
              {descuento > 0 ? (
                <>
                  <p className="text-white text-sm mt-4 lg:ml-5 md:ml-5 xs:ml-0 sm:ml-1 bg-green-600 w-36 p-2 rounded-lg self-center flex justify-center font-normal">
                    Descuento:
                    <span className="text-white ml-1 font-normal text-sm">
                      {descuento}%
                    </span>
                  </p>

                  <div className="flex mt-4">
                    <p className="font-normal line-through text-white bg-red-500 lg:ml-5 md:ml-5 xs:ml-0 sm:ml-1 p-1 text-xs rounded-lg self-center">
                      MXN{" "}
                      <span className="text-white font-normal">
                        {new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(precio)}
                      </span>
                    </p>
                    <p className="text-white font-bold ml-4 text-2xl">
                      MXN{" "}
                      <span className="text-white font-bold">
                        {new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(precio - precio * (descuento / 100))}
                      </span>{" "}
                    </p>
                  </div>
                </>
              ) : (
                <p className="text-white mb-2 mt-4 text-2xl font-bold lg:ml-5 md:ml-5 xs:ml-1 sm:ml-1">
                  MXN{" "}
                  <span className="text-white font-bold">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(precio)}
                  </span>
                </p>
              )}
            </div>

            <div className="flex justify-between mt-4 lg:ml-5 md:ml-5 xs:ml-0 sm:ml-1">
              <div className="flex">
                <button
                  onClick={handleEditar}
                  className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 p-2 inline-flex items-center mr-3 xs:text-sm sm:text-sm pr-3 shadow-lg"
                >
                  Editar
                </button>
                <select
                  className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 p-2 inline-flex items-center xs:text-sm sm:text-sm pr-3 shadow-lg text-center"
                  value={existencia}
                  ref={existenciaRef}
                  onChange={() => actualizarDisponibilidad()}
                >
                  <option value="true">Disponible</option>
                  <option value="false">No disponible</option>
                </select>
              </div>

              <button
                onClick={handleEliminar}
                className="rounded-lg text-white bg-red-500 hover:bg-red-600 inline-flex items-center p-2 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base sm:mr-5 xs:mr-3 shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Producto;
