import React, { useContext, useRef, useEffect, useState } from "react";
import { FirebaseContext } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { FirebaseContextRevdev } from "../../firebaseRevdev";
import { useAuth } from "../../context/AuthContext";

export default function Paypal({
  totalPedido,
  pedidoObj,
  meses,
  plan,
  expira,
}) {
  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //Context con las operaciones de firebase de Revdev
  const { firebaseRevdev } = useContext(FirebaseContextRevdev);

  //extraer el usuario de auth
  const { usuario } = useAuth();

  const paypal = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    if (totalPedido > 0) {
      const descripcion =
        "Tu Subscripcion por " +
        meses +
        " meses en el plan " +
        plan +
        " en Revdev Store";
      window.paypal
        .Buttons({
          createOrder: (data, actions, err) => {
            return actions.order.create({
              intent: "CAPTURE",
              purchase_units: [
                {
                  description: descripcion,
                  amount: {
                    currency_code: "MXN",
                    value: totalPedido.toFixed(2),
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            //        console.log(order);

            const pedido = firebase.db
              .collection("suscripcion")
              .add(pedidoObj)
              .then((docRef) => {
                const pedidoDet = {
                  idPedido: docRef.id,
                  plataforma: "paypal",
                  response: order,
                  meses: meses,
                  plan: plan,
                };

                //AÑADE LOS MESES  A LA SUBSCRIPCION
                firebaseRevdev.db
                  .collection("suscripciones")
                  .add(pedidoDet)
                  .then(() => {
                    ///se actualiza la fecha de expiración

                    if (expira < Date.now()) {
                      //Si la fecha ya expiró la nueva fecha será hoy mas lo meses que se está subscribiendo

                      var d = new Date(Date.now());
                      d.setMonth(d.getMonth() + meses);

                      firebaseRevdev.db
                        .collection("customers")
                        .doc(pedidoObj.customerdocid)
                        .update({ expira: d.getTime(), plan: plan })
                        .then(() => {
                          navigate("/confirmacionsuscripcion");
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    } else {
                      //Si la fecha todavía no expira se suma

                      firebaseRevdev.db
                        .collection("customers")
                        .doc(pedidoObj.customerdocid)
                        .update({ expira: expira, plan: plan })
                        .then(() => {
                          navigate("/confirmacionsuscripcion");
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch(function (error) {
                console.log(error);
              });
          },
          onError: (err) => {
            console.log(err);

            navigate("/error");
          },
        })
        .render(paypal.current);
    }
  }, [totalPedido]);

  return (
    <div>
      <div className="flex justify-center" ref={paypal}></div>
    </div>
  );
}
