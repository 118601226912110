import React, { Fragment, useEffect, useContext, useState } from "react";
import { FirebaseContext } from "../../firebase";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MenuCategories = ({ mobile }) => {
  const [loading, setLoading] = useState(false);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //State que guarda las categorias
  const [categorias, guardarCategorias] = useState([]);

  //hook para navegar
  const navigate = useNavigate();

  const [treeData, setTreedata] = useState({
    currentNode: {},
    searchFocusIndex: 0,
    treeData: [
      {
        title: "Default",
        children: [],
      },
    ],
  });

  useEffect(() => {
    setLoading(true);

    //obtiene las categorias
    const db = firebase.db;
    const collectionRef = db.collection("categorias");

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const categorias = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      guardarCategorias(categorias);

      //SI DATOS CONTIENE CATEGORIAS LAS PONE EN EL STATE TREEDATA

      if (Object.keys(categorias).length > 0) {
        setTreedata({ treeData: categorias[0].treeData });
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="flex">
      {/* Profile dropdown */}
      <Menu as="div" className=" relative z-50">
        {({ open }) => (
          <>
              <div className="flex">
                <Menu.Button className="flex rounded-md mr-1 hover:bg-yellow-500 active:bg-yellow-500">
                  <div className="p-3 rounded-md text-base font-medium text-white flex">
                    Categorías
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 mt-1 ml-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>{" "}
                  </div>
                </Menu.Button>
              </div>
          

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute left-0 mt-2 rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none bg-gray-800 overflow-y-auto h-auto  max-h-screen xs:w-80 md:w-96 lg:w-96 xl:w-96"
              >
                {treeData.treeData.map((nodo, id) => {
                  const { title } = nodo;
                  var categoria = title;
                  return (
                    <div className="text-white m-4" key={id}>
                      <p
                        className="font-bold cursor-default hover:text-yellow-500"
                        onClick={() => navigate("/redirectcategoria/" + title)}
                      >
                        {title}
                      </p>

                      {nodo.children ? (
                        <div>
                          {/* SI HAY UN HIJO LO MAPEA */}
                          {nodo.children.map((nodo1, id) => {
                            const { title } = nodo1;
                            const { children } = nodo1;

                            var subcategoria = title;

                            return (
                              <div key={id}>
                                <p
                                  className="font-bold cursor-default hover:text-yellow-500 pl-8"
                                  onClick={() =>
                                    navigate(
                                      "/redirectsubcategoria/" +
                                        categoria +
                                        "/" +
                                        title
                                    )
                                  }
                                >
                                  {title}
                                </p>

                                {children ? (
                                  <div>
                                    {children.map((nodo2, id) => {
                                      const { title } = nodo2;

                                      return (
                                        <div key={id}>
                                          <p
                                            className="font-bold cursor-default hover:text-yellow-500 pl-16"
                                            onClick={() =>
                                              navigate(
                                                "/redirectsubsubcategoria/" +
                                                  categoria +
                                                  "/" +
                                                  subcategoria +
                                                  "/" +
                                                  title
                                              )
                                            }
                                          >
                                            {title}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

export default MenuCategories;
