import React, { useState, useEffect } from "react";
import { InstantSearch, RefinementList, Hits } from 'react-instantsearch-dom';
import MeiliSearch from "meilisearch";

const client = new MeiliSearch({
    host:   "https://ms-45d1aa497ad1-2539.sfo.meilisearch.io",
    headers: {
        Authorization: `Bearer ${"97954f67ba633c88526a831057eab8acc8962660"}`,
        "Content-Type": "application/json",
    },
 });


 const searchClient = new MeiliSearch({
  host: "https://ms-45d1aa497ad1-2539.sfo.meilisearch.io",
  apiKey: "97954f67ba633c88526a831057eab8acc8962660",
});



const SearchMeliSearchExp1 = () => {


  function Hit(props) {
    return (
      <div>
        <h2>{props.hit.name}</h2>
        <p>{props.hit.description}</p>
      </div>
    );
  } 


  return (
    <InstantSearch indexName="productos" searchClient={searchClient}>
      <RefinementList attribute="categoria" />
      <Hits hitComponent={Hit} />
    </InstantSearch> )
}

export default SearchMeliSearchExp1