import React, { useState, useContext, useEffect, useRef } from "react";
import Sidebar from "../ui/Sidebar";
import ReactLoading from "react-loading";
import Notifications from "../ui/Notifications";
import UserMenu from "../ui/UserMenu";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { FirebaseContextRevdev } from "../../firebaseRevdev";
import { FirebaseContext } from "../../firebase";
import SettingsContext from "../../context/SettingsContext";
import _ from "lodash";
import ReactTimeAgo from "react-time-ago";
import toast, { Toaster } from "react-hot-toast";
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from "emoji-picker-react";

const Subscription = () => {
  const messagesEndRef = useRef(null);

  //state que detecta si la pantalla es movil o de escritorio

  const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

  const [loading, setLoading] = useState(false);

  //Context con las operaciones de firebase de Revdev
  const { firebaseRevdev } = useContext(FirebaseContextRevdev);

  //Context con los Settings

  const { settings } = React.useContext(SettingsContext);

  //state datos del cliente
  const [customer, setCustomer] = useState([]);

  //extraer el usuario de auth
  const { usuario } = useAuth();

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const [role, setRole] = useState("");

  const [mensaje, setMensaje] = useState("");

  const [mensajes, setMensajes] = useState([]);

  const [first, setFirst] = useState(true);

  const navigate = useNavigate();

  //EMOJIS

  const [showEmojis, setShowEmojis] = useState(false);

  function onClick(emojiData, event) {
    setMensaje(mensaje + emojiData.emoji);
  }

  useEffect(() => {
    setLoading(true);

    const docRef = firebaseRevdev.db;

    //obtiene la información de la tienda

    const obtenerCustomer = () => {
      if (settings[0].tiendaid) {
        docRef
          .collection("customers")
          .where("tiendaid", "==", settings[0].tiendaid)
          .onSnapshot(manejarSnapshot);
      } else {
        navigate("/");
      }
    };

    //se ejecuta la funcion para obtener datos

    obtenerCustomer();

    function manejarSnapshot(snapshot) {
      let customerSnap = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      setCustomer(customerSnap);
    }

    console.log(usuario);

    usuario.getIdTokenResult().then((idTokenResult) => {
      setRole(idTokenResult.claims.role);
    });

    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);

    const docRef = firebaseRevdev.db;

    //obtiene la información de la tienda

    const obtenerMensajes = () => {
      if (settings[0].tiendaid) {
        docRef
          .collection("soporte")
          .where("tiendaid", "==", settings[0].tiendaid)
          .limit(50)
          .onSnapshot(manejarSnapshot)
      } else {
        navigate("/");
      }
    };

    //se ejecuta la funcion para obtener datos

    obtenerMensajes();

    function manejarSnapshot(snapshot) {
      let mensajesSnap = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      mensajesSnap = _.orderBy(mensajesSnap, "timestamp", "asc");

      setMensajes(mensajesSnap);
      console.log(mensajes);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    //desplaza el scroll hacia abajo de los mensajes
    if (!first) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      window.scrollTo(0, 0);
    }
  }, [mensajes]);

  const sendMessage = () => {
    setFirst(false);

    if (mensaje) {
      const messageObj = {
        mensaje: mensaje,
        photoURL: usuario.photoURL,
        usuario: usuario.displayName,
        timestamp: Date.now(),
        role: role,
        tiendaid: settings[0].tiendaid,
        uidUsuario: usuario.uid,
      };

      console.log(messageObj);

      const dbRef = firebaseRevdev.db;

      dbRef
        .collection("soporte")
        .add(messageObj)
        .then((respuesta) => {
          console.log("done");
          setMensaje("");
        });
    } else {
      toast.error("Escribe un mensaje para enviar", {
        duration: 4000,
        position: "bottom-center",
        style: {
          borderRadius: "10px",
          background: "#000",
          color: "#fff",
        },
      });
    }
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "mensaje":
        setMensaje(e.target.value);
        break;
      default:
        break;
    }
  };

  const sendMessageRevdev = () => {
    const messageObj = {
      mensaje: "holirijilla",
      photoURL: usuario.photoURL,
      usuario: "Soporte Revdev",
      timestamp: Date.now(),
      role: role,
      tiendaid: settings[0].tiendaid,
      uidUsuario: "revdev",
    };

    console.log(messageObj);

    const dbRef = firebaseRevdev.db;

    dbRef
      .collection("soporte")
      .add(messageObj)
      .then((respuesta) => {
        console.log("done");
        setMensaje("");
      });
  };

  const emojis = () => {
    if (showEmojis) {
      setShowEmojis(false);
    }
  };

  return (
    <div className="md:flex min-h-screen">
      <Sidebar />

      {loading ? (
        <div className="md:w-4/5 xl:w-4/5 p-6 self-center justify-center flex ">
          <ReactLoading color={"#3B82F6"} type={"spin"} />
        </div>
      ) : (
        <div className="md:w-4/5 xl:w-4/5 p-4 bg-gray-900 ">
          <div className="relative flex flex-col justify-center items-center bg-gray-900 h-screen">
            <Toaster />

            <div className="w-full ">
              <div className="relative rounded-3xl px-4 bg-gray-700 shadow-md max-w-6xl">
                <div class="flex-1 sm:p-6 justify-between flex flex-col h-screen">
                  <div class="flex sm:items-center justify-between mb-4">
                    <div class="relative flex items-center space-x-4">
                      <div class="relative">
                        <span class="absolute text-green-500 right-0 bottom-0">
                          <svg width="20" height="20">
                            <circle
                              cx="8"
                              cy="8"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </svg>
                        </span>
                        <img
                          src={usuario.photoURL}
                          alt=""
                          class="w-10 sm:w-16 h-10 sm:h-16 rounded-full"
                        />
                      </div>
                      <div class="flex flex-col leading-tight">
                        <div class="text-2xl mt-1 flex items-center">
                          <span class="text-gray-300 mr-3">
                            {usuario.displayName}
                          </span>
                        </div>
                        <span class="text-lg text-gray-300">{role}</span>
                      </div>
                    </div>
                    <div class="flex items-center space-x-2">
                      {/*                       <button
                        type="button"
                        class="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="h-6 w-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          ></path>
                        </svg>
                      </button> */}
                      {/*                       <button
                        type="button"
                        class="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="h-6 w-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                          ></path>
                        </svg>
                      </button> */}
                      {/*                       <button
                        type="button"
                        class="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-300 hover:bg-gray-300 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="h-6 w-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                          ></path>
                        </svg>
                      </button> */}

                      <div className="flex justify-end mb-5">
                        {/*               <div className="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 mt-1 mr-2 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <h1 className="text-3xl text-white">Soporte</h1>
              </div> */}

                        {/* <button
            type="button"
            class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none m-4"
            onClick={sendMessageRevdev}
          >
            <span class="font-bold">
              Enviar
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="h-6 w-6 ml-2 transform rotate-90"
            >
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
            </svg>
          </button> */}

                        <div className="flex">
                          <div className="mt-2">
                            {isStatic ? <Notifications /> : null}
                          </div>
                          <div className=" z-50">
                            {isStatic ? <UserMenu /> : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="messages"
                    className="flex flex-col origin-bottom-right space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch overflow-x-hidden border-2 rounded-lg border-gray-600"
                  >
                    {mensajes.map((mensaje, id) => {
                      return (
                        <>
                          {mensaje.uidUsuario !== "revdev" ? (
                            <div key={id}>
                              <div class="chat-message">
                                <div class="flex items-end">
                                  <div class="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                                    <div class="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">
                                      <p>
                                        {new Date(
                                          mensaje.timestamp
                                        ).toLocaleString()}
                                        {","}
                                        <ReactTimeAgo
                                          className="ml-1 "
                                          date={mensaje.timestamp}
                                          locale="es-MX"
                                        />
                                      </p>
                                      <p className="text-gray-800 xs:text-xs md:text-base">
                                        {mensaje.mensaje}
                                      </p>
                                    </div>
                                  </div>
                                  <img
                                    src={mensaje.photoURL}
                                    alt="My profile"
                                    class="w-6 h-6 rounded-full order-1"
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div key={id}>
                              <div class="chat-message">
                                <div class="flex items-end justify-end">
                                  <div class="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                                    <div class="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">
                                      <p>
                                        {new Date(
                                          mensaje.timestamp
                                        ).toLocaleString()}
                                        {","}
                                        <ReactTimeAgo
                                          className="ml-1 "
                                          date={mensaje.timestamp}
                                          locale="es-MX"
                                        />
                                      </p>
                                      <p className="text-gray-800 xs:text-xs md:text-base">
                                        {mensaje.mensaje}
                                      </p>
                                    </div>
                                  </div>
                                  <img
                                    src={mensaje.photoURL}
                                    alt="My profile"
                                    class="w-6 h-6 rounded-full order-1"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                    <div ref={messagesEndRef} />
                  </div>

                  {showEmojis ? (
                    <div className="absolute bottom-24 right-2">
                      <svg
                        onClick={emojis}
                        className="w-6 h-6 mb-1 fill-current stroke-current text-blue-500 hover:fill-current hover:stroke-current hover:text-blue-600"
                        clip-rule="evenodd"
                        fill-rule="evenodd"
                        stroke-linejoin="round"
                        stroke-miterlimit="2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                          fill-rule="nonzero"
                        />
                      </svg>
                      <EmojiPicker
                        onEmojiClick={onClick}
                        autoFocusSearch={false}
                        skinTonesDisabled
                        searchPlaceHolder="Buscar"
                      />
                    </div>
                  ) : null}
                  <div class=" pt-4 mb-4">
                    <div class="relative flex">
                      {/*                       <span class="absolute inset-y-0 flex items-center">
                        <button
                          type="button"
                          class="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="h-6 w-6 text-gray-600"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                            ></path>
                          </svg>
                        </button>
                      </span> */}
                      <textarea
                        type="text"
                        rows="1"
                        placeholder="Escribe un mensaje"
                        class="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-2 bg-gray-200 rounded-md py-3 xs:text-xs sm:text-sm md:text-base lg:text-base h-12 xs:pr-32"
                        value={mensaje}
                        name="mensaje"
                        onChange={handleChange}
                      />
                      <div class="absolute right-0 items-center inset-y-0 sm:flex">
{/*                         <button
                          type="button"
                          class="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="h-6 w-6 text-gray-600"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                            ></path>
                          </svg>
                        </button> */}
{/*                         <button
                          type="button"
                          class="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="h-6 w-6 text-gray-600"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                            ></path>
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                            ></path>
                          </svg>
                        </button> */}

                        <button
                          type="button"
                          class="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none self-center"
                          onClick={() => {
                            setShowEmojis(!showEmojis);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="h-6 w-6 text-gray-600"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>
                        </button>

                        <button
                          type="button"
                          class="inline-flex items-center justify-center rounded-md px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-600 focus:outline-none xs:text-xs lg:text-base "
                          onClick={sendMessage}
                        >
                          <span class="font-bold">Enviar</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-6 w-6 ml-2 transform rotate-90"
                          >
                            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscription;
