import React, { useContext, useState, useEffect } from "react";
import { FirebaseContext } from "../../firebase";
import Ratings from "react-ratings-declarative";

const RatingFront = ({ id }) => {

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //state de rating

  const [ratingGlobal, establecerRatingGlobal] = useState([]);
  const [ratingGlobalNum, establecerRatingGlobalNum] = useState(0);



  useEffect(() => {
    
    //obtiene ranking global del producto y calcula su promedio
    const db = firebase.db
    const collectionRef = db.collection('ratings').where("id", "==", id)

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const ratingGlobal = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      establecerRatingGlobal(ratingGlobal);

      let sum = 0;

      if (ratingGlobal.length > 0) {
        ratingGlobal.forEach((element) => {
          sum += element.rating;
        });

        establecerRatingGlobalNum(sum / ratingGlobal.length);
      } else {
        establecerRatingGlobalNum(0);
      }

    });

    return () => unsubscribe();
  }, []);

  
  return (
    <>
      <div className="flex flex-col justify-center m-1">
        {/* <p className="mr-2 font-semibold text-gray-800"> {ratingGlobalNum.toFixed(1)} </p> */}
        <div className="-mt-3">
        <Ratings
          rating={ratingGlobalNum}
          widgetRatedColors="#F59E0B"
          widgetHoverColors="#D97706"
          widgetDimensions="20px"
          widgetSpacings="1px"
        >
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
        </Ratings>
        </div>
         <p className="ml-2 text-xs mb-2">de {ratingGlobal.length} calificaciones</p>
      </div>     

    </>
  );
};

export default RatingFront;
