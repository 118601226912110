import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../ui/Sidebar";
import ReactLoading from "react-loading";
import { FirebaseContext } from "../../firebase";
import Usuario from "../paginas/Usuario";
import UserMenu from "../ui/UserMenu";
import Notifications from "../ui/Notifications";
import { useMediaQuery } from "react-responsive";

const Usuarios = () => {
  //state que detecta si la pantalla es movil o de escritorio
  const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

  const [loading, setLoading] = useState(false);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const [usuarios, guardarUsuarios] = useState([]);

  const [searchterm, setSearchTerm] = useState("");

  useEffect(() => {
    const obtenerUsuarios = () => {
      setLoading(true);

      firebase.db.collection("usuarios").onSnapshot(manejarSnapshot);
    };
    obtenerUsuarios();
  }, []);

  function manejarSnapshot(snapshot) {
    let usuarios = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    guardarUsuarios(usuarios);

    setLoading(false);
  }

  return (
    <div className="md:flex min-h-screen">
      <Sidebar />

      {loading === true ? (
        <div className="md:w-4/5 xl:w-4/5 bg-gray-900 flex justify-center items-center h-screen">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="md:w-4/5 xl:w-4/5 p-4 bg-gray-900">
          <header>
            <div className="flex justify-end">
{/*               <div className="flex text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 mt-1 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>

                <h1 className="text-3xl">Usuarios</h1>
              </div> */}
              <div className="flex">
                <div className="mt-2">
                  {isStatic ? <Notifications /> : null}
                </div>
                <div className=" z-50">{isStatic ? <UserMenu /> : null}</div>
              </div>
            </div>

            <div className="md:flex text-sm lg:justify-end mb-8 xs:justify-start mt-2">
              <div className="relative">
                <input
                  type="text"
                  className="p-2 pl-8 rounded ring-2 ring-yellow-600 border-2 focus:bg-white focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:border-transparent w-full shadow-xl"
                  placeholder="Nombre de usuario
                        "
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />
                <svg
                  className="w-5 h-5 absolute left-2.5 top-3"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#1f2937"
                  width='24px' height='24px' fill='#1f2937'  x='0px' y='0px' viewBox='0 -1 64 64' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3'
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23 s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92 c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17 s-17-7.626-17-17S14.61,6,23.984,6z"
                  />
                </svg>
              </div>
            </div>
          </header>

          {usuarios
            .filter((usuario) => {
              if (searchterm == "") {
                return usuario;
              } else if (
                usuario.nombre.toLowerCase().includes(searchterm.toLowerCase())
              ) {
                return usuario;
              }
            })
            .map((usuario, id) => {
              return (
                <div key={usuario.id} className="text-lg mt-4 mb-4">
                  <Usuario key={usuario.id} usuario={usuario} />
                </div>
              );
            })}

          {/*                       <div className="text-lg mt-4 mb-4">                                  
                            {usuarios.map( usuario => (
                                <Usuario
                                    key={usuario.id}
                                    usuario={usuario}
                                />
                            ))}
                      </div>   */}
        </div>
      )}
    </div>
  );
};

export default Usuarios;
