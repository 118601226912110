import React, { useContext, useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FirebaseContext } from "../../firebase";
import { useNavigate } from "react-router-dom";
import Sidebar from "../ui/Sidebar";
import { useAuth } from "../../context/AuthContext";
import ProductosContext from "../../context/ProductosContext";
import Swal from "sweetalert2";
import CurrencyInput from "react-currency-input-field";
import "reactjs-popup/dist/index.css";
import "../ui/css/popup.css";
import ReactTooltip from "react-tooltip";
import ImagesArray from "../ui/ImagesArray";
import { Prompt } from "react-router-dom";
import SettingsContext from "../../context/SettingsContext";
import notfound from "../../images/not-found.png";

const EditarProducto = ({ producto }) => {
  //Context con los Settings
  const { settings } = useContext(SettingsContext);

  //state que detecta cambios en el form
  const [changes, setChanges] = useState(false);

  //state para las imagenes

  //imagen principal
  const [urlimagen, guardarUrlImagen] = useState("");
  const [altText, setAltText] = useState("");
  const [title, setTitle] = useState("");

  //resto de imagenes
  const [images, setImages] = useState([]);

  const { productoState, setProductoState } =
    React.useContext(ProductosContext);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //extraer el usuario de auth
  const { usuario } = useAuth();

  //hook para redireccionar
  const navigate = useNavigate();

  //State de edicion
  const [editando, establecerEditando] = useState(false);

  const [loading, setLoading] = useState(false);

  //State que guarda las categorias
  const [datos, guardarDatos] = useState([]);

  //state de las categoria y subcategorias
  const [categoria, guardarCategoria] = useState({
    filtro1: productoState.categoria,
  });
  const [subcategoria, guardarSubcategoria] = useState({
    filtro2: productoState.subcategoria,
  });
  const [subsubcategoria, guardarSubsubcategoria] = useState({
    filtro3: productoState.subsubcategoria,
  });

  //extraer del state filtro1
  const { filtro1 } = categoria;

  //extraer del state filtro2
  const { filtro2 } = subcategoria;

  const [subcategoriaTree, guardarSubcategoriaTree] = useState({});

  //extraer del state filtro3
  const { filtro3 } = subsubcategoria;

  const [subsubcategoriaTree, guardarSubsubcategoriaTree] = useState({});

  //State de las categorias en arbol

  const [treeData, setTreedata] = useState({
    currentNode: {},
    searchFocusIndex: 0,
    treeData: [
      {
        title: "Default",
        children: [],
      },
    ],
  });

  useEffect(() => {
    setLoading(true);

    window.scrollTo(0, 0);

    const obtenerDatos = () => {
      firebase.db.collection("categorias").onSnapshot(manejarSnapshot);
    };

    //se ejecuta la funcion para obtener datos, en este caso los datos son las CATEGORIAS

    obtenerDatos();

    function manejarSnapshot(snapshot) {
      let datos = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      guardarDatos(datos);

      //SI DATOS CONTIENE CATEGORIAS LAS PONE EN EL STATE TREEDATA

      if (Object.keys(datos).length > 0) {
        setTreedata({ treeData: datos[0].treeData });
      }

      // console.log(treeData)

      setLoading(false);
    }
  }, []);

  useEffect(() => {
    guardarUrlImagen(productoState.imagen);
    setAltText(productoState.altText);
    setTitle(productoState.title);

    console.log(altText);
    console.log(title);

    setImages(productoState.images);

    setIsChecked(productoState.noAplicaStock);
  }, []);

  useEffect(() => {
    //POPULA LAS CATEGORIAS AL INICIO

    //copia el actual arreglo de categorias

    const miArbol = [treeData.treeData];

    //   console.log(miArbol);

    miArbol.map((sub, index) => {
      //  console.log(sub);

      sub.map((subsub) => {
        if (subsub.title == productoState.categoria) {
          guardarSubcategoriaTree(subsub.children);

          if (Object.keys(subcategoriaTree).length > 0) {
            subcategoriaTree.map((sub, index) => {
              if (sub.title == productoState.subcategoria) {
                guardarSubsubcategoriaTree(sub.children);
              }
            });
          }
        }
      });
    });
  }, [treeData, loading]);

  //las siguientes funciones handlechange y handleOnBlur así como el estado value son exclusivas del input del precio para dar formato moneda

  const [value, setValue] = useState(0);

  useEffect(() => {
    if (productoState.precio) {
      setValue(Number(productoState.precio).toFixed(2));
    } else {
    }
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setChanges(true);
    const { value = "" } = e.target;
    const parsedValue = value.replace(/[^\d.]/gi, "");
    setValue(parsedValue);
    formik.values.precio = Number(parsedValue);
  };

  const handleOnBlur = () => setValue(Number(value).toFixed(2));

  //validacion y leer los datos del form

  const formik = useFormik({
    initialValues: {
      nombre: productoState.nombre,
      sku: productoState.sku,
      marca: productoState.marca,
      precio: productoState.precio,
      // categoria: productoState.categoria,
      descripcion: productoState.descripcion,
      descuento: productoState.descuento,
      stock: productoState.stock,
      hashtag: productoState.hashtag,
      facebook: productoState.facebook,
      twitter: productoState.twitter,
      instagram: productoState.instagram,
      noAplicaStock: productoState.noAplicaStock,
      created: productoState.created,
      existencia: productoState.existencia,
      keywords: productoState.keywords,
      titleSEO: productoState.titleSEO,
      slug: productoState.slug,
      description: productoState.description,
    },
    validationSchema: Yup.object({
      nombre: Yup.string()
        .min(3, "El producto tiene que tener al menos 3 caracteres")
        .max(150, "El nombre del producto no puede pasar de 150 caracteres")
        .required("El nombre del producto es obligatorio"),
      precio: Yup.number()
        .min(1, "Debes agregar un número")
        .max(99999.99, "El limite es de MXN$99,999.99")
        .required("El precio del producto es obligatorio"),
      // categoria: Yup.string().required("La categoria es obligatoria"),
      descripcion: Yup.string()
        .min(10, "La descipción es de minimo 10 caracteres")
        .max(500, "La descripción no puede pasar de 500 caracteres")
        .required("La descripcion es obligatoria"),
      descuento: Yup.number()
        .min(0, "El descuento debe ser mayor o igual a 0")
        .max(99, "No se puede aplicar un descuento mayor a 99%"),
      stock: Yup.number().min(0, "El stock debe ser mayor a 0"),
      hashtag: Yup.string().max(
        150,
        "El o los hashtag del producto no puede ser mayor a 150 caracteres"
      ),
    }),
    onSubmit: (producto) => {
      try {
        //ESTA VALIDACION ES POR QUE FIREBASE NO SOPORTA VALORES UNDEFINED, AQUI VAN LOS CAMPOS OPCIONALES Y LOS VALORES DEBEN SER CORROBORADOS O TIRA UN ERROR

        producto.uidUsuario = usuario.uid;

        if (producto.created === undefined) {
          producto.created = Date();
        }

        producto.lastmod = Date();

        producto.existencia = true;

        producto.categoria = filtro1;

        if (filtro2 === "" || filtro2 === undefined) {
          producto.subcategoria = "";
        } else {
          producto.subcategoria = filtro2;
        }

        if (filtro3 === "" || filtro3 === undefined) {
          producto.subsubcategoria = "";
        } else {
          producto.subsubcategoria = filtro3;
        }

        if (altText === "" || altText === undefined) {
          //      console.log("no hay foto");
          producto.altText = "";
        } else {
          producto.altText = altText;
        }

        if (title === "" || title === undefined) {
          producto.title = "";
        } else {
          producto.title = title;
        }

        if (producto.noAplicaStock === undefined) {
          producto.noAplicaStock = false;
        } else {
          producto.noAplicaStock = isChecked;
        }

        if (producto.sku === undefined) {
          producto.sku = "";
        }

        //IMÁGENES
        if (urlimagen === "" || urlimagen === undefined) {
          //      console.log("no hay foto");
          producto.imagen = "";
        } else {
          producto.imagen = urlimagen;
        }

        if (images === "" || images === undefined) {
          producto.images = [];
        } else {
          producto.images = images;
        }

        //REDES SOCIALES

        if (producto.hashtag === undefined) {
          producto.hashtag = "";
        }

        if (producto.facebook === undefined) {
          producto.facebook = "";
        }

        if (producto.twitter === undefined) {
          producto.twitter = "";
        }

        if (producto.instagram === undefined) {
          producto.instagram = "";
        }

        if (producto.marca === undefined) {
          producto.marca = "";
        }

        //SEO

        if (producto.keywords === undefined) {
          producto.keywords = "";
        }

        if (producto.titleSEO === undefined) {
          producto.titleSEO = "";
        }

        if (producto.slug === undefined) {
          producto.slug = "";
        }

        if (producto.description === undefined) {
          producto.description = "";
        }

        // ojo aqui, es mayor a 2 por que el productoState que se trae ya tiene 2 elementos por default que es stock y descuento inicializados en 0
        // si cumple esto estoy editando
        if (productoState.id) {
          firebase.db
            .collection("productos")
            .doc(productoState.id)
            .update(producto);

          Swal.fire({
            title: "<p style='color:#22c55e'>" + "Guardado" + "</p>",
            html: '<p style="color:#fff">El producto se actualizó correctamente</p>',
            icon: "success",
            color: "#fff",
            background: "#18181b",
            position: "top-end",
            iconColor: "#22c55e",
            timer: 1500,
            showConfirmButton: false,
          });
        } else {
          firebase.db.collection("productos").add(producto);

          Swal.fire({
            title: "<p style='color:#22c55e'>" + "Guardado" + "</p>",
            html: '<p style="color:#fff">El producto se añadió correctamente</p>',
            icon: "success",
            color: "#fff",
            background: "#18181b",
            position: "top-end",
            iconColor: "#22c55e",
            timer: 1500,
            showConfirmButton: false,
          });

          navigate("/catalogo");
        }

        setChanges(false);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  //TODO SOBRE LAS CATEGORIAS

  const seleccionCategoria = (e) => {
    setChanges(true);

    guardarCategoria({ ...categoria, [e.target.name]: e.target.value });

    //  guardarSubsubcategoria("");

    guardarSubcategoria("");
    guardarSubsubcategoria("");
    guardarSubcategoria({ filtro2: "" });
    guardarSubsubcategoria({ filtro3: "" });
    guardarSubcategoriaTree({});
    guardarSubsubcategoriaTree({});

    //copia el actual arreglo de categorias
    const miArbol = [treeData.treeData];

    miArbol.map((sub, index) => {
      sub.map((subsub) => {
        if (subsub.title == e.target.value) {
          guardarSubcategoriaTree(subsub.children);
        }
      });
    });
  };

  const seleccionSubcategoria = (e) => {
    setChanges(true);

    guardarSubcategoria({ ...subcategoria, [e.target.name]: e.target.value });

    guardarSubsubcategoria("");
    guardarSubsubcategoria({ filtro3: "" });
    guardarSubsubcategoriaTree({});

    subcategoriaTree.map((sub, index) => {
      if (sub.title == e.target.value) {
        guardarSubsubcategoriaTree(sub.children);
      }
    });
  };

  const seleccionSubsubcategoria = (e) => {
    setChanges(true);

    guardarSubsubcategoria({
      ...subsubcategoria,
      [e.target.name]: e.target.value,
    });

    subcategoriaTree.map((sub, index) => {
      if (sub.title == e.target.value) {
        guardarSubsubcategoriaTree(sub.children);
      }
    });
  };

  //checkbox del stock

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChanges(true);
    setIsChecked(event.target.checked);
  };

  const handleEliminar = () => {
    Swal.fire({
      title:
        "<p style='color:#fff'>" +
        "¿Estás seguro de eliminar este producto?" +
        "</p>",
      html: '<p style="color:#fff">Los cambios no podrán ser revertidos</p>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#581c87",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar",
      color: "#fff",
      background: "#18181b",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        firebase.db
          .collection("productos")
          .doc(productoState.id)
          .delete()
          .then(() => {
            Swal.fire({
              title: "<p style='color:#22c55e'>" + "Eliminado" + "</p>",
              html: '<p style="color:#fff">El producto ha sido eliminado</p>',
              icon: "success",
              color: "#fff",
              background: "#18181b",
            });

            navigate("/catalogo");
          })
          .catch((error) => {
            console.error("Error al eliminar el producto: ", error);

            Swal.fire({
              title:
                "<p style='color:#22c55{e'>" +
                "Error al eliminar el producto" +
                "</p>",
              html: '<p style="color:#fff">{error}</p>',
              icon: "error",
              color: "#fff",
              background: "#18181b",
            });
          });
      }
    });
  };

  //modificar el estado de disponibilidad en firebase
  const actualizarDisponibilidad = () => {
    /* 
    try {
      firebase.db.collection("productos").doc(productoState.id).update({
        existencia,
      });


    } catch (error) {
      console.log(error);
    } */
  };

  return (
    <div className="md:flex min-h-screen bg-gray-900">
      <Sidebar />
      <ReactTooltip />

      <Prompt
        when={changes}
        message="¿Estás seguro de que deseas abandonar la página? Tus cambios no se guardarán."
      />

      <div className="md:w-4/5 xl:w-4/5 p-4 bg-gray-800 shadow-md rounded-xl box-border m-4 ">
        <div className="flex justify-end mb-4">
          <select
            className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 p-2 inline-flex items-center xs:text-sm sm:text-sm pr-3 shadow-lg text-center mr-2"
            value={formik.values.existencia}
            //     ref={existenciaRef}
            onChange={() => actualizarDisponibilidad()}
          >
            <option value="true">Disponible</option>
            <option value="false">No disponible</option>
          </select>
          <button
            className="rounded-lg text-white bg-red-500 hover:bg-red-400 p-2 inline-flex items-center xs:text-sm sm:text-sm pr-2 shadow-lg"
            onClick={handleEliminar}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </button>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="lg:flex">
            <div className="rounded-lg px-4 py-4 shadow-md bg-gray-700 lg:w-2/3 lg:mr-4 mb-4 lg:mb-0">
              <p className="text-2xl ml-4 mt-2 xs:ml-0 sm:ml-0 md:ml-4 text-white">
                Detalles del producto
              </p>

              <div className="w-full md:p-4 text-gray-300">
                <div className="mb-4">
                  <label
                    className="mt-4 font-semibold text-sm"
                    htmlFor="nombre"
                  >
                    Nombre
                  </label>
                  <input
                    className="border border-gray-400 p-3 rounded w-full leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 mt-2 transition-shadow xs:text-xs sm:text-sm md:text-base"
                    id="nombre"
                    type="text"
                    placeholder="Nombre del producto"
                    value={formik.values.nombre}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></input>
                </div>
                {formik.touched.nombre && formik.errors.nombre ? (
                  <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-5 role='alert' ">
                    <p className="font-bold">Este campo es obligatorio</p>
                    <p>{formik.errors.nombre}</p>
                  </div>
                ) : null}

                <div className="mb-4">
                  <label
                    className="block mb-2 font-semibold text-sm"
                    htmlFor="sku"
                  >
                    SKU (opcional)
                  </label>
                  <input
                    className="md:w-1/2 lg:w-2/6  border border-gray-400 p-3 rounded  leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 transition-shadow xs:text-xs sm:text-sm md:text-base"
                    id="sku"
                    type="text"
                    placeholder="SKU"
                    value={formik.values.sku}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></input>
                </div>

                <div className="mb-4">
                  <label
                    className="block mb-2 font-semibold text-sm"
                    htmlFor="marca"
                  >
                    Marca (opcional)
                  </label>
                  <input
                    className="md:w-1/2 lg:w-2/6  border border-gray-400 p-3 rounded leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 transition-shadow xs:text-xs sm:text-sm md:text-base"
                    id="marca"
                    type="text"
                    placeholder="Marca"
                    value={formik.values.marca}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></input>
                </div>

                <div className="flex flex-wrap">
                  <div className="mb-4">
                    <label
                      className="block mb-2 font-semibold text-sm"
                      htmlFor="precio"
                    >
                      Precio
                    </label>
                    <CurrencyInput
                      className="border border-gray-400 p-3 rounded text-base  placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 self-center mr-2 transition-shadow"
                      prefix={"$"}
                      name="currencyInput"
                      id="currencyInput"
                      data-number-to-fixed="2"
                      data-number-stepfactor="100"
                      placeholder="$0.00"
                      value={value}
                      onChange={handleChange}
                      onBlur={handleOnBlur}
                      allowDecimals
                      decimalsLimit="2"
                      disableAbbreviations
                    />

                    {/*                     <input
                      className="border border-gray-400 p-3 rounded text-base  placeholder-gray-400 text-gray-700 focus:ring-blue-500 focus:ring-2 self-center mr-2 transition-shadow"
                      id="precio"
                      type="number"
                      placeholder="$0.00"
                      step="0.01"
                      min="0"
                      value={formik.values.precio}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur} 
                    ></input> */}
                    {formik.touched.nombre && formik.errors.precio ? (
                      <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-2 role='alert' ">
                        <p className="font-bold">Hubo un error</p>
                        <p>{formik.errors.precio}</p>
                      </div>
                    ) : null}
                  </div>

                  <div className="mb-4">
                    <label
                      className="block mb-2 font-semibold text-sm"
                      htmlFor="descuento"
                    >
                      Descuento
                    </label>
                    <input
                      className="w-20 border border-gray-400 p-3 rounded text-base  placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 self-center mr-2 transition-shadow"
                      id="descuento"
                      type="number"
                      placeholder="0%"
                      step="1"
                      min="0"
                      value={formik.values.descuento}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></input>
                  </div>
                </div>
                {formik.touched.descuento && formik.errors.descuento ? (
                  <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-5 role='alert' ">
                    <p className="font-bold">Hubo un error</p>
                    <p>{formik.errors.descuento}</p>
                  </div>
                ) : null}

                {formik.values.descuento > 0 && formik.values.precio > 0 ? (
                  <div className="flex mb-4 bg-green-600 p-4 rounded-lg">
                    <label className="text-sm text-white font-semibold ">
                      Precio con descuento:
                    </label>
                    <p className="font-semibold text-sm ml-1 text-white">
                      $
                      {(
                        formik.values.precio -
                        (formik.values.precio * formik.values.descuento) / 100
                      ).toFixed(2)}
                    </p>
                  </div>
                ) : null}

                <div className="mb-2">
                  <label
                    className="block mb-2 font-semibold text-sm "
                    htmlFor="stock"
                  >
                    Stock
                  </label>
                  <div className="flex">
                    <input
                      className=" w-2/5 lg:w-28 xl:w-28 border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 transition-shadow"
                      id="stock"
                      type="number"
                      placeholder="0"
                      min="0"
                      value={formik.values.stock}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={isChecked}
                    ></input>

                    <div className="self-center ml-4">
                      <label>
                        <input
                          type="checkbox"
                          value={formik.values.noAplicaStock}
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />{" "}
                        No aplica stock
                      </label>
                    </div>

                    <svg
                      data-tip="Marca esta casilla si deseas que no se tenga ninguna limitación y se pueda consumir indefinidamente el producto."
                      alt="tooltip"
                      className="ml-2 self-center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke="white"
                      fill="white"
                      strokeWidth=".1"
                    >
                      <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
                    </svg>
                  </div>
                </div>

                {/* MAPEA LAS CATEGORIAS */}
                <div className="lg:flex mb-4">
                  <div className="">
                    <label
                      className="block mb-2 mt-2 font-semibold text-sm"
                      htmlFor="categoria"
                    >
                      Categoria
                    </label>
                    <select
                      className="border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 bg-white mr-2 transition-shadow"
                      onChange={seleccionCategoria}
                      name="filtro1"
                      value={filtro1}
                      id="filtro1"

                      /*               id="categoria"
                        name="categoria"
                        value={formik.values.categoria} 
                        onChange={formik.handleChange}
                      onBlur={formik.handleBlur} 
                              */
                    >
                      <option value="">-Selecciona una categoría-</option>

                      {treeData.treeData.map((nodo, id) => {
                        const { title } = nodo;
                        return (
                          <option key={id} value={title}>
                            {title}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {subcategoriaTree !== undefined ? (
                    <div>
                      {subcategoriaTree.length > 0 ? (
                        <div>
                          <label
                            className="block mb-2 mt-2 font-semibold text-sm"
                            htmlFor="categoria"
                          >
                            Subcategoria
                          </label>
                          <select
                            className="border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 bg-white mr-2 transition-shadow"
                            onChange={seleccionSubcategoria}
                            name="filtro2"
                            value={filtro2}
                            id="filtro2"
                          >
                            <option value="">-Selecciona una categoría-</option>

                            {subcategoriaTree.map((nodo) => {
                              const { title } = nodo;
                              return <option value={title}>{title}</option>;
                            })}
                          </select>
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {subsubcategoriaTree !== undefined &&
                  subcategoriaTree !== undefined ? (
                    <div>
                      {subsubcategoriaTree.length > 0 &&
                      subcategoriaTree.length > 0 ? (
                        <div className="">
                          <label
                            className="block mb-2 mt-2 font-semibold text-sm"
                            htmlFor="categoria"
                          >
                            Subsubcategoria
                          </label>
                          <select
                            className="border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 bg-white mr-2 transition-shadow"
                            onChange={seleccionSubsubcategoria}
                            name="filtro3"
                            value={filtro3}
                            id="filtro3"
                          >
                            <option className="" value="">
                              -Selecciona una categoría-
                            </option>

                            {subsubcategoriaTree.map((nodo) => {
                              const { title } = nodo;
                              return <option value={title}>{title}</option>;
                            })}
                          </select>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>

                {formik.touched.nombre && formik.errors.categoria ? (
                  <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-5 role='alert' ">
                    <p className="font-bold">Hubo un error</p>
                    <p>{formik.errors.categoria}</p>
                  </div>
                ) : null}

                <div className="mb-4">
                  <label
                    className="block mb-2 font-semibold text-sm"
                    htmlFor="descripcion"
                  >
                    Descripción
                  </label>
                  <textarea
                    className="border border-gray-400 p-3 rounded leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 w-full transition-shadow xs:text-xs sm:text-sm md:text-base"
                    id="descripcion"
                    placeholder="Descripción del producto"
                    value={formik.values.descripcion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                </div>
                {formik.touched.nombre && formik.errors.descripcion ? (
                  <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-5 role='alert' ">
                    <p className="font-bold">Hubo un error</p>
                    <p>{formik.errors.descripcion}</p>
                  </div>
                ) : null}
              </div>
            </div>

            <ImagesArray
              urlimagen={urlimagen}
              guardarUrlImagen={guardarUrlImagen}
              title={title}
              setTitle={setTitle}
              altText={altText}
              setAltText={setAltText}
              images={images}
              setImages={setImages}
              setChanges={setChanges}
            />
          </div>

          <div className="mb-4 mt-8 lg:flex">
            <div className="rounded-lg px-4 py-4 shadow-md bg-gray-700 lg:w-2/3 lg:mr-4 mb-4 lg:mb-0">
              <p className="text-2xl ml-4 mt-2 xs:ml-0 sm:ml-0 md:ml-4 text-white">
                Redes Sociales
              </p>

              <div className="lg:ml-4 lg:mr-4 text-gray-300">
                <label
                  className="block mt-2 font-semibold text-sm"
                  htmlFor="hashtag"
                >
                  Hashtags
                </label>
                <input
                  className="border border-gray-400 p-3 rounded w-full leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 mt-2 transition-shadow xs:text-xs sm:text-sm md:text-base"
                  id="hashtag"
                  type="text"
                  placeholder="#ejemplo, #otro"
                  value={formik.values.hashtag}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></input>
              </div>

              <div className="lg:ml-4 lg:mr-4 text-gray-300">
                <label
                  className="block mt-2 font-semibold text-sm"
                  htmlFor="facebook"
                >
                  Facebook
                </label>
                <input
                  className="border border-gray-400 p-3 rounded w-full leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 mt-2 transition-shadow xs:text-xs sm:text-sm md:text-base"
                  id="facebook"
                  type="text"
                  placeholder="Ejemplo: PaginaFacebook"
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></input>
              </div>

              <div className="lg:ml-4 lg:mr-4 text-gray-300">
                <label
                  className="block mt-2 font-semibold text-sm"
                  s
                  htmlFor="twitter"
                >
                  Twitter
                </label>
                <input
                  className="border border-gray-400 p-3 rounded w-full leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 mt-2 transition-shadow xs:text-xs sm:text-sm md:text-base"
                  id="twitter"
                  type="text"
                  placeholder="Ejemplo: UsuarioTwitter"
                  value={formik.values.twitter}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></input>
              </div>

              <div className="lg:ml-4 lg:mr-4 text-gray-300">
                <label
                  className="block mt-2 font-semibold text-sm"
                  S
                  htmlFor="instagram"
                >
                  Instagram
                </label>
                <input
                  className="border border-gray-400 p-3 rounded w-full leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 mt-2 transition-shadow xs:text-xs sm:text-sm md:text-base mb-4"
                  id="instagram"
                  type="text"
                  placeholder="Ejemplo: UsuarioInstagram"
                  value={formik.values.instagram}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></input>
              </div>
            </div>

            <div className="rounded-lg px-4 py-4 shadow-md bg-gray-700 lg:w-2/3  mb-4 lg:mb-0">
              <p className="text-2xl ml-4 mt-2 xs:ml-0 sm:ml-0 md:ml-4 text-white">
                SEO
              </p>
              <div className="lg:ml-4 lg:mr-4 text-gray-300">
                <label className="block mt-2 font-semibold text-sm">
                  Vista previa en buscadores
                </label>
              </div>
              <div className="p-8 bg-white m-4 rounded-md ">
                {formik.values.slug ? (
                  <div className=" text-gray-600 text-sm ">
                    {window.location.host}/
                    {formik.values.slug.replace(/ /g, "-")}
                  </div>
                ) : null}

                <div className=" text-2xl  text-blue-500 mt-4 ">
                  {formik.values.titleSEO} - {settings[0].nombreTienda}
                </div>

                <div className="flex mt-2">
                  {formik.values.description ? (
                    <div className=" text-gray-600 text-sm w-2/3 mr-4 ">
                      {formik.values.description.slice(0, 156)}
                      {formik.values.description.length > 156 ? <>...</> : null}
                    </div>
                  ) : null}

                  {urlimagen === "" || urlimagen === undefined ? (
                    <div className="flex justify-center">
                      <img
                        className="ring-1 ring-yellow-500 rounded-md w-1/3"
                        src={notfound}
                        alt="Imagen no encontrada"
                      />
                    </div>
                  ) : (
                    <img
                      className="rounded-md w-1/3"
                      src={urlimagen}
                      alt={altText}
                    />
                  )}
                  <div></div>
                </div>
              </div>

              <div className="lg:ml-4 lg:mr-4 text-gray-300">
                <label className="block mt-2 font-semibold text-sm">
                  Palabras clave
                </label>
                <input
                  className="border border-gray-400 p-3 rounded w-full leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 mt-2 transition-shadow xs:text-xs sm:text-sm md:text-base"
                  id="keywords"
                  type="text"
                  placeholder="palabra clave1, palabra clave2..."
                  value={formik.values.keywords}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></input>
              </div>

              <div className="lg:ml-4 lg:mr-4 text-gray-300">
                <label className="block mt-2 font-semibold text-sm">
                  Título SEO
                </label>
                <input
                  className="border border-gray-400 p-3 rounded w-full leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 mt-2 transition-shadow xs:text-xs sm:text-sm md:text-base"
                  id="titleSEO"
                  type="text"
                  value={formik.values.titleSEO}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></input>
              </div>

              <div className="lg:ml-4 lg:mr-4 text-gray-300">
                <label className="block mt-2 font-semibold text-sm">Slug</label>
                <input
                  className="border border-gray-400 p-3 rounded w-full leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 mt-2 transition-shadow xs:text-xs sm:text-sm md:text-base"
                  id="slug"
                  type="text"
                  value={formik.values.slug}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></input>
              </div>

              <div className="lg:ml-4 lg:mr-4 text-gray-300">
                <div className="block mt-2 mb-2 font-semibold text-sm">
                  Descripción SEO{" "}
                  {formik.values.description ? (
                    <> ({formik.values.description.length}) </>
                  ) : null}
                  {formik.values.description ? (
                    <div>
                      {formik.values.description.length < 120 ? (
                        <div className=" text-red-500">
                          La descripción es muy corta, mínimo 120 caracteres
                        </div>
                      ) : null}
                      {formik.values.description.length >= 120 &&
                      formik.values.description.length <= 156 ? (
                        <div className=" text-green-500">
                          La descripción es correcta
                        </div>
                      ) : null}
                      {formik.values.description.length > 156 ? (
                        <div className=" text-red-500">
                          La descripción es muy larga, máximo 156 caracteres
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <textarea
                  className="border border-gray-400 p-3 rounded leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 w-full transition-shadow xs:text-xs sm:text-sm md:text-base mb-4"
                  id="description"
                  placeholder="Descripción SEO"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></textarea>
              </div>
            </div>
          </div>

          <input
            type="submit"
            className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 p-4 mt-4 w-full"
            value="Actualizar producto"
          />
        </form>
      </div>
    </div>
  );
};

export default EditarProducto;
