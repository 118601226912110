import React, { useState, useContext, useEffect } from "react";
import { FirebaseContext } from "../../firebase";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import FrontHeader from "./FrontHeader";
import { useAuth } from "../../context/AuthContext";
import Cart from "./Cart";
import Drawer from "react-modern-drawer";
import user from "../../images/user.png";
import FrontFooter from "./FrontFooter";
import InputMask from "react-input-mask";
import flag from "../../images/flag_mx.png";
import ReactLoading from "react-loading";

const UserAccount = () => {
  //Drawer carro de compras y su state
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  //state de la busqueda
  const [searchterm, setSearchTerm] = useState("");

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const [nombre, establecerNombre] = useState("");
  const [calle, establecerCalle] = useState("");
  const [colonia, establecerColonia] = useState("");
  const [ciudad, establecerCiudad] = useState("");
  const [estado, establecerEstado] = useState("");
  const [codigoPostal, establecerCodigoPostal] = useState("");
  const [telefono, establecerTelefono] = useState("");

  const [editando, establecerEditando] = useState(false);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");

  const [datos, guardarDatos] = useState([]);

  //extraer el usuario de auth
  const { usuario } = useAuth();

  //hook para redireccionar
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    //obtiene los datos de usuario
    const db = firebase.db;
    const collectionRef = db
      .collection("usuarios")
      .where("uidUsuario", "==", usuario.uid);

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const datos = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      guardarDatos(datos);

      if (Object.keys(datos).length === 0) {
        establecerEditando(false);
      } else establecerEditando(true);

      if (editando === true) {
        establecerNombre(datos[0].nombre);
        establecerCalle(datos[0].calle);
        establecerColonia(datos[0].colonia);
        establecerCiudad(datos[0].ciudad);
        establecerEstado(datos[0].estado);
        establecerCodigoPostal(datos[0].codigoPostal);
        establecerTelefono(datos[0].telefono);
      }

      usuario.getIdTokenResult().then((idTokenResult) => {
        setRole(idTokenResult.claims.role);
      });

      setLoading(false);
    });

    return () => unsubscribe();
    
  }, [editando]);

  //maneja el cambio en el formulario

  const handleChange = (e) => {
    switch (e.target.name) {
      case "nombre":
        establecerNombre(e.target.value);
        break;
      case "calle":
        establecerCalle(e.target.value);
        break;
      case "colonia":
        establecerColonia(e.target.value);
        break;
      case "ciudad":
        establecerCiudad(e.target.value);
        break;
      case "estado":
        establecerEstado(e.target.value);
        break;
      case "codigoPostal":
        establecerCodigoPostal(e.target.value);
        break;
      case "telefono":
        establecerTelefono(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    //validar el formulario
    if (
      nombre === "" ||
      calle === "" ||
      colonia === "" ||
      ciudad === "" ||
      estado === "" ||
      telefono === ""
    ) {
      Toast.fire({
        icon: "error",
        title: "Todos los campos son obligatorios",
      });

      return;
    }

    /*     if (telefono.length != 10) {
      Toast.fire({
        icon: "warning",
        title: "El número telefonico debe ser igual a 10 caracteres",
      });

      return;
    } */

    const userObj = {
      uidUsuario: usuario.uid,
      nombre: nombre,
      calle: calle,
      colonia: colonia,
      ciudad: ciudad,
      estado: estado,
      codigoPostal: codigoPostal,
      telefono: telefono,
      imagen: usuario.photoURL,
      correo: usuario.email,
    };

    if (editando == false) {
      firebase.db.collection("usuarios").add(userObj);
    } else {
      firebase.db.collection("usuarios").doc(datos[0].id).update(userObj);
    }

    Toast.fire({
      title: "<p style='color:#22c55e'>" + "Guardado" + "</p>",
      html: '<p style="color:#fff">Se guardó tu información</p>',
      icon: "success",
      color: "#fff",
      background: "#18181b",
      position: "top-end",
      iconColor: "#22c55e",
      timer: 1500,
      showConfirmButton: false,
    });
  };

  return (
    <>
      <div>
        <FrontHeader
          toggleDrawer={toggleDrawer}
          searchterm={searchterm}
          setSearchTerm={setSearchTerm}
        />

        <Drawer
          size={350}
          open={isOpen}
          onClose={toggleDrawer}
          direction="right"
        >
          <Cart isOpen={isOpen} setIsOpen={setIsOpen} />
        </Drawer>

        {loading === true ? (
          <div className="bg-gray-900 flex justify-center items-center h-screen">
            <ReactLoading color={"#22C55E"} type={"spin"} />
          </div>
        ) : (
          <div className="p-5 bg-gray-800 ">
            <div className="mx-auto  rounded-xl shadow-md overflow-hidden max-w-screen-lg bg-gray-100 ">
              <label className="block text-gray-700 text-center text-3xl font-light mb-2 mt-2">
                Cuenta de usuario
              </label>

              {role ? (
                <p className=" inline-flex  ml-5 p-2 rounded-lg bg-gradient-to-l from-indigo-500 to-indigo-600  text-white xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base  shadow-xl">
                  {role}
                </p>
              ) : null}

              <div className="flex justify-center">
                {usuario.photoURL ? (
                  <img
                    className="h-24 w-24 rounded-full"
                    alt=""
                    src={usuario.photoURL}
                  />
                ) : (
                  <img className="h-24 w-24 rounded-full" alt="" src={user} />
                )}
              </div>
              <div className="flex justify-center">
                {/*             <button className="bg-green-500 m-2 p-2 rounded-lg  text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">Configuración de la tienda</button>
                 */}

                <div className="flex m-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <p className="xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
                    {usuario.email}
                  </p>
                </div>
              </div>

              <div className="flex justify-center ml-5 mr-5 text-gray-500 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
                <p>
                  La información proporcionada por el usuario es para fines de
                  entrega del pedido realizado. Los datos financieros como
                  número de tarjeta y otra información no serán guardados en
                  esta plataforma.
                </p>
              </div>

              <div className="rounded-lg mt-4 m-2 ml-5 mr-5 p-2 max-w-screen-lg bg-gray-200">
                <form
                  onSubmit={handleSubmit}
                  className="mt-5 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base"
                >
                  <div className="pl-2 pr-2">
                    <p className="mt-4 mb-2 font-semibold text-sm">Nombre</p>

                    <input
                      onChange={handleChange}
                      value={nombre}
                      name="nombre"
                      type="text"
                      placeholder="Nombre legal"
                      className="border border-gray-400 p-3 rounded w-full text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 text-center"
                    />
                  </div>

                  <div className="sm:flex">
                    <div className="pl-2 pr-2">
                      <p className="mt-4 mb-2 font-semibold text-sm">
                        Teléfono
                      </p>
                      <div className="flex">
                        <img
                          className="block h-10 cursor-pointer self-center mr-2"
                          src={flag}
                          alt="Revdev Store"
                        />
                        <InputMask
                          mask="(+52) 999 999 9999"
                          alwaysShowMask={true}
                          onChange={handleChange}
                          value={telefono}
                          name="telefono"
                          placeholder="Teléfono"
                          className="w-44 xl:w-44 lg:w-44 border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 bg-white text-center transition-shadow"
                        ></InputMask>
                      </div>
                    </div>
                  </div>

                  <div className="md:flex">
                    <div className="pl-2 pr-2">
                      <p className="mt-4 mb-2 font-semibold text-sm">Estado</p>

                      <select
                        name="estado"
                        onChange={handleChange}
                        value={estado}
                        className="w-44  xl:w-44 lg:w-44 border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 bg-white text-center transition-shadow"
                      >
                        <option value="no">Seleccione uno...</option>
                        <option value="Aguascalientes">Aguascalientes</option>
                        <option value="Baja California">Baja California</option>
                        <option value="Baja California Sur">
                          Baja California Sur
                        </option>
                        <option value="Campeche">Campeche</option>
                        <option value="Chiapas">Chiapas</option>
                        <option value="Chihuahua">Chihuahua</option>
                        <option value="CDMX">Ciudad de México</option>
                        <option value="Coahuila">Coahuila</option>
                        <option value="Colima">Colima</option>
                        <option value="Durango">Durango</option>
                        <option value="Estado de México">
                          Estado de México
                        </option>
                        <option value="Guanajuato">Guanajuato</option>
                        <option value="Guerrero">Guerrero</option>
                        <option value="Hidalgo">Hidalgo</option>
                        <option value="Jalisco">Jalisco</option>
                        <option value="Michoacán">Michoacán</option>
                        <option value="Morelos">Morelos</option>
                        <option value="Nayarit">Nayarit</option>
                        <option value="Nuevo León">Nuevo León</option>
                        <option value="Oaxaca">Oaxaca</option>
                        <option value="Puebla">Puebla</option>
                        <option value="Querétaro">Querétaro</option>
                        <option value="Quintana Roo">Quintana Roo</option>
                        <option value="San Luis Potosí">San Luis Potosí</option>
                        <option value="Sinaloa">Sinaloa</option>
                        <option value="Sonora">Sonora</option>
                        <option value="Tabasco">Tabasco</option>
                        <option value="Tamaulipas">Tamaulipas</option>
                        <option value="Tlaxcala">Tlaxcala</option>
                        <option value="Veracruz">Veracruz</option>
                        <option value="Yucatán">Yucatán</option>
                        <option value="Zacatecas">Zacatecas</option>
                      </select>
                    </div>

                    <div className="pl-2 pr-2">
                      <p className="mt-4 mb-2 font-semibold text-sm">Ciudad</p>

                      <input
                        onChange={handleChange}
                        value={ciudad}
                        name="ciudad"
                        type="text"
                        placeholder="Ciudad"
                        className="w-44  xl:w-44 lg:w-44 border text-center border-gray-400 p-3 rounded  text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 transition-shadow"
                      />
                    </div>

                    <div className="pl-2 pr-2">
                      <p className="mt-4 mb-2 font-semibold text-sm">Colonia</p>

                      <input
                        onChange={handleChange}
                        value={colonia}
                        name="colonia"
                        type="text"
                        placeholder="Colonia"
                        className="w-44 xl:w-44 lg:w-44 border border-gray-400 p-3 rounded  text-center text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 transition-shadow"
                      />
                    </div>
                  </div>
                  <div className="md:flex justify-around">
                    <div className="pl-2 w-full">
                      <p className="mt-4 mb-2 font-semibold text-sm">
                        Calle y número
                      </p>

                      <input
                        onChange={handleChange}
                        value={calle}
                        name="calle"
                        type="text"
                        placeholder="Calle y número"
                        className="border border-gray-400 p-3 rounded w-full text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 transition-shadow text-center"
                      />
                    </div>

                    <div className="pl-2 pr-2">
                      <p className="mt-4 mb-2 font-semibold text-sm">
                        Código Postal
                      </p>

                      <input
                        onChange={handleChange}
                        value={codigoPostal}
                        name="codigoPostal"
                        type="number"
                        placeholder="Código Postal"
                        min="0"
                        className="w-24 border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 bg-white pl-3 text-center transition-shadow"
                      />
                    </div>
                  </div>

                  <div className="p-5">
                    <button className="bg-blue-500 w-full py-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105 mt-4">
                      Guardar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        <FrontFooter />
      </div>
    </>
  );
};

export default UserAccount;
