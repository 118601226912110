import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../ui/Sidebar";
import ReactLoading from "react-loading";
import Notifications from "../ui/Notifications";
import UserMenu from "../ui/UserMenu";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { FirebaseContextRevdev } from "../../firebaseRevdev";
import SettingsContext from "../../context/SettingsContext";

const Subscription = () => {
  //state que detecta si la pantalla es movil o de escritorio
  const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

  const [loading, setLoading] = useState(false);

  //Context con las operaciones de firebase de Revdev
  const { firebaseRevdev } = useContext(FirebaseContextRevdev);

  const {
    mensualidad,
    guardarMensualidad,
    meses,
    guardarMeses,
    plan,
    guardarPlan,
  } = React.useContext(FirebaseContextRevdev);

  //Context con los Settings

  const { settings } = React.useContext(SettingsContext);

  //state datos del cliente
  const [customer, setCustomer] = useState([]);

  //state con los planes
  const [negocio, setNegocio] = useState([]);
  const [pyme, setPyme] = useState([]);
  const [gratis, setGratis] = useState([]);

  const [mensualidadPyme, setMensualidadPyme] = useState();
  const [mensualidadNegocio, setMensualidadNegocio] = useState();

  const [diferencia, setDiferencia] = useState();

  //extraer el usuario de auth
  const { usuario } = useAuth();

  //state del select de fecha
  const [fechaPyme, guardarFechaPyme] = useState({ filtro1: "1mes" });
  const [fechaNegocio, guardarFechaNegocio] = useState({ filtro2: "1mes" });

  //extraer del state filtro
  const { filtro1 } = fechaPyme;

  const { filtro2 } = fechaNegocio;

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    const docRef = firebaseRevdev.db;

    //obtiene la información de la tienda

    const obtenerDatos = () => {
      if (settings[0].tiendaid) {
        docRef
          .collection("customers")
          .where("tiendaid", "==", settings[0].tiendaid)
          .onSnapshot(manejarSnapshot);
      } else {
        navigate("/");
      }
    };

    //se ejecuta la funcion para obtener datos

    obtenerDatos();

    function manejarSnapshot(snapshot) {
      let customerSnap = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      setCustomer(customerSnap);

      //CALCULA LO QUE FALTA PARA QUE TERMINE LA SUBSCRIPCION
      //Un día en milisegundos
      var one_day = 1000 * 60 * 60 * 24;

      //Fechas
      var date1_ms = customerSnap[0].expira;
      var date2_ms = Date.now();

      // Diferencia de fechas en milisegundos
      var difference_ms = date1_ms - date2_ms;

      console.log("LA DIFERENCIA EN MILISEGUNDOS ES ", difference_ms);

      // Convierte a días
      setDiferencia(Math.round(difference_ms / one_day));
    }

    //plan Gratis
    docRef
      .collection("planes")
      .doc("aekLcZ398K3GutXujmOn")
      .get()
      .then((doc) => {
        if (doc.exists) {
          setGratis(doc.data());
        } else {
          console.log("No se encontró el documento!");
        }
      })
      .catch(function (error) {
        console.log("Error obteniendo el documento:", error);
      });

    //plan PYME
    docRef
      .collection("planes")
      .doc("HZnGBDcubgAAged6IcmV")
      .get()
      .then((doc) => {
        if (doc.exists) {
          setPyme(doc.data());
          setMensualidadPyme(doc.data().precio);
        } else {
          console.log("No se encontró el documento!");
        }
      })
      .catch(function (error) {
        console.log("Error obteniendo el documento:", error);
      });

    //plan negocio
    docRef
      .collection("planes")
      .doc("1E5IGsZf1ruO4OkCIAdD")
      .get()
      .then((doc) => {
        if (doc.exists) {
          setNegocio(doc.data());
          setMensualidadNegocio(doc.data().precio);
        } else {
          console.log("No se encontró el documento!");
        }
      })
      .catch(function (error) {
        console.log("Error obteniendo el documento:", error);
      });

    guardarMeses(1);

    setLoading(false);
  }, []);

  //lee la info del select Fecha
  const seleccionFechaPyme = (e) => {
    guardarFechaPyme({ ...fechaPyme, [e.target.name]: e.target.value });

    if (e.target.value === "1mes") {
      setMensualidadPyme(pyme.precio);
      guardarMeses(1);
    }

    if (e.target.value === "3meses") {
      setMensualidadPyme(pyme.precio * 3);
      guardarMeses(3);
    }

    if (e.target.value === "6meses") {
      setMensualidadPyme(pyme.precio * 6);
      guardarMeses(6);
    }

    if (e.target.value === "1año") {
      setMensualidadPyme(pyme.precio * 12);
      guardarMeses(12);
    }
  };

  //lee la info del select Fecha
  const seleccionFechaNegocio = (e) => {
    guardarFechaNegocio({ ...fechaNegocio, [e.target.name]: e.target.value });

    if (e.target.value === "1mes") {
      setMensualidadNegocio(negocio.precio);
      guardarMeses(1);
    }

    if (e.target.value === "3meses") {
      setMensualidadNegocio(negocio.precio * 3);
      guardarMeses(3);
    }

    if (e.target.value === "6meses") {
      setMensualidadNegocio(negocio.precio * 6);
      guardarMeses(6);
    }

    if (e.target.value === "1año") {
      setMensualidadNegocio(negocio.precio * 12);
      guardarMeses(12);
    }
  };

  const userObj = {
    uidUsuario: usuario.uid,
  };

  const makePaymentPyme = () => {
    guardarMensualidad(mensualidadPyme);
    guardarPlan(pyme.nombre);
    navigate("/renewsubscription");
  };

  const makePaymentNegocio = () => {
    guardarMensualidad(mensualidadNegocio);
    guardarPlan(negocio.nombre);
    navigate("/renewsubscription");
  };

  return (
    <div className="md:flex min-h-screen">
      <Sidebar />

      {loading ? (
        <div className="md:w-4/5 xl:w-4/5 p-6 self-center justify-center flex ">
          <ReactLoading color={"#3B82F6"} type={"spin"} />
        </div>
      ) : (
        <div className="md:w-4/5 xl:w-4/5 p-4 bg-gray-900">
          <header>
            <div className="flex justify-between mb-5">
              <div className="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 mt-1 mr-2 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <h1 className="text-3xl text-white">Suscripción</h1>
              </div>
              <div className="flex">
                <div className="mt-2">
                  {isStatic ? <Notifications /> : null}
                </div>
                <div className=" z-50">{isStatic ? <UserMenu /> : null}</div>
              </div>
            </div>
          </header>

          {Object.keys(customer).length === 0 ? null : (
            <div className="mx-auto bg-gray-800 to-black rounded-xl shadow-lg overflow-hidden text-gray-300 max-w-screen-lg">
              <div className="text-lg font-semibold bg-gradient-to-l from-yellow-400 to-yellow-500 p-2">
                <div className="ml-2">Tu cuenta en Revdev store</div>
              </div>
              <div className="m-4">
                {/* <p>Propietario: {customer[0].propietario}</p> */}
                <p>Tienda ID: {customer[0].tiendaid}</p>
                <p>
                  Fecha de creación:{" "}
                  {new Date(customer[0].creado).toLocaleString()}
                </p>
                <p>
                  Fecha de expiración:{" "}
                  {new Date(customer[0].expira).toLocaleString()}
                </p>
                <p>Plan actual: {customer[0].plan}</p>
              </div>

              {diferencia <= 0 ? (
                <p className="p-2 m-4 font-semibold bg-red-600 rounded-lg max-w-sm text-white text-center">
                  Tu subscripción ya expiró el día{" "}
                  {new Date(customer[0].expira).toLocaleString()}
                </p>
              ) : (
                <p className="p-2 m-4 font-semibold bg-green-500 rounded-lg max-w-sm text-white text-center">
                  Quedan {diferencia} días para renovar tu suscripción
                </p>
              )}
            </div>
          )}

          <div className="mt-4">
            <div className="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {/*{gratis.nombre ? (
                <div class="rounded-xl shadow-md  bg-gray-100 ">
                  <div class="h-full p-6 rounded-lg border-2 border-yellow-500">
                    <h2 class="text-sm tracking-widest title-font mb-1 font-medium">
                      {gratis.nombre}
                    </h2>
                    <h1 class="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">
                      Gratis
                    </h1>
                    <p class="flex items-center text-gray-600 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-yellow-500 text-white rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          class="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      {gratis.feature1}
                    </p>
                    <p class="flex items-center text-gray-600 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-yellow-500 text-white rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          class="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      {gratis.feature2}
                    </p>
                    <button class="flex items-center mt-auto text-white bg-yellow-500 hover:bg-yellow-700 border-0 py-2 px-4 w-full focus:outline-none rounded-lg">
                      Suscribirme
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        class="w-4 h-4 ml-auto"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="md:w-5/5 xl:w-5/5 p-6 self-center justify-center flex ">
                  <ReactLoading color={"#F59E0B"} type={"spin"} />
                </div>
              )} */}

              {mensualidadPyme ? (
                <div class="rounded-xl shadow-md  bg-gray-800">
                  <div class="h-full p-6 rounded-lg border-2 border-indigo-500  bg-gradient-to-tl from-indigo-700 via-indigo-800  to-indigo-900">
                    <h2 class="text-sm tracking-widest title-font mb-1 font-medium text-indigo-500">
                      {pyme.nombre}
                    </h2>

                    <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                      <span className=" text-gray-300">
                        {" "}
                        {new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(mensualidadPyme)}
                      </span>
                      <span class="text-lg ml-1 font-normal text-gray-300">
                        /{pyme.currency}
                      </span>
                      <span class="text-xs ml-1 font-normal text-gray-300">
                        *IVA incluido
                      </span>
                    </h1>

                    <p class="flex items-center text-gray-300 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          class="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      {pyme.feature1}
                    </p>
                    <p class="flex items-center text-gray-300 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          class="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      {pyme.feature2}
                    </p>
                    <p class="flex items-center text-gray-300 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          class="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      {pyme.feature3}
                    </p>
                    <p class="flex items-center text-gray-300 mb-6">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          class="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      {pyme.feature4}
                    </p>

                    <div class="relative  border-none">
                      <select
                        className="bg-indigo-500 hover:bg-indigo-400 text-white appearance-none border-none inline-block py-3 pl-3 pr-8 rounded-lg leading-tight w-full xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base mb-2"
                        onChange={seleccionFechaPyme}
                        name="filtro1"
                        value={filtro1}
                      >
                        <option className=" text-white" value="1mes">
                          1 mes
                        </option>
                        <option className=" text-white" value="3meses">
                          3 meses
                        </option>
                        <option className=" text-white" value="6meses">
                          6 meses
                        </option>
                        <option className=" text-white" value="1año">
                          1 año
                        </option>
                      </select>
                      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 pb-1 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>{" "}
                      </div>
                    </div>

                    <button
                      class="flex items-center mt-auto text-white bg-indigo-500 border-0 py-4 px-4 w-full focus:outline-none hover:bg-indigo-400 rounded"
                      onClick={() => makePaymentPyme()}
                    >
                      Hacer el pago
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        class="w-4 h-4 ml-auto"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
                    <div className="mt-2"></div>
                  </div>
                </div>
              ) : (
                <div className="md:w-5/5 xl:w-5/5 p-6 self-center justify-center flex ">
                  <ReactLoading color={"#6366F1"} type={"spin"} />
                </div>
              )}

              {mensualidadNegocio ? (
                <div class="rounded-xl shadow-md  bg-gray-800 ">
                  <div class="h-full p-6 rounded-lg border-2  bg-gradient-to-tl from-green-700 via-green-800 to-green-900 flex flex-col relative overflow-hidden">
                    <h2 class="text-sm tracking-widest title-font mb-1 font-medium text-green-500">
                      {negocio.nombre}
                    </h2>
                    <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                      <span className="text-gray-300">
                        {" "}
                        {new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(mensualidadNegocio)}
                      </span>
                      <span class="text-lg ml-1 font-normal text-gray-300">
                        /{negocio.currency}
                      </span>
                      <span class="text-xs ml-1 font-normal text-gray-300">
                        *IVA incluido
                      </span>
                    </h1>
                    <p class="flex items-center text-gray-300 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          class="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      {negocio.feature1}
                    </p>
                    <p class="flex items-center text-gray-300 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          class="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      {negocio.feature2}
                    </p>
                    <p class="flex items-center text-gray-300 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500  rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          class="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      {negocio.feature3}
                    </p>
                    <p class="flex items-center text-gray-300 mb-2">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500  rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          class="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      {negocio.feature4}
                    </p>
                    <p class="flex items-center text-gray-300 mb-6">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          class="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      {negocio.feature5}
                    </p>
                    <p class="flex items-center text-gray-300 mb-6">
                      <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-500 rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          class="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      {negocio.feature6}
                    </p>

                    <div class="relative border-none">
                      <select
                        className="bg-green-500 hover:bg-green-400 text-white appearance-none border-none inline-block py-3 pl-3 pr-8 rounded-lg leading-tight w-full xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base mb-2"
                        onChange={seleccionFechaNegocio}
                        name="filtro2"
                        value={filtro2}
                      >
                        <option className=" text-white" value="1mes">
                          1 mes
                        </option>
                        <option className=" text-white" value="3meses">
                          3 meses
                        </option>
                        <option className=" text-white" value="6meses">
                          6 meses
                        </option>
                        <option className=" text-white" value="1año">
                          1 año
                        </option>
                      </select>
                      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 pb-1 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>{" "}
                      </div>
                    </div>
                    <button
                      class="flex items-center mt-auto text-white bg-green-500 border-0 py-4 px-4 w-full focus:outline-none hover:bg-green-400 rounded"
                      onClick={() => makePaymentNegocio()}
                    >
                      Hacer el pago
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        class="w-4 h-4 ml-auto"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
                    <div className="mt-2"></div>
                  </div>
                </div>
              ) : (
                <div className="md:w-5/5 xl:w-5/5 p-6 self-center justify-center flex ">
                  <ReactLoading color={"#22C55E"} type={"spin"} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscription;
