import React, { useState, useEffect, useContext } from "react";
import Notifications from "../ui/Notifications";
import Sidebar from "../ui/Sidebar";
import ReactLoading from "react-loading";
import { FirebaseContext } from "../../firebase";
import { useMediaQuery } from "react-responsive";
import ReactTooltip from "react-tooltip";
import UserMenu from "../ui/UserMenu";


const PaypalSettings = () => {

  const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

  const [loading, setLoading] = useState(false);

  const [changes, setChanges] = useState(false);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  return (
    <div className="md:flex min-h-screen bg-gray-900">
      <Sidebar />
      <ReactTooltip />
      {loading === true ? (
        <div className="md:w-4/5 xl:w-4/5 bg-gray-900 flex justify-center items-center h-screen">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="md:w-4/5 xl:w-4/5 p-4 bg-gray-900">
          <header>
            <div className="flex justify-end">
              {/*               <div className="flex text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 mt-1 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <h1 className="text-3xl">Configuración Paypal</h1>
              </div> */}
              <div className="flex">
                <div className="mt-2">
                  {isStatic ? <Notifications /> : null}
                </div>
                <div className=" z-50">{isStatic ? <UserMenu /> : null}</div>
              </div>
            </div>
          </header>




          <div className="p-2 pt-2 bg-gray-800 rounded-lg max-w-screen-lg xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">

          <p className="text-2xl text-white flex justify-center mt-2 mb-2">
              Configuración de Paypal
            </p>

              <div className="rounded-lg px-4 py-4 m-1 shadow-md max-w-screen-lg bg-gray-700">
                <div className="md:p-4">
                  <div className="pl-2 pr-2">
                    <div className=" flex mt-4 mb-2">
                      <p className="font-semibold text-sm text-gray-300">
                        API pública
                      </p>

                      <svg
                        data-tip="El nombre de la tienda como la verá el público en general"
                        alt="tooltip"
                        className="ml-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="white"
                        fill="white"
                        strokeWidth=".1"
                      >
                        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
                      </svg>
                    </div>

                    <ReactTooltip place="top" type="dark" effect="float" />

                    <input
       
                      name="nombreTienda"
                      type="text"
                      placeholder="Nombre de la tienda"
                      className="border border-gray-400 p-3 rounded w-full text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 transition-shadow xs:text-xs md:text-base"
                    />
                  </div>

                  <div className="pl-2 pr-2">
                    <div className="flex mt-4 mb-2 ">
                      <p className=" font-semibold text-sm text-gray-300 ">
                        API privada
                      </p>

                      <svg
                        data-tip="Será utilizado por default en el sistema para enviar notificaciones al propietario de la tienda"
                        alt="tooltip"
                        className="ml-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="white"
                        fill="white"
                        strokeWidth=".1"
                      >
                        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
                      </svg>
                    </div>

                    <input
   
                      name="correoContacto"
                      type="text"
                      placeholder="Correo de contacto"
                      className="border border-gray-400 p-3 rounded w-full text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 transition-shadow xs:text-xs md:text-base"
                    />
                  </div>

                  {/* <div className="pl-2 pr-2">
                    <div className="flex mt-4 mb-2 ">
                      <p className="font-semibold text-sm text-gray-300">
                        Correo del remitente
                      </p>

                      <svg
                        data-tip="Los clientes verán esta dirección de correo al ser notificados con un correo"
                        className="ml-4"
                        alt="tooltip"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="white"
                        fill="white"
                        strokeWidth=".1"
                      >
                        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
                      </svg>
                    </div>

                    <input
            
                      name="correoRemitente"
                      type="text"
                      placeholder="Correo del remitente"
                      className="w-full xl:w-1/3 lg:w-1/3 border border-gray-400 p-3 mb-4 rounded text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 transition-shadow xs:text-xs md:text-base "
                    />
                  </div> */}
                </div>
              </div>

              <div className="mt-4">
                <button className="bg-purple-900 hover:bg-purple-700 w-full p-4 rounded-xl text-white  focus:outline-none transition duration-200 ease-in-out transform hover:-translate-x hover:scale-y-105">
                  Guardar
                </button>
              </div>
              </div>





        </div>
      )}
    </div>
  );
};

export default PaypalSettings;
