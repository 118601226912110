import React, { useContext, useState, useEffect } from "react";
import { FirebaseContext } from "../../firebase";
import { useAuth } from "../../context/AuthContext";
import Ratings from "react-ratings-declarative";
import Collapsible from "react-collapsible";
import user from "../../images/user.png";

const Rating = ({ id }) => {
  //extraer el usuario de auth
  const { usuario } = useAuth();

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //state que guarda si el usuario está habilitado o no
  const [enabled, isEnabled] = useState(true);

  //state de rating
  const [rating, establecerRating] = useState([]);
  const [ratingNum, establecerRatingNum] = useState(0);
  const [opinion, establecerOpinion] = useState("");
  const [ratingGlobal, establecerRatingGlobal] = useState([]);

  //state que guarda las pedidos
  const [pedidos, establecerPedidos] = useState([]);

  //state que guarda si encontró el producto entre las compras del usuario
  const [encontrado, establecerEncontrado] = useState(false);

  //este estado lo puse para detectar cuando firebase devuelve el snapshot
  const [listo, establecerListo] = useState(false);

  //Cuando cambia el rating del producto
  const ratingChanged = (newRating) => {
    if (usuario !== null) {
      const ratingObj = {
        id: id,
        rating: newRating,
        uidUsuario: usuario.uid,
        opinion: opinion,
        photoURL: usuario.photoURL,
        displayName: usuario.displayName,
      };

      firebase.db
        .collection("ratings")
        .where("uidUsuario", "==", usuario.uid)
        .where("id", "==", id)
        .onSnapshot(manejarSnapshot);

      function manejarSnapshot(snapshot) {
        let rating = snapshot.docs.map((doc) => {
          establecerRating(doc.id);

          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        if (Object.keys(rating).length > 0) {
          establecerRatingNum(rating[0].rating);
        }
      }

      if (Object.keys(rating).length > 0) {
        console.log("Se encontro este registro");
        firebase.db.collection("ratings").doc(rating).update(ratingObj);
      } else {
        console.log("No se encontro este rating!");
        firebase.db.collection("ratings").add(ratingObj);
      }
    }

    // console.log(newRating);
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "opinion":
        establecerOpinion(e.target.value);
        break;
      default:
        break;
    }
  };

  const guardarOpinion = () => {
    const ratingObj = {
      id: id,
      rating: ratingNum,
      uidUsuario: usuario.uid,
      opinion: opinion,
      photoURL: usuario.photoURL,
      displayName: usuario.displayName,
    };

    if (Object.keys(rating).length > 0) {
      console.log("Se encontro este registro");
      firebase.db.collection("ratings").doc(rating).update(ratingObj);
    } else {
      console.log("No se encontro este rating!");
      firebase.db.collection("ratings").add(ratingObj);
    }
  };

  useEffect(() => {
    if (usuario) {
      usuario.getIdTokenResult().then((idTokenResult) => {
        isEnabled(idTokenResult.claims.habilitado);
        //    console.log(enabled);
      });
    }
  }, []);

  useEffect(() => {
    //obtener rating personal sólo si está autentificado el usuario y tiene derecho a votar si compró el producto

    if (usuario !== null) {
      //traer la calificación del usuario
      firebase.db
        .collection("ratings")
        .where("uidUsuario", "==", usuario.uid)
        .where("id", "==", id)
        .onSnapshot(manejarSnapshot);

      function manejarSnapshot(snapshot) {
        let rating = snapshot.docs.map((doc) => {
          establecerRating(doc.id);
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        // console.log("el rating es de:", rating);

        if (Object.keys(rating).length > 0) {
          // console.log("Se encontro este registro");
          // console.log("el rating en arreglo es de:", rating[0].rating);

          establecerRatingNum(rating[0].rating);
          establecerOpinion(rating[0].opinion);

          // console.log("el rating numerico es de:", ratingNum);
        } else {
          //    console.log("No se encontro este rating!");
        }
      }
    }
  }, [ratingNum]);

  useEffect(() => {
    //verifica si tiene derecho a votar
    if (usuario !== null) {
      //traer los pedidos del usuario
      const getFirebaseData = () => {
        firebase.db
          .collection("ordenes")
          .where("uidUsuario", "==", usuario.uid)
          .onSnapshot(manejarSnapshotPedidos);

        function manejarSnapshotPedidos(snapshot) {
          let pedido = snapshot.docs.map((doc) => {
            establecerPedidos(doc.id);
            return {
              id: doc.id,
              ...doc.data(),
            };
          });

          if (Object.keys(pedido).length > 0) {
            establecerPedidos(pedido);

            //console.log("LOS PEDIDOS SON", pedidos);

            //este estado lo puse para detectar cuando firebase devuelve el snapshot
            establecerListo(true);
          }
        }
      };

      getFirebaseData();
    }
  }, []);

  useEffect(() => {
    //calcular el rating global

    //obtiene de firebase el rating con la id del producto y calcula su promedioo

    const obtenerRatingGlobal = () => {
      firebase.db
        .collection("ratings")
        .where("id", "==", id)
        .onSnapshot(manejarSnapshot);

      function manejarSnapshot(snapshot) {
        let ratingGlobal = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        establecerRatingGlobal(ratingGlobal);
      }
    };

    obtenerRatingGlobal();
  }, []);

  useEffect(() => {
    //se evalua cuando el estado "listo" cambia o sea al recibir el snapshot de firebase
    if (Object.keys(pedidos).length > 0) {
      //busca el producto en los pedidos del usuario
      pedidos.forEach((pedido) => {
        pedido.orden.forEach((producto) => {
          if (producto.id === id) {
            establecerEncontrado(true);
            //      console.log("ENCONTRADO");
          }
        });
      });
      //    console.log("listo cambió")
    }
  }, [listo]);

  return (
    <>
      {usuario && encontrado ? (
        <div className="mx-auto max-w-screen-md pl-5 pr-5 pb-5">
          {enabled === false ? (
            <p className="text-sm text-red-500">
              El usuario está deshabilitado y no puede calificar
            </p>
          ) : (
            <div className="">
              <div className="flex xs:flex-col lg:flex-row justify-between border-yellow-500 rounded-lg bg-gray-800 border-2 p-2 shadow-lg">
                <div className="p-2 xs:w-full lg:w-1/3">
                  <p className="text-gray-300 text-base font-medium">
                    Mi calificación
                  </p>
                  <div className="flex">
                    <p className="mr-2 self-center text-xl text-gray-300">
                      {ratingNum}
                    </p>
                    <Ratings
                      rating={ratingNum}
                      widgetRatedColors="#F59E0B"
                      changeRating={ratingChanged}
                      widgetHoverColors="#D97706"
                      widgetDimensions="25px"
                      widgetSpacings="3px"
                    >
                      <Ratings.Widget />
                      <Ratings.Widget />
                      <Ratings.Widget />
                      <Ratings.Widget />
                      <Ratings.Widget />
                    </Ratings>
                  </div>
                </div>
                <div className="p-2 xs:w-full lg:w-2/3">
                  <div className="text-base mb-2 font-medium text-gray-300">
                    Mi opinión
                  </div>
                  <textarea
                    className="focus:border-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-3"
                    name="opinion"
                    placeholder="Escribe aquí tu opinion"
                    value={opinion}
                    onChange={handleChange}
                  ></textarea>
                  <button
                    //         onClick={addComment}
                    className="px-4 py-2 text-base rounded-md text-white bg-blue-500 mt-3"
                    onClick={guardarOpinion}
                  >
                    Opinar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
      <div className="antialiased mx-auto max-w-screen-md pl-5 pr-5 pb-5 font-semibold text-lg">
        <Collapsible trigger="Opiniones sobre este producto">
                    <div className="mt-4">
            {ratingGlobal.map((opinion) => {
              return (
                <div>
                  {opinion.opinion ? (
                    <div className="bg-white p-4 rounded-md mt-2 shadow-md">
                      <div className="flex">
                        <div className="">
                          {opinion.photoURL ? (
                            <img
                              className="rounded-full w-8 h-8"
                              alt=""
                              src={opinion.photoURL}
                            />
                          ) : (
                            <img
                              className="rounded-full  w-8 sh-8"
                              alt=""
                              src={user}
                            />
                          )}
                        </div>
                        <p className=" mt-1 ml-2 text-base ">
                          {opinion.displayName}
                        </p>{" "}
                      </div>

                      <div className="pt-2 font-normal text-gray-700 xs:text-sm lg:text-base ">
                        {opinion.opinion}
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </Collapsible>
      </div>
    </>
  );
};

export default Rating;
