import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const RedirectSubSubCategoria = () => {
  const navigate = useNavigate();
  const { categoria } = useParams();
  const { subcategoria } = useParams();
  const { subsubcategoria } = useParams();

  useEffect(() => {
    navigate(
      "/categoria/" + categoria + "/" + subcategoria + "/" + subsubcategoria
    );
  }, []);

  return <></>;
};

export default RedirectSubSubCategoria;
