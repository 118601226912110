import React, { useState, useContext } from "react";
import { FirebaseContext } from "../../firebase";
import { useNavigate } from "react-router-dom";
import Alerta from "../ui/Alerta";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const PasswordReset = () => {
  const [correo, establecerCorreo] = useState("");
  const [password, establecerPassword] = useState("");
  const [estadoAlerta, cambiarEstadoAlerta] = useState(false);
  const [alerta, cambiarAlerta] = useState({});

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //hook para redireccionar
  const navigate = useNavigate();

  const handleChange = (e) => {
    switch (e.target.name) {
      case "email":
        establecerCorreo(e.target.value);
        break;
      case "password":
        establecerPassword(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    cambiarEstadoAlerta(false);
    cambiarAlerta({});

    //validar formulario

    if (correo === "") {
      cambiarEstadoAlerta(true);
      cambiarAlerta({
        titulo: "Todos los campos son obligatorios",
      });
      return;
    }

    try {
      firebase.auth
        .sendPasswordResetEmail(correo)
        .then(function () {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title:
              "Se envió un correo con las instrucciones para resetear el password",
          });

          //redireccionar con useNavigate
          navigate("/login");
        })
        .catch(function (error) {
          // An error happened.

          cambiarEstadoAlerta(true);
          cambiarAlerta({
            titulo: "Ese correo no existe en el sistema",
          });
        });
    } catch (error) {
      cambiarEstadoAlerta(true);
      let mensaje;

      //mensajes de error en Firebase
      switch (error.code) {
        case "auth/user-not-found":
          mensaje = "El correo no está asociado a una cuenta";
          break;

        case "auth/wrong-password":
          mensaje = "La contraseña no es correcta";
          break;

        default:
          mensaje = "No se pudo acceder al sistema";
          break;
      }

      cambiarAlerta({ titulo: mensaje });
    }
  };

  return (
    <>
      <div>
        <div className="relative min-h-screen flex flex-col justify-center items-center bg-gray-900 p-10">
          <div className="relative sm:max-w-sm w-full">
            <div className="card bg-yellow-500 shadow-lg w-full h-full rounded-3xl absolute transform -rotate-6"></div>
            <div className="card bg-green-400 shadow-lg w-full h-full rounded-3xl absolute  transform rotate-6"></div>
            <div className="relative w-full rounded-3xl px-6 py-4 bg-gray-100 shadow-md">
              <label className="block text-gray-700 text-center text-3xl font-light mb-6 mt-2">
                   Recupera tu cuenta
              </label>
              <p className=" text-gray-500 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">Ingresa tu correo electrónico para recuperar tu cuenta</p>
              <form onSubmit={handleSubmit} className="mt-5">
                <Alerta
                  titulo={alerta.titulo}
                  mensaje={alerta.mensaje}
                  estadoAlerta={estadoAlerta}
                  cambiarEstadoAlerta={cambiarEstadoAlerta}
                />

                <div>
                  <input
                    onChange={handleChange}
                    value={correo}
                    name="email"
                    type="email"
                    placeholder="Correo"
                    className="w-full mt-1 block  border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-4 ring-1 focus:outline-none xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base"
                  />
                </div>

                <div className="mt-6">
                  <button className="bg-blue-500 w-full py-3 rounded-xl text-white shadow-xl focus:outline-none transition duration-200 ease-in-out  transform hover:-translate-x hover:scale-105">
                    Enviar correo
                  </button>
                </div>
                <div className=" flex justify-center mt-4 mb-4">
                  <div className="">
                    <label className="text-sm text-gray-800 mb-3 ">
                      ¿Eres nuevo?
                    </label>
                    <Link
                      to="/registro"
                      className=" text-blue-500 transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105 text-sm ml-4"
                    >
                      Crear cuenta
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
