import React from "react";
import { useAuth } from "../../context/AuthContext";
import { Route } from "react-router-dom";
import Login from "../usuarios/Login";

const RutaProtegida = ({ children, ...restoDePropiedades }) => {
  const { usuario } = useAuth();

  if (usuario) {
    return <Route {...restoDePropiedades}>{children}</Route>;
  } else {
    return <Login />;
  }
};

export default RutaProtegida;
