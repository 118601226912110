import React, { useState, useContext, useEffect } from "react";
import { FirebaseContext } from "../firebase";

//creamos el contexto
const AuthContext = React.createContext();

//Hook para acceder al contexto
const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const [usuario, cambiarUsuario] = useState();

  //creamos un state para saber cuando termina de cargar la comprobación de onAuthStateChanged (en el useEffect)
  const [cargando, cambiarCargando] = useState(true);

  //efecto para ejecutar la comprobación una sola vez

  useEffect(() => {
    const cancelarSuscripcion = firebase.auth.onAuthStateChanged((usuario) => {
      cambiarUsuario(usuario);
      cambiarCargando(false);
    });

    return cancelarSuscripcion;
  }, []);

  return (
    <AuthContext.Provider value={{ usuario: usuario }}>
      {/* retornamos los elementos hijos cuando no está cargando*/}
      {!cargando && children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext, useAuth };
