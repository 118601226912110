import React from "react";
import {
  InstantSearch,
  Hits,
  SortBy,
  SearchBox,
  Pagination,
  Highlight,
  ClearRefinements,
  RefinementList,
  Configure,
  Snippet,
} from "react-instantsearch-dom";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { useNavigate } from "react-router-dom";

const searchClient = instantMeiliSearch(
  "https://ms-45d1aa497ad1-2539.sfo.meilisearch.io",
  "97954f67ba633c88526a831057eab8acc8962660",
  {
    finitePagination: true,

  }
);



const SearchMeliSearch = () => {

  const navigate = useNavigate();

  const Hit = ({ hit }) => (
    <div
      className="flex flex-col m-4 cursor-pointer"
      key={hit.id}
      onClick={() => {
        navigate("/redirect/" + hit._firestore_id);
      }}
    >
      <div className="text-lg font-bold">
        <Highlight attribute="nombre" hit={hit} />
      </div>
      <img
        className="w-1/6 rounded-lg"
        src={hit.imagen}
        align="left"
        alt={hit.nombre}
      />
      <div className="text-sm">Categoria: {hit.categoria}</div>
      <div className="text-sm">
        <Snippet attribute="descripcion" hit={hit} />
      </div>
      <div className="text-sm">
        Precio:{" "}
        {new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(hit.precio)}
      </div>
    </div>
  );

  return (
    <div className="">
      <InstantSearch indexName="productos" searchClient={searchClient}>
        <div className="flex justify-center bg-gray-800 p-2 ">
          <SearchBox
            translations={{
              placeholder: "Busca un producto...",
            }}
          />
        </div>

        <div className="flex m-4">
          <div className="w-1/3 m-4 bg-gray-300 rounded-lg">
            <div className="m-4">
              <div className=" font-light text-base">Filtrar por:</div>
              
              <p className="mt-4  font-semibold">Categorias</p>
                  <div className="text-base mt-2 mb-2">
                   {/* <RefinementList attribute="categoria" />  */}
                  </div>

              <ClearRefinements />
            </div>
          </div>
          <div className="w-2/3 m-4">
            <Hits hitComponent={Hit} />
            <Pagination showLast={true} />
          </div>
        </div>
      </InstantSearch>
    </div>
  );
};

export default SearchMeliSearch;
