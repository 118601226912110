import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../ui/Sidebar";
import ReactLoading from "react-loading";
import { FirebaseContext } from "../../firebase";
import UserMenu from "../ui/UserMenu";
import ProductosContext from "../../context/ProductosContext";
import _ from "lodash";
import Notifications from "../ui/Notifications";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../context/AuthContext";
import Swal from "sweetalert2";
import axios from "axios";
import user from "../../images/user.png";
import { useNavigate, useHistory } from "react-router-dom";


const UsuarioDerechos = () => {
  //state que detecta si la pantalla es movil o de escritorio
  const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

  //state de loading
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  //state de usuario que se seleccionó y que se le dará derechos
  const { usuarioState, setUsuarioState } = React.useContext(ProductosContext);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const [estadoAlerta, cambiarEstadoAlerta] = useState(false);

  //hook para redireccionar
  const navigate = useNavigate();

  //extraer el usuario de auth
  const { usuario } = useAuth();

  //state con los datos del usuario
  const [datos, guardarDatos] = useState([]);

  const [role, establecerRole] = useState("");

  useEffect(() => {
    usuario.getIdTokenResult().then((idTokenResult) => {

      // console.log("ESTÁ HABILITADO", idTokenResult.claims.habilitado);
      // console.log("ROLE", idTokenResult.claims.role);
      
      establecerRole(usuarioState.role);
    });
  }, []);

  useEffect(() => {
   
   // console.log(usuarioState);

    const obtenerDatos = () => {
      firebase.db
        .collection("usuarios")
        .where("uidUsuario", "==", usuarioState.uidUsuario)
        .onSnapshot(manejarSnapshot);
    };

    //se ejecuta la funcion para obtener datos

    obtenerDatos();

    function manejarSnapshot(snapshot) {
      let datos = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      guardarDatos(datos);
    }
  }, []);

  /*   function manejarSnapshot(snapshot) {
    let comentarios = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    setLoading(false);
  } */

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading1(true);

    //la id del documento la saco del snapshot que hago en un principio
    const userRef = firebase.db.collection("usuarios").doc(datos[0].id);
    const res = await userRef.update({ role: role });

    try {
      axios({
        method: "POST",
        url: "https://us-central1-mystore-d7058.cloudfunctions.net/changeRole",
        data: {
          email: usuarioState.correo,
          role: role,
        },
        timeout: 5000,
      })
        .then(function () {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "<p style='color:#22c55e'>" + "El usuario se convirtió en " + role + "</p>",
            color: "#fff",
            background: "#18181b",
            iconColor: "#22c55e",
          });
          setLoading1(false);

          navigate("/usuarios");

        })
        .catch(function (error) {
          // An error happened.
        });
    } catch (error) {}
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "role":
        establecerRole(e.target.value);
        break;

      default:
        break;
    }
  };

  return (
    <div className="md:flex min-h-screen">
      <Sidebar />

      {loading === true ? (
        <div className="md:w-4/5 xl:w-4/5 p-6 self-center justify-center flex ">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="md:w-4/5 xl:w-4/5 p-6 bg-gray-900 h-screen">
          <header>
            <div className="flex justify-between mb-5">
              <div className="flex text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 mt-1 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <h1 className="text-3xl">Perfil de {usuarioState.nombre}</h1>
              </div>
              <div className="flex">
                <div className="mt-2">
                  {isStatic ? <Notifications /> : null}
                </div>
                <div className=" z-50">{isStatic ? <UserMenu /> : null}</div>
              </div>
            </div>
          </header>
          {usuarioState.correo ? (
            <div className="flex justify-center">
              <div className="flex flex-col lg:w-1/2 md:w-full bg-gray-700 p-4 rounded-lg ">
                <div className="bg-purple-600 w-20 p-2 flex justify-center text-white rounded-lg">{usuarioState.role}</div>
                <div className="flex justify-center">
                  {usuario.photoURL ? (
                    <img
                      className="h-24 w-24 rounded-full"
                      alt=""
                      src={usuarioState.imagen}
                    />
                  ) : (
                    <img className="h-24 w-24 rounded-full" alt="" src={user} />
                  )}
                </div>

                <h1 className="flex justify-center m-4 text-white text-2xl">
                  {usuarioState.correo}
                </h1>

                {role === "owner" ? (
                  <>
                    <div>
                      <p className="flex justify-center p-4 bg-green-500 rounded-lg text-white ">
                        Propietario
                      </p>
                      <p className="flex justify-center text-gray-300 m-4">
                        Este perfil es el propietario de esta tienda y su rol no
                        puede ser cambiado.
                      </p>
                    </div>
                  </>
                ) : (
                  <form onSubmit={handleSubmit}>
                    {!loading1 ? (
                      <div>
                        <select
                          className="bg-purple-600 hover:bg-purple-700 text-white appearance-none border-none  py-3 pl-2 pr-8  rounded-lg leadig-tight xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base focus:ring-4 focus:ring-purple-300 focus:bg-purple-600 shadow-md text-center w-full mb-4 cursor-pointer"
                          name="role"
                          onChange={handleChange}
                          value={role}
                        >
                          <option className="text-white" value="admin">
                            Admin
                          </option>
                          <option className="text-white" value="editor">
                            Editor
                          </option>
                          <option className="text-white" value="cliente">
                            Cliente
                          </option>
                        </select>
                        <button className="bg-red-500  py-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none hover:bg-red-600 w-full">
                          Otorgar derechos
                        </button>
                      </div>
                    ) : (
                      <div className="flex justify-center">
                      <ReactLoading color={"#22C55E"} type={"spin"} />
                      </div>
                    )}

                    <div></div>
                  </form>
                )}
              </div>
            </div>
          ) : (
            <h1 className="flex justify-center mb-4 text-2xl text-white m-10">
              No hay un correo asociado a esta cuenta, no es posible cambiar de
              rol de usuario
            </h1>
          )}
        </div>
      )}
    </div>
  );
};

export default UsuarioDerechos;
