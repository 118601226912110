import React from "react";


class App extends React.Component {
  componentDidMount() {

    //Convertimos el pedido al formato de Stripe

    const productos = [];

    this.props.pedidoObj.orden.forEach((item) => {
      const precio_final = (
        (item.precio - item.precio * (item.descuento / 100)) *
        100
      ).toFixed(0);

      let objeto_item = {
        price_data: {
          currency: "MXN",
          product_data: {
            name: item.nombre,
            images: [item.imagen],
          },
          unit_amount: precio_final,
        },
        quantity: item.cantidad,
      };

      productos.push(objeto_item);
    });

    var cancelUrl = window.location.protocol + "//" + window.location.host+"/error"    
    var successUrl =  window.location.protocol + "//" + window.location.host+"/confirmacion"    
      
 
     console.log(successUrl)
     console.log(cancelUrl)

    //añadimos el gasto de envio como otro producto
     
/*     let envio_item = {
      price_data: {
        currency: "MXN",
        product_data: {
          name: "Gastos de envío",
        },
        unit_amount: this.props.pedidoObj.costo_envio*100,
      },
      quantity: 1,
    };

    console.log("COSTO DE ENVIO", this.props.pedidoObj.costo_envio)

    if(this.props.pedidoObj.envio == "costo"){
      productos.push(envio_item);
    }  */


    // Publishable Key from Stripe Dashboard
    const stripe = window.Stripe(
      "pk_test_51GINHRIA0uYTSwVywetk54kDALUE27s3rzyHUUOKGzoYGAdturNFWzBiEDZI8vXoJ9K8tJQwqe0p7RhVe9cQ498o00HOHYBHaO"
    );
    const paymentBtn = document.getElementById("stripe-payment-btn");
    let sessionId;
    console.log(this.props.pedidoObj)
    paymentBtn.addEventListener("click", () => {
      let orderData = {
        /*      currency: "mxn",
        quantity: 1,
        amount: 50 * 100,
        name: "Taquitos de prueba",
        description: "Tacos al pastor",
        image:
          "https://firebasestorage.googleapis.com/v0/b/mystore-d7058.appspot.com/o/productos%2F05986a22-3cab-4ed6-afc4-94f48a29bf84.jpg?alt=media&token=a9e148ef-4823-4628-ae71-74ade171cf1d", */

        customerEmail: this.props.pedidoObj.correo,
        clientId: "12345",
        pedidoObj: this.props.pedidoObj,
        line_items: productos,
        successUrl:successUrl,
        cancelUrl:cancelUrl,
        /*         line_items: [
          {
            price_data: {
              currency: "MXN",
              product_data: {
                name: "mi producto",
                images: [
                  "https://firebasestorage.googleapis.com/v0/b/mystore-d7058.appspot.com/o/productos%2F05986a22-3cab-4ed6-afc4-94f48a29bf84.jpg?alt=media&token=a9e148ef-4823-4628-ae71-74ade171cf1d",
                ],
              },
              unit_amount: 12783,
            },
            quantity: 5,
          },
          {
            price_data: {
              currency: "MXN",
              product_data: {
                name: "mi otro producto",
                images: [
                  "https://firebasestorage.googleapis.com/v0/b/mystore-d7058.appspot.com/o/productos%2F05986a22-3cab-4ed6-afc4-94f48a29bf84.jpg?alt=media&token=a9e148ef-4823-4628-ae71-74ade171cf1d",
                ],
              },
              unit_amount: 12783,
            },
            quantity: 3,
          }
          ,
        ] */
      }; // Url to Firebase function
      fetch(
        " https://us-central1-mystore-d7058.cloudfunctions.net/createOrderAndSession",
        {
          method: "POST", // Adding the order data to payload
          body: JSON.stringify(orderData),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          // Getting the session id from firebase function
          var body = JSON.parse(data.body);
          return (sessionId = body.sessionId);
        })
        .then((sessionId) => {

          // Redirecting to payment form page
          stripe
            .redirectToCheckout({
              sessionId: sessionId,
            })
            .then(function (result) {});
        });
    });
  }
  render() {
    return (
      <div className="flex justify-center" id="stripe-payment-btn">
        <button className="rounded-xl text-white bg-blue-500 hover:bg-blue-600 p-3 text-xl mt-3 mb-5 inline-flex items-center shadow-lg" disabled={this.props.disabled} style={this.props.disabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 mr-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
            />
          </svg>
          PROCEDER AL PAGO
        </button>
      </div>
    );
  }
}
export default App;
