// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCRDiXjpIm6GpRq2uHjhiY__0NgngXmkFg",
    authDomain: "mystore-d7058.firebaseapp.com",
    projectId: "mystore-d7058",
    storageBucket: "mystore-d7058.appspot.com",
    messagingSenderId: "1081921734550",
    appId: "1:1081921734550:web:90d450298465eeb7a17fb7",
    measurementId: "G-QMP8K1P9J2"
  };
  
  export default firebaseConfig;