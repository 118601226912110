import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../../firebase";
import FrontProducto from "./FrontProducto";
import _ from "lodash";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import FrontHeader from "./FrontHeader";
import FrontFooter from "./FrontFooter";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Cart from "./Cart";
import { useParams } from "react-router-dom";
import SearchAlgoliaFront from "../ui/SearchAlgoliaFront";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Categoria = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const { categoria } = useParams();

  const { subcategoria } = useParams();

  const { subsubcategoria } = useParams();

  const navigate = useNavigate();

  const [productos, guardarProductos] = useState([]);

  const [loading, setLoading] = useState(false);

  //state de la busqueda
  const [searchterm, setSearchTerm] = useState("");

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //state que cambia el numero de pagina en react-pagination
  const [currentPage, setCurrentPage] = useState(0);

  //state que forza a cambiar el numero de pagina en react-pagination
  const [seleccionada, setSeleccionada] = useState(0);

  //estado que guarda solo las imagenes que se mostrarán
  const [displayedProducts, setDisplayedProducts] = useState([]);

  const PAGE_SIZE = 16;

  const MyPaginate = styled(ReactPaginate).attrs({
    // You can redefine classes here, if you want.
    activeClassName: "active", // default to "selected"
  })`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    li a {
      border-radius: 7px;
      padding: 0.3rem 0.8rem;
      border-color: transparent;
      cursor: pointer;
      color: white;
      background-color: #eab308;
    }
    li.previous a,
    li.next a,
    li.break a {
      border-color: transparent;
      color: #1f2937;
      background-color: transparent;
    }
    li.active a {
      background-color: #3b82f6;
      border-color: transparent;
      color: white;
    }
    li.disabled a {
      color: grey;
    }
    li.disable,
    li.disabled a {
      cursor: default;
    }
  `;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (
      categoria !== undefined &&
      subcategoria == undefined &&
      subsubcategoria == undefined
    ) {
      const obtenerProductos = () => {
        setLoading(true);
        const dbRef = firebase.db;

        dbRef
          .collection("productos")
          .where("categoria", "==", categoria)
          .where("subcategoria", "==", "")
          .where("subsubcategoria", "==", "")
          .onSnapshot(manejarSnapshot);

        return () => dbRef();
      };
      obtenerProductos();
    }

    if (
      categoria !== undefined &&
      subcategoria !== undefined &&
      subsubcategoria == undefined
    ) {
      const obtenerProductos = () => {
        setLoading(true);
        const dbRef = firebase.db;

        dbRef
          .collection("productos")
          .where("categoria", "==", categoria)
          .where("subcategoria", "==", subcategoria)
          .where("subsubcategoria", "==", "")
          .onSnapshot(manejarSnapshot);

        return () => dbRef();
      };
      obtenerProductos();
    }

    if (
      categoria !== undefined &&
      subcategoria !== undefined &&
      subsubcategoria !== undefined
    ) {
      const obtenerProductos = () => {
        setLoading(true);
        const dbRef = firebase.db;

        dbRef
          .collection("productos")
          .where("categoria", "==", categoria)
          .where("subcategoria", "==", subcategoria)
          .where("subsubcategoria", "==", subsubcategoria)
          .onSnapshot(manejarSnapshot);

        return () => dbRef();
      };
      obtenerProductos();
    }
  }, [categoria]);

  function manejarSnapshot(snapshot) {
    let productos = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    //ordenar por categorias con lodash
    productos = _.orderBy(productos, "nombre", "asc");

    //almacena resultados en el state
    guardarProductos(productos);

    setLoading(false);
  }

  useEffect(() => {
    console.log("PAGINA", currentPage);
    console.log("DESDE", currentPage * PAGE_SIZE);
    console.log("HASTA", currentPage * PAGE_SIZE + PAGE_SIZE);

    const corte = productos.slice(
      currentPage * PAGE_SIZE,
      currentPage * PAGE_SIZE + PAGE_SIZE
    );
    setDisplayedProducts(corte);
  }, [currentPage, productos]);

  const handlePageChange = ({ selected }) => {
    setLoading(true);
    setCurrentPage(selected);
    //este state lo puse por que cuando daba click en la seleccion se volvia de vuelta a la pagina uno y así tuve que forzar su estado
    setSeleccionada(selected);
    setLoading(false);
  };

  //state del filtro Orden
  const [orden, guardarOrden] = useState({ filtro1: "defecto" });

  //extraer del state filtro
  const { filtro1 } = orden;

  const seleccionOrden = (e) => {
    guardarOrden({ ...orden, [e.target.name]: e.target.value });
    const productosClone = _.orderBy(productos, "nombre", "asc");
    guardarProductos(productosClone);

    if (e.target.value === "defecto") {
    }

    if (e.target.value === "menoramayor") {
      const productosClone = _.orderBy(productos, "precio", "asc");
      guardarProductos(productosClone);
    }

    if (e.target.value === "mayoramenor") {
      const productosClone = _.orderBy(productos, "precio", "desc");
      guardarProductos(productosClone);
    }

    if (e.target.value === "descuento") {
      const productosClone = _.orderBy(productos, "descuento", "desc");
      guardarProductos(productosClone);
    }
  };

  return (
    <div className="overflow-x-hidden">
      {loading === true ? (
        <div className="md:w-5/5 xl:w-5/5 bg-gray-900 flex justify-center items-center h-screen">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="overflow-x-hidden">
          <FrontHeader
            toggleDrawer={toggleDrawer}
            searchterm={searchterm}
            setSearchTerm={setSearchTerm}
          />

          <div className="text-3xl font-light mb-4">
            <div> <SearchAlgoliaFront /> </div>
            <div className="p-5 mx-auto max-w-screen-2xl">
              <Drawer
                size={350}
                open={isOpen}
                onClose={toggleDrawer}
                direction="right"
              >
                <Cart isOpen={isOpen} setIsOpen={setIsOpen} />
              </Drawer>

              <div className="flex justify-between flex-wrap mb-4">
                <div>
                  <label
                    className="font-bold cursor-pointer hover:underline hover:text-yellow-500 self-center"
                    onClick={() => {
                      navigate("/" + "redirectcategoria/" + categoria);
                    }}
                  >
                    {categoria}
                  </label>
                  <label
                    className="font-bold cursor-pointer hover:underline hover:text-yellow-500 self-center"
                    onClick={() => {
                      navigate(
                        "/" +
                          "redirectsubcategoria/" +
                          categoria +
                          "/" +
                          subcategoria
                      );
                    }}
                  >
                    {subcategoria ? " / " + subcategoria : null}
                  </label>

                  <label
                    className="font-bold cursor-pointer hover:underline hover:text-yellow-500 self-center"
                    onClick={() => {
                      navigate(
                        "/" +
                          "redirectsubsubcategoria/" +
                          categoria +
                          "/" +
                          subcategoria +
                          "/" +
                          subsubcategoria
                      );
                    }}
                  >
                    {subsubcategoria ? " / " + subsubcategoria : null}
                  </label>
                </div>

                <select
                  className="bg-purple-900 hover:bg-purple-700 text-white appearance-none border-none rounded-lg xs:text-xs sm:text-sm focus:ring-4 focus:ring-purple-300 shadow-md text-center xs:mt-2 p-2 justify-end"
                  onChange={seleccionOrden}
                  name="filtro1"
                  value={filtro1}
                >
                  <option className="text-white" value="defecto">
                    Orden por nombre
                  </option>
                  <option className="text-white" value="menoramayor">
                    Ordenar de menor a mayor precio
                  </option>
                  <option className="text-white" value="mayoramenor">
                    Ordenar de mayor a menor precio
                  </option>
                  <option className="text-white" value="descuento">
                    Ordenar de mayor a menor descuento
                  </option>
                </select>
              </div>

              {Object.keys(productos).length > 0 ? (
                <div>
                  <div className="grid grid-flow-row-dense grid-cols-2 gap-3 justify-between xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-5">
                    {displayedProducts.map((producto, id) => {
                      const { categoria } = producto;

                      return (
                        <div key={id}>
                          {/* {mostrarHeading(categoria, id)} */}

                          <div key={producto.id}>
                            <FrontProducto
                              key={producto.id}
                              producto={producto}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex justify-center mt-6 text-base">
                    <MyPaginate
                      pageCount={Math.ceil(productos.length / PAGE_SIZE)}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageChange}
                      className="pagination"
                      previousLabel="<"
                      nextLabel=">"
                      containerClassName={
                        "flex flex-row items-center justify-center space-x-2"
                      }
                      pageClassName={
                        "rounded-full w-8 h-8 flex items-center justify-center text-gray-500 hover:text-gray-900 hover:bg-gray-200 transition duration-150 ease-in-out ml-2 mr-2"
                      }
                      activeClassName={"bg-gray-500 text-white"}
                      previousClassName={
                        "rounded-full w-8 h-8 flex items-center justify-center text-gray-500 hover:text-gray-900 hover:bg-gray-200 transition duration-150 ease-in-out mr-2"
                      }
                      nextClassName={
                        "rounded-full w-8 h-8 flex items-center justify-center text-gray-500 hover:text-gray-900 hover:bg-gray-200 transition duration-150 ease-in-out ml-2 "
                      }
                      disabledClassName={"opacity-50 cursor-not-allowed"}
                      forcePage={seleccionada}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex justify-center m-4">
                  <p>No hay productos en esta categoría</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <FrontFooter />
    </div>
  );
};

export default Categoria;
