import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router";

//FIREBASE DE ESTE STORE
import firebase, { FirebaseContext } from "./firebase";

//PROVEEDOR DE AUTENTIFICACIÓN DE FIREBASE
import { AuthProvider } from "./context/AuthContext";

//CONTEXT
import PedidoState from "./context/pedidos/pedidosState";
import ProductosContext from "./context/ProductosContext";

import SettingsContext from "./context/SettingsContext";

//FIREBASE CUSTOMERS DE REVDEV
import firebaseRevdev, { FirebaseContextRevdev } from "./firebaseRevdev";

//Importaciones de Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

//PAGES
import Pedidos from "./components/paginas/Pedidos";
import Catalogo from "./components/paginas/Catalogo";
import Registro from "./components/usuarios/Registro";
import Login from "./components/usuarios/Login";
import RutaPrivada from "./components/usuarios/RutaPrivada";
import RutaPrivadaAdmin from "./components/usuarios/RutaPrivadaAdmin";
import RutaPrivadaOwner from "./components/usuarios/RutaPrivadaOwner";
import EditarProducto from "./components/paginas/EditarProducto";
import VerProducto from "./components/paginas/VerProducto";
import Settings from "./components/paginas/Settings";
import Usuarios from "./components/paginas/Usuarios";
import Reportes from "./components/paginas/Reportes";
import FrontPage from "./components/paginas/FrontPage";
import UsuarioDetail from "./components/paginas/UsuarioDetail";
import UsuarioDerechos from "./components/paginas/UsuarioDerechos";
import UserActivity from "./components/paginas/UserActivity";
import PasswordReset from "./components/usuarios/PasswordReset";
import Page404 from "./components/paginas/Page404";
import Page403 from "./components/paginas/Page403";
import UserAccount from "./components/paginas/UserAccount";
import Checkout from "./components/paginas/Checkout";
import Payment from "./components/paginas/Payment";
import Categoria from "./components/paginas/Categoria";
import Subscription from "./components/paginas/Subscription";
import RenewSubscription from "./components/paginas/RenewSubscription";
import Confirmacion from "./components/paginas/Confirmacion";
import ConfirmacionSuscripcion from "./components/paginas/ConfirmacionSubscripcion";
import FrontPedidos from "./components/paginas/FrontPedidos";
import Error from "./components/paginas/Error";
import CommentsAdmin from "./components/paginas/CommentsAdmin";
import MakeAdmin from "./components/usuarios/MakeAdmin";
import RemoveAdmin from "./components/usuarios/RemoveAdmin";
import About from "./components/paginas/About";
import MakeOwner from "./components/usuarios/MakeOwner";
import RemoveOwner from "./components/usuarios/RemoveOwner";
import Categories from "./components/paginas/Categories";
import Redirect from "./components/paginas/Redirect";
import RedirectCategoria from "./components/paginas/RedirectCategoria";
import RedirectSubCategoria from "./components/paginas/RedirectSubCategoria";
import RedirectSubSubCategoria from "./components/paginas/RedirectSubSubCategoria";
import GenerateSitemap from "./components/paginas/GenerateSitemap";
import ImageGallery from "./components/ui/ImageGallery";
import Contact from "./components/paginas/Contact";
import Soporte from "./components/paginas/Soporte";
import Admin from "./components/paginas/Admin";
import PaypalSettings from './components/paginas/PaypalSettings'
import StripeSettings from './components/paginas/StripeSettings'
import AlgoliaSettings from './components/paginas/AlgoliaSettings'
import MelisearchSettings from './components/paginas/MelisearchSettings'
import { HelmetProvider } from 'react-helmet-async';

//libreria que dice cuanto tiempo ha transcurrido
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";

import { DragDropContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

const helmetContext = {};

// import { SortableTreeWithoutDndContext as SortableTree } from "react-sortable-tree";

var isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);

function App() {
  const [productoState, setProductoState] = useState({});
  const [usuarioState, setUsuarioState] = useState({});
  const [totalPedido, guardarTotalPedido] = useState(0);

  //Todo sobre la subscripción
  const [mensualidad, guardarMensualidad] = useState();
  const [meses, guardarMeses] = useState(1);
  const [plan, guardarPlan] = useState();
  const [diferencia, setDiferencia] = useState();
  const [customer, setCustomer] = useState([]);

  //State de los settings
  const [settings, guardarSettings] = useState([""]);

  //Stripe
  const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

  //  Tiempo que transcurrio
  TimeAgo.addDefaultLocale(es);

  // Online state
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  //DETECTA SI HAY CONEXION A INTERNET
  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    const obtenerSettings = () => {
      //obtiene los settings
      const db = firebase.db;
      const collectionRef = db.collection("settings");

      const unsubscribe = collectionRef.onSnapshot((snapshot) => {
        const settings = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        guardarSettings(settings);

        //Verifica la subscripción

        const docRef = firebaseRevdev.db;

        //obtiene el usuario de la BD de REVDEV CUSTOMERS

        const obtenerDatos = () => {
          docRef
            .collection("customers")
            .where("tiendaid", "==", settings[0].tiendaid)
            .onSnapshot(manejarSnapshot);
        };

        //se ejecuta la funcion para obtener datos

        obtenerDatos();

        function manejarSnapshot(snapshot) {
          let customerSnap = snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });

          setCustomer(customerSnap);

          //CALCULA LO QUE FALTA PARA QUE TERMINE LA SUBSCRIPCION
          //Un día en milisegundos
          const one_day = 1000 * 60 * 60 * 24;

          //Fechas
          const date1_ms = customerSnap[0].expira;
          const date2_ms = Date.now();

          // Diferencia de fechas en milisegundos
          const difference_ms = date1_ms - date2_ms;

          //diferencia es la cantidad de días qrestantes para que renueve la subscripción
          // Convierte a días
          setDiferencia(Math.round(difference_ms / one_day));
        }
      });

      return () => unsubscribe();
    };

    obtenerSettings();
  }, []);

  return (
    <>
      {isOnline ? (
        <FirebaseContextRevdev.Provider
          value={{
            firebaseRevdev,
            diferencia,
            mensualidad,
            guardarMensualidad,
            meses,
            guardarMeses,
            plan,
            guardarPlan,
          }}
        >
          <FirebaseContext.Provider value={{ firebase }}>
          <HelmetProvider context={helmetContext}>
              <SettingsContext.Provider value={{ settings }}>
              <AuthProvider>
                <ProductosContext.Provider
                  value={{
                    productoState,
                    setProductoState,
                    usuarioState,
                    setUsuarioState,
                    totalPedido,
                    guardarTotalPedido,
                  }}
                >
                  <PedidoState>
                    {/*RUTAS PRIVADAS DE ADMINISTRADOR*/}
                    <Routes>
                      <RutaPrivadaAdmin path="/pedidos">
                        <Pedidos />
                      </RutaPrivadaAdmin>

                      <RutaPrivadaAdmin path="/catalogo">
                        <Catalogo />
                      </RutaPrivadaAdmin>

                      <RutaPrivadaAdmin path="/editar-producto">
                        <EditarProducto />
                      </RutaPrivadaAdmin>

                      <RutaPrivadaAdmin path="/usuarios">
                        <Usuarios />
                      </RutaPrivadaAdmin>

                      <RutaPrivada path="/reportes">
                        <Reportes />
                      </RutaPrivada>

                      <RutaPrivadaAdmin path="/usuariodetail">
                        <UsuarioDetail />
                      </RutaPrivadaAdmin>

                      <RutaPrivadaAdmin path="/categorias">
                        <Categories />
                      </RutaPrivadaAdmin>

                      <RutaPrivadaAdmin path="/usuarioderechos">
                        <UsuarioDerechos />
                      </RutaPrivadaAdmin>

                      <RutaPrivadaAdmin path="/actividad">
                        <UserActivity />
                      </RutaPrivadaAdmin>

                      <RutaPrivadaAdmin path="/admin">
                        <Admin />
                      </RutaPrivadaAdmin>

                      <RutaPrivadaAdmin path="/gallery">
                        <ImageGallery />
                      </RutaPrivadaAdmin>

                      {/*RUTAS PRIVADAS DE OWNER*/}

                      <RutaPrivadaOwner path="/configuracion">
                        <Settings />
                      </RutaPrivadaOwner>

                      <RutaPrivadaOwner path="/subscription">
                        <Subscription />
                      </RutaPrivadaOwner>

                      <RutaPrivadaOwner path="/renewsubscription">
                        <RenewSubscription />
                      </RutaPrivadaOwner>

                      <RutaPrivadaOwner path="/makeadmin">
                        <MakeAdmin />
                      </RutaPrivadaOwner>

                      <RutaPrivadaOwner path="/removeadmin">
                        <RemoveAdmin />
                      </RutaPrivadaOwner>

                      <RutaPrivadaOwner path="/makeowner">
                        <MakeOwner />
                      </RutaPrivadaOwner>

                      <RutaPrivadaOwner path="/removeowner">
                        <RemoveOwner />
                      </RutaPrivadaOwner>

                      <RutaPrivadaOwner path="/paypalsettings">
                        <PaypalSettings />
                      </RutaPrivadaOwner>

                      <RutaPrivadaOwner path="/stripesettings">
                        <StripeSettings />
                      </RutaPrivadaOwner>

                      <RutaPrivadaOwner path="/algoliasettings">
                        <AlgoliaSettings />
                      </RutaPrivadaOwner>

                      <RutaPrivadaOwner path="/melisearchsettings">
                        <MelisearchSettings />
                      </RutaPrivadaOwner>

                      <RutaPrivada path="/confirmacionsuscripcion">
                        <ConfirmacionSuscripcion />
                      </RutaPrivada>

                      {/*RUTAS PRIVADAS DE USUARIO*/}
                      <RutaPrivada path="/useraccount">
                        <UserAccount />
                      </RutaPrivada>

                      <RutaPrivada path="/checkout">
                        <Checkout />
                      </RutaPrivada>

                      <RutaPrivada path="/payment">
                        {/*PROVIDER DE STRIPE*/}
                        <Elements stripe={stripePromise}>
                          <Payment />
                        </Elements>
                      </RutaPrivada>

                      <RutaPrivada path="/confirmacion">
                        <Confirmacion />
                      </RutaPrivada>

                      <RutaPrivada path="/mispedidos">
                        <FrontPedidos />
                      </RutaPrivada>

                      <RutaPrivada path="/error">
                        <Error />
                      </RutaPrivada>

                      <RutaPrivada path="/comentarios">
                        <CommentsAdmin />
                      </RutaPrivada>

                      <RutaPrivada path="/soporte">
                        <Soporte />
                      </RutaPrivada>

                      {/*RUTAS PUBLICAS */}

                      <Route exact path="/" element={<FrontPage />} />
                      <Route exact path="/nosotros" element={<About />} />
                      <Route exact path="/contacto" element={<Contact />} />
                      <Route exact path="/registro" element={<Registro />} />
                      <Route exact path="/login" element={<Login />} />
                      <Route
                        exact
                        path="/passwordreset"
                        element={<PasswordReset />}
                      />
                      <Route
                        exact
                        path="/producto/:id"
                        element={<VerProducto />}
                      />
                      <Route exact path="/page404" element={<Page404 />} />
                      <Route exact path="/page403" element={<Page403 />} />
                      <Route
                        exact
                        path="/sitemap"
                        element={<GenerateSitemap />}
                      />

                      <Route
                        exact
                        path="/categoria/:categoria"
                        element={<Categoria />}
                      />
                      <Route
                        exact
                        path="/categoria/:categoria/:subcategoria"
                        element={<Categoria />}
                      />
                      <Route
                        exact
                        path="/categoria/:categoria/:subcategoria/:subsubcategoria"
                        element={<Categoria />}
                      />

                      {/*REDIRECCIONA SI SE ENCUENTRA EN LA MISMA RUTA POR UN PROBLEMA DE REACT ROUTER DOM */}

                      <Route
                        exact
                        path="/redirect/:id"
                        element={<Redirect />}
                      />

                      <Route
                        exact
                        path="/redirectcategoria/:categoria"
                        element={<RedirectCategoria />}
                      />
                      <Route
                        exact
                        path="/redirectsubcategoria/:categoria/:subcategoria"
                        element={<RedirectSubCategoria />}
                      />

                      <Route
                        exact
                        path="/redirectsubsubcategoria/:categoria/:subcategoria/:subsubcategoria"
                        element={<RedirectSubSubCategoria />}
                      />

                      <Route path="*" element={<Page404 />} />
                    </Routes>
                  </PedidoState>
                </ProductosContext.Provider>
              </AuthProvider>
            </SettingsContext.Provider>
            </HelmetProvider>
          </FirebaseContext.Provider>
          
        </FirebaseContextRevdev.Provider>
      ) : (
        <div className="relative min-h-screen flex flex-col justify-center items-center bg-gray-900 p-10">
          <div className="relative sm:max-w-sm w-full text-white">
            <svg
              clipRule="evenodd"
              fillRule="evenodd"
              strokeLinejoin="round"
              strokeMiterlimit="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              stroke="white"
              fill="white"
              strokeWidth=".1"
            >
              <path
                d="m12.002 21.534c5.518 0 9.998-4.48 9.998-9.998s-4.48-9.997-9.998-9.997c-5.517 0-9.997 4.479-9.997 9.997s4.48 9.998 9.997 9.998zm0-1.5c-4.69 0-8.497-3.808-8.497-8.498s3.807-8.497 8.497-8.497 8.498 3.807 8.498 8.497-3.808 8.498-8.498 8.498zm0-6.5c-.414 0-.75-.336-.75-.75v-5.5c0-.414.336-.75.75-.75s.75.336.75.75v5.5c0 .414-.336.75-.75.75zm-.002 3c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z"
                fillRule="nonzero"
              />
            </svg>
            <div className="text-xl bg-red-500 text-white flex justify-center w-full p-5 rounded-lg text-center">
              No tienes conexión a internet
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DragDropContext(isTouchDevice ? TouchBackend : HTML5Backend)(
  App
);
