import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../../firebase";
import Sidebar from "../ui/Sidebar";
import ReactLoading from "react-loading";
import PayPal from "./PayPal";
import FrontHeader from "./FrontHeader";
import FrontFooter from "./FrontFooter";
import PedidoContext from "../../context/pedidos/pedidosContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import CheckoutForm from "./Stripe";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeTest from "./StripeTest";
import axios from "axios";
import StripeButton from "../ui/StripeButton";
import SettingsContext from "../../context/SettingsContext";
import Modal from "react-modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(17, 24, 39)",
  },
  overlay: {
    backgroundColor: "rgba(17, 24, 39, 0.5)",
  },
};

const Payment = () => {
  //mueve hacia arriba de la pagina
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  let stripePromise;

  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_KEY
      );
    }
    return stripePromise;
  };

  const item = {
    quantity: 1,
    price_data: {
      currency: "mxn",
      unit_amount: 10 * 100,
      product_data: {
        name: "PRUEBA DE PRODUCTO",
      },
    },
  };

  //MODAL WINDOW
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const checkoutOptions = {
    lineItems: [item],
    mode: "payment",
    succcessUrl: `${window.location.origin}/confirmacion`,
    cancelUrl: `${window.location.origin}/error`,
  };

  const redirectToCheckout = async () => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log(error);
  };

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const [loading, setLoading] = useState(false);

  const [loading1, setLoading1] = useState(false);

  const [totalPedido, guardarTotalPedido] = useState(0);

  //Context con los Settings

  const { settings } = useContext(SettingsContext);

  //state de la busqueda
  const [searchterm, setSearchTerm] = useState("");

  //Context del pedido
  const {
    pedido,
    mostrarResumen,
    eliminarProducto,
    pedidoRealizado,
    guardarPedido,
  } = useContext(PedidoContext);

  const navigate = useNavigate();

  //state de los datos de usuario

  const [datos, guardarDatos] = useState([]);

  //extraer el usuario de auth
  const { usuario } = useAuth();

  const [nombre, establecerNombre] = useState("");
  const [calle, establecerCalle] = useState("");
  const [colonia, establecerColonia] = useState("");
  const [ciudad, establecerCiudad] = useState("");
  const [estado, establecerEstado] = useState("");
  const [codigoPostal, establecerCodigoPostal] = useState("");
  const [telefono, establecerTelefono] = useState("");
  const [correo, establecerCorreo] = useState("");

  //estado con envios
  const [envio, establecerEnvio] = useState("");
  const [costo_envio, establecerCosto_envio] = useState(0);

  //el check de terminos y condiciones
  const [isDisabled, setIsDisabled] = useState(true);

  /*   useEffect(() => {
    const getSettings = async () => {
      const dbRef = firebase.db.collection("settings");
      try {
        const settings = await dbRef.onSnapshot((snapshot) => {
          const settings = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          //guarda los settings obtenidos
          guardarSettings(settings);

          if (Object.keys(settings).length > 0) {
            establecerEnvio(settings[0].envio);
            establecerCosto_envio(settings[0].costo_envio);
          }

          //recalcula el pedido
          const calcularTotalPedido = () => {
            let suma = 0;

            pedido.forEach((item) => {
              if (item.descuento > 0) {
                let precio_final =
                  item.precio - item.precio * (item.descuento / 100);
                suma = suma + precio_final.toFixed(2) * item.cantidad;
              } else {
                suma = suma + item.precio * item.cantidad;
              }
            });

            if (usuario && Object.keys(pedido).length > 0) {
            //suma al total del pedido el costo de envio
            if (settings[0].envio == "costo") {
              suma = suma + settings[0].costo_envio;
            }}

            guardarTotalPedido(suma);
          };

          calcularTotalPedido();

          //se limpia la referencia a la base de datos
          return () => dbRef();
        });
      } catch (error) {
        console.log(error);
      }
    };

    getSettings();
  }, [pedido]); */

  useEffect(() => {
    if (Object.keys(settings).length > 0) {
      establecerEnvio(settings[0].envio);
      establecerCosto_envio(settings[0].costo_envio);
    }

    //recalcula el pedido
    const calcularTotalPedido = () => {
      let suma = 0;

      pedido.forEach((item) => {
        if (item.descuento > 0) {
          let precio_final = item.precio - item.precio * (item.descuento / 100);
          suma = suma + precio_final.toFixed(2) * item.cantidad;
        } else {
          suma = suma + item.precio * item.cantidad;
        }
      });

      if (usuario && Object.keys(pedido).length > 0) {
        //suma al total del pedido el costo de envio
        if (settings[0].envio == "costo") {
          suma = suma + settings[0].costo_envio;
        }
      }

      guardarTotalPedido(suma);
    };

    calcularTotalPedido();
  }, [pedido]);

  //carga el carrito de comprass
  useEffect(() => {
    //verifica que esté el usuario auth y su pedido sin items y su carro no tenga items

    if (usuario && Object.keys(pedido).length === 0) {
      //lo ponemos en el state del pedido
      const dbRef = firebase.db
        .collection("cart")
        .doc(usuario.uid)
        .get()
        .then((doc) => {
          //firebase nos entrega un objeto que debe ser convertido a un arreglo
          //console.log("DOC DATA ES", doc.data());

          if (doc.data()) {
            //convertimos de Objeto a un Arreglo, es que el formto del carrito
            const newArrayDataOfObject = Object.values(doc.data());

            //console.log("EL ARREGLO ES", newArrayDataOfObject);

            //por cada item del pedido
            newArrayDataOfObject.forEach((item) => {
              const pedidoSpread = { ...item };
              //al guardar el pedido se mete item por item al pedido
              guardarPedido(pedidoSpread);
            });
          }
        });
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    const obtenerDatos = () => {
      firebase.db
        .collection("usuarios")
        .where("uidUsuario", "==", usuario.uid)
        .onSnapshot(manejarSnapshot);
    };

    //se ejecuta la funcion para obtener los datos del usuario

    obtenerDatos();

    function manejarSnapshot(snapshot) {
      let datos = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      guardarDatos(datos);

      if (datos.length !== 0) {
        //los tengo que poner en un state si no pueden dar undefined
        establecerNombre(datos[0].nombre);
        establecerCalle(datos[0].calle);
        establecerColonia(datos[0].colonia);
        establecerCiudad(datos[0].ciudad);
        establecerEstado(datos[0].estado);
        establecerCodigoPostal(datos[0].codigoPostal);
        establecerTelefono(datos[0].telefono);
        establecerCorreo(datos[0].correo);
      }

      setLoading(false);
    }
  }, []);

  //crear un objeto con el pedido
  const pedidoObj = {
    uidUsuario: usuario.uid,
    tiempoentrega: 0,
    completado: "recibido",
    total: Number(totalPedido).toFixed(2),
    orden: pedido,
    creado: Date.now(),
    nombre: nombre,
    calle: calle,
    colonia: colonia,
    ciudad: ciudad,
    estado: estado,
    codigoPostal: codigoPostal,
    telefono: telefono,
    creadostring: Date.now().toLocaleString(),
    correo: usuario.email,
    envio: envio,
    costo_envio: costo_envio,
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    /* 
const createStripeCheckout = firebase.functions().httpsCallable('createStripeCheckout')
 createStripeCheckout().then( response => {
    const sessionID = response.data.id
    stripePromise.redirectToCheckout({})
 })
  */

    try {
      axios({
        method: "POST",
        url: "https://us-central1-mystore-d7058.cloudfunctions.net/createStripeCheckout",
        data: {},
      })
        .then(function (response) {
          console.log(response.data.id);
          const sessionId = response.data.id;
          const stripe = getStripe();
          //     stripe.redirectToCheckout({sessionId: sessionId})

          const { error } = stripe.redirectToCheckout(checkoutOptions);
          console.log(error);
        })
        .catch(function (error) {
          // An error happened.

          console.log(error);
        });
    } catch (error) {}
  };

  return (
    <>
      <FrontHeader
        toggleDrawer={toggleDrawer}
        searchterm={searchterm}
        setSearchTerm={setSearchTerm}
      />

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="">
          <div className="flex justify-center text-white text-lg">
            Términos y condiciones
          </div>
          <div className="text-white">
            <ReactQuill
              readOnly={true}
              theme={"bubble"}
              value={settings[0].terminos}
            />
          </div>
          <div className="w-full flex justify-center">
            <button className="bg-green-500  rounded-md p-4  ">Aceptar</button>
          </div>
        </div>
      </Modal>

      {Object.keys(settings).length > 0 ? (
        <div className="p-4 bg-gray-800">
          <div class="mx-auto bg-gray-100 rounded-xl shadow-md overflow-hidden max-w-screen-lg  ">
            <h1 className="flex justify-center p-4 text-3xl font-semibold">
              Confirmación de pedido
            </h1>
            <div className=" w-full">
              {loading === true ? (
                <div className="flex justify-center items-center h-screen">
                  <ReactLoading color={"#22C55E"} type={"spin"} />
                </div>
              ) : (
                <div className="">
                  <label className="block text-gray-800 ml-4 text-2xl">
                    Información de envío
                  </label>
                  <div className="rounded-lg  m-4 p-2 max-w-screen-lg bg-gray-600  text-white shadow-lg xs:text-sm md:text-base">
                    <div className="ml-4 mt-2">
                      <div className="flex">
                        <p className="font-bold">Nombre: </p>{" "}
                        <p className="ml-1 text-gray-200">{nombre}</p>
                      </div>
                      <div className="flex">
                        <p className="font-bold">Calle: </p>
                        <p className="ml-1 text-gray-200">{calle}</p>
                      </div>
                      <div className="flex">
                        <p className="font-bold">Ciudad: </p>
                        <p className="ml-1 text-gray-200">{ciudad}</p>
                      </div>
                      <div className="flex">
                        <p className="font-bold">Estado: </p>
                        <p className="ml-1 text-gray-200">{estado}</p>
                      </div>
                      <div className="flex">
                        <p className="font-bold">Código postal: </p>
                        <p className="ml-1 text-gray-200">{codigoPostal}</p>
                      </div>
                      <div className="flex">
                        <p className="font-bold">Telefono:</p>
                        <p className="ml-1 text-gray-200">{telefono}</p>
                      </div>
                      <div className="flex">
                        <p className="font-bold">Correo:</p>
                        <p className="ml-1 mb-2 text-gray-200">
                          {usuario.email}
                        </p>
                      </div>
                    </div>
                  </div>

                  <label className="block text-gray-800  ml-4 text-2xl mt-6 mb-4 ">
                    Productos en el carrito
                  </label>

                  {pedido.map((producto, i) => {
                    const {
                      cantidad,
                      nombre,
                      imagen,
                      id,
                      precio,
                      descripcion,
                      descuento,
                    } = producto;
                    return (
                      <div className="bg-gray-200 rounded border-gray-600 p-2 shadow-md mb-4 ml-4 mr-4">
                        <div className="flex">
                          <img
                            className="rounded-lg  mr-2 shadow-md md:w-1/6 self-center w-1/2 "
                            alt=""
                            src={producto.imagen}
                          />

                          <div className="flex-col w-full">
                            <div className="w-full h-3/4">
                              {producto.sku ? (
                                <p className="text-gray-700 text-xs">
                                  SKU: {producto.sku}
                                </p>
                              ) : (
                                <p className="text-gray-600 text-xs mt-2"></p>
                              )}

                              <div className="flex">
                                {producto.descuento > 0 ? (
                                  <p className="text-gray-700 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
                                    {producto.cantidad} x{" "}
                                    {new Intl.NumberFormat("es-MX", {
                                      style: "currency",
                                      currency: "MXN",
                                    }).format(
                                      producto.precio -
                                        producto.precio *
                                          (producto.descuento / 100)
                                    )}{" "}
                                    {"(Descuento " + producto.descuento + "%)"}{" "}
                                    - {producto.nombre}
                                  </p>
                                ) : (
                                  <p className="text-gray-700 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
                                    {producto.cantidad} x{" "}
                                    {new Intl.NumberFormat("es-MX", {
                                      style: "currency",
                                      currency: "MXN",
                                    }).format(producto.precio)}{" "}
                                    - {producto.nombre}
                                  </p>
                                )}
                              </div>

                              <div className="font-light text-sm text-gray-700 full">
                                {descripcion.slice(0, 200)}{" "}
                                {descripcion.length >= 200 ? <>...</> : null}
                              </div>
                            </div>

                            <div className="flex justify-end h-1/4">
                              <p className="text-gray-700 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base flex font-semibold self-end ">
                                MXN{" "}
                                {new Intl.NumberFormat("es-MX", {
                                  style: "currency",
                                  currency: "MXN",
                                }).format(
                                  producto.cantidad *
                                    (
                                      producto.precio -
                                      producto.precio *
                                        (producto.descuento / 100)
                                    ).toFixed(2)
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {settings[0].envio == "gratis" &&
                  Object.keys(pedido).length > 0 ? (
                    <div className="flex justify-end">
                      <div className="m-2 justify-end text-base bg-gray-400 rounded-lg  text-white p-4 mr-4">
                        Gastos de envío: Gratis
                      </div>
                    </div>
                  ) : null}

                  {settings[0].envio == "costo" &&
                  Object.keys(pedido).length > 0 ? (
                    <div className="flex justify-end">
                      <div className="m-2 justify-end text-base bg-gray-400 rounded-lg  text-white p-4 mr-4">
                        Gastos de envío: ${settings[0].costo_envio.toFixed(2)}
                      </div>
                    </div>
                  ) : null}

                  {settings[0].envio == "posicionamiento" &&
                  Object.keys(pedido).length > 0 ? (
                    <div className="flex justify-end">
                      <div className="m-2 justify-end text-base bg-gray-400 rounded-lg  text-white p-4 mr-4">
                        Nos encotramos trabajando en esta característica
                      </div>
                    </div>
                  ) : null}
                  {settings[0].envio == "noenvio" &&
                  Object.keys(pedido).length > 0 ? (
                    <div className="flex justify-end">
                      <div className="m-2 justify-end text-base bg-gray-400 rounded-lg  text-white p-4 mr-4">
                        Actualmente no contamos con entrega a domicilio, pero
                        puedes pasar por tu pedido a nuestra tienda física.
                      </div>
                    </div>
                  ) : null}
                  {parseInt(totalPedido, 10) <= 0 ? null : (
                    <div className="flex justify-end pr-4">
                      <div></div>
                      <p className="font-bold mt-2 mb-4 justify-end text-xl xl:text-2xl  text-gray-800  border-t border-gray-400 p-4">
                        Total: MXN
                        {new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(totalPedido)}
                      </p>
                      <div></div>
                    </div>
                  )}

                  {nombre == "" ||
                  calle == "" ||
                  correo == "" ||
                  ciudad == "" ||
                  estado == "" ||
                  codigoPostal == "" ||
                  telefono == "" ? (
                    <p className="flex justify-center text-lg m-10 text-white bg-red-500 rounded-lg p-4">
                      Por favor completa toda la información de envío para
                      acceder al pago
                    </p>
                  ) : (
                    <div>
                      {Object.keys(pedido).length > 0 ? (
                        <div className="m-5 border rounded-lg p-5 bg-gray-600">
                          <div className="flex justify-center mb-4 text-base xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base mt-2">
                            <input
                              type="checkbox"
                              id="topping"
                              name="topping"
                              value="Paneer"
                              className="mr-2 "
                              onClick={() => {
                                setIsDisabled(!isDisabled);
                              }}
                            />
                            <div
                              className=" text-white hover:underline cursor-pointer"
                              onClick={() => {
                                Swal.fire({
                                  title:
                                    "<p style='color:#22c55e'>" +
                                    "Términos y condiciones" +
                                    "</p>",
                                  html:
                                    '<div style="text-align:left;color:#fff; font-size:10px;">' +
                                    settings[0].terminos +
                                    "</div> ",
                                  icon: "success",
                                  color: "#fff",
                                  background: "#18181b",
                                });

                                // setModalIsOpen(true);
                              }}
                            >
                              Acepto los términos y condiciones de The Revdev
                              Store
                            </div>
                          </div>

                          {settings[0].plataforma === "paypal" ? (
                            <div className="bg-gray-500 rounded-lg p-4">
                              <PayPal
                                totalPedido={totalPedido}
                                pedidoObj={pedidoObj}
                              />
                            </div>
                          ) : null}

                          {settings[0].plataforma === "stripe" ? (
                            <>
                              {loading1 ? (
                                <>
                                  <div className="flex justify-center mt-2">
                                    {" "}
                                    <ReactLoading
                                      color={"#22C55E"}
                                      type={"spin"}
                                    />
                                  </div>
                                  <p className="flex justify-center text-white mt-2 text-sm mb-2 text-center">
                                    Espera un momento mientas te redirigimos a
                                    la página de pago
                                  </p>
                                </>
                              ) : (
                                <div
                                  onClick={() => {
                                    //guarda el pedido en el carrito
                                    firebase.db
                                      .collection("cart")
                                      .doc(usuario.uid)
                                      .set({ ...pedido });

                                    setLoading1(true);
                                  }}
                                >
                                  <StripeButton
                                    totalPedido={totalPedido}
                                    pedidoObj={pedidoObj}
                                    disabled={isDisabled}
                                  />
                                </div>
                              )}
                            </>
                          ) : null}
                        </div>
                      ) : (
                        <div className=" flex flex-col justify-center items-center pb-8">
                          <div className="relative sm:max-w-sm w-full text-white p-16">
                            <svg
                              clip-rule="evenodd"
                              fill-rule="evenodd"
                              stroke-linejoin="round"
                              stroke-miterlimit="2"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#374151"
                              fill="#374151"
                              stroke-width=".1"
                            >
                              <path d="M10.975 8l.025-.5c0-.517-.067-1.018-.181-1.5h5.993l-.564 2h-5.273zm-2.475 10c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm11.305-15l-3.432 12h-10.428l-.455-1.083c-.323.049-.653.083-.99.083-.407 0-.805-.042-1.191-.114l1.306 3.114h13.239l3.474-12h1.929l.743-2h-4.195zm-6.305 15c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm-4.5-10.5c0 2.485-2.017 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.017-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-3.086-2.122l-1.414 1.414-1.414-1.414-.707.707 1.414 1.415-1.414 1.414.707.708 1.414-1.415 1.414 1.414.708-.708-1.414-1.413 1.414-1.414-.708-.708z" />
                            </svg>
                            <h1 className="text-lg bg-red-500 text-white flex justify-center w-full p-5 rounded-lg text-center">
                              No hay productos en el carrito
                            </h1>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full justify-center bg-gray-900  overflow-y-auto flex">
          <div className="relative sm:max-w-sm w-full text-white">
            <svg
              clipRule="evenodd"
              fillRule="evenodd"
              strokeLinejoin="round"
              strokeMiterlimit="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              stroke="white"
              fill="white"
              strokeWidth=".1"
            >
              <path
                d="m12.002 21.534c5.518 0 9.998-4.48 9.998-9.998s-4.48-9.997-9.998-9.997c-5.517 0-9.997 4.479-9.997 9.997s4.48 9.998 9.997 9.998zm0-1.5c-4.69 0-8.497-3.808-8.497-8.498s3.807-8.497 8.497-8.497 8.498 3.807 8.498 8.497-3.808 8.498-8.498 8.498zm0-6.5c-.414 0-.75-.336-.75-.75v-5.5c0-.414.336-.75.75-.75s.75.336.75.75v5.5c0 .414-.336.75-.75.75zm-.002 3c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z"
                fillRule="nonzero"
              />
            </svg>
            <div className="text-xl bg-red-500 text-white flex justify-center p-2 rounded-lg text-center  m-4">
              No es posible calcular el pedido debido a un problema de conexión
            </div>
          </div>
        </div>
      )}

      <FrontFooter />
    </>
  );
};

export default Payment;
