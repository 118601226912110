import React, { useState, useEffect, useContext, Children } from "react";
import { FirebaseContext } from "../../firebase";
import Sidebar from "../ui/Sidebar";
import ReactLoading from "react-loading";
import UserMenu from "../ui/UserMenu";
import Notifications from "../ui/Notifications";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import Example from "./Example";
import { Prompt } from "react-router-dom";
import Modal from "react-modal";
import styled from "styled-components";

//import useSound from "use-sound";
//import boopSfx from "../../media/save.wav";

import { SortableTreeWithoutDndContext as SortableTree } from "react-sortable-tree";

// Temas de React Sortable Tree
// import {FileExplorerTheme as FileExplorerThemeDrag} from 'react-sortable-tree-theme-full-node-drag';
// import FileExplorerTheme from "react-sortable-tree-theme-minimal";

import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";

//import MaterialTheme from 'react-sortable-tree-theme-material-ui';

import {
  toggleExpandedForAll,
  changeNodeAtPath,
  insertNode,
  removeNodeAtPath,
  addNodeUnderParent,
  getFlatDataFromTree,
  getNodeAtPath,
} from "react-sortable-tree";

// CSS de React Sortable Tree
import "react-sortable-tree/style.css";
import { get, update } from "lodash";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { isCompositeComponent } from "react-dom/test-utils";
import isTaxID from "validator/lib/isTaxID";

const MySortableTree = styled(SortableTree).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
})`
  .rst__lineChildren::after {
    background-color: white;
  }
  .rst__moveHandle {
    border: none;
  }
  .rst__rowContents {
    border-radius: 5px;
  }

  .rst__collapseButton {
    width: 22px;
    height: 22px;
    -webkit-box-shadow: 0 0 0 0px #000;
  }

  .rst__expandButton {
    width: 22px;
    height: 22px;
    -webkit-box-shadow: 0 0 0 0px #000;
  }

  .rst__collapseButton:hover {
    -webkit-box-shadow: 0 0 0 2px #22c55e;
    width: 26px;
    height: 26px;
  }

  .rst__expandButton:hover {
    -webkit-box-shadow: 0 0 0 2px #22c55e;
    width: 26px;
    height: 26px;
  }

  .rst__expandButton:focus {
    -webkit-box-shadow: 0 0 0 2px #22c55e;
    width: 26px;
    height: 26px;
  }

  .rst__collapseButton:focus {
    -webkit-box-shadow: 0 0 0 2px #22c55e;
    width: 26px;
    height: 26px;
  }
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(17, 24, 39)",
  },
  overlay: {
    backgroundColor: "rgba(17, 24, 39, 0.5)",
  },
};

const Categories = () => {
  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const [treeData, setTreedata] = useState({
    currentNode: {},
    searchFocusIndex: 0,
    treeData: [
      {
        title: "Default",
        children: [],
      },
    ],
  });

  //alerta de sonido
  // const [play] = useSound(boopSfx);

  const [loading, setLoading] = useState(false);

  const [changes, setChanges] = useState(false);

  //state que detecta si la pantalla es movil o de escritorio
  const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

  //state de la busqueda
  const [searchterm, setSearchTerm] = useState("");

  const [editando, establecerEditando] = useState(false);

  //State que guarda las categorias
  const [datos, guardarDatos] = useState([]);

  const [nodoTest, setNodoTest] = useState([]);

  //state de las categoria y subcategorias
  const [categoria, guardarCategoria] = useState({ filtro1: "" });
  const [subcategoria, guardarSubcategoria] = useState({ filtro2: "" });
  const [subsubcategoria, guardarSubsubcategoria] = useState({ filtro3: "" });

  //extraer del state filtro1
  const { filtro1 } = categoria;

  //extraer del state filtro2
  const { filtro2 } = subcategoria;

  const [subcategoriaTree, guardarSubcategoriaTree] = useState({});

  //extraer del state filtro3
  const { filtro3 } = subsubcategoria;

  const [subsubcategoriaTree, guardarSubsubcategoriaTree] = useState({});

  //NODO A ELIMINAR
  const [nodoEliminar, setNodoEliminar] = useState([]);
  const [pathEliminar, setPathEliminar] = useState([]);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    //POPULA LAS CATEGORIAS AL INICIO

    //copia el actual arreglo de categorias

    const miArbol = [treeData.treeData];

    //   console.log(miArbol);

    miArbol.map((sub, index) => {
      //  console.log(sub);

      sub.map((subsub) => {
        if (subsub.title == categoria) {
          guardarSubcategoriaTree(subsub.children);

          if (Object.keys(subcategoriaTree).length > 0) {
            subcategoriaTree.map((sub, index) => {
              if (sub.title == subcategoria) {
                guardarSubsubcategoriaTree(sub.children);
              }
            });
          }
        }
      });
    });
  }, [treeData, loading]);

  //MODAL WINDOW
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.

    guardarSubcategoria("");
    guardarSubsubcategoria("");
    guardarSubcategoria({ filtro2: "" });
    guardarSubsubcategoria({ filtro3: "" });
    guardarSubcategoriaTree({});
    guardarSubsubcategoriaTree({});
  }

  function closeModal() {
    guardarCategoria({ filtro1: "" });
    guardarSubcategoria({ filtro2: "" });
    guardarSubsubcategoria({ filtro3: "" });
    setIsOpen(false);
  }

  //evalua si es un dispositivo movil o un navegador convencional
  const isTouchDevice = !!(
    "ontouchstart" in window || navigator.maxTouchPoints
  );

  useEffect(() => {
    setLoading(true);

    const obtenerDatos = () => {
      firebase.db.collection("categorias").onSnapshot(manejarSnapshot);
    };

    //se ejecuta la funcion para obtener datos

    obtenerDatos();

    function manejarSnapshot(snapshot) {
      let datos = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      guardarDatos(datos);

      if (Object.keys(datos).length === 0) {
        establecerEditando(false);
      } else establecerEditando(true);

      if (editando === true) {
        //ESTABLECE UNA ID UNICA A CADA NODO PARA FACILITAR LAS OPERACIONES
        // Create a function that recursively adds unique IDs to each node
        const addIdsToNodes = (node) => {
          node.id = Math.floor(Math.random() * 1000000);

          if (node.children) {
            node.children.forEach((childNode) => {
              addIdsToNodes(childNode, node.id);
            });
          }
        };

        // Add unique IDs to each node in the data
        datos[0].treeData.forEach((node) => {
          addIdsToNodes(node);
        });

        // console.log(datos[0].treeData)
        setTreedata({ treeData: datos[0].treeData });
      }

      setLoading(false);
    }
  }, [editando]);

  //Expande o contrae los nodos
  const expandAndCollapse = (expanded) => {
    setTreedata({
      treeData: toggleExpandedForAll({
        treeData: treeData.treeData,
        expanded,
      }),
    });
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  const selectThis = (node, path) => {
    setTreedata({ treeData: treeData.treeData, currentNode: node, path: path });
  };

  const insertNewCategory = () => {
    setTreedata((treeData) => ({
      treeData: insertNode({
        treeData: treeData.treeData,
        depth: 0,
        minimumTreeIndex: treeData.treeData.length,
        newNode: { title: "Nueva categoría", children: [] },
        getNodeKey: ({ treeIndex }) => treeIndex,
      }).treeData,
    }));
  };

  const insertNewNode = (path) => {
    setTreedata((treeData) => ({
      treeData: addNodeUnderParent({
        treeData: treeData.treeData,
        parentKey: path[path.length - 1],
        expandParent: true,
        getNodeKey,
        newNode: {
          title: "Nueva categoría",
        },
        addAsFirstChild: treeData.addAsFirstChild,
      }).treeData,
    }));
  };

  // vacia el carrito de compras

  const handleEliminar = async (path, node) => {
    setNodoEliminar(node);
    console.log(node);
    setPathEliminar(path);
    console.log(path);
    console.log(path.length);

    const obtenerProductos = async () => {
      //manejando subsubcategoria
      if (path.length === 3) {
        //Root del nodo
        let rootNode = getNodeAtPath({
          treeData: treeData.treeData,
          path: [path[0]],
          getNodeKey,
          ignoreCollapsed: true,
        });

        const parent = [path[0], path[1]];

        let parentNode = getNodeAtPath({
          treeData: treeData.treeData,
          path: parent,
          getNodeKey,
          ignoreCollapsed: true,
        });

        console.log(rootNode.node.title);
        console.log(parentNode.node.title);

        const dbRef = firebase.db.collection("productos");
        const snapshot = await dbRef
          .where("categoria", "==", rootNode.node.title)
          .where("subcategoria", "==", parentNode.node.title)
          .where("subsubcategoria", "==", node.title)
          .get();
        if (snapshot.empty) {
          console.log("No matching documents.");

          //lo elimina al instante ya que no tiene productos dentro de la categoría

          setTreedata((treeData) => ({
            treeData: removeNodeAtPath({
              treeData: treeData.treeData,
              path,
              getNodeKey: ({ treeIndex }) => treeIndex,
            }),
          }));

          console.log("El arbol de categorias es", treeData.treeData);

          return;
        }

        setCounter(snapshot.size);
        setIsOpen(true);
      }
      //manejando subsubcategoria
      if (path.length === 2) {
        //Root del nodo
        let rootNode = getNodeAtPath({
          treeData: treeData.treeData,
          path: [path[0]],
          getNodeKey,
          ignoreCollapsed: true,
        });

        console.log(rootNode.node.title);

        const dbRef = firebase.db.collection("productos");
        const snapshot = await dbRef
          .where("categoria", "==", rootNode.node.title)
          .where("subcategoria", "==", node.title)
          .get();
        if (snapshot.empty) {
          console.log("No matching documents.");

          //lo elimina al instante ya que no tiene productos dentro de la categoría

          setTreedata((treeData) => ({
            treeData: removeNodeAtPath({
              treeData: treeData.treeData,
              path,
              getNodeKey: ({ treeIndex }) => treeIndex,
            }),
          }));

          return;
        }

        setCounter(snapshot.size);
        setIsOpen(true);
      }

      //manejando subsubcategoria
      if (path.length === 1) {
        const dbRef = firebase.db.collection("productos");
        const snapshot = await dbRef.where("categoria", "==", node.title).get();
        if (snapshot.empty) {
          console.log("No matching documents.");

          //lo elimina al instante ya que no tiene productos dentro de la categoría

          setTreedata((treeData) => ({
            treeData: removeNodeAtPath({
              treeData: treeData.treeData,
              path,
              getNodeKey: ({ treeIndex }) => treeIndex,
            }),
          }));

          return;
        }

        setCounter(snapshot.size);
        setIsOpen(true);
      }
    };

    if (node.children !== undefined) {
      console.log("no undefined");
      console.log(node.children.length);
      if (node.children.length > 0) {
        console.log("tiene hijos");

        Swal.fire({
          title: "<p style='color:#ef4444'>" + "Advertencia" + "</p>",
          html: '<p style="color:#fff">Para eliminar esta categoria es necesario que primero elimines o muevas los hijos que dependan de ella</p>',
          icon: "warning",
          confirmButtonColor: "#581c87",
          color: "#fff",
          background: "#18181b",
          position: "center",
          iconColor: "#ef4444",
        });
      } else {
        console.log("no tiene hijos");
        obtenerProductos();
      }
    } else {
      console.log("es undefined");
      obtenerProductos();
    }

    //   obtenerProductos();

    /*       setTreedata((treeData) => ({
          treeData: removeNodeAtPath({
            treeData: treeData.treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
          }),
     })); */
  };

  const confirmarEliminar = (path, e) => {

    e.preventDefault()

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    console.log("ELIMINANDO NODO");
    //elimina de treedata
    setTreedata((treeData) => ({
      treeData: removeNodeAtPath({
        treeData: treeData.treeData,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
      }),
    }));


    setChanges(true);


    //GUARDAR CATEGORIAS
 
/*          const categorias = {
      treeData: treeData.treeData,
    };

    if (editando == false) {
      firebase.db.collection("categorias").add(categorias);
    } else {
      firebase.db.collection("categorias").doc(datos[0].id).update(categorias);
    }  
 */
/* 
    Swal.fire({
      title:
        "<p style='color:#fff'>" +
        "Los productos se movieron con éxito, ¿quieres guardar los cambios en las categorías?" +
        "</p>",
      html: '<p style="color:#fff">Los cambios no podrán ser revertidos</p>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Guardar",
      color: "#fff",
      background: "#18181b",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        setChanges(true);
      }
    }); */

       Toast.fire({
      title: "<p style='color:#22c55e'>" + "Productos movidos" + "</p>",
      html: '<p style="color:#fff">Los productos se movieron con con éxito</p>',
      icon: "success",
      color: "#fff",
      background: "#18181b",
      position: "top-end",
      iconColor: "#22c55e",
      timer: 1500,
      showConfirmButton: false,
    }); 
  };
  const categoriasUpdate = () => {
    const categorias = {
      treeData: treeData.treeData,
    };

    console.log("El arbol de categorias es", treeData.treeData);

    if (editando == false) {
      firebase.db.collection("categorias").add(categorias);
    } else {
      firebase.db.collection("categorias").doc(datos[0].id).update(categorias);
    }
  };

  const guardarCategorias = () => {
    // console.log(treeData);

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    const categorias = {
      treeData: treeData.treeData,
    };

    if (editando == false) {
      firebase.db.collection("categorias").add(categorias);
    } else {
      firebase.db.collection("categorias").doc(datos[0].id).update(categorias);
    }

    changeName.forEach((item) => {
      const obtenerProductos = async () => {
        //manejando subsubcategoria
        if (item.lengthOriginal === 3) {
          const dbRef = firebase.db.collection("productos");
          const snapshot = await dbRef
            .where("subsubcategoria", "==", item.original)
            .get();
          if (snapshot.empty) {
            console.log("No matching documents.");
            return;
          }

          console.log("MODIFICANDO UNA SUBSUBCATEGORIA", item.original);

          snapshot.forEach((doc) => {
            console.log(doc.id, "=>", doc.data());

            //Mover a la subsubcategoría a su nueva posición

            if (item.lengthOriginal === item.lengthNuevo) {
              console.log("El elemento no se movio");

              if (item.original !== item.nuevo) {
                console.log(
                  "El elemento cambió de nombre",
                  item.original,
                  item.nuevo
                );
                firebase.db
                  .collection("productos")
                  .doc(doc.id)
                  .update({ subsubcategoria: item.nuevo });
              }
            } else {
              if (item.lengthNuevo === 2) {
                console.log("El elemento ahora pasó a ser subcategoría");
                firebase.db
                  .collection("productos")
                  .doc(doc.id)
                  .update({ subcategoria: item.nuevo, subsubcategoria: "" });
              }

              if (item.lengthNuevo === 1) {
                console.log("El elemento ahora pasó a ser categoría");
                firebase.db.collection("productos").doc(doc.id).update({
                  categoria: item.nuevo,
                  subcategoria: "",
                  subsubcategoria: "",
                });
              }
            }
          });
        }

        if (item.lengthOriginal === 2) {
          const dbRef = firebase.db.collection("productos");
          const snapshot = await dbRef
            .where("subcategoria", "==", item.original)
            .get();
          if (snapshot.empty) {
            console.log("No matching documents.");
            return;
          }

          console.log("MODIFICANDO UNA SUBCATEGORIA", item.original);

          snapshot.forEach((doc) => {
            console.log(doc.id, "=>", doc.data());

            //Mover la subcategoría a su nueva posición

            if (item.lengthOriginal === item.lengthNuevo) {
              console.log("El elemento no se movio");

              if (item.original !== item.nuevo) {
                console.log(
                  "El elemento cambió de nombre",
                  item.original,
                  item.nuevo
                );
                firebase.db
                  .collection("productos")
                  .doc(doc.id)
                  .update({ subcategoria: item.nuevo });
              }
            } else {
              if (item.lengthNuevo === 3) {
                console.log("El elemento ahora pasó a ser subsubcategoría");
                firebase.db.collection("productos").doc(doc.id).update({
                  categoria: item.root,
                  subcategoria: item.parent,
                  subsubcategoria: item.nuevo,
                });
              }

              if (item.lengthNuevo === 1) {
                console.log("El elemento ahora pasó a ser categoría");
                firebase.db.collection("productos").doc(doc.id).update({
                  categoria: item.nuevo,
                  subcategoria: "",
                  subsubcategoria: "",
                });
              }
            }
          });
        }

        if (item.lengthOriginal === 1) {
          const dbRef = firebase.db.collection("productos");
          const snapshot = await dbRef
            .where("categoria", "==", item.original)
            .get();
          if (snapshot.empty) {
            console.log("No matching documents.");
            return;
          }

          console.log("MODIFICANDO UNA CATEGORIA", item.original);

          snapshot.forEach((doc) => {
            console.log(doc.id, "=>", doc.data());

            //Mover la subcategoría a su nueva posición

            if (item.lengthOriginal === item.lengthNuevo) {
              console.log("El elemento no se movio");

              if (item.original !== item.nuevo) {
                console.log(
                  "El elemento cambió de nombre",
                  item.original,
                  item.nuevo
                );
                firebase.db
                  .collection("productos")
                  .doc(doc.id)
                  .update({ categoria: item.nuevo });
              }
            } else {
              if (item.lengthNuevo === 3) {
                console.log("El elemento ahora pasó a ser subsubcategoría");
                firebase.db.collection("productos").doc(doc.id).update({
                  categoria: item.root,
                  subcategoria: item.parent,
                  subsubcategoria: item.nuevo,
                });
              }

              if (item.lengthNuevo === 2) {
                console.log("El elemento ahora pasó a ser categoría");
                firebase.db.collection("productos").doc(doc.id).update({
                  categoria: item.parent,
                  subcategoria: item.nuevo,
                });
              }
            }
          });
        }
      };

      obtenerProductos();

      //vaciar arreglo de cambios

      const arregloVacio = [];

      setChangeName(arregloVacio);

      console.log(changeName);

      console.log("DONE");
    });

    setChanges(false);

    Toast.fire({
      title: "<p style='color:#22c55e'>" + "Guardado" + "</p>",
      html: '<p style="color:#fff">Se guardaron las categorías</p>',
      icon: "success",
      color: "#fff",
      background: "#18181b",
      position: "top-end",
      iconColor: "#22c55e",
      timer: 1500,
      showConfirmButton: false,
    });
  };

  const flatData = getFlatDataFromTree({
    treeData: treeData.treeData,
    getNodeKey: ({ node }) => node.id, // This ensures your "id" properties are exported in the path
    ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
  }).map(({ node, path }) => ({
    id: node.id,
    title: node.title,

    // The last entry in the path is this node's key
    // The second to last entry (accessed here) is the parent node's key
    parent: path.length > 1 ? path[path.length - 2] : null,
  }));
  //TODO SOBRE LAS CATEGORIAS

  const seleccionCategoria = (e) => {
    guardarCategoria({ ...categoria, [e.target.name]: e.target.value });

    //  guardarSubsubcategoria("");

    guardarSubcategoria("");
    guardarSubsubcategoria("");
    guardarSubcategoria({ filtro2: "" });
    guardarSubsubcategoria({ filtro3: "" });
    guardarSubcategoriaTree({});
    guardarSubsubcategoriaTree({});

    //copia el actual arreglo de categorias
    const miArbol = [treeData.treeData];

    miArbol.map((sub, index) => {
      sub.map((subsub) => {
        if (subsub.title == e.target.value) {
          guardarSubcategoriaTree(subsub.children);
        }
      });
    });
  };

  const seleccionSubcategoria = (e) => {
    guardarSubcategoria({ ...subcategoria, [e.target.name]: e.target.value });

    guardarSubsubcategoria("");
    guardarSubsubcategoria({ filtro3: "" });
    guardarSubsubcategoriaTree({});

    subcategoriaTree.map((sub, index) => {
      if (sub.title == e.target.value) {
        guardarSubsubcategoriaTree(sub.children);
      }
    });
  };

  const seleccionSubsubcategoria = (e) => {
    guardarSubsubcategoria({
      ...subsubcategoria,
      [e.target.name]: e.target.value,
    });

    subcategoriaTree.map((sub, index) => {
      if (sub.title == e.target.value) {
        guardarSubsubcategoriaTree(sub.children);
      }
    });
  };

  //LO RELACIONADO PARA DETECTAR CAMBIOS EN LAS CATEGORIAS

  //Si se cambia el nombre a una categoría guardo en un arreglo los cambios y despúes hago los updates a firebase
  const [changeName, setChangeName] = useState([]);

  //AL MOVER UN NODO TENGO QUE REGISTRAR EL PARENT NODE Y SU POSICIÓN (1,2 O 3) QUE REPRESENTA CATEGORIA, SUBCATEGORIA O SUBSUBCATEGORIA

  const onMoveNode = ({ node, path, prevPath, nextParentNode }) => {
    //state que detecta cambios
    setChanges(true);

    //Root del nodo
    let rootNode = getNodeAtPath({
      treeData: treeData.treeData,
      path: [path[0]],
      getNodeKey,
      ignoreCollapsed: true,
    });

    console.log("ROOT", rootNode.node.title);

    //extrae la raiz del nodo que se está moviendo

    //checa si el nodo está registrado o no en el arreglo de cambios

    const check = changeName.find((item) => item.nuevo === node.title);

    let cambioNombre = [...changeName];

    if (check) {
      changeName.forEach((item, index) => {
        console.log("ITEM", item);
        console.log(item.id, node.id);
        if (item.nuevo === node.title) {
          console.log("ESTOY MODIFICANDO", item.nuevo, node.title, index);

          //nextParentNode arroja null si no tiene Parent, tengo que declarar como "none" si no tiene Parent el nodo

          if (nextParentNode !== null) {
            cambioNombre[index].lengthNuevo = path.length;
            cambioNombre[index].parent = nextParentNode.title;
            cambioNombre[index].root = rootNode.node.title;
          } else {
            cambioNombre[index].lengthNuevo = path.length;
            cambioNombre[index].parent = "none";
            cambioNombre[index].root = "none";
          }

          setChangeName([...cambioNombre]);

          //    console.log("changeName", changeName);
        }
      });
    } else {
      console.log("PRIMERA VEZ");

      //nextParentNode arroja null si no tiene Parent, tengo que declarar como "none" si no tiene Parent el nodo

      if (nextParentNode !== null) {
        const cambioObj = {
          id: node.id,
          original: node.title,
          nuevo: node.title,
          lengthOriginal: prevPath.length,
          lengthNuevo: path.length,
          parent: nextParentNode.title,
          root: rootNode.node.title,
        };

        cambioNombre = [...cambioNombre, cambioObj];

        setChangeName([...cambioNombre]);
      } else {
        const cambioObj = {
          id: node.id,
          original: node.title,
          nuevo: node.title,
          lengthOriginal: prevPath.length,
          lengthNuevo: path.length,
          parent: "none",
          root: "none",
        };

        cambioNombre = [...cambioNombre, cambioObj];

        setChangeName([...cambioNombre]);
      }
    }

    //EN UNA SUBCATEORIA PUEDE MOVERSE CON TODO E HIJOS, POR LO QUE HAY QUE ACTUALIZAR LOS HIJOS TAMBIEN
    //AQUI SE ASUME QUE AL TENER UN LENGTH DE 3 NO PUEDE TENER HIJOS POR LO TANTO NO TENGO QUE ACTUALIZARLOS

    if (path.length >= 1) {
      //Tiene hijos

      if (node.children) {
        console.log("path.length + 1", path.length + 1);
        console.log("rootNode.node.title", rootNode.node.title);
        if (path.length + 1 > 2) {
          var rootChild = rootNode.node.title;
        } else {
          var rootChild = node.title;
        }

        console.log("Hijos", node.children.length);

        //por cada hijo va a comprobar si existe o no en el arreglo
        node.children.forEach((children, i) => {
          const checkChildren = changeName.find(
            (item) => item.id === children.id
          );

          if (checkChildren) {
            console.log(children, i, "existe hijo en el arreglo");

            cambioNombre[i + 1].lengthNuevo = path.length + 1;
            cambioNombre[i + 1].root = rootChild;

            setChangeName([...cambioNombre]);
          } else {
            console.log(children, "no existe hijo en el arreglo");

            const cambioObjChildren = {
              id: children.id,
              original: children.title,
              nuevo: children.title,
              lengthOriginal: prevPath.length + 1,
              lengthNuevo: path.length + 1,
              parent: node.title,
              root: rootChild,
            };

            cambioNombre = [...cambioNombre, cambioObjChildren];

            setChangeName([...cambioNombre]);
          }
        });
      }
    }

    console.log("changeName", changeName);
  };

  return (
    <div className="md:flex min-h-screen">
      <Sidebar />

      {loading === true ? (
        <div className="md:w-4/5 xl:w-4/5 bg-gray-900 flex justify-center items-center h-screen">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="md:w-4/5 xl:w-4/5 bg-gray-900 p-4 h-screen overflow-x-hidden">
          <header>
            <div className="flex justify-end">
              {/*               <div className="flex text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 mt-1 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </svg>

                <h1 className="text-3xl mb-2">Categorías</h1>
              </div> */}
              <div className="flex">
                <div className="mt-2">
                  {isStatic ? <Notifications /> : null}
                </div>
                <div className=" z-50">{isStatic ? <UserMenu /> : null}</div>
              </div>
            </div>
          </header>

          <Prompt
            when={changes}
            message="¿Estás seguro de que deseas abandonar la página? Tus cambios no se guardarán."
          />

          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Estás eliminando una categoría"
          >
            <div className="w-full justify-center flex">
              <svg
                clipRule="evenodd"
                fillRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ef4444"
                fill="#ef4444"
                strokeWidth=".1"
                className="w-1/2"
              >
                <path
                  d="m12.002 21.534c5.518 0 9.998-4.48 9.998-9.998s-4.48-9.997-9.998-9.997c-5.517 0-9.997 4.479-9.997 9.997s4.48 9.998 9.997 9.998zm0-1.5c-4.69 0-8.497-3.808-8.497-8.498s3.807-8.497 8.497-8.497 8.498 3.807 8.498 8.497-3.808 8.498-8.498 8.498zm0-6.5c-.414 0-.75-.336-.75-.75v-5.5c0-.414.336-.75.75-.75s.75.336.75.75v5.5c0 .414-.336.75-.75.75zm-.002 3c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z"
                  fillRule="nonzero"
                />
              </svg>
            </div>
            <div className="text-white text-base p-4 m-4 text-center">
              Si eliminas este nodo{" "}
              {nodoEliminar ? <div>{nodoEliminar.title}</div> : null} {counter}{" "}
              productos se quedarán sin categoría, ¿Qué quieres hacer con ellos?
            </div>
            <div className="text-white text-base text-left font-bold">
              Moverlos a:
            </div>
            {/* MAPEA LAS CATEGORIAS */}
            <div className="flex flex-wrap mb-4 justify-center justify-items-center text-white">
              <div className="">
                <label
                  className="block mb-2 mt-2 font-semibold text-sm"
                  htmlFor="categoria"
                >
                  Categoria
                </label>
                <select
                  className="border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 bg-white mr-2 transition-shadow"
                  onChange={seleccionCategoria}
                  name="filtro1"
                  value={filtro1}
                  id="filtro1"

                  /*               id="categoria"
                        name="categoria"
                        value={formik.values.categoria} 
                        onChange={formik.handleChange}
                      onBlur={formik.handleBlur} 
                              */
                >
                  <option value="">-Selecciona una categoría-</option>

                  {treeData.treeData.map((nodo, id) => {
                    const { title } = nodo;
                    return (
                      <option key={id} value={title}>
                        {title}
                      </option>
                    );
                  })}
                </select>
              </div>

              {subcategoriaTree !== undefined ? (
                <div>
                  {subcategoriaTree.length > 0 ? (
                    <div>
                      <label
                        className="block mb-2 mt-2 font-semibold text-sm"
                        htmlFor="categoria"
                      >
                        Subcategoria
                      </label>
                      <select
                        className="border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 bg-white mr-2 transition-shadow"
                        onChange={seleccionSubcategoria}
                        name="filtro2"
                        value={filtro2}
                        id="filtro2"
                      >
                        <option value="" onClick={console.log("me escogiste")}>
                          -Selecciona una categoría-
                        </option>

                        {subcategoriaTree.map((nodo) => {
                          const { title } = nodo;
                          return <option value={title}>{title}</option>;
                        })}
                      </select>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {subsubcategoriaTree !== undefined &&
              subcategoriaTree !== undefined ? (
                <div>
                  {subsubcategoriaTree.length > 0 &&
                  subcategoriaTree.length > 0 ? (
                    <div className="">
                      <label
                        className="block mb-2 mt-2 font-semibold text-sm"
                        htmlFor="categoria"
                      >
                        Subsubcategoria
                      </label>
                      <select
                        className="border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 bg-white mr-2 transition-shadow"
                        onChange={seleccionSubsubcategoria}
                        name="filtro3"
                        value={filtro3}
                        id="filtro3"
                      >
                        <option className="" value="">
                          -Selecciona una categoría-
                        </option>

                        {subsubcategoriaTree.map((nodo) => {
                          const { title } = nodo;
                          return <option value={title}>{title}</option>;
                        })}
                      </select>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>

            <div className="flex justify-center text-white">
              {/*               <div>{filtro1}</div>
              <div>{filtro2}</div>
              <div>{filtro3}</div> */}

              <button
                onClick={(e) => {

                  e.preventDefault();
                  e.stopPropagation();

                  console.log(nodoEliminar);

                  

                  const obtenerProductos = async () => {
                    if (pathEliminar.length === 3) {
                      //Root del nodo
                      let rootNode = getNodeAtPath({
                        treeData: treeData.treeData,
                        path: [pathEliminar[0]],
                        getNodeKey,
                        ignoreCollapsed: true,
                      });

                      const parent = [pathEliminar[0], pathEliminar[1]];

                      let parentNode = getNodeAtPath({
                        treeData: treeData.treeData,
                        path: parent,
                        getNodeKey,
                        ignoreCollapsed: true,
                      });

                      console.log(rootNode.node.title);
                      console.log(parentNode.node.title);

                      const dbRef = firebase.db.collection("productos");
                      const snapshot = await dbRef
                        .where("categoria", "==", rootNode.node.title)
                        .where("subcategoria", "==", parentNode.node.title)
                        .where("subsubcategoria", "==", nodoEliminar.title)
                        .get();

                      snapshot.forEach((doc) => {
                        console.log(doc.id, "=>", doc.data());

                        if (subsubcategoria !== "") {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ subsubcategoria: filtro3 });
                        } else {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ subsubcategoria: "" });
                        }

                        if (subcategoria !== "") {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ subcategoria: filtro2 });
                        } else {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ subcategoria: "" });
                        }

                        if (categoria !== "") {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ categoria: filtro1 });
                        } else {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ categoria: "" });
                        }
                      });
                    }

                    if (pathEliminar.length === 2) {
                      //Root del nodo
                      let rootNode = getNodeAtPath({
                        treeData: treeData.treeData,
                        path: [pathEliminar[0]],
                        getNodeKey,
                        ignoreCollapsed: true,
                      });

                      const dbRef = firebase.db.collection("productos");
                      const snapshot = await dbRef
                        .where("categoria", "==", rootNode.node.title)
                        .where("subcategoria", "==", nodoEliminar.title)
                        .get();

                      snapshot.forEach((doc) => {
                        console.log(doc.id, "=>", doc.data());

                        if (subsubcategoria !== "") {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ subsubcategoria: filtro3 });
                        } else {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ subsubcategoria: "" });
                        }

                        if (subcategoria !== "") {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ subcategoria: filtro2 });
                        } else {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ subcategoria: "" });
                        }

                        if (categoria !== "") {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ categoria: filtro1 });
                        } else {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ categoria: "" });
                        }
                      });
                    }

                    if (pathEliminar.length === 1) {
                      const dbRef = firebase.db.collection("productos");
                      const snapshot = await dbRef
                        .where("categoria", "==", nodoEliminar.title)
                        .get();

                      snapshot.forEach((doc) => {
                        console.log(doc.id, "=>", doc.data());

                        if (subsubcategoria !== "") {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ subsubcategoria: filtro3 });
                        } else {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ subsubcategoria: "" });
                        }

                        if (subcategoria !== "") {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ subcategoria: filtro2 });
                        } else {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ subcategoria: "" });
                        }

                        if (categoria !== "") {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ categoria: filtro1 });
                        } else {
                          firebase.db
                            .collection("productos")
                            .doc(doc.id)
                            .update({ categoria: "" });
                        }
                      });
                    }

                    console.log(pathEliminar);
                    console.log(nodoEliminar);

                    confirmarEliminar(pathEliminar, e);



                    guardarSubcategoria("");
                    guardarSubsubcategoria("");
                    guardarSubcategoria({ filtro2: "" });
                    guardarSubsubcategoria({ filtro3: "" });
                    guardarSubcategoriaTree({});
                    guardarSubsubcategoriaTree({});
                    setIsOpen(false);
                  };

                  obtenerProductos();
                }}
                className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 p-3 inline-flex text-base m-2 transition duration-500 ease-in-out shadow-lg"
              >
                Aceptar
              </button>
              <div>
                <button
                  onClick={(e) => {
                    guardarCategoria({ filtro1: "" });
                    guardarSubcategoria({ filtro2: "" });
                    guardarSubsubcategoria({ filtro3: "" });
                    setIsOpen(false);
                  }}
                  className="rounded-lg text-white bg-red-500 hover:bg-red-400 p-3 inline-flex text-base m-2 transition duration-500 ease-in-out shadow-lg"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </Modal>
          <div className="w-full flex xs:flex-wrap-reverse sm:flex-wrap-reverse md:justify-between lg:justify-between mt-2 ">
            <div className="lg:flex xs:grid xs:grid-cols-2 xs:w-full lg:w-max bg-gray-800 rounded-lg xs:gap-2 lg:gap-0 xs:pr-4 lg:pr-0">
              {/*               <button
                onClick={() =>
                  console.log("El arbol de categorias es", treeData.treeData)
                }
              >
                click
              </button> */}

              <button
                className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 lg:p-3 inline-flex text-base m-2 transition duration-500 ease-in-out shadow-lg w-1/4 xs:w-full justify-center xs:p-2"
                onClick={(e) =>{                  
                  e.preventDefault();
                  guardarCategorias()}}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
                  />
                </svg>
                Guardar
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                //  e.stopPropagation();
                  insertNewCategory();
                }}
                className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 lg:p-3 inline-flex text-base m-2 transition duration-500 ease-in-out shadow-lg w-1/4 xs:w-full justify-center xs:p-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                Añadir
              </button>
              <button
                className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 lg:p-3 inline-flex text-base m-2 transition duration-500 ease-in-out shadow-lg w-1/4 xs:w-full justify-center xs:p-2"
                onClick={() => expandAndCollapse(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
                  />
                </svg>
                Expandir
              </button>
              <button
                className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 lg:p-3 inline-flex text-base m-2 transition duration-500 ease-in-out shadow-lg w-1/4 xs:w-full justify-center xs:p-2"
                onClick={() => expandAndCollapse(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z"
                  />
                </svg>
                Colapsar
              </button>
            </div>

            <div className="text-sm justify-items-end mb-4 xs:w-full sm:w-full md:w-64 lg:w-64  self-center">
              <div className="relative">
                <input
                  type="text"
                  className="p-2 pl-8 rounded ring-2 ring-yellow-600 border-2 focus:bg-white focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:border-transparent w-full shadow-xl"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />
                <svg
                  className="w-5 h-5 absolute left-2.5 top-3"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#1f2937"
                  width="24px"
                  height="24px"
                  fill="#1f2937"
                  x="0px"
                  y="0px"
                  viewBox="0 -1 64 64"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23 s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92 c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17 s-17-7.626-17-17S14.61,6,23.984,6z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="mt-4 xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base bg-gray-800 rounded-lg max-w-lg">
            <MySortableTree
              maxDepth={3}
              searchQuery={searchterm}
              searchFocusOffset={treeData.searchFocusIndex}
              treeData={treeData.treeData}
              onChange={(treeData) => setTreedata({ treeData })}
              isVirtualized={false}
              onMoveNode={onMoveNode}
              generateNodeProps={({ node, path }) => ({
                title: (
                  <form
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      selectThis(node, path);
                    }}
                  >
                    <input
                      className="xs:w-24 sm:w-44 md:w-44 xs:text-xs md:text-sm"
                      value={node.title}
                      onChange={(event) => {
                        setChanges(true);

                        //SI HAY UN CAMBIO LO GUARDO EN UN ARREGLO
                        //NECESITO 3 PARAMETROS. VALOR ACTUAL DEL NOMBRE, LONGITUD DEL NODO PARA SABER SI ES CATEGORIA SUB, O SUBSUB Y VALOR AL QUE SE VA A CAMBIAR
                        //extrae la raiz del nodo que se está moviendo

                        console.log("cambiando nombre length", path.length);

                        if (path.length > 1) {
                          console.log([path[0]]);

                          //Root del nodo
                          let rootNode = getNodeAtPath({
                            treeData: treeData.treeData,
                            path: [path[0]],
                            getNodeKey,
                            ignoreCollapsed: true,
                          });

                          var root_id = rootNode.node.id;
                          var root = rootNode.node.title;

                          //parent del nodo

                          //clonamos path
                          const pathClone = [...path];
                          //quitamos ultimo elemento del array
                          pathClone.splice(-1, 1);

                          let parentNode = getNodeAtPath({
                            treeData: treeData.treeData,
                            path: pathClone,
                            getNodeKey,
                            ignoreCollapsed: true,
                          });

                          var parent_id = parentNode.node.id;
                          var parent = parentNode.node.title;
                        } else {
                          var root = "none";
                          var root_id = "none";
                          var parent = "none";
                          var parent_id = "none";
                        }

                        const check = changeName.find(
                          (item) => item.nuevo == node.title
                        );

                        let cambioNombre = [...changeName];

                        console.log(cambioNombre);

                        if (check) {
                          console.log("hay igual, ya existe el registro");

                          changeName.forEach((item, index) => {
                            if (item.id === node.id) {
                              console.log(
                                "Estoy cambiando de nombre a",
                                item.original,
                                item.nuevo
                              );
                              cambioNombre[index].nuevo = event.target.value;
                              //      cambioNombre[index].lenght = path.length;
                              setChangeName([...cambioNombre]);
                            }
                          });
                        } else {
                          console.log("no hay igual, primera vez");

                          const cambioObj = {
                            id: node.id,
                            original: node.title,
                            nuevo: event.target.value,
                            lengthOriginal: path.length,
                            lengthNuevo: path.length,
                            parent: parent,
                            root: root,
                          };

                          cambioNombre = [...cambioNombre, cambioObj];

                          //      console.log("cambioNombre", cambioNombre)

                          setChangeName([...cambioNombre]);

                          //       console.log("changeName", changeName)
                        }

                        console.log("changeName", changeName);

                        //hasta aqui la rutina para identificar cambios en el nombre

                        //actualiza el arreglo si tiene hijos

                        const title = event.target.value;
                        setTreedata((treeData) => ({
                          treeData: changeNodeAtPath({
                            treeData: treeData.treeData,
                            path,
                            getNodeKey,
                            newNode: { ...node, title },
                          }),
                        }));
                      }}
                    />

                    {path.length < 3 ? (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          //no puede añadir más nodos despues de subsubcategoría
                          if (path.length < 3) {
                            insertNewNode(path);
                          }
                        }}
                        className="rounded-lg text-white bg-blue-600 hover:bg-blue-700 inline-flex items-center p-1 ml-2 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base mr-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3 w-3"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                      </button>
                    ) : null}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //                        removeNode(path);
                        handleEliminar(path, node);
                      }}
                      className="rounded-lg text-white bg-red-500 hover:bg-red-600 inline-flex items-center p-1 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </form>
                ),
              })}
            />
          </div>

          <div className="relative text-lg bg-transparent text-gray-800 mb-5 mt-5">
            {/* <Example /> */}

            {/*             <select
              className=" w-96 block border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-5 ring-1 mb-2 focus:outline-none xs:text-sm sm:text-xs md:text-base lg:text-base xl:text-base"
              onChange={seleccionCategoria}
              name="filtro1"
              value={filtro1}
            >
              <option value="">-- Selecciona una categoría--</option>

              {treeData.treeData.map((nodo) => {
                const { title } = nodo;
                return <option value={title}>{title}</option>;
              })}
            </select>

            {subcategoria.length > 0 ? (
              <select
                className=" w-96 block border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-5 ring-1 mb-2 focus:outline-none xs:text-sm sm:text-xs md:text-base lg:text-base xl:text-base"
                onChange={seleccionSubcategoria}
                name="filtro2"
                value={filtro2}
              >
                <option value="">-- Selecciona una Subcategoría--</option>

                {subcategoria.map((nodo) => {
                  const { title } = nodo;
                  return <option value={title}>{title}</option>;
                })}
              </select>
            ) : null}

            {subsubcategoria.length > 0 && subcategoria.length > 0 ? (
              <select
                className=" w-96 block border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-5 ring-1 mb-2 focus:outline-none xs:text-sm sm:text-xs md:text-base lg:text-base xl:text-base"
                name="filtro3"
                value={filtro3}
              >
                <option value="">-- Selecciona una Subsubcategoría--</option>

                {subsubcategoria.map((nodo) => {
                  const { title } = nodo;
                  return <option value={title}>{title}</option>;
                })}
              </select>
            ) : null} */}

            {/*             {treeData.treeData.map((nodo, id) => {
              const { title } = nodo;
              return (
                <div key={nodo.id}>
                  <p className="font-bold">{title}</p>

                  {nodo.children ? (
                    <div>
                      {nodo.children.map((nodo1, id) => {
                        const { title } = nodo1;
                        const { children } = nodo1;

                        return (
                          <div>
                            <p className="ml-8">{title}</p>

                            {children ? (
                              <div>
                                {children.map((nodo2, id) => {
                                  const { title } = nodo2;
                                  return (
                                    <div>
                                      <p className="ml-16">{title}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Categories;
