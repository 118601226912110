import { Menu, Transition } from "@headlessui/react";
import { Fragment, useContext } from "react";
import { FirebaseContext } from "../../firebase/";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import user from "../../images/user.png";

const UserMenu = () => {
  
  const navigate = useNavigate();

  const { firebase } = useContext(FirebaseContext);

  const { usuario } = useAuth();

  const Logout = () => {

    const activityObj = {
      uidUsuario: usuario.uid,
      activity: "El usuario salió del sistema",
      fecha: Date.now(),
      code:"02"
    };

    firebase.auth.signOut().then(()=>{
  
      firebase.db.collection("usuarios_log").add(activityObj);
  
      //redireccionar con useNavigate
  
      navigate("/login");

    })

  };

  const UserAccount = () => {
    navigate("/useraccount");
  };

  const Subscription = () => {
    navigate("/subscription");
  };
  const Soporte = () => {
    navigate("/soporte");
  };

  return (
    <div className="text-right z-50  ">
      <Menu as="div" className="relative text-left">
        <div>
          <Menu.Button className="flex justify-center w-full px-1  text-sm font-medium text-white bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <div className=" rounded-full h-10 mt-1">
              {usuario.photoURL ? (
                <img
                  className="h-8 w-8 mt-1 rounded-full"
                  alt=""
                  src={usuario.photoURL}
                />
              ) : (
                <img
                  className="h-8 w-8 mt-1 rounded-full"
                  alt=""
                  src={user}
                />
              )}
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-2 py-1 ">
              {/*   <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    {active ? (
                      <EditActiveIcon
                        className="w-5 h-5 mr-2"
                        aria-hidden="true"
                      />
                    ) : (
                      <EditInactiveIcon
                        className="w-5 h-5 mr-2"
                        aria-hidden="true"
                      />
                    )}
                    Edit
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    {active ? (
                      <DuplicateActiveIcon
                        className="w-5 h-5 mr-2"
                        aria-hidden="true"
                      />
                    ) : (
                      <DuplicateInactiveIcon
                        className="w-5 h-5 mr-2"
                        aria-hidden="true"
                      />
                    )}
                    Duplicate
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    {active ? (
                      <ArchiveActiveIcon
                        className="w-5 h-5 mr-2"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArchiveInactiveIcon
                        className="w-5 h-5 mr-2"
                        aria-hidden="true"
                      />
                    )}
                    Archive
                  </button>
                )}
              </Menu.Item> */}
{/*               <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={UserAccount}
                    className={`${
                      active ? "bg-yellow-500 rounded-lg text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    Cuenta de usuario
                  </button>
                )}
              </Menu.Item> */}
            </div>
            <div className="px-2 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={Subscription}
                    className={`${
                      active ? "bg-yellow-500 rounded-lg text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    Subscripción
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="px-2 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={Soporte}
                    className={`${
                      active ? "bg-yellow-500 rounded-lg text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    Soporte técnico
                  </button>
                )}
              </Menu.Item>
            </div>

{/*             <div className="px-2 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={MakeAdmin}
                    className={`${
                      active ? "bg-yellow-500 rounded-lg text-white " : "text-gray-900"
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    Permisos de usuarios
                  </button>
                )}
              </Menu.Item>
            </div> */}
            <div className="px-2 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={Logout}
                    className={`${
                      active ? "bg-yellow-500 rounded-lg text-white  " : "text-gray-900"
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 pr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                    Salir
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default UserMenu;
