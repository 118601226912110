import React, { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import Sidebar from "../ui/Sidebar";
import ReactLoading from "react-loading";
import Notifications from "../ui/Notifications";
import UserMenu from "../ui/UserMenu";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../context/AuthContext";
import { FirebaseContext } from "../../firebase";


const Admin = () => {

    const [loading, setLoading] = useState(false);

    //state que detecta si la pantalla es movil o de escritorio
    const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

      //extraer el usuario de auth
  const { usuario } = useAuth();

    //context de firebase
    const { firebase } = useContext(FirebaseContext);

  return (
    <div className="md:flex min-h-screen">
      <Sidebar />
      <ReactTooltip />
      {loading === true ? (
        <div className="md:w-4/5 xl:w-4/5 bg-gray-900 flex justify-center items-center h-screen">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="md:w-4/5 xl:w-4/5 p-4 bg-gray-900 h-screen">
          <header>
            <div className="flex justify-between">
            <div className="text-white  self-center bg-green-500 p-2 rounded-lg text-sm">
            Bienvenido {usuario.displayName}
          </div>
              <div className="flex">
                <div className="mt-2">
                  {isStatic ? <Notifications /> : null}
                </div>
                <div className=" z-50">{isStatic ? <UserMenu /> : null}</div>
              </div>
            </div>
          </header>
          <div className=" flex flex-col justify-center items-center  p-10 text-white">
                <div className="text-3xl rounded-lg border-gray-300 border-4 p-10">
                  Selecciona una opción del menú para comenzar

                </div>

                <div className="mt-6">You are running this application in <b>{process.env.NODE_ENV}</b> mode.</div>

              </div>


        </div>
      )}
    </div>
  );
};
export default Admin;
