import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const RedirectCategoria = () => {
  const navigate = useNavigate();
  const { categoria } = useParams();

  useEffect(() => {
    navigate("/categoria/" + categoria);
  }, []);

  return <></>;
};

export default RedirectCategoria;
