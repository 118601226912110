import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../../firebase";
import ReactLoading from "react-loading";
import PayPal from "./PayPal";
import FrontHeader from "./FrontHeader";
import FrontFooter from "./FrontFooter";
import Cart from "../paginas/Cart";
import Drawer from "react-modern-drawer";
import PedidoContext from "../../context/pedidos/pedidosContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import InputMask from "react-input-mask";
import flag from "../../images/flag_mx.png";
import SettingsContext from "../../context/SettingsContext";

const Checkout = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const [loading, setLoading] = useState(false);

  const [totalPedido, guardarTotalPedido] = useState(0);

  //Context con los Settings

  const { settings } = useContext(SettingsContext);

  //state de la busqueda
  const [searchterm, setSearchTerm] = useState("");

  const { pedido, total, mostrarResumen, eliminarProducto, pedidoRealizado } =
    useContext(PedidoContext);

  const navigate = useNavigate();

  //state de los datos de usuario

  const [datos, guardarDatos] = useState([]);

  //extraer el usuario de auth
  const { usuario } = useAuth();

  const [nombre, establecerNombre] = useState("");
  const [calle, establecerCalle] = useState("");
  const [colonia, establecerColonia] = useState("");
  const [ciudad, establecerCiudad] = useState("");
  const [estado, establecerEstado] = useState("");
  const [codigoPostal, establecerCodigoPostal] = useState("");
  const [telefono, establecerTelefono] = useState("");

  // vacia el carrito de compras

  const handleEliminar = () => {
    Swal.fire({
      title: "¿Estás seguro de vaciar el carrito?",
      text: "Los cambios no podrán ser revertidos",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Vaciar",
    }).then((result) => {
      if (result.isConfirmed) {
        pedidoRealizado();
      }
    });
  };

  //elimina un producto del carrito

  const confirmarEliminacion = (id) => {
    eliminarProducto(id);
  };

  //mueve hacia arriba de la pagina
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //State de los settings
  //const [settings, guardarSettings] = useState([""]);

  //estado con envios
  const [envio, establecerEnvio] = useState("");
  const [costo_envio, establecerCosto_envio] = useState(0);

  /*   useEffect(() => {
    const getSettings = async () => {
      const dbRef = firebase.db.collection("settings");
      try {
        const settings = await dbRef.onSnapshot((snapshot) => {
          const settings = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          //guarda los settings obtenidos
          guardarSettings(settings);

          if (Object.keys(settings).length > 0) {
            establecerEnvio(settings[0].envio);
            establecerCosto_envio(settings[0].costo_envio);
          }

          //recalcula el pedido
          const calcularTotalPedido = () => {
            let suma = 0;

            pedido.forEach((item) => {
              if (item.descuento > 0) {
                let precio_final =
                  item.precio - item.precio * (item.descuento / 100);
                suma = suma + precio_final.toFixed(2) * item.cantidad;
              } else {
                suma = suma + item.precio * item.cantidad;
              }
            });

            if (usuario && Object.keys(pedido).length > 0) {
            //suma al total del pedido el costo de envio
            if (settings[0].envio == "costo") {
              suma = suma + settings[0].costo_envio;
            }}

            guardarTotalPedido(suma);
          };

          calcularTotalPedido();

          //se limpia la referencia a la base de datos
          return () => dbRef();
        });
      } catch (error) {
        console.log(error);
      }
    };

    getSettings();
  }, [pedido]); */

  useEffect(() => {
    if (Object.keys(settings).length > 0) {
      establecerEnvio(settings[0].envio);
      establecerCosto_envio(settings[0].costo_envio);
    }

    //recalcula el pedido
    const calcularTotalPedido = () => {
      let suma = 0;

      pedido.forEach((item) => {
        if (item.descuento > 0) {
          let precio_final = item.precio - item.precio * (item.descuento / 100);
          suma = suma + precio_final.toFixed(2) * item.cantidad;
        } else {
          suma = suma + item.precio * item.cantidad;
        }
      });

      if (usuario && Object.keys(pedido).length > 0) {
        //suma al total del pedido el costo de envio
        if (settings[0].envio == "costo") {
          suma = suma + settings[0].costo_envio;
        }
      }

      guardarTotalPedido(suma);
    };

    calcularTotalPedido();
  }, [pedido]);

  const handleChange = (e) => {
    switch (e.target.name) {
      case "nombre":
        establecerNombre(e.target.value);
        break;
      case "calle":
        establecerCalle(e.target.value);
        break;
      case "colonia":
        establecerColonia(e.target.value);
        break;
      case "ciudad":
        establecerCiudad(e.target.value);
        break;
      case "estado":
        establecerEstado(e.target.value);
        break;
      case "codigoPostal":
        establecerCodigoPostal(e.target.value);
        break;
      case "telefono":
        establecerTelefono(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    //validar el formulario
    if (
      nombre === "" ||
      calle === "" ||
      colonia === "" ||
      ciudad === "" ||
      estado === "" ||
      codigoPostal === "" ||
      telefono === ""
    ) {
      Toast.fire({
        icon: "warning",
        title: "Todos los campos son necesarios",
      });

      return;
    }

    const userObj = {
      uidUsuario: usuario.uid,
      nombre: nombre,
      calle: calle,
      colonia: colonia,
      ciudad: ciudad,
      estado: estado,
      codigoPostal: codigoPostal,
      telefono: telefono,
      correo: usuario.email,
    };

    firebase.db.collection("usuarios").doc(datos[0].id).update(userObj);

    Toast.fire({
      title: "<p style='color:#22c55e'>" + "Guardado" + "</p>",
      html: '<p style="color:#fff">Se guardó tu información</p>',
      icon: "success",
      color: "#fff",
      background: "#18181b",
      position: "top-end",
      iconColor: "#22c55e",
      timer: 1500,
      showConfirmButton: false,
    });
  };

  useEffect(() => {
    setLoading(true);

    //obtiene los datos del usuario
    const db = firebase.db;
    const collectionRef = db
      .collection("usuarios")
      .where("uidUsuario", "==", usuario.uid);

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const datos = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      guardarDatos(datos);

      establecerNombre(datos[0].nombre);
      establecerCalle(datos[0].calle);
      establecerColonia(datos[0].colonia);
      establecerCiudad(datos[0].ciudad);
      establecerEstado(datos[0].estado);
      establecerCodigoPostal(datos[0].codigoPostal);
      establecerTelefono(datos[0].telefono);

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleCheckout = () => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    //validar el formulario
    if (
      nombre === "" ||
      calle === "" ||
      colonia === "" ||
      ciudad === "" ||
      estado === "" ||
      codigoPostal === "" ||
      telefono === ""
    ) {
      Toast.fire({
        icon: "warning",
        title: "Todos los campos son necesarios",
      });

      return;
    }

    navigate("/payment");
  };

  return (
    <>
      <FrontHeader
        toggleDrawer={toggleDrawer}
        searchterm={searchterm}
        setSearchTerm={setSearchTerm}
      />

      {Object.keys(settings).length > 0 ? (
        <div className="p-4 bg-gray-800 shadow-md">
          <div class="mx-auto bg-gray-100 rounded-xl shadow-md overflow-hidden max-w-screen-lg">
            <h1 className="flex justify-center p-4 text-3xl font-semibold">
              Carro de compras
            </h1>

            <div className="p-3">
              <div className=" w-full">
                {loading === true ? (
                  <div className="flex justify-center items-center h-screen">
                    <ReactLoading color={"#22C55E"} type={"spin"} />
                  </div>
                ) : (
                  <div>
                    <div>
                      <Drawer
                        size={350}
                        open={isOpen}
                        onClose={toggleDrawer}
                        direction="right"
                      >
                        <Cart isOpen={isOpen} setIsOpen={setIsOpen} />
                      </Drawer>

                      <div className="h-full justify-center text-black">
                        <label className="block text-gray-800 ml-2 text-2xl">
                          Información de envío
                        </label>

                        <div className="rounded-lg  mt-4 m-2 ml-2 mr-2 p-2 max-w-screen-lg bg-gray-200 shadow-md">
                          <form
                            onSubmit={handleSubmit}
                            className="mt-5 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base"
                          >
                            <div className="pl-2 pr-2">
                              <p className="mt-4 mb-2 text-sm font-semibold ">
                                Nombre
                              </p>

                              <input
                                onChange={handleChange}
                                value={nombre}
                                name="nombre"
                                type="text"
                                placeholder="Nombre legal"
                                className="border border-gray-400 p-3 rounded w-full xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 text-center"
                              />
                            </div>

                            <div className="sm:flex">
                              <div className="pl-2 pr-2">
                                <p className="mt-4 mb-2  text-sm font-semibold ">
                                  Teléfono
                                </p>
                                <div className="flex">
                                  <img
                                    className="block h-10 cursor-pointer self-center mr-2"
                                    src={flag}
                                    alt="Revdev Store"
                                  />
                                  <InputMask
                                    mask="(+52) 999 999 9999"
                                    alwaysShowMask={true}
                                    onChange={handleChange}
                                    value={telefono}
                                    name="telefono"
                                    placeholder="Teléfono"
                                    className="w-44 xl:w-44 lg:w-44 border border-gray-400 p-3 rounded xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 bg-white text-center transition-shadow"
                                  ></InputMask>
                                </div>
                              </div>
                            </div>

                            <div className="md:flex">
                              <div className="pl-2 pr-2">
                                <p className="mt-4 mb-2  text-sm font-semibold ">
                                  Estado
                                </p>

                                <select
                                  name="estado"
                                  onChange={handleChange}
                                  value={estado}
                                  className="w-44  xl:w-44 lg:w-44 border border-gray-400 p-3 rounded  xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 bg-white text-center transition-shadow"
                                >
                                  <option value="no">Seleccione uno...</option>
                                  <option value="Aguascalientes">
                                    Aguascalientes
                                  </option>
                                  <option value="Baja California">
                                    Baja California
                                  </option>
                                  <option value="Baja California Sur">
                                    Baja California Sur
                                  </option>
                                  <option value="Campeche">Campeche</option>
                                  <option value="Chiapas">Chiapas</option>
                                  <option value="Chihuahua">Chihuahua</option>
                                  <option value="CDMX">Ciudad de México</option>
                                  <option value="Coahuila">Coahuila</option>
                                  <option value="Colima">Colima</option>
                                  <option value="Durango">Durango</option>
                                  <option value="Estado de México">
                                    Estado de México
                                  </option>
                                  <option value="Guanajuato">Guanajuato</option>
                                  <option value="Guerrero">Guerrero</option>
                                  <option value="Hidalgo">Hidalgo</option>
                                  <option value="Jalisco">Jalisco</option>
                                  <option value="Michoacán">Michoacán</option>
                                  <option value="Morelos">Morelos</option>
                                  <option value="Nayarit">Nayarit</option>
                                  <option value="Nuevo León">Nuevo León</option>
                                  <option value="Oaxaca">Oaxaca</option>
                                  <option value="Puebla">Puebla</option>
                                  <option value="Querétaro">Querétaro</option>
                                  <option value="Quintana Roo">
                                    Quintana Roo
                                  </option>
                                  <option value="San Luis Potosí">
                                    San Luis Potosí
                                  </option>
                                  <option value="Sinaloa">Sinaloa</option>
                                  <option value="Sonora">Sonora</option>
                                  <option value="Tabasco">Tabasco</option>
                                  <option value="Tamaulipas">Tamaulipas</option>
                                  <option value="Tlaxcala">Tlaxcala</option>
                                  <option value="Veracruz">Veracruz</option>
                                  <option value="Yucatán">Yucatán</option>
                                  <option value="Zacatecas">Zacatecas</option>
                                </select>
                              </div>

                              <div className="pl-2 pr-2">
                                <p className="mt-4 mb-2  text-sm font-semibold ">
                                  Ciudad
                                </p>

                                <input
                                  onChange={handleChange}
                                  value={ciudad}
                                  name="ciudad"
                                  type="text"
                                  placeholder="Ciudad"
                                  className="w-44  xl:w-44 lg:w-44 border text-center border-gray-400 p-3 rounded  xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 transition-shadow"
                                />
                              </div>

                              <div className="pl-2 pr-2">
                                <p className="mt-4 mb-2  text-sm font-semibold ">
                                  Colonia
                                </p>

                                <input
                                  onChange={handleChange}
                                  value={colonia}
                                  name="colonia"
                                  type="text"
                                  placeholder="Colonia"
                                  className="w-44 xl:w-44 lg:w-44 border border-gray-400 p-3 rounded  text-center xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 transition-shadow"
                                />
                              </div>
                            </div>

                            <div className="md:flex justify-around">
                              <div className="pl-2 w-full">
                                <p className="mt-4 mb-2 font-semibold text-sm">
                                  Calle y número
                                </p>

                                <input
                                  onChange={handleChange}
                                  value={calle}
                                  name="calle"
                                  type="text"
                                  placeholder="Calle y número"
                                  className="border border-gray-400 p-3 rounded w-full xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 transition-shadow text-center"
                                />
                              </div>

                              <div className="pl-2 pr-2">
                                <p className="mt-4 mb-2 font-semibold text-sm">
                                  Código Postal
                                </p>

                                <input
                                  onChange={handleChange}
                                  value={codigoPostal}
                                  name="codigoPostal"
                                  type="number"
                                  placeholder="Código Postal"
                                  min="0"
                                  className="w-24 border border-gray-400 p-3 rounded xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 bg-white pl-3 text-center transition-shadow"
                                />
                              </div>
                            </div>

                            <div className="flex justify-center mt-4">
                              <button className="rounded-lg text-white bg-blue-500 hover:bg-blue-600 p-3 pl-8 pr-8 text-lg mb-4 shadow-lg">
                                Guardar
                              </button>
                            </div>
                          </form>
                        </div>

                        <label className="block text-gray-800 ml-2 text-2xl  mb-4 mt-6 ">
                          Productos en el carrito
                        </label>

                        {pedido.map((producto, i) => {
                          const {
                            cantidad,
                            nombre,
                            imagen,
                            id,
                            precio,
                            descripcion,
                            descuento,
                            sku,
                          } = producto;
                          return (
                            <div className="bg-gray-200 rounded border-gray-600 p-2 shadow-md mb-4 ml-2 mr-2">
                                <div className="flex">
                                  <img
                                    className="rounded-lg  mr-2 shadow-md md:w-1/6 self-center w-1/2 "
                                    alt=""
                                    src={producto.imagen}
                                  />

                                  <div className="flex-col w-full">
                                    <div className="w-full h-3/4">
                                      {producto.sku ? (
                                        <p className="text-gray-700 text-xs">
                                          SKU: {producto.sku}
                                        </p>
                                      ) : (
                                        <p className="text-gray-600 text-xs mt-2"></p>
                                      )}

                                      <div className="flex">
                                        {producto.descuento > 0 ? (
                                          <p className="text-gray-700 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
                                            {producto.cantidad} x{" "}
                                            {new Intl.NumberFormat("es-MX", {
                                              style: "currency",
                                              currency: "MXN",
                                            }).format(
                                              producto.precio -
                                                producto.precio *
                                                  (producto.descuento / 100)
                                            )}{" "}
                                            {"(Descuento " +
                                              producto.descuento +
                                              "%)"}{" "}
                                            - {producto.nombre}
                                          </p>
                                        ) : (
                                          <p className="text-gray-700 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
                                            {producto.cantidad} x{" "}
                                            {new Intl.NumberFormat("es-MX", {
                                              style: "currency",
                                              currency: "MXN",
                                            }).format(producto.precio)}{" "}
                                            - {producto.nombre}
                                          </p>
                                        )}
                                      </div>

                                      <div className="font-light text-sm text-gray-700 full">
                                        {descripcion.slice(0, 200)}{" "}
                                        {descripcion.length >= 200 ? (
                                          <>...</>
                                        ) : null}
                                      </div>
                                    </div>

                                    <div className="flex justify-end h-1/4">
                                      <p className="text-gray-700 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base flex font-semibold self-end ">
                                        MXN{" "}
                                        {new Intl.NumberFormat("es-MX", {
                                          style: "currency",
                                          currency: "MXN",
                                        }).format(
                                          producto.cantidad *
                                            (
                                              producto.precio -
                                              producto.precio *
                                                (producto.descuento / 100)
                                            ).toFixed(2)
                                        )}
                                      </p>
                                    </div>
                                  </div>
                              </div>

                              <div className="flex justify-start">
                                <button
                                  onClick={() => confirmarEliminacion(id)}
                                  className="rounded text-white bg-red-500 hover:bg-red-600 text-sm inline-flex items-center p-1 mt-2"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3 w-3"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          );
                        })}

                        {/*                       {parseInt(totalPedido, 10) <= 0 ? null : (
                        <p className="flex justify-center m-5 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1"
                              d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                            />
                          </svg>
                          Gastos de envío: Gratis
                        </p>
                      )} */}

                        {parseInt(totalPedido, 10) <= 0 ? (
                          <div className="flex flex-col justify-center items-center pb-8">
                            <div className="relative sm:max-w-sm w-full text-white p-16">
                              <svg
                                clipRule="evenodd"
                                fillRule="evenodd"
                                strokeLinejoin="round"
                                strokeMiterlimit="2"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                stroke="#374151"
                                fill="#374151"
                                strokeWidth=".1"
                              >
                                <path d="M10.975 8l.025-.5c0-.517-.067-1.018-.181-1.5h5.993l-.564 2h-5.273zm-2.475 10c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm11.305-15l-3.432 12h-10.428l-.455-1.083c-.323.049-.653.083-.99.083-.407 0-.805-.042-1.191-.114l1.306 3.114h13.239l3.474-12h1.929l.743-2h-4.195zm-6.305 15c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm-4.5-10.5c0 2.485-2.017 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.017-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-3.086-2.122l-1.414 1.414-1.414-1.414-.707.707 1.414 1.415-1.414 1.414.707.708 1.414-1.415 1.414 1.414.708-.708-1.414-1.413 1.414-1.414-.708-.708z" />
                              </svg>
                              <h1 className="text-lg bg-red-500 text-white flex justify-center w-full p-5 rounded-lg text-center">
                                No hay productos en el carrito
                              </h1>
                            </div>
                          </div>
                        ) : (
                          <>
                            {settings[0].envio == "gratis" &&
                            Object.keys(pedido).length > 0 ? (
                              <div className="flex justify-end">
                                <div className="m-2 justify-end text-base bg-gray-400 rounded-lg  text-white p-4">
                                  Gastos de envío: Gratis
                                </div>
                              </div>
                            ) : null}

                            {settings[0].envio == "costo" &&
                            Object.keys(pedido).length > 0 ? (
                              <div className="flex justify-end">
                                <div className="m-2 justify-end text-base bg-gray-400 rounded-lg  text-white p-4">
                                  Gastos de envío: $
                                  {settings[0].costo_envio.toFixed(2)}
                                </div>
                              </div>
                            ) : null}

                            {settings[0].envio == "posicionamiento" &&
                            Object.keys(pedido).length > 0 ? (
                              <div className="flex justify-end">
                                <div className="m-2 justify-end text-base bg-gray-400 rounded-lg  text-white p-4">
                                  Nos encotramos trabajando en esta
                                  característica
                                </div>
                              </div>
                            ) : null}
                            {settings[0].envio == "noenvio" &&
                            Object.keys(pedido).length > 0 ? (
                              <div className="flex justify-end">
                                <div className="m-2 justify-end text-base bg-gray-400 rounded-lg  text-white p-4">
                                  Actualmente no contamos con entrega a
                                  domicilio, pero puedes pasar por tu pedido a
                                  nuestra tienda física.
                                </div>
                              </div>
                            ) : null}

                            <div className="flex justify-end pr-2">
                              <p className="font-bold mt-2 mb-4 justify-end text-xl xl:text-2xl  text-gray-800  border-t border-gray-400 p-4">
                                Total MXN
                                {new Intl.NumberFormat("es-MX", {
                                  style: "currency",
                                  currency: "MXN",
                                }).format(totalPedido)}
                              </p>
                            </div>

                            <div className="flex justify-center mt-4">
                              <button
                                onClick={handleCheckout}
                                className="rounded-lg text-white bg-blue-500 hover:bg-blue-600 p-3 text-lg mb-4 shadow-lg"
                              >
                                Confirmación de pedido
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full justify-center bg-gray-900  overflow-y-auto flex">
          <div className="relative sm:max-w-sm w-full text-white">
            <svg
              clipRule="evenodd"
              fillRule="evenodd"
              strokeLinejoin="round"
              strokeMiterlimit="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              stroke="white"
              fill="white"
              strokeWidth=".1"
            >
              <path
                d="m12.002 21.534c5.518 0 9.998-4.48 9.998-9.998s-4.48-9.997-9.998-9.997c-5.517 0-9.997 4.479-9.997 9.997s4.48 9.998 9.997 9.998zm0-1.5c-4.69 0-8.497-3.808-8.497-8.498s3.807-8.497 8.497-8.497 8.498 3.807 8.498 8.497-3.808 8.498-8.498 8.498zm0-6.5c-.414 0-.75-.336-.75-.75v-5.5c0-.414.336-.75.75-.75s.75.336.75.75v5.5c0 .414-.336.75-.75.75zm-.002 3c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z"
                fillRule="nonzero"
              />
            </svg>
            <div className="text-xl bg-red-500 text-white flex justify-center p-2 rounded-lg text-center  m-4">
              No es posible calcular el pedido debido a un problema de conexión
            </div>
          </div>
        </div>
      )}

      <FrontFooter />
    </>
  );
};

export default Checkout;
