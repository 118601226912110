import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../ui/Sidebar";
import { FirebaseContext } from "../../firebase";
import Pedido from "../ui/Pedido";
import _ from "lodash";
import ReactLoading from "react-loading";
import UserMenu from "../ui/UserMenu";
import Notifications from "../ui/Notifications";
import { useMediaQuery } from "react-responsive";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ScrollToTop from "react-scroll-to-top";
import ReactTooltip from "react-tooltip";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import SearchAlgoliaPedidos from "../ui/SearchAlgoliaPedidos";

const MyCalendar = styled(Calendar).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
})`
  background-color: #4b5563;
  border: none;
  border-radius: 5px;
  color: #d1d5db;

  .react-calendar__month-view__days__day--weekend {
    color: #f87171;
  }

  .react-calendar__tile--now {
    background-color: #eab308;
    border-radius: 5px;
  }

  .react-calendar__tile--now:hover {
    background-color: #fb923c;
    border-radius: 5px;
  }

  .react-calendar__tile:hover {
    background-color: #1f2937;
    border-radius: 5px;
  }

  .react-calendar__navigation button:hover {
    background-color: #1f2937;
    border-radius: 5px;
  }
`;

const MyPaginate = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    border-radius: 7px;
    padding: 0.3rem 0.8rem;
    border-color: transparent;
    cursor: pointer;
    margin: 4px;
    color: white;
    background-color: #eab308;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
    color: #1f2937;
    background-color: transparent;
  }
  li.active a {
    background-color: #3b82f6;
    border-color: transparent;
    color: white;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const MyPaginateSmall = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  li a {
    border-radius: 4px;
    cursor: pointer;
    color: white;
    padding: 0.3rem 0.6rem;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
    color: white;
  }
  li.active a {
    border-color: transparent;
    color: white;
    background-color: #3b82f6;
  }
  li.disabled a {
    color: grey;
    cursor: default;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const Pedidos = () => {
  const [loading, setLoading] = useState(true);

  //Búsqueda
  const [searchterm, setSearchTerm] = useState("");
  const [resultados, setResultados] = useState([]);

  //state que detecta si la pantalla es movil o de escritorio
  const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

  //state del select de filtro
  const [datos, guardarDatos] = useState({ filtro: "" });

  //state del select de fecha
  const [fecha, guardarFecha] = useState({ filtro1: "1 dia" });

  //extraer del state filtro
  const { filtro } = datos;

  //extraer del state filtro
  const { filtro1 } = fecha;

  //context de firebase
  const { firebase } = useContext(FirebaseContext);

  const PAGE_SIZE = 20;

  //state que cambia el numero de pagina en react-pagination
  const [currentPage, setCurrentPage] = useState(0);

  //state que forza a cambiar el numero de pagina en react-pagination
  const [seleccionada, setSeleccionada] = useState(0);

  //estado que guarda solo las imagenes que se mostrarán
  const [displayedPedidos, setDisplayedPedidos] = useState([]);

  //calendario
  //state que muestra el calendario
  const [showCalendar, setShowCalendar] = useState(false);
  const [value, onChange] = useState();
  const [rango1, setRango1] = useState();
  const [rango2, setRango2] = useState();

  //state con los pedidos
  const [pedidos, guardarPedidos] = useState([]);

  useEffect(() => {
    setLoading(false);
  }, []);

  /*   useEffect(() => {
    const obtenerPedidos = () => {
      setLoading(true);
      const dia = Date.now() - 86400000 * 30;

      firebase.db
        .collection("ordenes")
        .where("creado", ">=", dia)
        .onSnapshot(manejarSnapshot);
    };
    obtenerPedidos();
  }, []); */

  function manejarSnapshot(snapshot) {
    let pedidos = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    //ordenar por fecha con lodash
    pedidos = _.orderBy(pedidos, "creado", "desc");

    guardarPedidos(pedidos);

    setLoading(false);
  }

  useEffect(() => {
    /*  console.log("PAGINA", currentPage);
        console.log("DESDE", currentPage * PAGE_SIZE);
        console.log("HASTA", currentPage * PAGE_SIZE + PAGE_SIZE);
     */
    //paginación
    const corte = pedidos.slice(
      currentPage * PAGE_SIZE,
      currentPage * PAGE_SIZE + PAGE_SIZE
    );
    setDisplayedPedidos(corte);
  }, [currentPage, pedidos]);

  const handlePageChange = ({ selected }) => {
    setLoading(true);
    setCurrentPage(selected);
    //este state lo puse por que cuando daba click en la seleccion se volvia de vuelta a la pagina uno y así tuve que forzar su estado
    setSeleccionada(selected);
    window.scrollTo(0, 0);
    setLoading(false);
  };

  const onClickDay = (date) => {
    let dia1 = Date.parse(date[0]);
    let dia2 = Date.parse(date[1]);

    setRango1(dia1);
    setRango2(dia2);

    firebase.db
      .collection("ordenes")
      .where("completado", "==", datos.filtro)
      .where("creado", ">=", dia1)
      .where("creado", "<=", dia2)
      .onSnapshot(manejarSnapshot);
  };

  //lee la info del select
  const seleccion = (e) => {
    guardarDatos({ ...datos, [e.target.name]: e.target.value });

    if (fecha.filtro1 === "1 dia") {
      var dia = Date.now() - 86400000;
    }

    if (fecha.filtro1 === "3 dias") {
      var dia = Date.now() - 86400000 * 3;
    }

    if (fecha.filtro1 === "7 dias") {
      var dia = Date.now() - 86400000 * 7;
    }

    if (fecha.filtro1 === "30 dias") {
      dia = Date.now() - 86400000 * 30;
    }

    if (e.target.value === "recibido" && fecha.filtro1 !== "todos") {
      setLoading(true);

      firebase.db
        .collection("ordenes")
        .where("completado", "==", "recibido")
        .where("creado", ">=", dia)
        .onSnapshot(manejarSnapshot);
    }
    if (e.target.value === "preparando" && fecha.filtro1 !== "todos") {
      setLoading(true);

      firebase.db
        .collection("ordenes")
        .where("completado", "==", "preparando")
        .where("creado", ">=", dia)
        .onSnapshot(manejarSnapshot);
    }
    if (e.target.value === "enviado" && fecha.filtro1 !== "todos") {
      setLoading(true);

      firebase.db
        .collection("ordenes")
        .where("completado", "==", "enviado")
        .where("creado", ">=", dia)
        .onSnapshot(manejarSnapshot);
    }
    if (e.target.value === "completado" && fecha.filtro1 !== "todos") {
      setLoading(true);

      firebase.db
        .collection("ordenes")
        .where("completado", "==", "completado")
        .where("creado", ">=", dia)
        .onSnapshot(manejarSnapshot);
    }
    if (e.target.value === "cancelado" && fecha.filtro1 !== "todos") {
      setLoading(true);

      firebase.db
        .collection("ordenes")
        .where("completado", "==", "cancelado")
        .where("creado", ">=", dia)
        .onSnapshot(manejarSnapshot);
    }
    if (e.target.value === "todos" && fecha.filtro1 !== "todos") {
      setLoading(true);

      firebase.db.collection("ordenes").onSnapshot(manejarSnapshot);
    }

    //si el segundo filtro es igual a todos
    if (fecha.filtro1 === "todos") {
      switch (e.target.value) {
        case "recibido":
          setLoading(true);

          firebase.db
            .collection("ordenes")
            .where("completado", "==", "recibido")
            .onSnapshot(manejarSnapshot);
          break;
        case "preparando":
          setLoading(true);

          firebase.db
            .collection("ordenes")
            .where("completado", "==", "preparando")
            .onSnapshot(manejarSnapshot);
          break;
        case "enviado":
          setLoading(true);

          firebase.db
            .collection("ordenes")
            .where("completado", "==", "enviado")
            .onSnapshot(manejarSnapshot);
          break;
        case "completado":
          setLoading(true);

          firebase.db
            .collection("ordenes")
            .where("completado", "==", "completado")
            .onSnapshot(manejarSnapshot);
          break;
        case "cancelado":
          setLoading(true);

          firebase.db
            .collection("ordenes")
            .where("completado", "==", "cancelado")
            .onSnapshot(manejarSnapshot);
          break;
        case "todos":
          setLoading(true);

          firebase.db.collection("ordenes").onSnapshot(manejarSnapshot);
          break;
      }
    }
  };

  //lee la info del select Fecha
  const seleccionFecha = (e) => {
    guardarFecha({ ...fecha, [e.target.name]: e.target.value });

    if (e.target.value === "1 dia") {
      var dia = Date.now() - 86400000;
    }

    if (e.target.value === "3 dias") {
      var dia = Date.now() - 86400000 * 3;
    }

    if (e.target.value === "7 dias") {
      var dia = Date.now() - 86400000 * 7;
    }

    if (e.target.value === "30 dias") {
      dia = Date.now() - 86400000 * 30;
    }

    //HACE EL QUERY DEPENDIENDO DE EL VALOR DE LOS 2 FILTROS, ESTADO DEL PEDIDO Y FECHA DEL PEDIDO

    if (datos.filtro === "recibido" && e.target.value !== "todos") {
      var completado = "recibido";
      firebase.db
        .collection("ordenes")
        .where("creado", ">=", dia)
        .where("completado", "==", completado)
        .onSnapshot(manejarSnapshot);
    }

    if (datos.filtro === "preparando" && e.target.value !== "todos") {
      var completado = "preparando";
      firebase.db
        .collection("ordenes")
        .where("creado", ">=", dia)
        .where("completado", "==", completado)
        .onSnapshot(manejarSnapshot);
    }

    if (datos.filtro === "enviado" && e.target.value !== "todos") {
      var completado = "enviado";
      firebase.db
        .collection("ordenes")
        .where("creado", ">=", dia)
        .where("completado", "==", completado)
        .onSnapshot(manejarSnapshot);
    }

    if (datos.filtro === "completado" && e.target.value !== "todos") {
      var completado = "completado";
      firebase.db
        .collection("ordenes")
        .where("creado", ">=", dia)
        .where("completado", "==", completado)
        .onSnapshot(manejarSnapshot);
    }

    if (datos.filtro === "cancelado" && e.target.value !== "todos") {
      var completado = "cancelado";
      firebase.db
        .collection("ordenes")
        .where("creado", ">=", dia)
        .where("completado", "==", completado)
        .onSnapshot(manejarSnapshot);
    }

    //si el segundo filtro es igual a todos
    if (e.target.value === "todos") {
      switch (datos.filtro) {
        case "recibido":
          firebase.db
            .collection("ordenes")
            .where("completado", "==", "recibido")
            .onSnapshot(manejarSnapshot);
          break;
        case "preparando":
          firebase.db
            .collection("ordenes")
            .where("completado", "==", "preparando")
            .onSnapshot(manejarSnapshot);
          break;
        case "enviado":
          firebase.db
            .collection("ordenes")
            .where("completado", "==", "enviado")
            .onSnapshot(manejarSnapshot);
          break;
        case "completado":
          firebase.db
            .collection("ordenes")
            .where("completado", "==", "completado")
            .onSnapshot(manejarSnapshot);
          break;
        case "cancelado":
          firebase.db
            .collection("ordenes")
            .where("completado", "==", "cancelado")
            .onSnapshot(manejarSnapshot);
          break;
        case "todos":
          firebase.db.collection("ordenes").onSnapshot(manejarSnapshot);
          break;
      }
    }
  };

  return (
    <div className="md:flex min-h-screen bg-gray-900">
      <Sidebar />
      {loading ? (
        <div className="md:w-4/5 xl:w-4/5 bg-gray-900 flex justify-center items-center h-screen">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="md:w-4/5 xl:w-4/5 p-4 bg-gray-900 overflow-hidden ">
          <header>


            
            <div className="flex justify-end">
              {/*               <div className="flex text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 mt-1 mr-2 mb-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                  />
                </svg>
                <h1 className="text-3xl">Pedidos</h1>
              </div> */}
              <div className="flex">
                <div className="mt-2">
                  {isStatic ? <Notifications /> : null}
                </div>
                <div className=" z-50">{isStatic ? <UserMenu /> : null}</div>
              </div>
            </div>
          </header>

          <ReactTooltip place="top" type="dark" effect="float" />


          <ScrollToTop
            top={5000}
            component={
              <svg
                className="shadow-lg fill-current text-purple-600 "
                clipRule="evenodd"
                fillRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m9.001 10.978h-3.251c-.412 0-.75-.335-.75-.752 0-.188.071-.375.206-.518 1.685-1.775 4.692-4.945 6.069-6.396.189-.2.452-.312.725-.312.274 0 .536.112.725.312 1.377 1.451 4.385 4.621 6.068 6.396.136.143.207.33.207.518 0 .417-.337.752-.75.752h-3.251v9.02c0 .531-.47 1.002-1 1.002h-3.998c-.53 0-1-.471-1-1.002z"
                  fill-rule="nonzero"
                />
              </svg>
            }
          />

          <div className="w-full flex xs:flex-wrap-reverse sm:flex-wrap-reverse md:justify-between lg:justify-between mt-2">
            <div>
              <div className="flex bg-gray-800 p-2 rounded-lg">
                <div className="relative  border-none">
                  <select
                    className="bg-purple-900 hover:bg-purple-700 text-white appearance-none border-none inline-block py-3 pl-2 pr-8  rounded-lg leading-tight w-full xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base focus:ring-4 focus:ring-purple-300 shadow-md text-center"
                    onChange={seleccionFecha}
                    name="filtro1"
                    value={filtro1}
                  >

                    <option className="text-white" value="1 dia">
                      24 horas
                    </option>
                    <option className="text-white" value="3 dias">
                      3 días
                    </option>
                    <option className="text-white" value="7 dias">
                      7 días
                    </option>
                    <option className="text-white" value="30 dias">
                      30 días
                    </option>{" "}
                    <option className="text-white" value="todos">
                      Todos
                    </option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>{" "}
                  </div>
                </div>

                <div className="relative border-none ml-2">
                  <select
                    className="bg-purple-900 hover:bg-purple-700 text-white appearance-none border-none inline-block py-3 pl-2 pr-8 rounded-lg leading-tight w-full xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base focus:ring-4 focus:ring-purple-300 shadow-md text-center"
                    onChange={seleccion}
                    name="filtro"
                    value={filtro}
                  >
                    <option className="" value="">
                      Estado de envío
                    </option>
                    <option className="" value="recibido">
                      Recibido
                    </option>
                    <option className="" value="preparando">
                      Preparando
                    </option>
                    <option className="" value="enviado">
                      Enviado
                    </option>
                    <option className="" value="completado">
                      Completado
                    </option>
                    <option className="" value="cancelado">
                      Cancelado
                    </option>
                    <option className="" value="todos">
                      Todos
                    </option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 mr-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>{" "}
                  </div>
                </div>
                <div className="ml-2 flex">
                  <button
                    className="bg-purple-900 hover:bg-purple-700 text-white border-none inline-block p-3 rounded-lg leading-tight w-full xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base focus:ring-4 focus:ring-purple-300  shadow-md"
                    onClick={() => {
                      if (showCalendar) {
                        setShowCalendar(false);
                      } else {
                        setShowCalendar(true);
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="xs:h-5 xs:w-5 md:h-6 md:w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                  <div className="self-center">
                  <svg
                        data-tip="Selecciona un día del calendario dando click en el y a continuación selecciona un segundo día para filtrar todos los pedidos en ese rango de tiempo"
                        className="ml-2 hover:animate-bounce"
                        alt="tooltip"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="white"
                        fill="white"
                        strokeWidth=".1"
                      >
                        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
                      </svg>  
                      </div>

                </div>
              </div>

              {showCalendar ? (
                <div className=" text-gray-700 m-2">
                  <MyCalendar
                    onChange={onClickDay}
                    value={value}
                    selectRange={true}
                  />
    
                  {rango1 && rango2 !== undefined ? (
                    <div className="bg-green-500 rounded-lg shadow-md inline-block mt-2">
                      <p className=" text-sm p-2 text-white ">
                        Desde el día {new Date(rango1).toLocaleDateString()}{" "}
                        hasta el día {new Date(rango2).toLocaleDateString()},{" "}
                        {datos.filtro}
                      </p>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {filtro1 === "" || filtro === "" ? (
                <div className="bg-yellow-500 rounded-lg shadow-md inline-block mt-2">
                  <p className=" text-sm p-2 text-white">
                    Selecciona un estado de envío
                  </p>
                </div>
              ) : (
                <div>
                  {pedidos.length > 0 ? (
                    <div className="bg-yellow-500 rounded-lg shadow-md inline-block mt-2">
                      <p className=" text-sm p-2 text-white ">
                        Hay {pedidos.length} pedidos que concuerdan con este
                        filtro: {filtro1}, {filtro}
                      </p>
                    </div>
                  ) : (
                    <div className="bg-yellow-500 rounded-lg shadow-md inline-block mt-2">
                      <p className=" text-sm p-2 text-white">
                        No hay pedidos que concuerden con este filtro: {filtro1}
                        , {filtro}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="flex justify-center lg:justify-end mb-4 xs:w-full sm:w-full md:w-1/3 lg:w-1/3">
              <SearchAlgoliaPedidos
                setDisplayedPedidos={setDisplayedPedidos}
                displayedPedidos={displayedPedidos}
                pedidos={pedidos}
                guardarPedidos={guardarPedidos}
              />

              {/*               <div className="relative">
                <input
                  type="text"
                  className="p-2 pl-8 rounded border border-purple-600 focus:bg-white focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:border-transparent w-full shadow-xl"
                  placeholder="Nombre o ID de pedido"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);

                    console.log(searchterm);

                    //busca termino desde el arreglo de pedidos que se encuentra actualmente, depende del filtro el numero de pedidos en el arreglo

                                         const resultados = pedidos.filter((pedido) => {
                      if (searchterm === "") {
                        return  setResultados([{}])

                      } else if (
                        pedido.nombre
                          .toLowerCase()
                          .includes(searchterm.toLowerCase()) ||
                        pedido.id
                          .toLowerCase()
                          .includes(searchterm.toLowerCase()) ||
                        pedido.ciudad
                          .toLowerCase()
                          .includes(searchterm.toLowerCase()) ||
                        pedido.correo
                          .toLowerCase()
                          .includes(searchterm.toLowerCase()) ||
                        pedido.telefono
                          .toLowerCase()
                          .includes(searchterm.toLowerCase()) ||
                        pedido.calle
                          .toLowerCase()
                          .includes(searchterm.toLowerCase())
                      ) {
                        return pedido;
                      }
                    }); 

                    //consulta desde firebase con snapshot

                const dbRef = firebase.db;
                    dbRef
                      .collection("ordenes")
                      .where("nombre", "==", searchterm)
                      .onSnapshot(manejarSnapshot);

                    //snapshot que nos permite utilizar la bd en tiempo real de firestore
                    function manejarSnapshot(snapshot) {
                      let resultados = snapshot.docs.map((doc) => {
                        return {
                          id: doc.id,
                          ...doc.data(),
                        };
                      });
                    }

      

                    //tiene que hacer match perfectamente igual con el término de búsqueda y get

                   const obtenerPedidosFirebase = async () => {
                    const citiesRef = firebase.db.collection('ordenes');
                    const snapshot = await citiesRef.where('nombre', '==', searchterm).get();
                    console.log(searchterm)
                 
                    if (snapshot.empty) {
                      console.log('No matching documents.');
                      return;
                    }

                    let resultadosArray = []

                    snapshot.forEach(doc => {
                      console.log(doc.id, '=>', doc.data());
                      resultadosArray.push(doc)
                    });
                  
                    setResultados(resultadosArray);
                  
                  }

                  obtenerPedidosFirebase() 
   
                    
                  }}
                />
  
                    

                <svg
                  className="w-4 h-4 absolute left-2.5 top-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="black"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
                {resultados.length > 0 ? (
                  <div
                    static
                    className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none bg-gray-800 overflow-y-auto h-auto max-h-96 xs:w-80 md:w-96 lg:w-96 xl:w-96"
                  >
                    <p className="flex justify-center m-2 text-white ">
                      Resultados
                    </p>
                    {resultados.map((resultado) => {
                      return (
                        <div className="text-white hover:bg-yellow-500 m-2 p-2 rounded-md">
                          <p>
                            {resultado.nombre} {resultado.id}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div> */}
            </div>
          </div>

          <div className="flex justify-between">
            {pedidos.length === 0 ? null : (
              <div className="flex justify-center text-sm w-full mt-4 font-bold">
                <MyPaginateSmall
                  pageCount={Math.ceil(pedidos.length / PAGE_SIZE)}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageChange}
                  previousLabel="<"
                  nextLabel=">"
                  containerClassName={"flex items-center justify-center"}
                  pageClassName={
                    "p-4 rounded-full w-2 h-2 flex items-center justify-center"
                  }
                  previousClassName={
                    "p-4 rounded-full w-2 h-2 flex items-center justify-center transition duration-200 ease-in-out transform hover:-translate-x hover:scale-150"
                  }
                  nextClassName={
                    "p-4 rounded-full w-2 h-2 flex items-center justify-center transition duration-200 ease-in-out transform hover:-translate-x hover:scale-150"
                  }
                  disabledClassName={"opacity-50 cursor-not-allowed"}
                  breakClassName={"text-gray-500"}
                  activeClassName={"active"}
                  forcePage={seleccionada}
                />
              </div>
            )}
          </div>

          {/*           {displayedPedidos
            .filter((pedido) => {
              if (searchterm === "") {
                return pedido;
              } else if (
                pedido.nombre
                  .toLowerCase()
                  .includes(searchterm.toLowerCase()) ||
                pedido.id.toLowerCase().includes(searchterm.toLowerCase()) ||
                pedido.calle.toLowerCase().includes(searchterm.toLowerCase())
              ) {
                return pedido;
              }
            })
            .map((pedido, id) => {
              return (
                <div>
                  <div key={id}>
                    {
                      (pedidos.lenght = 0 ? (
                        <p>No hay pedidos</p>
                      ) : (
                        <div key={pedido.id} className="text-lg mt-4 mb-4">
                          <Pedido pedido={pedido} />
                        </div>
                      ))
                    }
                  </div>
                </div>
              );
            })} */}

          {displayedPedidos.map((pedido, id) => {
            return (
              <div>
                <div key={id}>
                  {
                    (pedidos.lenght = 0 ? (
                      <p>No hay pedidos</p>
                    ) : (
                      <div key={pedido.id} className="text-lg mt-4 mb-4">
                        <Pedido pedido={pedido} />
                      </div>
                    ))
                  }
                </div>
              </div>
            );
          })}

          {searchterm === "" ? (
            <>
              {pedidos.length === 0 ? null : (
                <div className="flex justify-center text-sm w-full mt-4 mb-4 font-bold">
                  <MyPaginateSmall
                    pageCount={Math.ceil(pedidos.length / PAGE_SIZE)}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    previousLabel="<"
                    nextLabel=">"
                    containerClassName={
                      "flex flex-row flex-wrap items-center justify-center"
                    }
                    pageClassName={
                      "p-4 rounded-full w-2 h-2 flex items-center justify-center"
                    }
                    previousClassName={
                      "p-4 rounded-full w-2 h-2 flex items-center justify-center transition duration-200 ease-in-out transform hover:-translate-x hover:scale-150"
                    }
                    nextClassName={
                      "p-4 rounded-full w-2 h-2 flex items-center justify-center transition duration-200 ease-in-out transform hover:-translate-x hover:scale-150"
                    }
                    disabledClassName={"opacity-50 cursor-not-allowed"}
                    breakClassName={"text-gray-500"}
                    activeClassName={"active"}
                    forcePage={seleccionada}
                  />
                </div>
              )}

              {/*               <div className="flex justify-center m-2 text-base xs:invisible md:visible">
               <MyPaginate
                  pageCount={Math.ceil(pedidos.length / PAGE_SIZE)}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageChange}
                  className="pagination"
                  previousLabel="<"
                  nextLabel=">"
                  containerClassName={
                    "flex flex-row items-center justify-center space-x-2"
                  }
                  pageClassName={
                    "rounded-full w-8 h-8 flex items-center justify-center text-gray-500 hover:text-gray-900  transition duration-150 ease-in-out ml-2 mr-2"
                  }
                  activeClassName={"bg-gray-500 text-white"}
                  previousClassName={
                    "rounded-full w-8 h-8 flex items-center justify-center text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out mr-2"
                  }
                  nextClassName={
                    "rounded-full w-8 h-8 flex items-center justify-center text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out ml-2 "
                  }
                  disabledClassName={"opacity-50 cursor-not-allowed"}
                  forcePage={seleccionada}
                /> 
              </div> */}
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Pedidos;
