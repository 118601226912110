import React, { Fragment, useEffect, useContext, useState } from "react";
import { FirebaseContext } from "../../firebase";
import {
  BellIcon
} from "@heroicons/react/outline";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { useAuth } from "../../context/AuthContext";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import ReactTimeAgo from "react-time-ago";
import user from "../../images/user.png";
import useSound from 'use-sound';
import boopSfx from '../../media/notification.wav';


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Notifications = () => {
  //Tiempo que transcurrio desde la notificacion
 // TimeAgo.addDefaultLocale(es);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //State de las notificaciones

  const [notificaciones, guardarNotificaciones] = useState([]);
  const [notificacionesCount, guardarNotificacionesCount] = useState(0);

  //extraer el usuario de auth
  const { usuario } = useAuth();

  //hook para navegar
  const navigate = useNavigate();

  //alerta de sonido
  const [play] = useSound(boopSfx);

  //consulta la base de datos al cargar con el usuario actual y si ha sido consultada la notificacion o no con isViewed

  useEffect(() => {
    const obtenerNotificaciones = () => {
      //si el usuario no está autentificado no carga las notificaciones
      if (usuario !== null) {
        firebase.db
          .collection("notifications")
          .where("to", "==", usuario.uid)
          .onSnapshot(manejarSnapshot);
      }
    };

    obtenerNotificaciones();

    /*     var first = firebase.db.collection("notifications")
        .orderBy("creado")
        .limit(10);

 first.get().then((documentSnapshots) => {
  // Get the last visible document
  var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
  console.log("last", lastVisible);

  // Construct a new query starting at this document,
  // get the next 25 cities.
  var next = firebase.db.collection("notifications")
          .orderBy("creado")
          .startAfter(lastVisible)
          .limit(10);
}); */
  }, []);

  //snapshot que nos permite utilizar la bd en tiempo real de firestore
  function manejarSnapshot(snapshot) {
    let notifications = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    notifications = _.orderBy(notifications, "creado", "desc");

    //hace un slice del arreglo para que solo muestre las primeras 10
    const firstTen = notifications.slice(0, 10);

    guardarNotificaciones(firstTen);

    //  console.log('NOTIFICACIONES',notificaciones)
  }

  useEffect(() => {
    //calcula el número de notificaciones sin leer para mostrar en el indicador
    var count = 0;
    for (var i = 0; i < notificaciones.length; ++i) {
      if (notificaciones[i].isViewed == false) count++;
    }
    guardarNotificacionesCount(count);

  }, [notificaciones]);

  const handleViewed = () => {
    //UPDATE del state isViewed al asumir que el usuario ya dio click en el botón de notificaciones

    notificaciones.forEach((element) => {
      firebase.db
        .collection("notifications")
        .doc(element.id)
        .update({ isViewed: true });
    });

    var count = 0;
    for (var i = 0; i < notificaciones.length; ++i) {
      if (notificaciones[i].isViewed == false) count++;
    }
    guardarNotificacionesCount(count);
  };

  return (
    <div className="flex">
      {/* Profile dropdown */}
      <Menu as="div" className="ml-3 relative z-50">
        {({ open }) => (
          <>
            <div className="flex">
              <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-green-500 mr-1 ">
                <div
                  onClick={handleViewed}
                  className="bg-gray-800  p-1 rounded-full  text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span className="sr-only">Notificaciones</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </div>
              </Menu.Button>
              {notificacionesCount === 0 ? null : (
                <div className="absolute -top-1 ml-5 z-10 bg-red-500 text-xs font-bold px-1 rounded-sm animate-pulse">
                  {notificacionesCount}
                </div>
              )}
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none bg-gray-800 overflow-y-auto h-auto max-h-96 xs:w-80 md:w-96 lg:w-96 xl:w-96"
              >
                <p className="flex justify-center m-2 text-white ">
                  Notificaciones
                </p>

                {notificaciones.map((notificacion, id) => {
                  const handleRoute = () => {
                    const ruta = "/producto/" + notificacion.idProducto;
                    navigate(ruta);
                  };

                  return (
                    <div key={id}>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-yellow-500" : "",
                              "block px-4 py-2 text-sm hover:text-white text-gray-400  cursor-pointer rounded-lg border-gray-700  m-1"
                            )}
                          >
                            <p className=" text-xs px-4 ">
                              {new Date(notificacion.creado).toLocaleString()}
                              <ReactTimeAgo
                                class="text-xs ml-1"
                                date={notificacion.creado}
                                locale="es-MX"
                              />
                            </p>
                            <div
                              onClick={handleRoute}
                              className={classNames(
                                active ? "bg-yellow-500" : "",
                                "block px-4 py-2 text-sm hover:text-white cursor-pointer  overflow-ellipsis overflow-hidden"
                              )}
                            >
                              <div className="flex">
                                {notificacion.photo ? (
                                  <img
                                    className="mt-2 rounded-full w-8 h-8 sm:w-10 sm:h-10 mr-2"
                                    alt=""
                                    src={notificacion.photo}
                                  />
                                ) : (
                                  <img
                                    className="mt-2 rounded-full w-8 h-8 sm:w-10 sm:h-10 mr-2"
                                    alt=""
                                    src={user}
                                  />
                                )}
                                {notificacion.name}

                                {notificacion.notification}
                              </div>
                            </div>
                          </div>
                        )}
                      </Menu.Item>
                    </div>
                  );
                })}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

export default Notifications;
