import React from 'react';
import ReactDOM from 'react-dom';
import './css/main.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'
import { hydrate, render } from "react-dom";

/* ReactDOM.hydrate(
<Router>
     <App />
</Router>,
  document.getElementById('root')
);  */

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Router>
    <App /></Router>
, rootElement);
} else {
  render(<Router>
    <App /></Router>
, rootElement);
} 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
