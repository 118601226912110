import React, { useContext, useRef, useEffect, useState } from "react";
import PedidoContext from "../../context/pedidos/pedidosContext";
import { FirebaseContext } from "../../firebase";
import { useNavigate } from "react-router-dom";

export default function Paypal({ totalPedido, pedidoObj }) {
  const { pedido, total, mostrarResumen, eliminarProducto, pedidoRealizado } =
    useContext(PedidoContext);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const paypal = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    if (totalPedido > 0) {
      //Convertimos el pedido al formato de Paypal

      const productos = [];

      var suma = 0;

      pedidoObj.orden.forEach((item) => {
        if (item.descuento > 0) {
          let precio_final = item.precio - item.precio * (item.descuento / 100);

          //     console.log("CON DESCUENTO",precio_final.toFixed(2))

          suma = suma + precio_final.toFixed(2) * item.cantidad;

          let objeto_item = {
            name: item.nombre,
            description: item.descripcion.substring(0, 120),
            sku: item.sku,
            unit_amount: {
              currency_code: "MXN",
              value: precio_final.toFixed(2),
            },

            quantity: item.cantidad,
            category: "PHYSICAL_GOODS",
          };

          /*         price_data: {
          currency: "MXN",
          product_data: {
            name: item.nombre,
            images: [item.imagen],
          },
          unit_amount: precio_final,
        },
        quantity: item.cantidad, */

          productos.push(objeto_item);
        } else {
          //     console.log("SIN DESCUENTO",item.precio.toFixed(2))

          suma = suma + item.precio * item.cantidad;

          let objeto_item = {
            name: item.nombre,
            description: item.descripcion.substring(0, 120),
            sku: item.sku,
            unit_amount: {
              currency_code: "MXN",
              value: item.precio.toFixed(2),
            },

            quantity: item.cantidad,
            category: "PHYSICAL_GOODS",
          };

          productos.push(objeto_item);
        }

        //      console.log("LA SUMA TOTAL ES", suma.toFixed(2))

        //envio

        /*            {
                  name: "Shoes",
                  description: "Running, Size 10.5",
                  sku: "sku02",
                  unit_amount: {
                       currency_code: "MXN",
                       value: "45.00"
                  },
                  tax: {
                      currency_code: "MXN",
                      value: "5.00"
                  },
                  quantity: "2",
                  category: "PHYSICAL_GOODS"
              } */
      });

      window.paypal
        .Buttons({
          createOrder: (data, actions, err) => {
            if (pedidoObj.envio == "costo") {
              var nuevoEnvio = pedidoObj.costo_envio;
            } else {
              nuevoEnvio = 0;
            }

            return actions.order.create({
              intent: "CAPTURE",

              //FUNCIONA PERO ES MUY BÁSICO
              /*               purchase_units: [
                {
                  description: "Tu pedido desde Revdev Store",
                  amount: {
                    currency_code: "MXN",
                    value: totalPedido.toFixed(2)
                  },

                },
              ] */

              //FUNCIONA PERO NO TIENE TODAS LAS FUNCIONES IMPLEMENTADAS
              /*               purchase_units: [
                {
                    reference_id: "PUHF",
                    description: "Some description",
    
                    custom_id: "Something7364",
                    soft_descriptor: "Great description 1",
                    amount: {
                        currency_code: "MXN",
                    
                        value: "200.00",
                        breakdown: {
                            item_total: {
                                currency_code: "MXN",
                                value: "200.00"
                            }
                        }
                    },
                    items: [
                        {
                            name: "Item 1",
                            description: "The best item ever",
                            sku: "xyz-2654",
                            unit_amount: {
                                currency_code: "MXN",
                                value: "100.00"
                            },
                            quantity: "1"
                        },
                        {
                            name: "Item 2",
                            description: "Not bad too",
                            sku: "zdc-3942",
                            unit_amount: {
                                currency_code: "MXN",
                                value: "50.00"
                            },
                            quantity: "2"
                        }
                    ],
    
                }
            ] */

              //FUNCIONA CON TODAS LAS FUNCIONES DE PAYPAL IMPLEMENTADAS
              purchase_units: [
                {
                  reference_id: "RevdevStore",
                  description: "Tu compra en Revdev Store",

                  custom_id: "",
                  soft_descriptor: "",

                  amount: {
                    currency_code: "MXN",

                    value: (suma + nuevoEnvio).toFixed(2),

                    breakdown: {
                      item_total: {
                        currency_code: "MXN",
                        value: suma.toFixed(2),
                      },
                      shipping: {
                        currency_code: "MXN",
                        value: nuevoEnvio,
                      },
                      handling: {
                        currency_code: "MXN",
                        value: "0.00",
                      },
                      tax_total: {
                        currency_code: "MXN",
                        value: "0.00",
                      },
                      shipping_discount: {
                        currency_code: "MXN",
                        value: "0.00",
                      },
                    },
                  },

                  /*               amount: {
                  currency_code: "MXN",
                  value: "230.00",
                  
                  breakdown: {
                      item_total: {
                          currency_code: "MXN",
                          value: "180.00"
                      },
                      shipping: {
                          currency_code: "MXN",
                          value: "30.00"
                      },
                      handling: {
                          currency_code: "MXN",
                          value: "10.00"
                      },
                      tax_total: {
                          currency_code: "MXN",
                          value: "20.00"
                      },
                      shipping_discount: {
                          currency_code: "MXN",
                          value: "10"
                      }
                  }
              } */

                  items: productos,

                  /*               [{
                name: "T-Shirt",
                description: "Green XL",
                sku: "sku01",
                unit_amount: {
                     currency_code: "MXN",
                     value: totalPedido.toFixed(2)
                },

                quantity: "1",
                category: "PHYSICAL_GOODS"
            }
            ] */

                  /*                [{
                  name: "T-Shirt",
                  description: "Green XL",
                  sku: "sku01",
                  unit_amount: {
                       currency_code: "MXN",
                       value: "90.00"
                  },
                  tax: {
                      currency_code: "MXN",
                      value: "10.00"
                  },
                  quantity: "1",
                  category: "PHYSICAL_GOODS"
              },
                  {
                  name: "Shoes",
                  description: "Running, Size 10.5",
                  sku: "sku02",
                  unit_amount: {
                       currency_code: "MXN",
                       value: "45.00"
                  },
                  tax: {
                      currency_code: "MXN",
                      value: "5.00"
                  },
                  quantity: "2",
                  category: "PHYSICAL_GOODS"
              }
              ]  */

                  shipping: {
                    method: "Paquetería",
                    address: {
                      name: {
                        full_name: pedidoObj.nombre,
                        surname: "",
                      },
                      address_line_1: pedidoObj.calle,
                      address_line_2: "",
                      admin_area_2: pedidoObj.ciudad,
                      admin_area_1: pedidoObj.estado,
                      postal_code: pedidoObj.codigoPostal,
                      country_code: "MX",
                    },
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();

            const nuevoPedidoObj = {
              ...pedidoObj,
              plataforma: "paypal",
              response: { ...order },
            };

            const pedido = firebase.db
              .collection("ordenes")
              .add(nuevoPedidoObj)
              .then((dbRef) => {
                //costo de envio hacemos esta pequeña lógica para enviar el valor en el mail

                if (nuevoPedidoObj.envio == "costo") {
                  var nuevoEnvio = nuevoPedidoObj.costo_envio.toFixed(2);
                } else {
                  nuevoEnvio = "0.00 - Gratis";
                }

                //enviamos el correo de confirmación con template en firestore
                firebase.db.collection("mail").add({
                  to: nuevoPedidoObj.correo,
                  template: {
                    name: "confirmacion_pedido",
                    data: {
                      id: dbRef.id,
                      nombre: nuevoPedidoObj.nombre.split(" ")[0],
                      nombreCompleto: nuevoPedidoObj.nombre,
                      calle: nuevoPedidoObj.calle,
                      colonia: nuevoPedidoObj.colonia,
                      ciudad: nuevoPedidoObj.ciudad,
                      estado: nuevoPedidoObj.estado,
                      codigoPostal: nuevoPedidoObj.codigoPostal,
                      creado: Date(nuevoPedidoObj.creado).toLocaleString(),
                      productos: nuevoPedidoObj.orden,
                      envio: nuevoPedidoObj.envio,
                      costo_envio: nuevoEnvio,
                      total: nuevoPedidoObj.total,
                    },
                  },
                });

                //quitamos los productos adquiridos del stock

                nuevoPedidoObj.orden.forEach((item) => {
                  if (item.noAplicaStock === false) {
                    var docRef = firebase.db
                      .collection("productos")
                      .doc(item.id);

                    docRef
                      .get()
                      .then((doc) => {
                        if (doc.exists) {
                          const cantidad = doc.data().stock;

                          const cantidadActualizada = cantidad - item.cantidad;

                          firebase.db
                            .collection("productos")
                            .doc(item.id)
                            .update({
                              stock: cantidadActualizada,
                            })
                            .then(() => {
                              console.log("SE ACTUALIZO LA CANTIDAD");
                            });
                        } else {
                          // doc.data() will be undefined in this case
                          console.log("No se encontro este producto!");
                        }
                      })
                      .catch((error) => {
                        console.log("Error getting document:", error);
                      });
                  }
           
                });

                //limpia el carrito en firebase

                firebase.db
                  .collection("cart")
                  .doc(nuevoPedidoObj.uidUsuario)
                  .delete();

                //limpia el carrito de compras
                pedidoRealizado();

                //navega a la pagina de confirmación
                navigate("/confirmacion");
              })
              .catch(function (error) {
                console.log(error);
              });
          },
          onError: (err) => {
            console.log(err);

            navigate("/error");
          },
        })
        .render(paypal.current);
    }
  }, [totalPedido]);

  return (
    <div>
      <div className="flex justify-center" ref={paypal}></div>
    </div>
  );
}
