import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../../firebase";
import _ from "lodash";
import ReactLoading from "react-loading";
import FrontHeader from "./FrontHeader";
import FrontFooter from "./FrontFooter";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Cart from "../paginas/Cart";
import SearchAlgolia from "../ui/SearchAlgolia";
import { useAuth } from "../../context/AuthContext";
import { TemplateIcon } from "@heroicons/react/outline";
import FrontPageCategoriaPagination from "../ui/FrontPageCategoriaPagination";
import SearchMeliSearch from "../ui/SearchMeliSearch";
import SearchMeliSearchExp1 from "../ui/SearchMeliSearchExp1";
import Autocomplete from "../ui/Autocomplete";
import FrontPageSinCategoriaPagination from "../ui/FrontPageSinCategoriaPagination";
import SearchAlgoliaFront from "../ui/SearchAlgoliaFront";
import cart from "../../images/cart.png";
import { Helmet } from 'react-helmet-async';


const FrontPage = () => {
  //Drawer del carro de compras
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //extraer el usuario de auth
  const { usuario } = useAuth();

  if (usuario) {
    usuario.getIdTokenResult().then((idTokenResult) => {
      //  console.log("ESTÁ HABILITADO", idTokenResult.claims.habilitado);
      //  console.log("SU ROL ES", idTokenResult.claims.role);
    });
  }

  //loading de la página principal
  const [loading, setLoading] = useState(false);

  //state de la busqueda
  const [searchterm, setSearchTerm] = useState("");

  //State que guarda las categorias
  const [categorias, guardarCategorias] = useState([]);

  const [treeData, setTreedata] = useState({
    currentNode: {},
    searchFocusIndex: 0,
    treeData: [],
  });

  useEffect(() => {
    setLoading(true);

    //obtiene las categorias
    const db = firebase.db;
    const collectionRef = db.collection("categorias");

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const categorias = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      guardarCategorias(categorias);

      //SI DATOS CONTIENE CATEGORIAS LAS PONE EN EL STATE TREEDATA

      if (Object.keys(categorias).length > 0) {
        setTreedata({ treeData: categorias[0].treeData });
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <Helmet>
        <title>The Revdev Store</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content="Potencia las ventas de tu negocio con tu tienda en linea. Tienda de demostración de The Revdev store."
        />
        <meta itemprop="image" content={cart} />

        {/* Open Graph / Facebook  */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://store.revdev.mx/" />
        <meta property="og:title" content="The Redev Store" />
        <meta property="og:description" content="Potencia las ventas de tu negocio con tu tienda en linea. Tienda de demostración de The Revdev store" />
        <meta property="og:image" content={cart} />

        {/* Twitter  */}
        <meta property="twitter:card" content={cart} />
        <meta property="twitter:url" content="https://store.revdev.mx/" />
        <meta property="twitter:title" content="The Redev Store" />
        <meta property="twitter:description" content="Potencia las ventas de tu negocio con tu tienda en linea. Tienda de demostración de The Revdev store" />
        <meta property="twitter:image" content={cart} />
      </Helmet>

      <div className="min-h-screen overflow-x-hidden">
        <FrontHeader
          toggleDrawer={toggleDrawer}
          searchterm={searchterm}
          setSearchTerm={setSearchTerm}
        />

        {loading === true ? (
          <div className="bg-gray-900 flex justify-center items-center h-screen">
            <ReactLoading color={"#22C55E"} type={"spin"} />
          </div>
        ) : (
          <>
            <div className="text-3xl font-light pb-10 bg-gray-200">
              <div>
                {" "}
                <SearchAlgoliaFront />{" "}
              </div>

              {/* <SearchMeliSearchExp1 /> */}
              {/* <div>{ <SearchMeliSearch/> }</div> */}

              <div className="p-5 mx-auto max-w-screen-2xl">
                <Drawer
                  size={350}
                  open={isOpen}
                  onClose={toggleDrawer}
                  direction="right"
                >
                  <Cart isOpen={isOpen} setIsOpen={setIsOpen} />
                </Drawer>
                {/* <FrontCategoriaPagination /> */}

                {treeData.treeData.map((nodo, id) => {
                  const { title } = nodo;

                  return (
                    <div key={id}>
                      {/* <FrontCategoria categorieName={title} /> */}

                      <FrontPageCategoriaPagination categorieName={title} />
                    </div>
                  );
                })}
                <FrontPageSinCategoriaPagination />
              </div>
            </div>
          </>
        )}

        <FrontFooter />
      </div>
    </>
  );
};

export default FrontPage;
