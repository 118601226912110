import React from 'react'
import { useNavigate} from 'react-router-dom';
import warning from "../../images/warning.png";


const Page404 = () => {


    const navigate = useNavigate();

    return (

            <div class=" bg-gray-800 text-white min-h-screen flex items-center">
            <div class="container mx-auto p-4 flex flex-wrap items-center">
                <div class="w-full md:w-5/12 text-center p-4">
                <img src={warning} alt="Not Found" />
                </div>
                <div class="w-full md:w-7/12 text-center md:text-left p-4">
                <div class="text-9xl font-medium">404</div>
                <div class="text-xl md:text-3xl font-medium mb-4">
                    No encontramos esta página en nuestro servidor.
                </div>
                <div class="text-lg mb-8">
                    Tal vez la has escrito mal o ya no existe
                </div>
                <button onClick={() => navigate('/')} class="border border-white rounded p-4">Volver a la tienda</button>
                </div>
            </div>
            </div>

    )
}

export default Page404
