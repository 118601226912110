import React, { useState, useContext, useEffect } from "react";
import { FirebaseContext } from "../../firebase";
import { useNavigate } from "react-router-dom";
import FrontHeader from "./FrontHeader";
import { useAuth } from "../../context/AuthContext";
import Cart from "./Cart";
import Drawer from "react-modern-drawer";
import PedidoContext from "../../context/pedidos/pedidosContext";

const Error = () => {
  //Drawer carro de compras y su state
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  //state de la busqueda
  const [searchterm, setSearchTerm] = useState("");

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const [editando, establecerEditando] = useState(false);
  const [loading, setLoading] = useState(false);

    //Context del pedido
    const {
      pedido,
      mostrarResumen,
      eliminarProducto,
      pedidoRealizado,
      guardarPedido,
    } = useContext(PedidoContext);
  

  //extraer el usuario de auth
  const { usuario } = useAuth();

  //hook para redireccionar
  const navigate = useNavigate();

   //carga el carrito de compras
   useEffect(() => {
    //verifica que esté el usuario auth y su pedido sin items y su carro no tenga items

    if (usuario) {

        //limpio carrito
        pedidoRealizado()

        //lo ponemos en el state del pedido
        const dbRef = firebase.db
          .collection("cart")
          .doc(usuario.uid)
          .get()
          .then((doc) => {
            //firebase nos entrega un objeto que debe ser convertido a un arreglo
            //console.log("DOC DATA ES", doc.data());

            if (doc.data()) {
              //convertimos de Objeto a un Arreglo, que es el formato del carrito
              const newArrayDataOfObject = Object.values(doc.data());

              //console.log("EL ARREGLO ES", newArrayDataOfObject);

              //por cada item del pedido
              newArrayDataOfObject.forEach((item) => {
                const pedidoSpread = { ...item };
                //al guardar el pedido se mete item por item al pedido
                guardarPedido(pedidoSpread);
              });
            }
          });
      
    }
  }, []);


  return (
    <>
      <div>
        <FrontHeader
          toggleDrawer={toggleDrawer}
          searchterm={searchterm}
          setSearchTerm={setSearchTerm}
        />

        <Drawer
          size={350}
          open={isOpen}
          onClose={toggleDrawer}
          direction="right"
        >
          <Cart />
        </Drawer>

        <div className="bg-gray-900 flex justify-center items-center h-screen " >
          <div class=" bg-gray-300 rounded-xl shadow-md m-6">
            <label className="block text-gray-700 text-center text-3xl font-light p-10">
              Lo sentimos, pero no se ha podido completar el pedido
            </label>

            <div className="flex justify-center pb-10">
              <img
                class="flex w-40 justify-center border  border-gray-400"
                src="http://localhost:3000/warning.png"
                alt=""
              />
            </div>

            <div className="flex justify-center items-center">
              <p className="text-gray-600 pl-5 pr-5">
                Puedes volver a intentar tu compra regresando al carrito de
                compra y comprobar la información proporcionada.
              </p>
            </div>
            <div className="flex justify-center p-5">
              <p className="text-gray-500  text-xl"></p>
            </div>
            <div className="flex justify-center pb-5">
              <button
                onClick={() => navigate("/checkout")}
                className="rounded-xl text-white bg-blue-500 hover:bg-blue-600 p-3 text-xl mt-3 mb-5 inline-flex items-center shadow-lg"
                >
                REGRESAR AL CARRITO DE COMPRA
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
