import React, { useState, useContext, useRef } from "react";
import { FirebaseContext } from "../../firebase";
import { useNavigate } from "react-router-dom";
import ProductosContext from "../../context/ProductosContext";
import Swal from "sweetalert2";
import ReactTimeAgo from 'react-time-ago'

const CommentAdmin = ({ comentario }) => {

  //context de firebase

  const { firebase } = useContext(FirebaseContext);

  const navigate = useNavigate();


  //formatea el numero de telefono

  //modificar el estado de disponibilidad en firebase
  const actualizarDisponibilidad = () => {};

  const verDetalles = () => {
    navigate("/UsuarioDetail");
  };

  const verComentarios = () => {
    navigate("/comentarios");
  };

  const handleEliminar = () => {
    Swal.fire({
      title: "¿Estás seguro de eliminar este comentario?",
      text: "Los cambios no podrán ser revertidos",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar",
    }).then((result) => {
      if (result.isConfirmed) {
        firebase.db
          .collection("comentarios")
          .doc(comentario.id)
          .delete()
          .then(() => {
            Swal.fire(
              "Eliminado",
              "El comentario ha sido eliminado",
              "success"
            );
          })
          .catch((error) => {
            console.error("Error al eliminar el comentario: ", error);
          });
      }
    });
  };

  return (
    <>
      {/*       <div className="w-full px-3 mb-4">
        <div className="p-3 shadow-md bg-white rounded-xl  max-w-screen-lg">
          <div className="lg:flex">
            <div className="text-xs lg:w-9/12 xl:w-9/12 pt-2">
              <p>{new Date(comentario.creado).toLocaleString()}</p>  
              <div className="text-gray-600 text-sm">
                {comentario.comentario}
              </div>
            </div>

            <div className="flex justify-around lg:w-3/12 xl:w-3/12">
              <button
                onClick={handleEliminar}
                className="rounded-lg text-white bg-red-500 hover:bg-red-600 p-2 text-sm inline-flex items-center mt-2   lg:mb-0 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
                Borrar
              </button>{" "}
              <button
                onClick={verComentarios}
                className="rounded-lg text-white bg-blue-500 hover:bg-blue-600 p-2 text-sm inline-flex items-center mt-2  lg:mb-0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                  />
                </svg>
                Comentar
              </button>{" "}
            </div>
          </div>
        </div>
      </div> */}

      <div className="containter mx-auto  ">
        <div className="bg-gray-800 p-3 rounded-lg shadow-lg relative hover:shadow-2xl transition duration-500">
          <p className=" text-sm text-gray-300 font-semibold mb-3">
            {new Date(comentario.creado).toLocaleString()}
            <ReactTimeAgo className="text-xs text-gray-300 ml-1"date={comentario.creado} locale="es-MX"/>
          </p>
          <p className="text-gray-300 leading-6 tracking-normal break-words">
            {comentario.comentario}
          </p><div className="flex justify-start lg:w-3/12 xl:w-3/12">
              <button
                onClick={handleEliminar}
                className="rounded-lg text-white bg-red-500 hover:bg-red-600 p-2 text-sm inline-flex items-center mt-4 mr-4  "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
                Borrar
              </button>{" "}
              <button
                onClick={verComentarios}
                className="rounded-lg text-white bg-purple-600 hover:bg-purple-700 p-2 text-sm inline-flex items-center mt-4  lg:mb-0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                  />
                </svg>
                Comentar
              </button>{" "}
            </div>
        </div>
      </div>
    </>
  );
};

export default CommentAdmin;
