import React, { useContext, useState, useEffect } from "react";
import { FirebaseContext } from "../../firebase";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import Sidebar from "../ui/Sidebar";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import ImagesArray from "./ImagesArray";

const MyPaginateSmall = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  li a {
    border-radius: 4px;
    cursor: pointer;
    color: white;
    padding: 0.3rem 0.6rem;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
    color: white;
  }
  li.active a {
    border-color: transparent;
    color: white;
    background-color: #3b82f6;
  }
  li.disabled a {
    color: grey;
    cursor: default;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const ImageGalleryComponent = ({
  guardarUrlImagen,
  setIsOpenModalGallery,
  images,
  setImages,
  urlimagen,
  title,
  altText,
  isArray,
  setChanges
}) => {
  const PAGE_SIZE = 20;

  const [loading, setLoading] = useState(false);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  // Get all the images from Storage
  const [files, setFiles] = useState([]);

  //estado que guarda solo las imagenes que se mostrarán
  const [displayedImages, setDisplayedImages] = useState([]);

  //state que cambia el numero de pagina en react-pagination
  const [currentPage, setCurrentPage] = useState(0);
  //state que forza a cambiar el numero de pagina en react-pagination
  const [seleccionada, setSeleccionada] = useState(0);

  var storageRef = firebase.storage.ref("productos");

  useEffect(() => {
    setLoading(true);

    const fetchImages = async () => {
      //lista todas las imagenes
      let result = await storageRef.listAll();

      //solo los primeros 100 resultados
      //   let result = await storageRef.list({ maxResults: 100 });

      let urlPromises = result.items.map((imageRef) =>
        imageRef.getDownloadURL()
      );

      return Promise.all(urlPromises);
    };

    const loadImages = async () => {
      const urls = await fetchImages();
      setFiles(urls);

      setLoading(false);
    };
    loadImages();
  }, []);

  useEffect(() => {
    const corte = files.slice(
      currentPage * PAGE_SIZE,
      currentPage * PAGE_SIZE + PAGE_SIZE
    );
    setDisplayedImages(corte);
  }, [currentPage, files]);

  /*    const displayedImages = files.slice(
    currentPage * PAGE_SIZE,
    currentPage * PAGE_SIZE + PAGE_SIZE
  ); */

  const handlePageChange = ({ selected }) => {
    setLoading(true);
    setCurrentPage(selected);
    //este state lo puse por que cuando daba click en la seleccion se volvia de vuelta a la pagina uno y así tuve que forzar su estado
    setSeleccionada(selected);
    setLoading(false);
    window.scrollTo(0, 0);
  };

  const deleteImage = (imageUrl) => {
    Swal.fire({
      title:
        "<p style='color:#fff'>" +
        "¿Estás seguro de eliminar permanentemente esta imagen?" +
        "</p>",
      html: '<p style="color:#fff">Los cambios no podrán ser revertidos</p>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#581c87",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar",
      color: "#fff",
      background: "#18181b",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        // Create a reference to the image file
        const imageRef = firebase.storage.refFromURL(imageUrl + "?alt=media");

        // Delete the image file
        imageRef
          .delete()
          .then(() => {
            const index = files.indexOf(imageUrl);
            if (index > -1) {
              console.log("se encontro el url de la imagen"); // only splice array when item is found
              const filesCopy = [...files]; //clona el arreglo
              filesCopy.splice(index, 1); // El segundo parametro dice que solo remuevo un item del arreglo
              setFiles(filesCopy);

              Swal.fire({
                title: "<p style='color:#22c55e'>" + "Eliminado" + "</p>",
                html: '<p style="color:#fff">La imagen se eliminó correctamente</p>',
                icon: "success",
                color: "#fff",
                background: "#18181b",
                confirmButtonColor: "#581c87",
              });
            } else {
              console.log("No se encontro el url de la imagen");
            }
          })
          .catch((error) => {
            console.error("Error al eliminar la imagen: ", error);
          });
      }
    });
  };

  return (
    <div className="md:flex bg-gray-900 max-w-4xl">
      {loading === true ? (
        <div className="w-full flex justify-center items-center">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="p-4 bg-gray-900 xs:h-screen lg:h-auto">
          {/* <div className="flex text-white ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mt-1 mr-2"
              fill="white"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth=".1"
            >
              <path d="M9 12c0-.552.448-1 1.001-1s.999.448.999 1-.446 1-.999 1-1.001-.448-1.001-1zm6.2 0l-1.7 2.6-1.3-1.6-3.2 4h10l-3.8-5zm5.8-7v-2h-21v15h2v-13h19zm3 2v14h-20v-14h20zm-2 2h-16v10h16v-10z" />
            </svg>
            <h1 className="text-lg mb-2 self-center">Selecciona una imagen</h1>

          </div> */}

          <div className="p-4 bg-gray-800 shadow-md rounded-xl box-border m-2">
            <div className="grid grid-flow-row-dense xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5  justify-between mt-2 ">
              {displayedImages.map((url) => (
                <>
                  <div
                    className="rounded-lg m-2  border-gray-300 border-2 p-2 hover:border-green-500 cursor-pointer"


                    onClick={() => {

                      setChanges(true)
                      console.log(isArray)
                      if(isArray === false){
                      //imagen principal
                       guardarUrlImagen(url);
                      setIsOpenModalGallery(false); }else{
                                   

                    if (images !== undefined) {

                        const imagesClone = [...images];

                        imagesClone.find((imagen, index) => {
                          if (imagen !== undefined) {
                            if (imagen.urlimagen === urlimagen) {
                              console.log("found it", imagen, index);
                              imagesClone[index] = {
                                urlimagen: url,
                                title: title,
                                altText: altText,
                              };
                            }
                          }
                        });

                        setImages(imagesClone);}

                        setIsOpenModalGallery(false)


                      }
                    }}
                  >
                    <img className="rounded-lg" src={url} />
                    {/*                     <button
                      onClick={() => deleteImage(url)}
                      className="rounded text-white bg-red-500 hover:bg-red-600 text-sm inline-flex items-center p-1 mt-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button> */}
                  </div>
                </>
              ))}
            </div>
            <div className="m-4 flex justify-center">
              <MyPaginateSmall
                pageCount={Math.ceil(files.length / PAGE_SIZE)}
                pageRangeDisplayed={1}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                previousLabel="<"
                nextLabel=">"
                containerClassName={
                  "flex flex-row flex-wrap items-center justify-center"
                }
                pageClassName={
                  "p-4 rounded-full w-2 h-2 flex items-center justify-center"
                }
                previousClassName={
                  "p-4 rounded-full w-2 h-2 flex items-center justify-center transition duration-200 ease-in-out transform hover:-translate-x hover:scale-150"
                }
                nextClassName={
                  "p-4 rounded-full w-2 h-2 flex items-center justify-center transition duration-200 ease-in-out transform hover:-translate-x hover:scale-150"
                }
                disabledClassName={"opacity-50 cursor-not-allowed"}
                breakClassName={"text-gray-500"}
                activeClassName={"active"}
                forcePage={seleccionada}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGalleryComponent;
