import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { Route } from "react-router-dom";
import Login from "../usuarios/Login";

const RutaProtegidaAdmin = ({ children, ...restoDePropiedades }) => {
  
  const { usuario } = useAuth();
  const [role, setRole] = useState()

//extraer informacion del admin y owner del token
 if (usuario) {
    usuario.getIdTokenResult().then((idTokenResult) => {
     setRole(idTokenResult.claims.role)
  });
 }

 //administradores y propietario pueden ver estas rutas 

  if (usuario && (role === "admin" || role === "owner")) {
    return <Route {...restoDePropiedades}>{children}</Route>;
  } else {
    return <Login />;
  }
};
export default RutaProtegidaAdmin;
