import React, { useContext, useEffect } from "react";
import Swal from "sweetalert2";
import PedidoContext from "../../context/pedidos/pedidosContext";
import { useNavigate } from "react-router-dom";
import ProductosContext from "../../context/ProductosContext";
import SettingsContext from '../../context/SettingsContext'


const Cart = ({ isOpen, setIsOpen }) => {

  //CONTEXT DEL PEDIDO

  const { pedido, eliminarProducto, pedidoRealizado } =
    useContext(PedidoContext);

 //Context con los Settings

  const { settings } = useContext(SettingsContext);   

  //TOTAL DEL PEDIDO CALCULADO EN PRODUCTOS CONTEXT
  const { totalPedido, guardarTotalPedido } = useContext(ProductosContext);



  const navigate = useNavigate();

  //State de los settings
  // const [settings, guardarSettings] = useState([""]);

  /*   useEffect(() => {
    //Funcion que obtiene los setttings con async (OLD APROACH)

    const obtenerSettings = async () => {
      try {
        await firebase.db
          .collection("settings")
          .onSnapshot(manejarSnapshot)
          .then(() => {
            console.log("SETTINGS OBTENIDAS");
          })
          .catch((error) => {
            // ..
          });
      } catch (error) {}
    };

    obtenerSettings();

    function manejarSnapshot(snapshot) {
      let settings = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      guardarSettings(settings);
    }
  }, []); */

/*   useEffect(() => {
    //obtiene los settings
    const db = firebase.db;
    const collectionRef = db.collection("settings");

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const settings = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      guardarSettings(settings);
    });

    return () => unsubscribe();
  }, []); */

  useEffect(() => {
    calcularTotalPedido();
  }, [pedido, settings]);

  const calcularTotalPedido = () => {
    let suma = 0;

    pedido.forEach((item) => {
      if (item.descuento > 0) {
        let precio_final = item.precio - item.precio * (item.descuento / 100);
        suma = suma + precio_final.toFixed(2) * item.cantidad;
      } else {
        suma = suma + item.precio * item.cantidad;
      }
    });

    if (Object.keys(pedido).length > 0 && Object.keys(settings).length > 0) {
      if (settings[0].envio == "costo") {
        suma = suma + settings[0].costo_envio;
      }
    }

    guardarTotalPedido(suma);
  };

  // vacia el carrito de compras

  const handleEliminar = () => {
    Swal.fire({
      title:
        "<p style='color:#fff'>" +
        "¿Estás seguro de vaciar el carrito?" +
        "</p>",
      html: '<p style="color:#fff">Los cambios no podrán ser revertidos</p>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar",
      color: "#fff",
      background: "#18181b",
      iconColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        pedidoRealizado();
      }
    });
  };

  const handleDrawer = () => {
    setIsOpen(false);
  };

  //elimina un producto del carrito

  const confirmarEliminacion = (id) => {
    eliminarProducto(id);
  };

  return (
    <>
      {Object.keys(settings).length > 0 ? (
        <div className=" h-full justify-center bg-gray-900 text-white border-l border-gray-400 overflow-y-auto">
          <div className="flex justify-between items-center p-2 m-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mt-1 hover:text-green-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={handleDrawer}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <p className="text-2xl self-center">Carro de compras</p>
            {Object.keys(pedido).length > 0 ? (
              <button
                onClick={handleEliminar}
                className="rounded-lg text-white bg-red-500 hover:bg-red-600 text-sm p-1 self-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
            ) : (
              <div></div>
            )}
          </div>

          {pedido.map((producto) => {
            const {
              cantidad,
              nombre,
              imagen,
              id,
              precio,
              descripcion,
              descuento,
            } = producto;
            return (
              <div
                className="bg-gray-800 m-4 rounded border-gray-600 p-2"
                key={id}
              >
                {producto.descuento > 0 ? (
                  <p className="text-white text-sm">
                    {producto.cantidad} x{" "}
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(
                      producto.precio -
                        producto.precio * (producto.descuento / 100)
                    )}
                    {" - "}
                    {"(Desc. " + descuento + "%)"} - {producto.nombre}
                  </p>
                ) : (
                  <p className="text-white text-sm">
                    {producto.cantidad} x{" "}
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(producto.precio)}{" "}
                    - {producto.nombre}
                  </p>
                )}

                <div className="flex justify-between mt-1">
                  <button
                    onClick={() => confirmarEliminacion(id)}
                    className="rounded text-white bg-red-500 hover:bg-red-600 text-sm inline-flex items-center p-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3 w-3"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </button>
                  <p className="text-white text-sm flex font-bold ">
                    MXN{" "}
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(
                      producto.cantidad *
                        (
                          producto.precio -
                          producto.precio * (producto.descuento / 100)
                        ).toFixed(2)
                    )}
                  </p>
                </div>
              </div>
            );
          })}

          {Object.keys(settings).length > 0 ? (
            <div>
              {settings[0].envio === "gratis" &&
              Object.keys(pedido).length > 0 ? (
                <p className="flex justify-center  text-base m-5 bg-gray-800 rounded-lg p-2  ">
                  Gastos de envío: Gratis
                </p>
              ) : null}

              {settings[0].envio === "costo" &&
              Object.keys(pedido).length > 0 ? (
                <p className="flex justify-center  text-base m-5 bg-gray-800 rounded-lg p-2  ">
                  Gastos de envío: ${settings[0].costo_envio.toFixed(2)}
                </p>
              ) : null}

              {settings[0].envio === "posicionamiento" &&
              Object.keys(pedido).length > 0 ? (
                <p className="flex justify-center  text-base m-5 bg-gray-800 rounded-lg p-2  ">
                  Nos encotramos trabajando en esta característica
                </p>
              ) : null}

              {settings[0].envio === "noenvio" &&
              Object.keys(pedido).length > 0 ? (
                <p className="flex justify-center  text-base m-5 bg-gray-800 rounded-lg p-2">
                  Actualmente no contamos con entrega a domicilio, pero puedes
                  pasar por tu pedido a nuestra tienda física.
                </p>
              ) : null}
            </div>
          ) : null}

          {parseInt(totalPedido, 10) <= 0 ? null : (
            <p className="flex justify-center text-2xl m-4 rounded-lg p-4 bg-green-500 font-bold">
              Total: MXN
              {new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(totalPedido)}
            </p>
          )}

          {Object.keys(pedido).length > 0 ? (
            <div className="flex justify-center">
              <button
                onClick={() => navigate("/checkout")}
                className=" ml-15 rounded-xl text-white bg-blue-500 hover:bg-blue-700 p-3 text-sm mt-3 mb-5 inline-flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                HACER EL PEDIDO
              </button>
            </div>
          ) : (
            <div className=" flex flex-col justify-center items-center pb-8">
              <div className="relative sm:max-w-sm w-full text-white p-20">
                <svg
                  clipRule="evenodd"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#d1d5db"
                  fill="#d1d5db"
                  strokeWidth=".1"
                >
                  <path d="M10.975 8l.025-.5c0-.517-.067-1.018-.181-1.5h5.993l-.564 2h-5.273zm-2.475 10c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm11.305-15l-3.432 12h-10.428l-.455-1.083c-.323.049-.653.083-.99.083-.407 0-.805-.042-1.191-.114l1.306 3.114h13.239l3.474-12h1.929l.743-2h-4.195zm-6.305 15c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm-4.5-10.5c0 2.485-2.017 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.017-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-3.086-2.122l-1.414 1.414-1.414-1.414-.707.707 1.414 1.415-1.414 1.414.707.708 1.414-1.415 1.414 1.414.708-.708-1.414-1.413 1.414-1.414-.708-.708z" />
                </svg>
                <h1 className="text-lg bg-red-500 text-white flex justify-center w-full p-5 rounded-lg text-center">
                  No hay productos en el carrito
                </h1>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="h-full justify-center bg-gray-900 text-white border-l border-gray-400 overflow-y-auto">
          <div className="relative sm:max-w-sm w-full text-white">
            <svg
              clipRule="evenodd"
              fillRule="evenodd"
              strokeLinejoin="round"
              strokeMiterlimit="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              stroke="white"
              fill="white"
              strokeWidth=".1"
            >
              <path
                d="m12.002 21.534c5.518 0 9.998-4.48 9.998-9.998s-4.48-9.997-9.998-9.997c-5.517 0-9.997 4.479-9.997 9.997s4.48 9.998 9.997 9.998zm0-1.5c-4.69 0-8.497-3.808-8.497-8.498s3.807-8.497 8.497-8.497 8.498 3.807 8.498 8.497-3.808 8.498-8.498 8.498zm0-6.5c-.414 0-.75-.336-.75-.75v-5.5c0-.414.336-.75.75-.75s.75.336.75.75v5.5c0 .414-.336.75-.75.75zm-.002 3c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z"
                fillRule="nonzero"
              />
            </svg>
            <div className="text-xl bg-red-500 text-white flex justify-center p-2 rounded-lg text-center  m-4">
              No es posible calcular el pedido debido a un problema de conexión
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
