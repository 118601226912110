import React, { useContext, useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { useParams } from "react-router-dom";
import { FirebaseContext } from "../../firebase";
import _ from "lodash";
import ReactTimeAgo from "react-time-ago";
import user from "../../images/user.png";

const Comment = ({ comentario }) => {
  //Tiempo que transcurrio desde el comentario
  //TimeAgo.addDefaultLocale(es);

  //extraer el usuario de auth
  const { usuario } = useAuth();

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //obtengo el id del producto desde la URL

  const { id } = useParams();

  //State de respuesta

  const [respuesta, guardarRespuesta] = useState("");
  const [respuestas, guardarRespuestas] = useState([]);

  const [replyState, setReplyState] = useState(false);

  useEffect(() => {
    //obtiene los settings
    const db = firebase.db;
    const collectionRef = db
      .collection("comentarios")
      .where("idProducto", "==", id)
      .where("root", "==", comentario.id);

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      let respuestas = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      respuestas = _.orderBy(respuestas, "creado", "asc");

      guardarRespuestas(respuestas);
    });

    return () => unsubscribe();
  }, []);

  //Añade un comentario
  const addReply = () => {
    const commentObj = {
      uidUsuario: usuario.uid,
      photo: usuario.photoURL,
      name: usuario.displayName,
      idProducto: id,
      comentario: respuesta,
      creado: Date.now(),
      root: comentario.id,
    };

    if(respuesta !== "")
    { 
    firebase.db.collection("comentarios").add(commentObj);
    }

    guardarRespuesta("");

    //envía una notificación a quien fué comentado
    const notificationObj = {
      uidUsuario: usuario.uid,
      photo: usuario.photoURL,
      name: usuario.displayName,
      idProducto: id,
      notification:
        " ha respondido a tu comentario de " + comentario.comentario,
      creado: Date.now(),
      to: comentario.uidUsuario,
      isViewed: false,
    };

    firebase.db.collection("notifications").add(notificationObj);
  };

  //  Maneja el estado al cambiar el valor en el comentario

  const handleChange = (e) => {
    switch (e.target.name) {
      case "respuesta":
        guardarRespuesta(e.target.value);
        break;

      default:
        break;
    }
  };

  //Añade una respuesta
  const replyS = () => {
    setReplyState(!replyState);
  };

  return (
    <div>
      <div className="antialiased mx-auto max-w-screen-md  ">
        <div key={comentario.id} className="mt-4 mb-4 ">
          <div className="flex">
            <div className="flex-1 border rounded-lg px-2 py-2 sm:px-4 sm:py-2 leading-relaxed bg-white shadow-md">
              <div className="flex">
                <div className="mr-3">
                  {comentario.photo ? (
                    <img
                      className="rounded-full w-8 h-8 sm:w-10 sm:h-10"
                      alt=""
                      src={comentario.photo}
                    />
                  ) : (
                    <img
                      className="rounded-full w-8 h-8 sm:w-10 sm:h-10"
                      alt=""
                      src={user}
                    />
                  )}
                </div>
                <p className="text-base self-center">
                  {comentario.name}{" "}
                  <ReactTimeAgo
                    className="text-gray-500 text-sm"
                    date={comentario.creado}
                    locale="es-MX"
                  />
                </p>
              </div>

              {/*               <p className="text-xs text-gray-400 mt-1 mb-1">
                {new Date(comentario.creado).toLocaleString()}{" "}
              </p> */}

              <p className="xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base break-normal w-auto mt-2">
                {comentario.comentario}
              </p>

              <div className="mt-2 flex items-center">
                {/*                 <div class="flex -space-x-2 mr-2">
                  <img
                    class="rounded-full w-6 h-6 border border-white"
                    src="http://localhost:3000/revdev.png"
                    alt=""
                  />
                  <img
                    class="rounded-full w-6 h-6 border border-white"
                    src="https://images.unsplash.com/photo-1513956589380-bad6acb9b9d4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&h=100&q=80"
                    alt=""
                  />
                </div> */}
                <div className=" flex-auto">
                  {/* <div class="text-sm text-gray-500 font-semibold">5 Replies</div> */}
                </div>

                {usuario !== null ? (
                  <div
                    onClick={replyS}
                    className="text-sm text-gray-500 font-semibold justify-start  hover:text-blue-500 cursor-pointer"
                  >
                    Responder
                  </div>
                ) : null}
              </div>
              <div className="space-y-4">
                {respuestas.map((comentario, id) => {
                  return (
                    <div className="" key={id}>
                      <div className="flex-1 bg-white rounded-lg px-4 py-2 sm:px-6 sm:py-4 ">
                        <div className="flex">
                          {comentario.photo ? (
                            <img
                              className="rounded-full w-8 h-8 sm:w-10 sm:h-10"
                              alt=""
                              src={comentario.photo}
                            />
                          ) : (
                            <img
                              className="rounded-full w-8 h-8 sm:w-10 sm:h-10"
                              alt=""
                              src={user}
                            />
                          )}
                          <p className="text-base self-center ml-2">
                            {comentario.name}{" "}
                            <ReactTimeAgo
                              className="text-gray-500 text-sm"
                              date={comentario.creado}
                              locale="es-MX"
                            />
                          </p>
                        </div>

                        {/*                         <span class="text-xs text-gray-400 pt-0">
                          {new Date(comentario.creado).toLocaleString()}{" "}
                        </span> */}

                        <p className="xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base break-normal w-auto mt-2 mb-1">
                          {comentario.comentario}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              {replyState === true ? (
                <div className="mt-2">
                  <div className="flex-1 border rounded-lg p-4 bg-gray-800">
                    <div className="flex mb-2">
                      {usuario.photoURL ? (
                        <img
                          className="rounded-full w-8 h-8 sm:w-10 sm:h-10"
                          alt=""
                          src={usuario.photoURL}
                        />
                      ) : (
                        <img
                          className="rounded-full w-8 h-8 sm:w-10 sm:h-10"
                          alt=""
                          src={user}
                        />
                      )}
                      <p className="text-base font-semibold mb-2 self-center ml-2 text-gray-300">
                        {usuario.displayName}
                      </p>
                    </div>

                    <textarea
                      className="focus:border-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-3"
                      name="respuesta"
                      placeholder="Escribe aquí tu comentario"
                      onChange={handleChange}
                      value={respuesta}
                    ></textarea>

                    <button
                      onClick={addReply}
                      className="px-4 py-2 text-base rounded-md text-white bg-blue-500 mt-3"
                    >
                      Comentar
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
