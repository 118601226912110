import React, { useState, useContext, useRef, useEffect } from "react";
import { FirebaseContext } from "../../firebase";
import Swal from "sweetalert2";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import ReactTimeAgo from "react-time-ago";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import cart from "../../images/cart.png";

const PedidoPrint = ({ pedido }) => {
  //Tiempo que transcurrio desde la notificacion
  // TimeAgo.addDefaultLocale(es);

  //context de firebase

  const [tiempoentrega, guardarTiempoEntrega] = useState(0);

  const { firebase } = useContext(FirebaseContext);

  const [datos, guardarDatos] = useState([]);

  //existencia ref para acceder a un valor directamente en el DOM
  const completadoRef = useRef(pedido.completado);

  const telefono_format =
    "(" +
    pedido.telefono.substring(0, 3) +
    ") " +
    pedido.telefono.substring(3, 6) +
    "-" +
    pedido.telefono.substring(6, 11);

  //define el tiempo de entrega
  const definirTiempo = (id) => {
    try {
      firebase.db.collection("ordenes").doc(id).update({ tiempoentrega });
    } catch (error) {
      console.log(error);
    }
  };

  //modificar el estado de disponibilidad en firebase
  const actualizarDisponibilidad = () => {
    const completado = completadoRef.current.value === "true";

    try {
      firebase.db.collection("ordenes").doc(pedido.id).update({
        completado,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const mostrarDetalle = async () => {
    try {
      await firebase.db
        .collection("pedidodet")
        .where("idPedido", "==", pedido.id)
        .onSnapshot(manejarSnapshot);
    } catch (err) {
      Swal.fire({
        title: "No se pudieron obtener los datos del pedido",
        text: "Si crees que hay un problema comunicate con el administrador",
      });
    }

    function manejarSnapshot(snapshot) {
      let datos = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      guardarDatos(datos);

      switch (datos[0].plataforma) {
        case "stripe":
          Swal.fire({
            title: "Detalles desde Stripe",
            html:
              '<p style="text-align:left;"><b>Stripe Id: </b>' +
              datos[0].response.id +
              "</p> " +
              '<p style="text-align:left;"><b>Cantidad: </b>' +
              (datos[0].response.amount / 100).toFixed(2) +
              "</p> " +
              '<p style="text-align:left;"><b>Captured: </b>' +
              datos[0].response.captured.toString() +
              "</p> " +
              '<p style="text-align:left;"><b>Created: </b>' +
              datos[0].response.created +
              "</p> " +
              '<p style="text-align:left;"><b>Currency: </b>' +
              datos[0].response.currency +
              "</p> " +
              '<p style="text-align:left;"><b>Paid: </b>' +
              datos[0].response.paid.toString() +
              "</p> " +
              '<p style="text-align:left;"><b>Payment Method: </b>' +
              datos[0].response.payment_method_details.card.last4 +
              "</p> " +
              '<p style="text-align:left;"><b>Last 4: </b>' +
              datos[0].response.paid.toString() +
              "</p> " +
              '<p style="text-align:left;"><b>Live Mode: </b>' +
              datos[0].response.livemode.toString() +
              "</p> ",
          });
          break;
        case "paypal":
          Swal.fire({
            title: "Detalles desde Paypal",
            html:
              '<p style="text-align:left;"><b>Paypal Id: </b>' +
              datos[0].response.id +
              '<p style="text-align:left;"><b>Status: </b>' +
              datos[0].response.status.toString() +
              "</p> " +
              '<p style="text-align:left;"><b>Created: </b>' +
              datos[0].response.create_time,
          });
          break;
        default:

        case "paypal":
          Swal.fire({
            title: "No hay una plataforma definida para este pedido",
          });
      }
    }
  };

  return (
    <>
      <div className="flex mt-6 ml-2">
        <img
          className="ml-2 w-12 pl-2 xs:w-10 xs:h-8 cursor-pointer self-center"
          src={cart}
          alt="The Revdev Store"
        />
        <p className="ml-3 text-white cursor-pointer xs:text-base md:text-2xl self-center">
          The Revdev Store®
        </p>
      </div>

      <div className="flex justify-between">

      <div className="">
        <div className=" m-2 p-2">
          <h1 className="font-bold ml-2">
            INFORMACIÓN DEL CLIENTE
          </h1>
          <div className="">
            <p className="text-gray-600 pl-2 font-semibold xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
              Nombre:{" "}
              <span className="text-gray-700 font-normal xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
                {pedido.nombre}
              </span>
            </p>
            <p className="text-gray-600 pl-2 font-semibold xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
              Calle:{" "}
              <span className="text-gray-700 font-normal xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
                {pedido.calle}
              </span>
            </p>
            <p className="text-gray-600 pl-2 font-semibold xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
              Colonia:{" "}
              <span className="text-gray-700 font-normal xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
                {pedido.colonia}
              </span>
            </p>
            <p className="text-gray-600 pl-2 font-semibold xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
              Ciudad:{" "}
              <span className="text-gray-700 font-normal xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
                {pedido.ciudad}
              </span>
            </p>
            <p className="text-gray-600 pl-2 font-semibold xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
              Estado:{" "}
              <span className="text-gray-700 font-normal xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
                {pedido.estado}
              </span>
            </p>
            <p className="text-gray-600 pl-2 font-semibold xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
              Codigo Postal:{" "}
              <span className="text-gray-700 font-normal xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
                {pedido.codigoPostal}
              </span>
            </p>
            <p className="text-gray-600 pl-2 font-semibold mb-2 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
              Telefono:{" "}
              <span className="text-gray-700 font-normal xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
                {pedido.telefono}
              </span>
            </p>
          </div>
        </div>

        
      </div>
      <div className="mt-4 mr-4">
        <h1 className="font-bold">
          PEDIDO ID: {pedido.id}
        </h1>
        <p className="text-sm">
          Fecha de pedido: {new Date(pedido.creado).toLocaleString()}
        </p>
      </div>
      </div>
      <div className="m-4 divide-y divide-gray-400 ">
        <h1 className="font-bold mb-2 mt-2 pl-2 xs:text-sm sm:text-sm md:text-base lg:text-lg xl:text-lg">
          PRODUCTOS
        </h1>
        {pedido.orden.map((productos, i) => (
          <div key={pedido.id + i} className="flex py-2 justify-between ">
            <div className="flex ml-2  w-2/3 ">
              <img
                className="rounded-lg h-10 w-10 filter grayscale"
                alt=""
                src={productos.imagen}
              />

              <div className="flex flex-col">
                {productos.sku ? (
                  <p className="text-xs ml-2 text-gray-500">
                    SKU: {productos.sku}
                  </p>
                ) : null}

                <p className="pl-2 mr-2 xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base text-gray-700">
                  {productos.cantidad} x{" "}
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(productos.precio)}{" "}
                  {productos.descuento
                    ? "(" + "-" + productos.descuento + "%" + ")"
                    : null}{" "}
                  {productos.nombre}
                </p>
              </div>
            </div>

            <p className=" mr-2 mt-2 justify-end xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base font-semibold">
              {" "}
              {new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(productos.total)}
            </p>
          </div>
        ))}
      </div>
      {pedido.envio == "gratis" ? (
        <div className="flex justify-end m-4">
          <div className="p-2 text-sm text-gray-300">
            Gastos de envío: Gratis
          </div>
        </div>
      ) : null}

      {pedido.envio == "costo" ? (
        <div className="flex justify-end m-4">
          <div className="p-2 text-sm text-gray-300">
            Gastos de envío: ${pedido.costo_envio.toFixed(2)}
          </div>
        </div>
      ) : null}

      {pedido.envio == "posicionamiento" ? (
        <div className="flex justify-end m-4">
          <div className="p-2 text-sm text-gray-300">
            Envío por geoposicionamiento
          </div>
        </div>
      ) : null}

      {pedido.envio == "noenvio" && Object.keys(pedido).length > 0 ? (
        <div className="flex justify-end m-4">
          <div className="p-2 text-sm text-gray-300">
            Entrega física en tienda
          </div>
        </div>
      ) : null}

      <div className="flex justify-end">
        <div></div>
        <p className="font-bold mt-2 mr-4 justify-end xs:text-base sm:text-base md:text-base lg:text-lg xl:text-lg border-2 border-gray-500 rounded-lg p-2 mb-4">
          Total: MXN{" "}
          {new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(pedido.total)}{" "}
        </p>
      </div>
      <div className="flex justify-center text-lg mt-4">
        Muchas gracias por tu compra
      </div>
    </>
  );
};

export default PedidoPrint;
