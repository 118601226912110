import React, { useState, useContext, useRef } from "react";
import { FirebaseContext } from "../../firebase";
import { useNavigate } from "react-router-dom";
import ProductosContext from "../../context/ProductosContext";
import Swal from "sweetalert2";
import axios from "axios";
import user from "../../images/user.png";
import useSound from "use-sound";
import boopSfx from "../../media/save.wav";
import ReactLoading from "react-loading";

const Usuario = ({ usuario }) => {

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  //context de firebase

  const { firebase } = useContext(FirebaseContext);

  const navigate = useNavigate();

  //alerta de sonido
  const [play] = useSound(boopSfx);

  const { usuarioState, setUsuarioState } = React.useContext(ProductosContext);

  const [loading1, setLoading1] = useState(false);

  //formatea el numero de telefono

  const telefono_format =
    "(" +
    usuario.telefono.substring(0, 3) +
    ") " +
    usuario.telefono.substring(3, 6) +
    "-" +
    usuario.telefono.substring(6, 11);

  const completadoRef = useRef(usuario.habilitado);

  //modificar el estado de disponibilidad en firebase
  const actualizarHabilitado = () => {
    setLoading1(true);

    const habilitado = completadoRef.current.value === "true";

    try {
      firebase.db.collection("usuarios").doc(usuario.id).update({
        habilitado,
      });
    } catch (error) {
      console.log(error);
    }

    //Se ejecuta la cloud function seteando el valor
    console.log(habilitado);
    console.log(usuario);

    if (!usuario.habilitado) {
      axios({
        method: "POST",
        url: "https://us-central1-mystore-d7058.cloudfunctions.net/setHabilitado",
        data: {
          uid: usuario.uidUsuario,
        },
        timeout: 5000,
      })
        .then(function () {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          play();

          Toast.fire({
            title: "<p style='color:#22c55e'>" + "Habilitado" + "</p>",
            html: '<p style="color:#fff">El usuario se Habilitó</p>',
            icon: "success",
            color: "#fff",
            background: "#18181b",
            position: "top-end",
            iconColor: "#22c55e",
            timer: 1500,
            showConfirmButton: false,
          });

          setLoading1(false);

          //redireccionar con useNavigate
        })
        .catch(function (error) {
          // An error happened.
        });
    } else {
      axios({
        method: "POST",
        url: "https://us-central1-mystore-d7058.cloudfunctions.net/setDeshabilitado",
        data: {
          uid: usuario.uidUsuario,
        },
        timeout: 5000,
      })
        .then(function () {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            title: "<p style='color:#22c55e'>" + "Deshabilitado" + "</p>",
            html: '<p style="color:#fff">El usuario se deshabilitó</p>',
            icon: "success",
            color: "#fff",
            background: "#18181b",
            position: "top-end",
            iconColor: "#22c55e",
            timer: 1500,
            showConfirmButton: false,
          });

          setLoading1(false);
        })
        .catch(function (error) {
          // An error happened.
        });
    }
  };

  const verDetalles = () => {
    setUsuarioState(usuario);

    navigate("/UsuarioDetail");
  };

  const verComentarios = () => {
    setUsuarioState(usuario);
    navigate("/comentarios");
  };

  const usuarioDerechos = () => {
    setUsuarioState(usuario);
    navigate("/usuarioderechos");
  };

  const verActividad = () => {
    setUsuarioState(usuario);
    navigate("/actividad");
  };

  return (
    <>
      <div className="w-full px-3 mb-4">
        <div className="p-2 shadow-md  bg-gray-700 rounded-xl xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base cursor-pointer hover:bg-gray-600">
          <div className="lg:flex">
            <div
              className="flex justify-center lg:w-2/12 xl:w-2/12 relative"
              onClick={usuarioDerechos}
            >
              {usuario.imagen ? (
                <img
                  className="rounded-full h-12 w-12 "
                  alt=""
                  src={usuario.imagen}
                />
              ) : (
                <img className="rounded-full h-12 w-12 " alt="" src={user} />
              )}
              {usuario.role === "owner" ? (
                <span className="top-0 left-px absolute bg-purple-600 text-xs rounded-md p-1 text-white ">
                  Owner
                </span>
              ) : null}
              {usuario.role === "admin" ? (
                <span className="top-0 left-px absolute bg-green-600 text-xs rounded-md p-1 text-white ">
                  Admin
                </span>
              ) : null}
            </div>

            <div
              className="flex  xs:justify-center sm:justify-center lg:justify-start lg:w-4/12 xl:w-4/12 pt-3"
              onClick={usuarioDerechos}
            >
              <p className="text-white font-bold text-md cursor-pointer">
                <span className="font-normal">
                  {usuario.nombre}
                </span>
              </p>
            </div>

            <div className="flex justify-around lg:w-9/12 xl:w-9/12">
              <div className="grid  xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 gap-4 px-4 xs:gap-2 sm:gap-1 md:gap-2 lg:gap-4 xl:gap-4">
                <button
                  onClick={verDetalles}
                  className="rounded-lg text-white bg-yellow-500 hover:bg-yellow-600 p-2 text-sm inline-flex items-center justify-center mt-2 lg:mb-0 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                  Pedidos
                </button>{" "}
                <button
                  onClick={verComentarios}
                  className="rounded-lg text-white bg-blue-500 hover:bg-blue-600 p-2 text-sm inline-flex items-center mt-2  lg:mb-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                    />
                  </svg>
                  Comentarios
                </button>{" "}
                <button
                  onClick={verActividad}
                  className="rounded-lg text-white  bg-purple-900 hover:bg-purple-700 p-2 text-sm inline-flex items-center justify-center mt-2  lg:mb-0 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                  Actividad
                </button>{" "}
                {loading1 ? (
                  <div className="flex justify-center self-center">
                    <ReactLoading color={"#22C55E"} type={"spin"} height={'30%'} width={'30%'}/>
                  </div>
                ) : (
                  <select
                   // className="bg-green-500 text-white text-sm shadow appearance-none border-green-500 rounded-lg py-2 px-2 focus:outline-none focus:shadow-outline mt-2 text-center"
                    className={classNames(
                      usuario.habilitado
                        ? "bg-green-500 text-white text-sm shadow appearance-none border-green-500 rounded-lg py-2 px-2 focus:outline-none focus:shadow-outline mt-2 text-center"
                        : "bg-red-500 text-white text-sm shadow appearance-none border-green-500 rounded-lg py-2 px-2 focus:outline-none focus:shadow-outline mt-2 text-center",
                      
                    )}
                    value={usuario.habilitado}
                    ref={completadoRef}
                    onChange={() => actualizarHabilitado()}
                  >
                    <option
                      className="bg-gray-100 text-gray-600 text-center"
                      value="true"
                    >
                      Habilitado
                    </option>
                    <option
                      className="bg-gray-100 text-gray-600 text-center"
                      value="false"
                    >
                      Deshabilitado
                    </option>
                  </select>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Usuario;
