import React, { useState, useContext, useEffect } from "react";
import { FirebaseContext } from "../../firebase";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import FrontHeader from "./FrontHeader";
import { useAuth } from "../../context/AuthContext";
import Cart from "./Cart";
import Drawer from "react-modern-drawer";
import ok from "../../images/ok.png";

const UserAccount = () => {
  //Drawer carro de compras y su state
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  //state de la busqueda
  const [searchterm, setSearchTerm] = useState("");

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const [nombre, establecerNombre] = useState("");
  const [calle, establecerCalle] = useState("");
  const [colonia, establecerColonia] = useState("");
  const [ciudad, establecerCiudad] = useState("");
  const [estado, establecerEstado] = useState("");
  const [codigoPostal, establecerCodigoPostal] = useState("");
  const [telefono, establecerTelefono] = useState("");

  const [editando, establecerEditando] = useState(false);
  const [loading, setLoading] = useState(false);

  const [estadoAlerta, cambiarEstadoAlerta] = useState(false);
  const [alerta, cambiarAlerta] = useState({});

  //state de los datos de usuario

  const [datos, guardarDatos] = useState([]);

  //extraer el usuario de auth
  const { usuario } = useAuth();

  //hook para redireccionar
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    const obtenerDatos = () => {
      firebase.db
        .collection("usuarios")
        .where("uidUsuario", "==", usuario.uid)
        .onSnapshot(manejarSnapshot);
    };

    //se ejecuta la funcion para obtener datos del producto

    obtenerDatos();

    function manejarSnapshot(snapshot) {
      let datos = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      guardarDatos(datos);

      if (Object.keys(datos).length === 0) {
        establecerEditando(false);
      } else establecerEditando(true);

      if (editando === true) {
        establecerNombre(datos[0].nombre);
        establecerCalle(datos[0].calle);
        establecerColonia(datos[0].colonia);
        establecerCiudad(datos[0].ciudad);
        establecerEstado(datos[0].estado);
        establecerCodigoPostal(datos[0].codigoPostal);
        establecerTelefono(datos[0].telefono);
      }

      setLoading(false);
    }
  }, [editando]);

  const handleChange = (e) => {
    switch (e.target.name) {
      case "nombre":
        establecerNombre(e.target.value);
        break;
      case "calle":
        establecerCalle(e.target.value);
        break;
      case "colonia":
        establecerColonia(e.target.value);
        break;
      case "ciudad":
        establecerCiudad(e.target.value);
        break;
      case "estado":
        establecerEstado(e.target.value);
        break;
      case "codigoPostal":
        establecerCodigoPostal(e.target.value);
        break;
      case "telefono":
        establecerTelefono(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    //validar el formulario
    if (
      nombre === "" ||
      calle === "" ||
      colonia === "" ||
      ciudad === "" ||
      estado === "" ||
      telefono === ""
    ) {
      Toast.fire({
        icon: "warning",
        title: "Todos los campos son necesarios",
      });

      return;
    }

    if (telefono.length < 10) {
      Toast.fire({
        icon: "warning",
        title: "El número telefonico debe ser igual a 10 caracteres",
      });

      return;
    }
    if (telefono.length > 10) {
      Toast.fire({
        icon: "warning",
        title: "El número telefonico debe ser igual a 10 caracteres",
      });

      return;
    }

    const userObj = {
      uidUsuario: usuario.uid,
      nombre: nombre,
      calle: calle,
      colonia: colonia,
      ciudad: ciudad,
      estado: estado,
      codigoPostal: codigoPostal,
      telefono: telefono,
    };

    if (editando == false) {
      firebase.db.collection("usuarios").add(userObj);
    } else {
      firebase.db.collection("usuarios").doc(datos[0].id).update(userObj);
    }

    Toast.fire({
      icon: "success",
      title: "Se guardó la información del usuario",
    });
  };

  return (
    <>
      <div>
        <FrontHeader
          toggleDrawer={toggleDrawer}
          searchterm={searchterm}
          setSearchTerm={setSearchTerm}
        />
        <Drawer
          size={350}
          open={isOpen}
          onClose={toggleDrawer}
          direction="right"
        >
          <Cart />
        </Drawer>

        <div className="p-6 m-5">
          <div class="mx-auto bg-gray-100 rounded-xl shadow-md overflow-hidden max-w-screen-lg ">
            <label className="block text-gray-700 text-center text-3xl font-light p-10">
              Tu suscripción ha sido realizada con éxito
            </label>

            <div className="flex justify-center pb-10">
              <img
                class="flex rounded-full  w-40 justify-center border border-white"
                src={ok}
                alt=""
              />
            </div>
            <div className="flex justify-center items-center">
              <p className="text-gray-500 pl-5 pr-5">
                Se ha enviado un correo con los detalles de tu suscripción.
                Puedes ver tus suscripciones en tu cuenta de administración.
              </p>
            </div>
            <div className="flex justify-center p-5">
              <p className="text-gray-500  text-xl">
                ¡Gracias por confiar en nosotros!
              </p>
            </div>
            <div className="flex justify-center pb-5">
              <button
                onClick={() => navigate("/subscription")}
                className=" ml-15 rounded-xl text-white bg-green-600 hover:bg-green-700 p-3 text-sm mt-3 mb-5 inline-flex items-center"
              >
                REGRESAR AL MANEJO DE SUSCRIPCIONES
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAccount;
