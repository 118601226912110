import React, { useState, useContext } from "react";
import { FirebaseContext } from "../../firebase";
import { useNavigate, Link } from "react-router-dom";
import Alerta from "../ui/Alerta";
import { useAuth } from "../../context/AuthContext";

const Registro = () => {
  const [nombre, establecerNombre] = useState("");
  const [correo, establecerCorreo] = useState("");
  const [password, establecerPassword] = useState("");
  const [password2, establecerPassword2] = useState("");
  const [estadoAlerta, cambiarEstadoAlerta] = useState(false);
  const [alerta, cambiarAlerta] = useState({});

  const navigate = useNavigate();

      //extraer el usuario de auth
      const { usuario } = useAuth();

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const handleChange = (e) => {
    switch (e.target.name) {
      case "nombre":
        establecerNombre(e.target.value);
        break;
      case "email":
        establecerCorreo(e.target.value);
        break;
      case "password":
        establecerPassword(e.target.value);
        break;
      case "password2":
        establecerPassword2(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    cambiarEstadoAlerta(false);
    cambiarAlerta({});

    //validar formulario

    if (nombre === "" || correo === "" || password === "" || password2 === "") {
      cambiarEstadoAlerta(true);
      cambiarAlerta({
        titulo: "Todos los campos son obligatorios",
      });
      return;
    }

    if (password !== password2) {
      cambiarEstadoAlerta(true);
      cambiarAlerta({
        titulo: "Las contraseñas no coinciden",
      });
      return;
    }

    if (password.length < 6) {
      cambiarEstadoAlerta(true);
      cambiarAlerta({
        titulo: "El password debe ser mayor a 6 caracteres",
      });
      return;
    }

    try {
      //insertar el registro de usuario en Firebase
      await firebase.auth.createUserWithEmailAndPassword(correo, password).then((userCredential) => {
        // Signed in 
        const usuario = userCredential.user;
        // ...

/*      console.log("CREDENCIAL ES", userCredential)
        console.log("EL USUARIO ES", usuario) */

        const userObj = {
          uidUsuario: usuario.uid,
          imagen: usuario.photoURL,
          correo: usuario.email,
          role:"cliente",
          habilitado:true,
          nombre:nombre,
          telefono:"",
        };

        firebase.db.collection("usuarios").add(userObj).then(() => {

          const activityObj = {
            uidUsuario: usuario.uid,
            activity: "El usuario se registró con éxito",
            fecha: Date.now(),
          };
      
          firebase.db.collection("usuarios_log").add(activityObj);

        }

          
        )


        firebase.auth.currentUser.sendEmailVerification();

        navigate("/");

      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });

    } catch (error) {
      cambiarEstadoAlerta(true);
      let mensaje;

      //mensajes de error en Firebase
      switch (error.code) {
        case "auth/email-already-in-use":
          mensaje = "Ya existe el correo en el sistema";
          break;
        case "auth/invalid-email":
          mensaje = "Correo electrónico no valido";
          break;
        default:
          mensaje = "Hubo un error al crear la cuenta";
          break;
      }
      cambiarAlerta({ titulo: mensaje });
    }
  };

  return (
    <>
      <div>
        <div className="relative min-h-screen flex flex-col justify-center items-center bg-gray-900 p-10">
          <div className="relative sm:max-w-sm w-full">
            <div className="card bg-yellow-500 shadow-lg  w-full h-full rounded-3xl absolute  transform -rotate-6"></div>
            <div className="card bg-green-400 shadow-lg  w-full h-full rounded-3xl absolute  transform rotate-6"></div>
            <div className="relative w-full rounded-3xl  px-6 py-4 bg-gray-100 shadow-md">
              <label
                htmlFor=""
                className="block text-black text-center text-3xl font-light mt-4"
              >
                Regístrate
              </label>
              <form onSubmit={handleSubmit} className="mt-5">
                <Alerta
                  titulo={alerta.titulo}
                  mensaje={alerta.mensaje}
                  estadoAlerta={estadoAlerta}
                  cambiarEstadoAlerta={cambiarEstadoAlerta}
                />

                <div>
                  <input
                    value={nombre}
                    name="nombre"
                    type="text"
                    placeholder="Nombre"
                    onChange={handleChange}
                    className="w-full mt-1 block  border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-5 ring-1 focus:outline-none xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base"
                  />
                </div>

                <div className="mt-5">
                  <input
                    value={correo}
                    name="email"
                    type="email"
                    placeholder="Correo"
                    onChange={handleChange}
                    className="w-full mt-1 block  border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-5 ring-1 focus:outline-none xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base"
                  />
                </div>

                <div className="mt-5">
                  <input
                    value={password}
                    name="password"
                    type="password"
                    placeholder="Contraseña"
                    onChange={handleChange}
                    className="w-full mt-1 block  border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-5 ring-1 focus:outline-none xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base"
                  />
                </div>

                <div className="mt-5">
                  <input
                    value={password2}
                    name="password2"
                    type="password"
                    placeholder="Confirmar contraseña"
                    onChange={handleChange}
                    className="w-full mt-1 block  border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-5 ring-1 focus:outline-none xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base"
                  />
                </div>

                <div className="mt-7">
                  <button
                    type="submit"
                    className="bg-blue-500 w-full py-3 rounded-xl text-white shadow-xl focus:outline-none transition duration-200 ease-in-out  transform hover:-translate-x hover:scale-105"
                  >
                    Registrar
                  </button>
                </div>

                {/*                                 <div className="flex mt-7 items-center text-center">
                                    <label className="block font-medium text-sm text-gray-700 w-full">
                                        Registrate con
                                    </label>
                                </div>
                    
                                <div className="flex mt-1 justify-center w-full">
                                            <Link to="/login" className="rounded-lg text-white bg-blue-700 hover:bg-blue-800 p-3 mr-5 text-sm mt-3 mb-5 inline-flex items-center">
                                            <svg className="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" width="" height="" fill="none" stroke="currentColor" viewBox="0 -1 26 26"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg> 

                                                   Facebook   
                                            </Link>
                            
                                            <Link to="/login" className="rounded-lg text-white bg-red-600 hover:bg-red-700 p-3 text-sm mt-3 mb-5 inline-flex items-center">
                                                <svg className="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" width="" height="" fill="none" stroke="currentColor" viewBox="0 0 48 48"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/></svg>
                                                   Google   
                                            </Link>
                                        </div> */}

                <div className=" flex justify-center mt-4 mb-4">
                  <div className="">
                    <label className="text-sm text-gray-800 mb-3 ">
                      ¿Ya tienes una cuenta?
                    </label>
                    <Link
                      to="/login"
                      className=" text-blue-500 transition duration-500 ease-in-out transform hover:-translate-x hover:scale-105 text-sm ml-4"
                    >
                      Entrar a tu cuenta
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registro;
