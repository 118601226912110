import React, { useState } from "react";
import ReactLoading from "react-loading";
import FrontHeader from "./FrontHeader";
import FrontFooter from "./FrontFooter";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Cart from "./Cart";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";

const About = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [loading, setLoading] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className=" min-h-screen">
      {loading === true ? (
        <div className="self-center justify-center flex ">
          <ReactLoading color={"#3B82F6"} type={"bars"} />
        </div>
      ) : (
        <>
          <FrontHeader toggleDrawer={toggleDrawer} />

          <div className="">
            <div className="mx-auto max-w-screen-2xl">
              <Drawer
                size={350}
                open={isOpen}
                onClose={toggleDrawer}
                direction="right"
              >
                <Cart isOpen={isOpen} setIsOpen={setIsOpen} />
              </Drawer>
            </div>
          </div>
        </>
      )}

      <div className="">
        <AwesomeSlider>
          <div
            className="z-0"
            data-src="https://firebasestorage.googleapis.com/v0/b/mystore-d7058.appspot.com/o/productos%2Fhuarache.webp?alt=media&token=2a23f56a-8e5a-4939-b90b-bb91487d48f8"
          >
            <p className="z-50 text-white bg-gray-800  pt-6 pl-6 pr-6 rounded-md opacity-75 xs:text-2xl sm:text-4xl md:text-5xl lg:text-7xl">
              La mejor experiencia de tu vida
            </p>
            <p className="z-50 text-white bg-gray-800  pb-6 pl-6 pr-6 rounded-md opacity-75 xs:text-2xl sm:text-4xl md:text-5xl lg:text-7xl">
              está por comenzar
            </p>
          </div>
          <div
            className="z-0"
            data-src="https://firebasestorage.googleapis.com/v0/b/mystore-d7058.appspot.com/o/productos%2Ftacos_big.webp?alt=media&token=b8602d13-c658-444f-87d1-ede0dde86f43"
          >
            <p className="z-50 text-white bg-gray-800 p-6 rounded-md opacity-75 xs:text-2xl sm:text-4xl md:text-5xl lg:text-7xl">
              La respuesta es, no lo pienses más
            </p>
          </div>
          <div
            className="z-0"
            data-src="https://firebasestorage.googleapis.com/v0/b/mystore-d7058.appspot.com/o/productos%2Fposole_big.webp?alt=media&token=bdd4beb4-8fed-4fe0-a46f-08bdd6544845"
          >
            <p className="z-50 text-white bg-gray-800 p-6 rounded-md opacity-75 xs:text-2xl sm:text-4xl md:text-5xl lg:text-7xl">
              Más que una ciencia, un arte
            </p>
          </div>
          <div
            className="z-0"
            data-src="https://firebasestorage.googleapis.com/v0/b/mystore-d7058.appspot.com/o/productos%2Fchiles_big.webp?alt=media&token=9f02650b-2052-481b-9ad4-fc6dd1003f43"
          >
            <p className="z-50 text-white bg-gray-800 p-6 rounded-md opacity-75 xs:text-2xl sm:text-4xl md:text-5xl lg:text-7xl">
              Amor, conexión, compartir
            </p>
          </div>
          <div
            className="z-0"
            data-src="https://firebasestorage.googleapis.com/v0/b/mystore-d7058.appspot.com/o/productos%2Fescamoles_big.webp?alt=media&token=7d5037f6-6336-4bce-b4a6-99ce8c029d4c"
          >
            <p className="z-50 text-white bg-gray-800 p-6 rounded-md opacity-75 xs:text-2xl sm:text-4xl md:text-5xl lg:text-7xl">
              Experimenta lo mejor en compañia
            </p>
          </div>
        </AwesomeSlider>
        <div className="grid grid-cols-2 xs:grid-cols-1 xs:m-2 xs:text-xs sm:grid-cols-1 sm:m-2 sm:text-sm md:grid-cols-2 md:text-base md:m-12 ">
          <div className="m-12">
            <h2 className=" text-4xl p-4">Titulo 1</h2>
            <p className="p-4">
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum."
            </p>
          </div>
          <div className="m-12">
            <h2 className=" text-4xl p-4">Titulo 2</h2>
            <p className="p-4">
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum."
            </p>
          </div>
        </div>
      </div>

      <FrontFooter />
    </div>
  );
};

export default About;
