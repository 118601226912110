import React, { useContext, useState, useEffect } from "react";
import notfound from "../../images/not-found.png";
import { FirebaseContext } from "../../firebase";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import Swal from "sweetalert2";
import Modal from "react-modal";
import ImageGalleryComponent from "./ImageGalleryComponent";

const Image = ({ image, images, setImages, isArray, setIsArray, setChanges }) => {
  const { urlimagen, title, altText } = image;

  const [subiendo, guardarSubiendo] = useState(false);
  const [progreso, guardarProgreso] = useState(0);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const [showEdit, setShowEdit] = useState(false);

  //MODAL WINDOW
  //Estados de la ventana modal
  const [altTextToShow, setAltTextToShow] = useState("");
  const [titleToShow, setTitleToShow] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalGalleryIsOpen, setIsOpenModalGallery] = React.useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      padding: "20px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgb(17, 24, 39)",
    },
    overlay: {
      backgroundColor: "rgba(17, 24, 39, 0.5)",
    },
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleChange = (e) => {
    setChanges(true)
    switch (e.target.name) {
      case "altTextToShow":
        setAltTextToShow(e.target.value);
        break;
      case "titleToShow":
        setTitleToShow(e.target.value);
        break;
      default:
        break;
    }
    console.log(titleToShow);
    console.log(altTextToShow);
  };

  //Galería de imágenes
  const handleGallery = () => {
    setIsOpenModalGallery(true);
  };

  const handleModal = () => {
    setIsArray(true);
  };

  //TODO SOBRE LAS IMAGENES
  const handleUploadStart = () => {
    guardarProgreso(0);
    guardarSubiendo(true);
  };

  const handleUploadError = (error) => {
    guardarSubiendo(false);
    console.log(error);
  };

  const handleUploadSuccess = async (nombre) => {
    setChanges(true)
    guardarProgreso(100);
    guardarSubiendo(false);

    //almacenar URL de destino
    const url = await firebase.storage
      .ref("productos")
      .child(nombre)
      .getDownloadURL();

    //BUSCA Y REEMPLAZA LA IMAGEN

    const imageObj = { urlimagen: url, title: "", altText: "" };

    /*    const imagesClone = [...images]

    imagesClone.find((imagen, index)=>{

        if(imagen.urlimagen === urlimagen)
        {
            console.log("found it", imagen, index )    
            images[index] =  imageObj;
        }

    })

   setImages(imagesClone)     */

    setImages([...images, imageObj]);

    // guardarUrlImagen(url)

    console.log(images);

    Toast.fire({
      icon: "success",
      title: "La imagen se subió con exito",
    });
  };

  const handleProgress = (progreso) => {
    guardarProgreso(progreso);
    console.log(progreso);
  };

  const handleEliminarImagen = () => {
    setChanges(true)

    const imagesClone = [...images];

    imagesClone.find((imagen, index) => {
      if (imagen !== undefined) {
        if (imagen.urlimagen === urlimagen) {
          console.log("found it", imagen, index);
          imagesClone.splice(index, 1); // El segundo parametro dice que solo remuevo un item del arreglo
        }
      }
    });

    setImages(imagesClone);
  };

  function closeModal() {
    setIsOpen(false);
    setIsOpenModalGallery(false);
  }

  return (
    <div>
      <div>
        <Modal
          isOpen={modalGalleryIsOpen}
          onAfterOpen={handleModal}
          onRequestClose={closeModal}
          style={customStyles}
        >
          <ImageGalleryComponent
            urlimagen={urlimagen}
            title={title}
            altText={altText}
            images={images}
            setImages={setImages}
            setIsOpenModalGallery={setIsOpenModalGallery}
          />
        </Modal>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={handleModal}
          onRequestClose={closeModal}
          style={customStyles}
        >
          <div className="flex justify-center">
            <img
              className="rounded-md w-2/3 max-w-2xl"
              src={urlimagen}
              alt="altText"
            />
          </div>
          <div className="">
            <div className="mb-2 mt-2">
              <label
                className="mt-4 font-semibold text-sm text-gray-300"
                htmlFor="nombre"
              >
                Texto Alternativo
              </label>
              <input
                className="border border-gray-400 p-3 rounded w-full leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 mt-2 transition-shadow xs:text-xs sm:text-sm md:text-base"
                name="altTextToShow"
                type="text"
                placeholder="Texto alternativo"
                onChange={handleChange}
                value={altTextToShow}
              ></input>
            </div>
            <div className="">
              <label
                className="mt-2 font-semibold text-sm text-gray-300"
                htmlFor="nombre"
              >
                Título de la imagen
              </label>
              <input
                className="border border-gray-400 p-3 rounded w-full leading-4 placeholder-gray-400 text-gray-700 focus:ring-purple-600 focus:ring-2 mt-2 transition-shadow xs:text-xs sm:text-sm md:text-base"
                name="titleToShow"
                type="text"
                placeholder="Título de la imagen"
                onChange={handleChange}
                value={titleToShow}
              ></input>
            </div>
          </div>
          <div className="mt-4 flex justify-center">
            <button
              className="bg-purple-900 hover:bg-purple-700 w-1/3 p-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105"
              onClick={() => {
                const imagesClone = [...images];

                imagesClone.find((imagen, index) => {
                  if (imagen !== undefined) {
                    if (imagen.urlimagen === urlimagen) {
                      console.log("found it", imagen, index);
                      imagesClone[index] = {
                        urlimagen: urlimagen,
                        title: titleToShow,
                        altText: altTextToShow,
                      };
                    }
                  }
                });

                setImages(imagesClone);

                setIsOpen(false);
              }}
            >
              Guardar
            </button>
          </div>
        </Modal>

        <div className="flex justify-center mb-2">
          <img
            className="rounded-lg"
            src={urlimagen}
            alt="Imagen"
            width="90%"
            height="90%"
            onMouseOver={() => {
              setShowEdit(true);
            }}
            onMouseLeave={() => {
              setShowEdit(false);
            }}
            onClick={() => {
              setTitleToShow(title);
              setAltTextToShow(altText);
              setIsOpen(true);
            }}
          />
          {showEdit ? (
            <div
              className="justify-center absolute self-center bg-black opacity-60 p-2 text-white rounded-md"
              onClick={() => {
                setTitleToShow(title);
                setAltTextToShow(altText);
                setIsOpen(true);
              }}
            >
              <svg
                clip-rule="evenodd"
                fill-rule="evenodd"
                fill="white"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                stroke="white"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m11.25 6c.398 0 .75.352.75.75 0 .414-.336.75-.75.75-1.505 0-7.75 0-7.75 0v12h17v-8.749c0-.414.336-.75.75-.75s.75.336.75.75v9.249c0 .621-.522 1-1 1h-18c-.48 0-1-.379-1-1v-13c0-.481.38-1 1-1zm1.521 9.689 9.012-9.012c.133-.133.217-.329.217-.532 0-.179-.065-.363-.218-.515l-2.423-2.415c-.143-.143-.333-.215-.522-.215s-.378.072-.523.215l-9.027 8.996c-.442 1.371-1.158 3.586-1.264 3.952-.126.433.198.834.572.834.41 0 .696-.099 4.176-1.308zm-2.258-2.392 1.17 1.171c-.704.232-1.274.418-1.729.566zm.968-1.154 7.356-7.331 1.347 1.342-7.346 7.347z"
                  fill-rule="nonzero"
                />
              </svg>
              Editar
            </div>
          ) : null}
        </div>

        {subiendo && (
          <div class="m-2 w-full pr-4">
            <div
              class="h-2 bg-green-500"
              style={{ width: `${progreso}%` }}
            ></div>
          </div>
        )}

        <div className="flex justify-center">
          <CustomUploadButton
            accept="image/*"
            randomizeFilename
            storageRef={firebase.storage.ref("productos")}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={handleUploadSuccess}
            onProgress={handleProgress}
            /*             style={{
              backgroundColor: "#581c87",
              color: "white",
              padding: 5,
              borderRadius: 6,
            }} */
            className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 inline-flex items-center p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>{" "}
          </CustomUploadButton>

          <button
            onClick={handleGallery}
            type="button"
            className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 inline-flex items-center p-2 mr-2 ml-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="white"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth=".1"
            >
              <path d="M9 12c0-.552.448-1 1.001-1s.999.448.999 1-.446 1-.999 1-1.001-.448-1.001-1zm6.2 0l-1.7 2.6-1.3-1.6-3.2 4h10l-3.8-5zm5.8-7v-2h-21v15h2v-13h19zm3 2v14h-20v-14h20zm-2 2h-16v10h16v-10z" />{" "}
            </svg>
          </button>

          {urlimagen === "" || urlimagen === undefined ? null : (
            <button
              onClick={handleEliminarImagen}
              type="button"
              className="rounded-lg text-white bg-red-500 hover:bg-red-600 text-sm inline-flex items-center p-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Image;
