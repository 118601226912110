import app from 'firebase/app'
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

import firebaseConfig from './config';

class Firebase {
    constructor(){
        if(!app.apps.lenght)
        {const secondary = app.initializeApp(firebaseConfig, 'secondary');      
        this.db = secondary.firestore();
        this.storage = secondary.storage();
        this.auth = secondary.auth();
        this.authGoogle = secondary.auth;}   
    }

}

const firebaseRevdev = new Firebase();

export default firebaseRevdev;
