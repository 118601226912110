import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../../firebase";
import FrontProducto from "../paginas/FrontProducto";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import _ from "lodash";


const MyPaginate = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  li a {
    border-radius: 7px;
    padding: 0.3rem .8rem;
    border-color: transparent;
    cursor: pointer;
    color: white;
    background-color: #eab308;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
    color: #1f2937;
    background-color: transparent;
  }
  li.active a {
    background-color: #3b82f6;
    border-color: transparent;
    color: white;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  } 
`;


const FrontPageSinCategoriaPagination = () => {
  
  const { firebase } = useContext(FirebaseContext);
  
  const PAGE_SIZE = 4;

  const [loading, setLoading] = useState(false);

  const [productos, guardarProductos] = useState([]);

  const navigate = useNavigate();

  //state que cambia el numero de pagina en react-pagination
  const [currentPage, setCurrentPage] = useState(0);
  
  //state que forza a cambiar el numero de pagina en react-pagination
  const [seleccionada, setSeleccionada] = useState(0);

  //estado que guarda solo las imagenes que se mostrarán
  const [displayedProducts, setDisplayedProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    const obtenerProductos = () => {
      const dbRef = firebase.db
     
        dbRef.collection("productos")
        .where("categoria", "==", "") 
        .where("subcategoria", "==", "")
        .where("subsubcategoria", "==", "")
        .limit(100)
        .onSnapshot(manejarSnapshot);

        return () => dbRef();

    };
    obtenerProductos();
  }, []);

  //snapshot que nos permite utilizar la bd en tiempo real de firestore
  function manejarSnapshot(snapshot) {
    let productos = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    productos = _.orderBy(productos, "nombre", "asc");

    //almacena resultados en el state
    guardarProductos(productos);

    setLoading(false);



  }

  useEffect(() => {
/*     console.log("PAGINA", currentPage);
    console.log("DESDE", currentPage * PAGE_SIZE);
    console.log("HASTA", currentPage * PAGE_SIZE + PAGE_SIZE);
 */
    const corte = productos.slice(
      currentPage * PAGE_SIZE,
      currentPage * PAGE_SIZE + PAGE_SIZE
    );
    setDisplayedProducts(corte);
  }, [currentPage, productos]);

  const handlePageChange = ({ selected }) => {
    setLoading(true);
    setCurrentPage(selected);
    //este state lo puse por que cuando daba click en la seleccion se volvia de vuelta a la pagina uno y así tuve que forzar su estado
    setSeleccionada(selected);
    setLoading(false);
  };


   //state del filtro Orden
  const [orden, guardarOrden] = useState({ filtro1: "defecto" });

  //extraer del state filtro
  const { filtro1 } = orden;
 
  const seleccionOrden = (e) => {
    guardarOrden({ ...orden, [e.target.name]: e.target.value });
    const productosClone = _.orderBy(productos, "nombre", "asc");
      guardarProductos(productosClone)

    if (e.target.value === "defecto") {
    }

    if (e.target.value === "menoramayor") {
      const productosClone = _.orderBy(productos, "precio", "asc");
      guardarProductos(productosClone)
    }

    if (e.target.value === "mayoramenor") {
      const productosClone = _.orderBy(productos, "precio", "desc");
      guardarProductos(productosClone)
    }

    if (e.target.value === "descuento") {
      const productosClone = _.orderBy(productos, "descuento", "desc");
      guardarProductos(productosClone)
    }

  }
  
    return (

    <div>
      {loading ? (
        <div className="flex justify-center items-center p-12">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div>
          {productos.length !== 0 ? (
            <div>

              <div className="flex justify-between flex-wrap mb-4">
              <p
                className="font-bold cursor-pointer hover:underline hover:text-yellow-500 self-center"
              >
                Sin categoría
              </p>

              <select
                  className="bg-purple-900 hover:bg-purple-700 text-white appearance-none border-none rounded-lg xs:text-xs sm:text-sm focus:ring-4 focus:ring-purple-300 shadow-md text-center xs:mt-2 p-2 justify-end"
                  onChange={seleccionOrden}
                  name="filtro1"
                  value={filtro1}                >
                  <option className="text-white" value="defecto">
                    Ordenar por nombre
                  </option>
                  <option className="text-white" value="menoramayor">
                    Ordenar de menor a mayor precio
                  </option>
                  <option className="text-white" value="mayoramenor">
                     Ordenar de mayor a menor precio
                  </option>
                  <option className="text-white" value="descuento">
                     Ordenar de mayor a menor descuento
                  </option>
                  </select>
                  </div>

              <div className="grid grid-flow-row-dense xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-between mt-2 shadow-lgs">
                {displayedProducts.map((producto, id) => (
                  <FrontProducto key={id} producto={producto} />
                ))}
              </div>
              <div className="flex justify-center mt-6 text-base">
                 <MyPaginate
                  pageCount={Math.ceil(productos.length / PAGE_SIZE)}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageChange}
                  className="pagination"
                  previousLabel="<"
                  nextLabel=">"
                  containerClassName={'flex flex-row items-center justify-center space-x-2'}
                  pageClassName={'rounded-full w-8 h-8 flex items-center justify-center text-gray-500 hover:text-gray-900 hover:bg-gray-200 transition duration-150 ease-in-out ml-2 mr-2'}
                  activeClassName={'bg-gray-500 text-white'}
                  previousClassName={'rounded-full w-8 h-8 flex items-center justify-center text-gray-500 hover:text-gray-900 hover:bg-gray-200 transition duration-150 ease-in-out mr-2'}
                  nextClassName={'rounded-full w-8 h-8 flex items-center justify-center text-gray-500 hover:text-gray-900 hover:bg-gray-200 transition duration-150 ease-in-out ml-2 '}
                  disabledClassName={'opacity-50 cursor-not-allowed'}
                  forcePage={seleccionada}
                /> 
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default FrontPageSinCategoriaPagination;
