import React, { useContext, Component, useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { FirebaseContext } from "../../firebase";
import ProductosContext from "../../context/ProductosContext";
import { useNavigate, NavLink } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import _ from "lodash";
import styled from "styled-components";
import ReactPaginate from "react-paginate";

const MyPaginateSmall = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  li a {
    border-radius: 4px;
    cursor: pointer;
    color: white;
    padding: 0.3rem 0.6rem;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
    color: white;
  }
  li.active a {
    border-color: transparent;
    color: white;
    background-color: #3b82f6;
  }
  li.disabled a {
    color: grey;
    cursor: default;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const PAGE_SIZE = 5;

const client = algoliasearch(process.env.REACT_APP_APPLICATION_ID, process.env.REACT_APP_SEARCH_API_KEY);
const index = client.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_PEDIDOS);

function SearchAlgoliaPedidos({
  setDisplayedPedidos,
  displayedPedidos,
  pedidos,
  guardarPedidos,
}) {
  const navigate = useNavigate();

  const { productoState, setProductoState } =
    React.useContext(ProductosContext);

  const [loading, setLoading] = useState(true);

  //state que cambia el numero de pagina en react-pagination
  const [currentPage, setCurrentPage] = useState(0);

  //state que forza a cambiar el numero de pagina en react-pagination
  const [seleccionada, setSeleccionada] = useState(0);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const [results, setResults] = useState(null);

  const [displayedResults, setDisplayedResults] = useState([]);

  const performSearch = async (value) => {
    const { hits } = await index.search(value, {
      hitsPerPage: 100,
    });

    const results = hits.map((hit) => {
      const {
        objectID: key,
        _highlightResult,
        total: total,
        ciudad: ciudad,
        estado: estado,
        completado: completado,
        creado: creado,
        correo: correo,
      } = hit;
      const {
        nombre: { value: nombre },
      } = _highlightResult;
      const {
        calle: { value: calle },
      } = _highlightResult;
      return {
        key,
        nombre,
        calle,
        ciudad,
        estado,
        completado,
        creado,
        total,
        correo,
      };
    });

    //ordenar por creado
    //const resultados = _.orderBy(results, "creado", "asc");

    setResults(results);
  };

  useEffect(() => {
    /*  console.log("PAGINA", currentPage);
        console.log("DESDE", currentPage * PAGE_SIZE);
        console.log("HASTA", currentPage * PAGE_SIZE + PAGE_SIZE);
     */

    if (results !== null) {
      //paginación
      const corte = results.slice(
        currentPage * PAGE_SIZE,
        currentPage * PAGE_SIZE + PAGE_SIZE
      );
      setDisplayedResults(corte);
    }
  }, [currentPage, results]);

  const handleChange = (e) => {
    const { value } = e.target;

    value === "" ? setResults(null) : performSearch(value);
  };

  const handlePageChange = ({ selected }) => {
    setLoading(true);
    setCurrentPage(selected);
    //este state lo puse por que cuando daba click en la seleccion se volvia de vuelta a la pagina uno y así tuve que forzar su estado
    setSeleccionada(selected);
    window.scrollTo(0, 0);
    setLoading(false);
  };

  return (
    <div className="w-full self-center">
      <div className=" md:flex text-sm lg:justify-end xs:justify-start">
        <div className="relative">
          <input
            className="p-2 pl-8 rounded ring-2 ring-yellow-600 border-2 focus:bg-white focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:border-transparent w-full shadow-xl"
            type="search"
            onChange={handleChange}
          />
          <svg
            className="w-5 h-5 absolute left-2.5 top-3"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#1f2937"
            width="24px"
            height="24px"
            fill="#1f2937"
            x="0px"
            y="0px"
            viewBox="0 -1 64 64"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23 s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92 c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17 s-17-7.626-17-17S14.61,6,23.984,6z"
            />
          </svg>
        </div>
      </div>

      {results === null ? null : (
        <div
          static
          className="origin-top-right absolute right-4 mt-2 rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none bg-gray-800 overflow-y-auto xs:w-5/6 md:w-1/2 lg:w-1/2 xl:w-1/3 max-h-screen p-4 border-green-500 border-2 z-50  xs:h-1/2 lg:h-5/6"
        >
          <p className="flex justify-center m-2 mb-4 text-xl text-white ">
            Resultados ({results.length})
          </p>
          <ul className="">
            {displayedResults.map((result) => {
              const {
                key,
                nombre,
                calle,
                ciudad,
                estado,
                completado,
                creado,
                total,
                correo,
              } = result;

              return (
                <div
                  className=" bg-gray-700 rounded-xl shadow-md hover:shadow-lg overflow-hidden  lg:max-w-screen-xl cursor-pointer mb-4 hover:bg-gray-600 p-4 "
                  onClick={() => {
                    const obtenerDatos = () => {
                      var docRef = firebase.db.collection("ordenes").doc(key);

                      docRef
                        .get()
                        .then((doc) => {
                          if (doc.exists) {
                            const objeto = { id: doc.id, ...doc.data() };
                            const arreglo = [objeto];
                            setDisplayedPedidos(arreglo);

                            setResults(null);
                          } else {
                            console.log("No se encontro este producto!");
                          }
                        })
                        .catch((error) => {
                          console.log("Error getting document:", error);
                        });
                    };

                    obtenerDatos();
                  }}
                >
                  <div className="">
                    <li className="text-white" key={key}>
                      {/*                          <p
                            className="text-sm"
                            dangerouslySetInnerHTML={{ __html: key }}
                          /> */}
                      <p className="text-sm">ID: {key}</p>
                      <p className="text-xs ">
                        Fecha de pedido:{" "}
                        {new Date(creado).toLocaleString() + ","}
                        <ReactTimeAgo
                          className="ml-1 text-xs text-green-500 font-semibold"
                          date={creado}
                          locale="es-MX"
                        />
                      </p>
                      <p
                        className="text-base mt-2 font-light text-gray-300"
                        dangerouslySetInnerHTML={{ __html: nombre }}
                      />
                      <p
                        className="text-sm font-light text-gray-300"
                        dangerouslySetInnerHTML={{ __html: calle }}
                      />
                      {/*  <p className="text-sm  font-light text-gray-300">
                          {calle}
                        </p> */}
                      <p className="text-sm  font-light text-gray-300">
                        {ciudad}, {estado}
                      </p>
                      <p className="text-sm  font-light text-gray-300">
                        Estado del pedido: {completado}
                      </p>

                      <div className="text-base text-right text-gray-300 ">
                        {" "}
                        Total{" "}
                        {new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                        }).format(total)}
                      </div>
                    </li>
                  </div>
                </div>
              );
            })}
          </ul>

          {results.length === 0 ? null : (
            <div className="flex justify-center text-sm w-full mt-4 font-bold">
              <MyPaginateSmall
                pageCount={Math.ceil(results.length / PAGE_SIZE)}
                pageRangeDisplayed={1}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                previousLabel="<"
                nextLabel=">"
                containerClassName={"flex items-center justify-center"}
                pageClassName={
                  "p-4 rounded-full w-2 h-2 flex items-center justify-center"
                }
                previousClassName={
                  "p-4 rounded-full w-2 h-2 flex items-center justify-center transition duration-200 ease-in-out transform hover:-translate-x hover:scale-150"
                }
                nextClassName={
                  "p-4 rounded-full w-2 h-2 flex items-center justify-center transition duration-200 ease-in-out transform hover:-translate-x hover:scale-150"
                }
                disabledClassName={"opacity-50 cursor-not-allowed"}
                breakClassName={"text-gray-500"}
                activeClassName={"active"}
                forcePage={seleccionada}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SearchAlgoliaPedidos;
