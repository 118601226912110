import React, { useContext, useState, useEffect } from "react";
import { FirebaseContext } from "../../firebase";
import { useAuth } from "../../context/AuthContext";
import Ratings from "react-ratings-declarative";

const Rating = ({ id }) => {

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //state de rating
  const [ratingNum, establecerRatingNum] = useState(0);
  const [ratingGlobal, establecerRatingGlobal] = useState([]);
  const [ratingGlobalNum, establecerRatingGlobalNum] = useState(0);


  useEffect(() => {

    //calcular el rating global

    //obtiene de firebase el rating con la id del producto y calcula su promedioo

    const obtenerRatingGlobal = () => {
      firebase.db
        .collection("ratings")
        .where("id", "==", id)
        .onSnapshot(manejarSnapshot);

      function manejarSnapshot(snapshot) {
        let ratingGlobal = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        establecerRatingGlobal(ratingGlobal);
        // console.log('GLOBAL',ratingGlobal)

        let sum = 0;

        if (ratingGlobal.length > 0) {
          ratingGlobal.forEach((element) => {
            sum += element.rating;
          });

          establecerRatingGlobalNum(sum / ratingGlobal.length);
        } else {
          establecerRatingGlobalNum(0);
        }

        // console.log('LA SUMA',sum)
        // console.log(ratingGlobal.length)
      }
    };

    obtenerRatingGlobal();
  }, [ratingNum]);



  return (
    <>
      <div className="w-full justify-end items-center flex justify-items-end ">
        <div className=" bg-gray-800 mr-4 p-2 rounded-lg shadow-md">
          <div className="flex">
            <p className="mr-2 text-white text-xl self-center">
              {" "}
              {ratingGlobalNum.toFixed(1)}{" "}
            </p>
            <Ratings
              rating={ratingGlobalNum}
              widgetRatedColors="#F59E0B"
              widgetHoverColors="#D97706"
              widgetDimensions="25px"
              widgetSpacings="3px"
            >
              <Ratings.Widget />
              <Ratings.Widget />
              <Ratings.Widget />
              <Ratings.Widget />
              <Ratings.Widget />
            </Ratings>
          </div>
          <div>
            <p className="text-white text-sm">de {ratingGlobal.length} calificaciones</p>
          </div>

          </div>
      </div>
    </>
  );
};

export default Rating;
