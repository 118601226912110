import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { Route } from "react-router-dom";
import Page403 from "../paginas/Page403";

const RutaProtegidaOwner = ({ children, ...restoDePropiedades }) => {
  const { usuario } = useAuth();
  const [role, setRole] = useState();

  if (usuario) {
    usuario.getIdTokenResult().then((idTokenResult) => {
      setRole(idTokenResult.claims.role);
    });
  }
  if (usuario && role === "owner") {
    return <Route {...restoDePropiedades}>{children}</Route>;
  } else {
    return <Page403 />;
  }
};
export default RutaProtegidaOwner;
