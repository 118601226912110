import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../ui/Sidebar";
import ReactLoading from "react-loading";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { FirebaseContext } from "../../firebase";
import Pedidos from "./Pedidos";
import _ from "lodash";
import moment from "moment";
import UserMenu from "../ui/UserMenu";
import dollar from "../../images/dollar-symbol.png";
import shopping from "../../images/shopping.png";
import Notifications from "../ui/Notifications";
import { useMediaQuery } from "react-responsive";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";


const MyCalendar = styled(Calendar).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
})`
  background-color: #4b5563;
  border: none;
  border-radius: 5px;
  color: #d1d5db;

  .react-calendar__month-view__days__day--weekend {
    color: #f87171;
  }

  .react-calendar__tile--now {
    background-color: #eab308;
    border-radius: 5px;
  }

  .react-calendar__tile--now:hover {
    background-color: #fb923c;
    border-radius: 5px;
  }

  .react-calendar__tile:hover {
    background-color: #1f2937;
    border-radius: 5px;
  }

  .react-calendar__navigation button:hover {
    background-color: #1f2937;
    border-radius: 5px;
  }

`;

const Reportes = () => {
  //state que detecta si la pantalla es movil o de escritorio
  const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

  //state de loading
  const [loading, setLoading] = useState(false);

  //state con los pedidos
  const [pedidos, guardarPedidos] = useState([]);

  //context de firebase
  const { firebase } = useContext(FirebaseContext);

  //state del select de filtro
  const [datos, guardarDatos] = useState({ filtro: "1dia" });

  const [ventas, setVentas] = useState("");

  const [totalPedidos, setotalPedidos] = useState("");

  //calendario
  //state que muestra el calendario
  const [showCalendar, setShowCalendar] = useState(false);
  const [value, onChange] = useState();
  const [rango1, setRango1] = useState();
  const [rango2, setRango2] = useState();

  //extraer del state filtro
  const { filtro } = datos;

  useEffect(() => {
    const obtenerPedidos = () => {
      setLoading(true);
      const dia = Date.now() - 86400000;
      firebase.db
        .collection("ordenes")
        .where("creado", ">=", dia)
        .onSnapshot(manejarSnapshot);
    };
    obtenerPedidos();
  }, []);

  function manejarSnapshot(snapshot) {
    let pedidos = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    //ordenar por categorias con lodash
    pedidos = _.orderBy(pedidos, "creado", "asc");

    guardarPedidos(pedidos);

    setLoading(false);

    //calcula las ventas en ese periodo
    const sumBy = (items, prop) => items.reduce((a, b) => +a + +b[prop], 0);
    setVentas(sumBy(pedidos, "total"));

    //calcula el numero de pedidos
    const totalPedidos = pedidos.filter((item) => item.total >= "0").length;
    setotalPedidos(totalPedidos);
  }

  //const data = pedidos

  const data = pedidos;

  //saco el valor maximo de un arreglo de objetos en este caso pedidos

  const maximo = Math.max.apply(
    Math,
    pedidos.map(function (o) {
      return o.total;
    })
  );

  //lee la info del select
  const seleccion = (e) => {
    guardarDatos({ ...datos, [e.target.name]: e.target.value });

    if (e.target.value === "todos") {
      firebase.db.collection("ordenes").onSnapshot(manejarSnapshot);
    }

    if (e.target.value === "30dias") {
      const dia = Date.now() - 86400000 * 30;
      firebase.db
        .collection("ordenes")
        .where("creado", ">=", dia)
        .onSnapshot(manejarSnapshot);
    }

    if (e.target.value === "7dias") {
      const dia = Date.now() - 86400000 * 7;
      firebase.db
        .collection("ordenes")
        .where("creado", ">=", dia)
        .onSnapshot(manejarSnapshot);
    }

    if (e.target.value === "3dias") {
      const dia = Date.now() - 86400000 * 3;
      firebase.db
        .collection("ordenes")
        .where("creado", ">=", dia)
        .onSnapshot(manejarSnapshot);
    }

    if (e.target.value === "1dia") {
      const dia = Date.now() - 86400000;
      firebase.db
        .collection("ordenes")
        .where("creado", ">=", dia)
        .onSnapshot(manejarSnapshot);
    }
  };

  const onClickDay = (date) => {
    let dia1 = Date.parse(date[0]);
    let dia2 = Date.parse(date[1]);

    setRango1(dia1);
    setRango2(dia2);

    firebase.db
      .collection("ordenes")
      .where("completado", "==", false)
      .where("creado", ">=", dia1)
      .where("creado", "<=", dia2)
      .onSnapshot(manejarSnapshot);
  };

  return (
    <div className="md:flex min-h-screen">
      <Sidebar />

      {loading === true ? (
        <div className="md:w-4/5 xl:w-4/5 bg-gray-900 flex justify-center items-center h-screen">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="md:w-4/5 xl:w-4/5 p-2 bg-gray-900 overflow-x-hidden">
          <header className="pt-4">
            <nav className="md:flex md:flex-row justify-between w-full text-white ml-2">
            <div className="flex bg-gray-800 p-2 rounded-lg  w-max justify-items-start ">
                <div className="flex">
                  <div className="relative  border-none">
                    <select
                      className="bg-purple-900 hover:bg-purple-700 text-white appearance-none border-none inline-block py-3 pl-3 pr-8 rounded-lg w-full"
                      onChange={seleccion}
                      name="filtro"
                      value={filtro}
                    >
                      <option
                        className=" text-gray-200"
                        value="1dia"
                      >
                        24 horas
                      </option>
                      <option
                        className=" text-gray-200"
                        value="3dias"
                      >
                        3 días
                      </option>
                      <option
                        className=" text-gray-200"
                        value="7dias"
                      >
                        7 días
                      </option>
                      <option
                        className=" text-gray-200"
                        value="30dias"
                      >
                        30 días
                      </option>
                      <option
                        className=" text-gray-200"
                        value="todos"
                      >
                        Todos
                      </option>
                    </select>

                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>{" "}
                    </div>
                  </div>
                </div>

                <div className="ml-2">
                  <button
                    className="bg-purple-900 hover:bg-purple-700 text-white border-none py-3 p-4 rounded-lg leading-tight w-full xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base focus:ring-4 focus:ring-purple-300 focus:bg-purple-700 inline-flex"
                    onClick={() => {
                      if (showCalendar == true) {
                        setShowCalendar(false);
                      } else {
                        setShowCalendar(true);
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="xs:h-5 xs:w-5 md:h-6 md:w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="md:items-center md:w-auto flex mr-4">
                <div className="flex text-sm">
                  <div className="mt-2">
                    {isStatic ? <Notifications /> : null}
                  </div>
                  <div className=" z-50">{isStatic ? <UserMenu /> : null}</div>
                </div>
              </div>
            </nav>
          </header>

          {showCalendar ? (
            <div className="m-2">
              <MyCalendar
                onChange={onClickDay}
                value={value}
                selectRange={true}
              />
              {rango1 && rango2 !== undefined ? (
                <p className="text-white">
                  Desde el día {new Date(rango1).toLocaleDateString()} hasta el
                  día {new Date(rango2).toLocaleDateString()}
                </p>
              ) : null}
            </div>
          ) : null}

          <div className="md:flex mt-2 text-sm ml-2">
            <div className="flex bg-green-700 bg-opacity-95 border-opacity-60 | p-2 border-solid rounded-lg mb-2">
              <img className="w-8 h-8 ml-2" src={dollar} alt="" />
              <div className="flex">
                <p className="text-gray-100 mr-2 ml-2 self-center">
                  Ventas en este periodo:
                </p>

                <p className="text-gray-100 self-center mr-2">
                  {Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(ventas)}
                </p>
              </div>
            </div>

            <div className=" flex bg-yellow-500 bg-opacity-95 border-opacity-60 | p-2 border-solid rounded-lg md:ml-2 mb-2">
              <img className="w-8 h-8 ml-2" src={shopping} alt="" />
              <div className="flex">
                <p className="text-gray-100 mr-2 ml-2 self-center">Pedidos realizados:</p>
                <p className="text-gray-100 self-center mr-2 ">{totalPedidos}</p>
              </div>
            </div>
          </div>

          <div>
            <h1 className="text-white mt-2 ml-2 xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
              Total
            </h1>
          </div>

          <div className="-ml-4 text-xs -mr-8">
            <ResponsiveContainer width="90%" height={720}>
              <LineChart width={1400} height={400} data={pedidos}>
                <Tooltip labelFormatter={(t) => new Date(t).toLocaleString()} />
                <Legend />
                <Line type="monotone" dataKey="total" stroke="#8884d8" />
                <XAxis
                  style={{ fontSize: 8 }}
                  interval={2}
                  dataKey="creado"
                  tickFormatter={(timeStr) =>
                    moment(timeStr).format("MMM Do, h:mm a")
                  }
                />
                <YAxis ticks={[maximo / 2, maximo]} dataKey="total" />
              </LineChart>
            </ResponsiveContainer>
          </div>

{/*           <div className="content">
            <div className="flex items-center justify-between w-full my-4 pl-4 sm:pr-4"></div>

            <div className=" bg-gray-900 dark:bg-gray-900  flex flex-col justify-center sm:py-12">
              <div className="grid  xl:grid-cols-5 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 gap-4 px-4">
                <div className=" bg-green-700 bg-opacity-95 border-opacity-60 | p-4 border-solid rounded-lg  | flex justify-around | transition-colors duration-500">
                  <img className="w-16 h-16 object-cover" src={dollar} alt="" />
                  <div className="flex flex-col justify-center">
                    <p className="text-gray-100 text-lg">
                      Ventas en este periodo
                    </p>
                    <p className="text-gray-100 dark:text-gray-100 text-justify font-semibold">
                      {Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(ventas)}
                    </p>
                  </div>
                </div>

                <div className="  bg-yellow-500 bg-opacity-95 border-opacity-60 | p-4 border-solid rounded-lg | flex justify-around  | transition-colors duration-500">
                  <img
                    className="w-16 h-16 object-cover"
                    src={shopping}
                    alt=""
                  />
                  <div className="flex flex-col justify-center">
                    <p className="text-gray-100 text-lg">Pedidos realizados</p>
                    <p className="text-gray-100 dark:text-gray-100 text-justify font-semibold">
                      {totalPedidos}
                    </p>
                  </div>
                </div>

                <div class="bg-gray-100 border-yellow-600 dark:bg-gray-800 bg-opacity-95 border-opacity-60 | p-4 border-solid rounded-3xl border-2 | flex justify-around cursor-pointer | hover:bg-yellow-400 dark:hover:bg-yellow-600 hover:border-transparent | transition-colors duration-500">
                  <img
                    class="w-16 h-16 object-cover"
                    src="https://milenyo.net/wp-content/uploads/2020/05/netflix-n-logo-png.png"
                    alt=""
                  />
                  <div class="flex flex-col justify-center">
                    <p class="text-gray-900 dark:text-gray-300 font-semibold">
                      Netflix
                    </p>
                    <p class="text-black dark:text-gray-100 text-justify font-semibold">
                      $15 000,00
                    </p>
                  </div>
                </div>

                <div class="bg-gray-100 border-green-600 dark:bg-gray-800 bg-opacity-95 border-opacity-60 | p-4 border-solid rounded-3xl border-2 | flex justify-around cursor-pointer | hover:bg-green-400 dark:hover:bg-green-600 hover:border-transparent | transition-colors duration-500">
                  <img
                    class="w-16 h-16 object-cover"
                    src="https://milenyo.net/wp-content/uploads/2020/05/netflix-n-logo-png.png"
                    alt=""
                  />
                  <div class="flex flex-col justify-center">
                    <p class="text-gray-900 dark:text-gray-300 font-semibold">
                      Netflix
                    </p>
                    <p class="text-black dark:text-gray-100 text-justify font-semibold">
                      $15 000,00
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


        </div>
      )}
    </div>
  );
};

export default Reportes;
