import React from 'react'
import { useNavigate} from 'react-router-dom';
import shield from "../../images/shield.png";


const Page403 = () => {


    const navigate = useNavigate();

    return (

            <div className=" bg-gray-800 text-white min-h-screen flex items-center">
            <div className="container mx-auto p-4 flex flex-wrap items-center">
                <div className="w-full md:w-5/12 text-center p-4">
                <img src={shield} alt="Not Found" />
                </div>
                <div className="w-full md:w-7/12 text-center md:text-left p-4">
                <div className="text-9xl font-medium">403</div>
                <div className="text-xl md:text-3xl font-medium mb-4">
                    No tienes suficientes derechos para acceder a esta sección
                </div>
                <button onClick={() => navigate('/')} className="border border-white rounded p-4">Volver a la tienda</button>
                </div>
            </div>
            </div>

    )
}

export default Page403
