import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../ui/Sidebar";
import { FirebaseContext } from "../../firebase";
import Pedido from "../ui/Pedido";
import _ from "lodash";
import ReactLoading from "react-loading";
import UserMenu from "../ui/UserMenu";
import ProductosContext from "../../context/ProductosContext";
import { useMediaQuery } from "react-responsive";
import Notifications from "../ui/Notifications";


const Pedidos = () => {

  //state que detecta si la pantalla es movil o de escritorio
  const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

  const [loading, setLoading] = useState(false);

  const [searchterm, setSearchTerm] = useState("");

  const { usuarioState, setUsuarioState } = React.useContext(ProductosContext);

  //state del select de filtro
  const [datos, guardarDatos] = useState({ filtro: "todos" });

  //extraer del state filtro
  const { filtro } = datos;

  //context de firebase
  const { firebase } = useContext(FirebaseContext);

  //state con los pedidos
  const [pedidos, guardarPedidos] = useState([]);

  useEffect(() => {
    const obtenerPedidos = () => {
      setLoading(true);

      firebase.db
        .collection("ordenes")
        .where("uidUsuario", "==", usuarioState.uidUsuario)
        .onSnapshot(manejarSnapshot);
      // firebase.db.collection('ordenes').onSnapshot(manejarSnapshot);
    };
    obtenerPedidos();
  }, []);

  function manejarSnapshot(snapshot) {
    let pedidos = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    //ordenar por categorias con lodash
    pedidos = _.orderBy(pedidos, "creado", "desc");

    guardarPedidos(pedidos);

    setLoading(false);
  }

  //lee la info del select
  const seleccion = (e) => {
    guardarDatos({ ...datos, [e.target.name]: e.target.value });

    if (e.target.value === "completado") {
      firebase.db
        .collection("ordenes")
        .where("completado", "==", true)
        .onSnapshot(manejarSnapshot);
    }
    if (e.target.value === "nocompletado") {
      firebase.db
        .collection("ordenes")
        .where("completado", "==", false)
        .onSnapshot(manejarSnapshot);
    }
    if (e.target.value === "todos") {
      firebase.db.collection("ordenes").onSnapshot(manejarSnapshot);
    }
  };

  return (
    <div className="md:flex min-h-screen">
      <Sidebar />

      {loading === true ? (
        <div className="md:w-4/5 xl:w-4/5 bg-gray-900 flex justify-center items-center h-screen">
        <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="md:w-3/5 xl:w-4/5 p-6 bg-gray-900 ">
          <header>
            <div className="flex justify-between mb-5">
              <div className="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 mt-1 mr-2 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <h1 className="text-3xl text-white">Pedidos de {usuarioState.nombre}</h1>
              </div>
              <div className="flex">
                <div className="mt-2">
                  {isStatic ? <Notifications /> : null}
                </div>
                <div className=" z-50">{isStatic ? <UserMenu /> : null}</div>
              </div>
            </div>
          </header>

          {/* <header className="pt-1">
            <nav className="flex justify-between w-full text-white p-1">
              <select
                className="bg-blue-500 text-white shadow  appearance-none border-green-500 rounded py-2 px-4 leading-tight focus:outline-none focus:shadow-outline  mr-5 ml-2"
                onChange={seleccion}
                name="filtro"
                value={filtro}
              >
                <option className="bg-gray-100 text-black" value="todos">
                  Todos los pedidos
                </option>
                <option className="bg-gray-100 text-black" value="completado">
                  Completados
                </option>
                <option className="bg-gray-100 text-black" value="nocompletado">
                  No completados
                </option>
              </select>
 
              <div className="md:items-center md:w-auto flex">
                <div className="flex text-sm" v-else>
                  <div className="inline-flex flex-col justify-center relative text-gray-500 mr-3">
                    <div className="relative">
                      <input
                        type="text"
                        className="p-2 pl-8 rounded border border-blue-500 focus:bg-white focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:border-transparent"
                        placeholder="Buscar"
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                      />
                      <svg
                        className="w-4 h-4 absolute left-2.5 top-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="black"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </div>
                  </div>



                  <UserMenu />
                </div>
              </div>
            </nav>
          </header> */}

          {pedidos
            .filter((pedido) => {
              if (searchterm == "") {
                return pedido;
              } else if (
                pedido.nombre
                  .toLowerCase()
                  .includes(searchterm.toLowerCase()) ||
                pedido.id.toLowerCase().includes(searchterm.toLowerCase()) ||
                pedido.calle.toLowerCase().includes(searchterm.toLowerCase())
              ) {
                return pedido;
              }
            })
            .map((pedido, id) => {
              return (
                <div key={pedido.id} className="text-lg mt-4 mb-4">
                  <Pedido pedido={pedido} />
                </div>
              );
            })}

          {/* 
                      <div className="text-lg mt-4 mb-4">                                  
                            {pedidos.map( pedido => (
                                <Pedido
                                    key={pedido.id}
                                    pedido={pedido}
                                />
                            ))}
                      </div>     */}
        </div>
      )}
    </div>
  );
};

export default Pedidos;
