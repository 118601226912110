import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../../firebase";
import FrontProducto from "../paginas/FrontProducto";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Producto from "./Producto";

const MyPaginateSmall = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  li a {
    border-radius: 4px;
    cursor: pointer;
    color: white;
    padding: 0.3rem 0.6rem;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
    color: white;
  }
  li.active a {
    border-color: transparent;
    color: white;
    background-color: #3b82f6;
  }
  li.disabled a {
    color: grey;
    cursor: default;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;
const CatalogoSinCategoriaPagination = ({ categorieName }) => {
  const { firebase } = useContext(FirebaseContext);

  const PAGE_SIZE = 4;

  const [loading, setLoading] = useState(false);

  const [productos, guardarProductos] = useState([]);

  const navigate = useNavigate();

  //state que cambia el numero de pagina en react-pagination
  const [currentPage, setCurrentPage] = useState(0);

  //state que forza a cambiar el numero de pagina en react-pagination
  const [seleccionada, setSeleccionada] = useState(0);

  //estado que guarda solo las imagenes que se mostrarán
  const [displayedProducts, setDisplayedProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    const obtenerProductos = () => {
      const dbRef = firebase.db;

      dbRef
        .collection("productos")
        .where("categoria", "==", "")
        .where("subcategoria", "==", "")
        .where("subsubcategoria", "==", "")
        .onSnapshot(manejarSnapshot);

      return () => dbRef();
    };
    obtenerProductos();
  }, []);

  //snapshot que nos permite utilizar la bd en tiempo real de firestore
  function manejarSnapshot(snapshot) {
    let productos = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    productos = _.orderBy(productos, "nombre", "asc");

    //almacena resultados en el state
    guardarProductos(productos);

    setLoading(false);
  }

  useEffect(() => {
    /*     console.log("PAGINA", currentPage);
      console.log("DESDE", currentPage * PAGE_SIZE);
      console.log("HASTA", currentPage * PAGE_SIZE + PAGE_SIZE);
   */
    const corte = productos.slice(
      currentPage * PAGE_SIZE,
      currentPage * PAGE_SIZE + PAGE_SIZE
    );
    setDisplayedProducts(corte);
  }, [currentPage, productos]);

  const handlePageChange = ({ selected }) => {
    setLoading(true);
    setCurrentPage(selected);
    //este state lo puse por que cuando daba click en la seleccion se volvia de vuelta a la pagina uno y así tuve que forzar su estado
    setSeleccionada(selected);
    setLoading(false);
  };

  //state del filtro Orden
  const [orden, guardarOrden] = useState({ filtro1: "defecto" });

  //extraer del state filtro
  const { filtro1 } = orden;

  const seleccionOrden = (e) => {
    guardarOrden({ ...orden, [e.target.name]: e.target.value });
    const productosClone = _.orderBy(productos, "nombre", "asc");
    guardarProductos(productosClone);

    if (e.target.value === "defecto") {
    }

    if (e.target.value === "menoramayor") {
      const productosClone = _.orderBy(productos, "precio", "asc");
      guardarProductos(productosClone);
    }

    if (e.target.value === "mayoramenor") {
      const productosClone = _.orderBy(productos, "precio", "desc");
      guardarProductos(productosClone);
    }

    if (e.target.value === "descuento") {
      const productosClone = _.orderBy(productos, "descuento", "desc");
      guardarProductos(productosClone);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center p-12">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div>
          {productos.length !== 0 ? (
            <div>
              <div className="flex justify-between flex-wrap mb-4 mt-4">
                <p
                  className="font-bold  text-yellow-500 cursor-pointer hover:underline hover:text-yellow-400 self-center text-3xl"
                >
                  Sin categoría
                </p>

                <select
                  className="bg-purple-900 hover:bg-purple-700 text-white appearance-none border-none rounded-lg xs:text-xs sm:text-sm focus:ring-4 focus:ring-purple-300 shadow-md text-center xs:mt-2 p-2 justify-end"
                  onChange={seleccionOrden}
                  name="filtro1"
                  value={filtro1}
                >
                  <option className="text-white" value="defecto">
                    Orden por nombre
                  </option>
                  <option className="text-white" value="menoramayor">
                    Ordenar de menor a mayor precio
                  </option>
                  <option className="text-white" value="mayoramenor">
                    Ordenar de mayor a menor precio
                  </option>
                  <option className="text-white" value="descuento">
                    Ordenar de mayor a menor descuento
                  </option>
                </select>
              </div>

              <div className="">
                {displayedProducts.map((producto, id) => (
                  <Producto key={id} producto={producto} />
                ))}
              </div>
              <div className="flex justify-center m-6 text-base font-bold">
                <MyPaginateSmall
                  pageCount={Math.ceil(productos.length / PAGE_SIZE)}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageChange}
                  previousLabel="<"
                  nextLabel=">"
                  containerClassName={"flex items-center justify-center"}
                  pageClassName={
                    "p-4 rounded-full w-2 h-2 flex items-center justify-center"
                  }
                  previousClassName={
                    "p-4 rounded-full w-2 h-2 flex items-center justify-center transition duration-200 ease-in-out transform hover:-translate-x hover:scale-150"
                  }
                  nextClassName={
                    "p-4 rounded-full w-2 h-2 flex items-center justify-center transition duration-200 ease-in-out transform hover:-translate-x hover:scale-150"
                  }
                  disabledClassName={"opacity-50 cursor-not-allowed"}
                  breakClassName={"text-gray-500"}
                  activeClassName={"active"}
                  forcePage={seleccionada}
                />
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default CatalogoSinCategoriaPagination;
