import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../../firebase";
import Sidebar from "../ui/Sidebar";
import _ from "lodash";
import ReactLoading from "react-loading";
import UserMenu from "../ui/UserMenu";
import ProductosContext from "../../context/ProductosContext";
import { useNavigate } from "react-router-dom";
import Notifications from "../ui/Notifications";
import { useMediaQuery } from "react-responsive";
import CatalogoCategoriaPagination from "../ui/CatalogoCategoriaPagination";
import CatalogoSinCategoriaPagination from "../ui/CatalogoSinCategoriaPagination";
import SearchAlgoliaCatalogo from "../ui/SearchAlgoliaCatalogo";

const Catalogo = () => {
  const { productoState, setProductoState } =
    React.useContext(ProductosContext);

  const navigate = useNavigate();

  const [productos, guardarProductos] = useState([]);

  const [loading, setLoading] = useState(false);

  //state que detecta si la pantalla es movil o de escritorio
  const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

  //state de la busqueda
  const [searchterm, setSearchTerm] = useState("");

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //State que guarda las categorias

  //state de las categoria y subcategorias
  const [categoria, guardarCategoria] = useState({ filtro1: "" });
  const [subcategoria, guardarSubcategoria] = useState({ filtro2: "" });
  const [subsubcategoria, guardarSubsubcategoria] = useState({ filtro3: "" });

  //extraer del state filtro1
  const { filtro1 } = categoria;

  //extraer del state filtro2
  const { filtro2 } = subcategoria;

  const [subcategoriaTree, guardarSubcategoriaTree] = useState({});

  //extraer del state filtro3
  const { filtro3 } = subsubcategoria;

  const [subsubcategoriaTree, guardarSubsubcategoriaTree] = useState({});

  const [treeData, setTreedata] = useState({
    currentNode: {},
    searchFocusIndex: 0,
    treeData: [],
  });

  useEffect(() => {
    //OBTIENE LAS CATEGORIAS
    setLoading(true);

    const obtenerDatos = () => {
      firebase.db.collection("categorias").onSnapshot(manejarSnapshot);
    };

    //se ejecuta la funcion para obtener datos

    obtenerDatos();

    function manejarSnapshot(snapshot) {
      let datos = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      // console.log(datos[0].treeData)
      setTreedata({ treeData: datos[0].treeData });

      setLoading(false);
    }
  }, []);

  useEffect(() => {
    //POPULA LAS CATEGORIAS AL INICIO

    //copia el actual arreglo de categorias

    const miArbol = [treeData.treeData];

    //   console.log(miArbol);

    miArbol.map((sub, index) => {
      //  console.log(sub);

      sub.map((subsub) => {
        if (subsub.title == categoria) {
          guardarSubcategoriaTree(subsub.children);

          if (Object.keys(subcategoriaTree).length > 0) {
            subcategoriaTree.map((sub, index) => {
              if (sub.title == subcategoria) {
                guardarSubsubcategoriaTree(sub.children);
              }
            });
          }
        }
      });
    });
  }, [treeData, loading]);

  //consulta la base de datos al cargar

  useEffect(() => {
    setLoading(true);
    const obtenerProductos = () => {
      firebase.db.collection("productos").onSnapshot(manejarSnapshot);
    };
    obtenerProductos();
  }, []);

  //snapshot que nos permite utilizar la bd en tiempo real de firestore
  function manejarSnapshot(snapshot) {
    let productos = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    //ordenar por categorias con lodash
    productos = _.sortBy(productos, "categoria");

    //almacena resultados en el state
    guardarProductos(productos);

    setLoading(false);
  }

  // resetea el state de producto para un nuevo producto

  const NuevoProducto = () => {
    setProductoState({ descuento: 0, stock: 0 });
    navigate("/editar-producto");
  };

  //TODO SOBRE LAS CATEGORIAS

  const seleccionCategoria = (e) => {
    guardarCategoria({ ...categoria, [e.target.name]: e.target.value });

    //  guardarSubsubcategoria("");

    guardarSubcategoria("");
    guardarSubsubcategoria("");
    guardarSubcategoria({ filtro2: "" });
    guardarSubsubcategoria({ filtro3: "" });
    guardarSubcategoriaTree({});
    guardarSubsubcategoriaTree({});

    //copia el actual arreglo de categorias
    const miArbol = [treeData.treeData];

    miArbol.map((sub, index) => {
      sub.map((subsub) => {
        if (subsub.title == e.target.value) {
          guardarSubcategoriaTree(subsub.children);
        }
      });
    });
  };

  const seleccionSubcategoria = (e) => {
    guardarSubcategoria({ ...subcategoria, [e.target.name]: e.target.value });

    guardarSubsubcategoria("");
    guardarSubsubcategoria({ filtro3: "" });
    guardarSubsubcategoriaTree({});

    subcategoriaTree.map((sub, index) => {
      if (sub.title == e.target.value) {
        guardarSubsubcategoriaTree(sub.children);
      }
    });
  };

  const seleccionSubsubcategoria = (e) => {
    guardarSubsubcategoria({
      ...subsubcategoria,
      [e.target.name]: e.target.value,
    });

    subcategoriaTree.map((sub, index) => {
      if (sub.title == e.target.value) {
        guardarSubsubcategoriaTree(sub.children);
      }
    });
  };

  return (
    <div className="md:flex min-h-screen">
      <Sidebar />

      {loading === true ? (
        <div className="md:w-4/5 xl:w-4/5 bg-gray-900 flex justify-center items-center h-screen">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="md:w-4/5 xl:w-4/5 p-4 bg-gray-900 overflow-x-hidden h-screen">
          <header>
            <div className="flex justify-end">
              {/*               <div className="flex text-white xs:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 mt-1 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                  />
                </svg>

                <h1 className="text-3xl mb-2">Catálogo</h1>
              </div> */}
              <div className="flex">
                <div className="mt-2">
                  {isStatic ? <Notifications /> : null}
                </div>
                <div className=" z-50">{isStatic ? <UserMenu /> : null}</div>
              </div>
            </div>
          </header>

          <div className="w-full flex xs:flex-wrap-reverse sm:flex-wrap-reverse md:justify-between lg:justify-between mt-2">
            <div className="flex bg-gray-800 p-2 rounded-lg xs:w-full sm:w-full xs:justify-around sm:justify-around md:w-max lg:w-max justify-items-start">
              <button
                onClick={NuevoProducto}
                className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 p-3 text-lg pr-5 inline-flex items-center xs:text-sm  mr-2 transition duration-500 ease-in-out shadow-xl"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-1 ml-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
                Añadir producto
              </button>

              <button
                onClick={(e) => {
                  navigate("/gallery");
                }}
                className="rounded-lg text-white bg-purple-900 hover:bg-purple-700 p-3 text-lg pr-5 inline-flex items-center xs:text-sm  transition duration-500 ease-in-out shadow-xl"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2 ml-1"
                  fill="white"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth=".1"
                >
                  <path d="M9 12c0-.552.448-1 1.001-1s.999.448.999 1-.446 1-.999 1-1.001-.448-1.001-1zm6.2 0l-1.7 2.6-1.3-1.6-3.2 4h10l-3.8-5zm5.8-7v-2h-21v15h2v-13h19zm3 2v14h-20v-14h20zm-2 2h-16v10h16v-10z" />{" "}
                </svg>
                Galería de imágenes
              </button>
            </div>

            <div className="flex justify-center lg:justify-end mb-4 xs:w-full sm:w-full md:w-1/3 lg:w-1/3">
              <SearchAlgoliaCatalogo />
            </div>
          </div>

          {/* MAPEA LAS CATEGORIAS */}
          <div className="flex flex-wrap mb-4 justify-center justify-items-center text-white">
            <div className="">
              <label
                className="block mb-2 mt-2 font-semibold text-sm"
                htmlFor="categoria"
              >
                Categoria
              </label>

              <div className="relative  border-none">
                <select
                  className="bg-purple-900 hover:bg-purple-700 text-white appearance-none border-none inline-block py-3 pl-2 pr-8  rounded-lg leading-tight w-full xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base focus:ring-4 focus:ring-purple-300 shadow-md text-center"
                  onChange={seleccionCategoria}
                  name="filtro1"
                  value={filtro1}
                  id="filtro1"

                  /*               id="categoria"
                        name="categoria"
                        value={formik.values.categoria} 
                        onChange={formik.handleChange}
                      onBlur={formik.handleBlur} 
                              */
                >
                  <option value="">Selecciona una categoría</option>

                  {treeData.treeData.map((nodo, id) => {
                    const { title } = nodo;
                    return (
                      <option key={id} value={title}>
                        {title}
                      </option>
                    );
                  })}
                </select>

                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>{" "}
                </div>
              </div>
            </div>

            {subcategoriaTree !== undefined ? (
              <div>
                {subcategoriaTree.length > 0 ? (
                  <div className="md:pl-4">
                    <label
                      className="block mb-2 mt-2 font-semibold text-sm"
                      htmlFor="categoria"
                    >
                      Subcategoria
                    </label>
                    <div className="relative  border-none">
                      <select
                        className="bg-purple-900 hover:bg-purple-700 text-white appearance-none border-none inline-block py-3 pl-2 pr-8  rounded-lg leading-tight w-full xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base focus:ring-4 focus:ring-purple-300 shadow-md text-center"
                        onChange={seleccionSubcategoria}
                        name="filtro2"
                        value={filtro2}
                        id="filtro2"
                      >
                        <option value="">Selecciona una categoría</option>

                        {subcategoriaTree.map((nodo) => {
                          const { title } = nodo;
                          return <option value={title}>{title}</option>;
                        })}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {subsubcategoriaTree !== undefined &&
            subcategoriaTree !== undefined ? (
              <div>
                {subsubcategoriaTree.length > 0 &&
                subcategoriaTree.length > 0 ? (
                  <div className="md:pl-4">
                    <label
                      className="block mb-2 mt-2 font-semibold text-sm"
                      htmlFor="categoria"
                    >
                      Subsubcategoria
                    </label>
                    <div className="relative  border-none">

                    <select
                      className="bg-purple-900 hover:bg-purple-700 text-white appearance-none border-none inline-block py-3 pl-2 pr-8  rounded-lg leading-tight w-full xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base focus:ring-4 focus:ring-purple-300 shadow-md text-center"
                      onChange={seleccionSubsubcategoria}
                      name="filtro3"
                      value={filtro3}
                      id="filtro3"
                    >
                      <option className="" value="">
                        Selecciona una categoría
                      </option>

                      {subsubcategoriaTree.map((nodo) => {
                        const { title } = nodo;
                        return <option value={title}>{title}</option>;
                      })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                      </div>

                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          {filtro1 === "" && filtro2 === "" && filtro3 === "" ? (
            <div>
              <div className=" flex flex-col justify-center items-center  p-10 text-white">
                <div className="text-3xl rounded-lg border-gray-300 border-4 p-10">
                  Selecciona una categoria o busca un producto para editarlo
                </div>
              </div>

              <CatalogoSinCategoriaPagination />
            </div>
          ) : (
            <div className="text-3xl font-light mb-3 mt-1">
              <CatalogoCategoriaPagination
                categorieName={filtro1}
                subcategorieName={filtro2}
                subsubcategorieName={filtro3}
              />
              {/* <CatalogoSinCategoriaPagination /> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Catalogo;
