import React, { useContext, useRef, useState, useEffect } from "react";
import { FirebaseContext } from "../../firebase";
import { useNavigate } from "react-router-dom";
import ProductosContext from "../../context/ProductosContext";
import Swal from "sweetalert2";
import PedidoContext from "../../context/pedidos/pedidosContext";
import { useAuth } from "../../context/AuthContext";
import RatingFront from "../ui/RatingFront";
import { FirebaseContextRevdev } from "../../firebaseRevdev";


const FrontProducto = ({ producto }) => {

  
  //Context con las operaciones de firebase de Revdev
  //diferencia es el número de días que faltan para que expire la subscripción
  const { diferencia} = useContext(FirebaseContextRevdev);


  //extraer el usuario de auth
  const { usuario } = useAuth();

  const { totalPedido, guardarTotalPedido } = useContext(ProductosContext);

  //pedido context
  const { pedido, productoPedido, guardarPedido } = useContext(PedidoContext);

  const {
    id,
    nombre,
    imagen,
    existencia,
    categoria,
    precio,
    descripcion,
    descuento,
    stock,
    sku,
    subcategoria,
    subsubcategoria,
    noAplicaStock,
    marca,
  } = producto;

  //state que guarda si el usuario está habilitado o no

  const [enabled, isEnabled] = useState(true);

  useEffect(() => {
    if (usuario) {
      usuario.getIdTokenResult().then((idTokenResult) => {
        isEnabled(idTokenResult.claims.habilitado);
      });
    }
  }, []);

  //State de los settings
  const [settings, guardarSettings] = useState([""]);

  /*   useEffect(() => {
    //Funcion que obtiene los setttings

    const obtenerSettings = () => {
      firebase.db.collection("settings").onSnapshot(manejarSnapshot);
    };

    obtenerSettings();

    function manejarSnapshot(snapshot) {
      let settings = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      guardarSettings(settings);
    }
  }, []); */

  useEffect(() => {
    //obtiene los settings
    const db = firebase.db;
    const collectionRef = db.collection("settings");

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const settings = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      guardarSettings(settings);
    });

    return () => unsubscribe();
  }, []);

  //state para cantidades

  const [cantidad, guardarCantidad] = useState(1);
  const [total, guardarTotal] = useState(0);

  //<--hasta aqui lo relacionado a pedidos

  //hook para redireccionar
  const navigate = useNavigate();

  //context de firebase para cambio en la db
  const { firebase } = useContext(FirebaseContext);

  /*
  // en cuanto el componente carga, calcula la cantidad a pagar
  useEffect(() => {
    calcularTotal();
  }, [cantidad]);

  //calcula el total
  const calcularTotal = () => {
    //validar el input

    if (descuento > 0) {
      var totalPagar = (precio - precio * (descuento / 100)) * cantidad;
    } else {
      var totalPagar = precio * cantidad;
    }

    guardarTotal(totalPagar);
  };
 */

  const calcularTotalPedido = () => {
    let suma = 0;

    pedido.forEach((item) => {
      if (item.descuento > 0) {
        let precio_final = item.precio - item.precio * (item.descuento / 100);
        suma = suma + precio_final.toFixed(2) * item.cantidad;
      } else {
        suma = suma + item.precio * item.cantidad;
      }
    });

    if (settings[0].envio == "costo" && Object.keys(pedido).length > 0) {
      suma = suma + settings[0].costo_envio;
    }

    guardarTotalPedido(suma);
  };

  const handleVer = () => {
    //   setProductoState(producto)
    const ruta = "/producto/" + id;

    navigate(ruta);
  };

  //decrementa 1
  const decrementarUno = () => {
    if (cantidad > 1) {
      const nuevaCantidad = parseInt(cantidad) - 1;
      guardarCantidad(nuevaCantidad);
    }
  };

  //incrementa 1
  const incrementarUno = () => {
    if (noAplicaStock) {
      const nuevaCantidad = parseInt(cantidad) + 1;
      guardarCantidad(nuevaCantidad);
    } else {
      if (cantidad < stock) {
        const nuevaCantidad = parseInt(cantidad) + 1;
        guardarCantidad(nuevaCantidad);
      }
    }
  };

  //CALCULA EL TOTAL DE CADA ARTICULO CUANDO SE AÑADE O CAMBIA DE CANTIDAD
  useEffect(() => {
    calcularTotal();
  }, [cantidad]);

  //calcula el total de un articulo como su descuento al entrar a una pagina
  const calcularTotal = () => {
    if (producto.descuento > 0) {
      var totalPagar =
        (
          producto.precio.toFixed(2) -
          producto.precio.toFixed(2) * (producto.descuento / 100)
        ).toFixed(2) * cantidad;
    } else {
      var totalPagar = producto.precio * cantidad;
    }

    guardarTotal(totalPagar.toFixed(2));
  };

  //CHECA SI EL PRODUCTO YA ESTÁ EN EL CARRITO
  const checkProduct = (pedido) => {
    //inicalizacion de alerta

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    ///checa si ya está en el carrito o no

    const check = pedido.find((producto) => producto.id === id);

    if (check !== undefined) {
      //    console.log("found it");

      //en que posición está
      const posicionFn = (producto) => producto.id === id;

      //  console.log(pedido.findIndex(posicionFn));

      const posicion = pedido.findIndex(posicionFn);

      //     console.log(posicion);

      //obtener valor de cantidad en esa posicion

      const cantidadCart = pedido[posicion].cantidad;

      //  console.log(cantidadCart);

      //cantidad actual a añadir
      //   console.log(cantidad);

      //nueva cantidad

      let nuevaCantidad = cantidad + cantidadCart;

      //    console.log(nuevaCantidad);

      pedido[posicion].cantidad = nuevaCantidad;

      console.log(
        "La nueva cantidad en el pedido es de",
        pedido[posicion].cantidad
      );

      //    console.log("pedido", pedido);

      //Recalcula el total del producto
      if (pedido[posicion].descuento > 0) {
        var totalPagar =
          (
            pedido[posicion].precio.toFixed(2) -
            pedido[posicion].precio.toFixed(2) *
              (pedido[posicion].descuento / 100)
          ).toFixed(2) * nuevaCantidad;
      } else {
        var totalPagar = pedido[posicion].precio * nuevaCantidad;
      }

      console.log("total pagar en funcion CHECK", totalPagar);

      pedido[posicion].total = totalPagar.toFixed(2);

      calcularTotalPedido();

      /* 
       //recalcula el total

      let nuevoTotal = 0;
      nuevoTotal = pedido.reduce(
        (nuevoTotal, articulo) => nuevoTotal + articulo.total,
        0
      );

      console.log("El nuevo total es:", nuevoTotal);

      guardarTotalPedido(nuevoTotal);

      console.log("En el estate", totalPedido); */

      Toast.fire({
        icon: "success",
        title: "<p style='color:#22c55e'>" + "Se añadió" + "</p>",
        text: cantidad + " x " + pedido[posicion].nombre,
        html:
          '<p style="color:#fff">' +
          cantidad +
          " x " +
          pedido[posicion].nombre +
          "</p>",
        color: "#fff",
        background: "#18181b",
        position: "bottom-end",
        iconColor: "#22c55e",
      });

      return;
    } else {
      console.log("not found it");
      calcularTotal();
    }

    const pedidoSpread = { ...producto, cantidad, total };
    guardarPedido(pedidoSpread);

    Toast.fire({
      icon: "success",
      title: "<p style='color:#22c55e'>" + "Se añadió" + "</p>",
      text: cantidad + " x " + pedidoSpread.nombre,
      html:
        '<p style="color:#fff">' +
        cantidad +
        " x " +
        pedidoSpread.nombre +
        "</p>",
      color: "#000",
      background: "#18181b",
      position: "bottom-end",
      iconColor: "#22c55e",
    });

    return;
  };

  //añade al carrito
  const addCart = () => {
    //almacenar el pedido al carrito de compras

    if (Number.isNaN(total) === true) {
      return null;
    }
    ///   const pedidoTemp = [...pedido];
    checkProduct(pedido);
  };

  return (
    <div className="w-full ">
      <div className="max-w-xs mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800">
        <img
          onClick={handleVer}
          className="cursor-pointer object-cover w-full h-56 shadow-xl"
          src={imagen}
          alt={nombre}
        />

        <div className="text-center">
          <a
            onClick={handleVer}
            to={`/verproducto/${id}`}
            className="cursor-pointer block text-2xl font-bold text-gray-800  mr-3 ml-3 mt-3"
          >
            {nombre}
          </a>

          <RatingFront id={id} />
          {/* 
          <p className="text-gray-600 mb-4 text-sm font-bold mt-3 flex justify-center">
            Categoría:
            <p className="text-gray-700 font-normal ml-1">{categoria}</p>{" "}
            {subcategoria ? (
              <p className="text-gray-700 font-normal">{"/" + subcategoria}</p>
            ) : null}{" "}
            {subsubcategoria ? (
              <p className="text-gray-700 font-normal">
                {"/" + subsubcategoria}
              </p>
            ) : null}
          </p> */}

          <p className="text-gray-600 text-sm text-center font-bold">{marca}</p>

          <p className="text-gray-600 mb-3 text-sm ml-3 mr-3">
            {descripcion.slice(0, 150)}
          </p>

{diferencia > 0 ?
      <div>
           {descuento > 0 ? (
            <>
              <div className="flex justify-center">
                <div></div>
                <p className="text-white mb-3 text-xs font-bold w-1/3 rounded bg-green-600 p-2 shadow-lg">
                  Descuento:{" "}
                  <span className="font-normal text-white">{descuento}%</span>
                </p>
                <div></div>
              </div>

              <div className="flex justify-evenly">
                <p className="mb-3 text-base font-bold line-through text-red-500">
                  MXN
                  <span className="text-red-500 font-bold">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(precio)}
                  </span>
                </p>
                <p className="text-gray-600 mb-3 text-xl font-bold">
                  MXN
                  <span className="text-gray-700 font-bold">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(precio - precio * (descuento / 100))}
                  </span>{" "}
                </p>
              </div>
            </>
          ) : (
            <p className="text-gray-600 mb-1 text-xl font-bold">
              MXN{" "}
              <span className="text-gray-700 font-bold">
                {new Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(precio)}
              </span>
            </p>
          )}

          {enabled === false ? (
            <p className="text-xs  text-red-500 p-8">
              El usuario está deshabilitado y no puede hacer compras
            </p>
          ) : (
            <div>
              {stock > 0 || noAplicaStock ? (
                <div>
                  {existencia ? (
                    <div>
                      <div className="inline-flex items-center">
                        <div className="grid grid-cols-3 pl-16 pr-16 pt-2 pb-2 w-72">
                          <button
                            onClick={decrementarUno}
                            className="rounded-md rounded-r-none  text-white text-lg bg-yellow-500 hover:bg-yellow-700 p-2 text-center shadow-lg"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="ml-2 h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M18 12H6"
                              />
                            </svg>
                          </button>
                          <input
                            readOnly
                            value={cantidad.toString()}
                            type="number"
                            max={stock}
                            className="font-bold focus:outline-none text-center w-full bg-white text-base  hover:text-gray-700 focus:hover:text-gray-700  flex items-center text-gray-700 outline-none border-gray-200 border p-1 shadow-lg"
                          />
                          <button
                            onClick={incrementarUno}
                            className="rounded-md rounded-l-none  text-white text-lg bg-yellow-500 hover:bg-yellow-700 p-1 text-center shadow-lg"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="ml-2 h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>

                      <button
                        onClick={addCart}
                        className=" ml-15 rounded-xl text-white bg-blue-500 hover:bg-blue-700 p-3 text-sm mt-3 inline-flex items-center shadow-lg mb-3"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 mr-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                          />
                        </svg>
                        AÑADIR AL CARRITO
                      </button>
                    </div>
                  ) : (
                    <p className="text-sm bg-red-500 p-2 m-4 text-white rounded-lg">
                      No disponible por el momento
                    </p>
                  )}
                </div>
              ) : (
                <p className="text-sm bg-red-500 p-2 m-4 text-white rounded-lg">
                  No disponible por el momento
                </p>
              )}
            </div>
          )}
        </div>: <div className="text-sm bg-red-500 text-white p-2">Tu susbscripción ha caducado y la tienda está en modo catálogo</div> }
        </div> 
      </div>
    </div>
  );
};

export default FrontProducto;
