import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import ReactLoading from "react-loading";
import FrontHeader from "./FrontHeader";
import FrontFooter from "./FrontFooter";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Cart from "./Cart";
import "react-awesome-slider/dist/styles.css";
import toast, { Toaster } from "react-hot-toast";
import cart from "../../images/cart.png";
import { NavLink, useNavigate } from "react-router-dom";
import { FirebaseContext } from "../../firebase";
import Swal from "sweetalert2";

const Contact = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [loading, setLoading] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const navigate = useNavigate();

  const [correo, guardarCorreo] = useState("");

  //correo del admin de la tienda
  const [correoContacto, guardarCorreoContacto] = useState("");

  const [nombre, guardarNombre] = useState("");
  const [mensaje, guardarMensaje] = useState("");

  const [enviado, guardarEnviado] = useState(false);

  //State de los settings
  const [settings, guardarSettings] = useState([""]);

  useEffect(() => {
    //obtiene los settings
    const db = firebase.db;
    const collectionRef = db.collection("settings")

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const settings = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
           
      guardarSettings(settings);
      console.log(settings)
   
    });

    return () => unsubscribe();
    
  }, []);

/* useEffect(() => {
    guardarCorreoContacto(settings[0].correoContacto);
    console.log(correoContacto)
}, [settings]) */




  const handleSubmit = (e) => {
    e.preventDefault();

    //validar formulario

    if (correo === "" || nombre === "" || mensaje === "") {
      toast.error("Todos los campos son obligatorios");
      return;
    }

    const contactoObj = {
      correo: correo,
      nombre: nombre,
      mensaje: mensaje,
    };



      //enviamos el correo de confirmación con template en firestore
      firebase.db.collection("mail").add({
        to: settings[0].correoContacto,
        template: {
          name: "contacto",
          data: {
            correo: correo,
            nombre: nombre,
            mensaje: mensaje
          }
        }
      });


    firebase.db
      .collection("contacto")
      .add(contactoObj)
      .then((respuesta) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          title:
            "<p style='color:#22c55e'>" +
            "El mensaje se envió correctamente" +
            "</p>",
          //        html: '<p style="color:#fff">Se guardó la configuración de la tienda</p>',
          icon: "success",
          color: "#fff",
          background: "#18181b",
          position: "top-end",
          iconColor: "#22c55e",
          timer: 1500,
          showConfirmButton: false,
        });
      });

    guardarEnviado(true);
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "correo":
        guardarCorreo(e.target.value);
        break;
      case "nombre":
        guardarNombre(e.target.value);
        break;
      case "mensaje":
        guardarMensaje(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <div className=" min-h-screen">
      {loading === true ? (
        <div className="self-center justify-center flex ">
          <ReactLoading color={"#3B82F6"} type={"bars"} />
        </div>
      ) : (
        <>
          <FrontHeader toggleDrawer={toggleDrawer} />

          <div className="">
            <div className="mx-auto max-w-screen-2xl">
              <Drawer
                size={350}
                open={isOpen}
                onClose={toggleDrawer}
                direction="right"
              >
                <Cart isOpen={isOpen} setIsOpen={setIsOpen} />
              </Drawer>
            </div>
          </div>
        </>
      )}

      <div className="">
        <div>
          <Toaster />
          <div className="relative min-h-screen flex flex-col justify-center items-center bg-gray-900 p-10">
            <div className="relative sm:max-w-lg w-full">
              <div className="card bg-yellow-500 shadow-lg w-full h-full rounded-3xl absolute transform -rotate-6"></div>
              <div className="card bg-green-400 shadow-lg w-full h-full rounded-3xl absolute transform rotate-6"></div>

              {!enviado ? (
                <div className="relative w-full rounded-3xl px-6 py-4 bg-gray-100 shadow-md ">
                  <div className="flex justify-center pt-5">
                    <img
                      onClick={() => {
                        navigate("/");
                      }}
                      className="block h-8 w-auto cursor-pointer"
                      src={cart}
                      alt="Revdev Store"
                    />
                    <p className="text-black mb-5 text-xl pl-2">
                      The Revdev Store
                    </p>
                  </div>
                  <label className="block text-gray-700 text-center text-3xl font-light m-2">
                    Contacto
                  </label>
                  <p className="flex justify-center text-gray-400">
                    ¿En qué te podemos ayudar?
                  </p>
                  <form onSubmit={handleSubmit} className="mt-5">
                    <div>
                      <input
                        onChange={handleChange}
                        value={correo}
                        name="correo"
                        type="email"
                        placeholder="Correo"
                        className="w-full mt-1 block  border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-4 ring-1 focus:outline-none xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base"
                      />
                    </div>

                    <div className="mt-5">
                      <input
                        onChange={handleChange}
                        value={nombre}
                        name="nombre"
                        placeholder="Nombre"
                        className="w-full mt-1 block  border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-4 ring-1 focus:outline-none xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base"
                      />
                    </div>

                    <div className="mt-5">
                      <textarea
                        onChange={handleChange}
                        value={mensaje}
                        name="mensaje"
                        placeholder="Mensaje"
                        rows="10"
                        cols="50"
                        className="w-full mt-1 bg-gray-100 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-5 ring-1 focus:outline-none xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base resize-none p-4"
                      />
                    </div>

                    <div className="mt-6">
                      <button className="bg-blue-500 w-full py-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-200 ease-in-out transform hover:-translate-x hover:scale-105">
                        Enviar
                      </button>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="relative w-full rounded-3xl p-40 bg-gray-100 shadow-md ">
                  <p className="flex justify-center text-center p-4 text-gray-800 text-3xl mb-2">
                    ¡Muchas gracias por tu mensaje!
                  </p>
                  <p className="flex justify-center text-center bg-green-500 rounded-lg p-4 text-white">
                    El mensaje se envió correctamente y será revisado a la
                    brevedad posible por nuestro equipo de trabajo.
                  </p>
                  <p
                    className="flex justify-center text-center p-4 text-gray-800 cursor-pointer hover:underline"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Regresar a la tienda
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <FrontFooter />
    </div>
  );
};

export default Contact;
