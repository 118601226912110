import React, { useContext, useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { useParams } from "react-router-dom";
import { FirebaseContext } from "../../firebase";
import Comment from "./Comment";
import _ from "lodash";
import user from "../../images/user.png";

const Comments = () => {
  //extraer el usuario de auth
  const { usuario } = useAuth();

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //obtengo el id del producto desde la URL

  const { id } = useParams();
  // console.log(id);

  //State del comentario

  const [comentario, guardarComentario] = useState("");
  const [comentarios, guardarComentarios] = useState([]);

  //state que guarda si el usuario está habilitado o no

  const [enabled, isEnabled] = useState(true);

  useEffect(() => {
    if (usuario) {
      usuario.getIdTokenResult().then((idTokenResult) => {
        isEnabled(idTokenResult.claims.habilitado);
      });
    }
  }, []);

/*   useEffect(() => {
    //CARGA DE COMENTARIOS
    const obtenerComentarios = () => {
      firebase.db
        .collection("comentarios")
        .where("idProducto", "==", id)
        .where("root", "==", "")
        .onSnapshot(manejarSnapshot);

      function manejarSnapshot(snapshot) {
        let comentarios = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        comentarios = _.orderBy(comentarios, "creado", "asc");

        guardarComentarios(comentarios);
      }
    };

    obtenerComentarios();
  }, []); */


  useEffect(() => {
    
    //obtiene los comentarios
    const db = firebase.db
    const collectionRef = db.collection('comentarios').where("idProducto", "==", id).where("root", "==", "")

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      let comentarios = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      comentarios = _.orderBy(comentarios, "creado", "asc");

      guardarComentarios(comentarios);    });

    return () => unsubscribe();
  }, []);

  //Añade un comentario
  const addComment = () => {
    const commentObj = {
      uidUsuario: usuario.uid,
      photo: usuario.photoURL,
      name: usuario.displayName,
      idProducto: id,
      comentario: comentario,
      creado: Date.now(),
      root: "",
    };

    if (comentario !== ""){
    firebase.db.collection("comentarios").add(commentObj);
    guardarComentario("");}
  };

  //Maneja el estado al cambiar el valor en el comentario

  const handleChange = (e) => {
    switch (e.target.name) {
      case "comentario":
        guardarComentario(e.target.value);
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <div className="mx-auto max-w-screen-md pl-5 pr-5">
        <h3 className="text-lg font-semibold text-gray-900">
         Preguntas y comentarios
        </h3>
        
        {comentarios.map((comentario, id) => {
          return (
            <div key={comentario.id} className="text-lg mt-4 mb-4">
              <Comment comentario={comentario} />
            </div>
          );
        })}

        <div>
          {enabled === false ? (
            <p className="text-white  p-4 bg-red-500 flex justify-center text-base rounded-md mb-8 mt-4">
              Tu cuenta está dehabilitada y no puedes comentar
            </p>
          ) : (
            <div className="flex mt-4 mb-8">
              {usuario ? (
                <div className="flex-1 border rounded-lg px-4 py-4 leading-relaxed bg-gray-800">
                  <div className="flex mb-2">
                    <div className="">
                      {usuario.photoURL ? (
                        <img
                          className="rounded-full w-8 h-8 sm:w-10 sm:h-10"
                          alt=""
                          src={usuario.photoURL}
                        />
                      ) : (
                        <img
                          className="rounded-full w-8 h-8 sm:w-10 sm:h-10"
                          alt=""
                          src={user}
                        />
                      )}
                    </div>
                    <p className=" mt-1 ml-2 text-base text-gray-300 ">
                      {usuario.displayName}
                    </p>{" "}
                  </div>
                  <textarea
                    className="focus:border-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-3"
                    name="comentario"
                    placeholder="Escribe aquí tu comentario"
                    value={comentario}
                    onChange={handleChange}
                  ></textarea>
                  <button
                    onClick={addComment}
                    class="px-4 py-2 text-base rounded-md text-white bg-blue-500 mt-3"
                  >
                    Comentar
                  </button>
                </div>
              ) : (
                <p className=" bg-blue-500 p-4 text-white rounded-lg mb-4">
                  Debes de ingresar para comentar
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Comments;
