import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../ui/Sidebar";
import ReactLoading from "react-loading";
import UserMenu from "../ui/UserMenu";
import Notifications from "../ui/Notifications";
import { FirebaseContext } from "../../firebase";
import Swal from "sweetalert2";
import validator from "validator";
import InputMask from "react-input-mask";
import paypal from "../../images/paypal.png";
import stripe from "../../images/stripe.png";
import flag from "../../images/flag_mx.png";
import { useMediaQuery } from "react-responsive";
import ReactTooltip from "react-tooltip";
import { Prompt } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";

const Settings = () => {
  //state que detecta si la pantalla es movil o de escritorio
  const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

  const [loading, setLoading] = useState(false);

  const [changes, setChanges] = useState(false);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //hook para redireccionar
  const navigate = useNavigate();

  //Detalles de la tienda
  const [nombreTienda, establecerNombreTienda] = useState("");
  const [correoContacto, establecerCorreoContacto] = useState("");
  const [correoRemitente, establecerCorreoRemitente] = useState("");

  //Detalles del negocio
  const [nombre, establecerNombre] = useState("");
  const [rfc, establecerRfc] = useState("");
  const [calle, establecerCalle] = useState("");
  const [colonia, establecerColonia] = useState("");
  const [ciudad, establecerCiudad] = useState("");
  const [estado, establecerEstado] = useState("");
  const [codigoPostal, establecerCodigoPostal] = useState("");
  const [telefono, establecerTelefono] = useState("");

  //plataforma de pago
  const [plataforma, establecerPlataforma] = useState("");
  const [limitarPedido, establecerLimitarPedido] = useState(0);
  const [limitarPrecio, establecerLimitarPrecio] = useState(0);

  //envios
  const [envio, establecerEnvio] = useState("");
  const [costo_envio, establecerCosto_envio] = useState(0);
  const [desde_envio, establecerDesde_envio] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);

  //legal
  const [terminos, establecerTerminos] = useState("");

  const [editando, establecerEditando] = useState(false);
  const [datos, guardarDatos] = useState([]);

  useEffect(() => {
    setLoading(true);

    const obtenerDatos = () => {
      firebase.db.collection("settings").onSnapshot(manejarSnapshot);
    };

    //se ejecuta la funcion para obtener datos

    obtenerDatos();

    function manejarSnapshot(snapshot) {
      let datos = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      guardarDatos(datos);

      if (Object.keys(datos).length === 0) {
        establecerEditando(false);
      } else establecerEditando(true);

      if (editando === true) {
        establecerNombreTienda(datos[0].nombreTienda);
        establecerCorreoContacto(datos[0].correoContacto);
        establecerCorreoRemitente(datos[0].correoRemitente);
        establecerNombre(datos[0].nombre);
        establecerRfc(datos[0].rfc);
        establecerCalle(datos[0].calle);
        establecerColonia(datos[0].colonia);
        establecerCiudad(datos[0].ciudad);
        establecerEstado(datos[0].estado);
        establecerCodigoPostal(datos[0].codigoPostal);
        establecerTelefono(datos[0].telefono);
        establecerPlataforma(datos[0].plataforma);
        establecerLimitarPedido(datos[0].limitarPedido);
        establecerLimitarPrecio(datos[0].limitarPrecio);
        establecerEnvio(datos[0].envio);
        establecerCosto_envio(datos[0].costo_envio);
        establecerDesde_envio(datos[0].desde_envio);
        establecerTerminos(datos[0].terminos);
      }

      setLoading(false);
    }
  }, [editando]);

  const handleChangeEditor = (value) => {
    establecerTerminos(value);
  };

  //maneja el cambio en el formulario

  const handleChange = (e) => {
    setChanges(true);

    switch (e.target.name) {
      case "nombreTienda":
        establecerNombreTienda(e.target.value);
        break;
      case "correoContacto":
        establecerCorreoContacto(e.target.value);
        break;
      case "correoRemitente":
        establecerCorreoRemitente(e.target.value);
        break;
      case "nombre":
        establecerNombre(e.target.value);
        break;
      case "rfc":
        establecerRfc(e.target.value);
        break;
      case "calle":
        establecerCalle(e.target.value);
        break;
      case "colonia":
        establecerColonia(e.target.value);
        break;
      case "ciudad":
        establecerCiudad(e.target.value);
        break;
      case "estado":
        establecerEstado(e.target.value);
        break;
      case "codigoPostal":
        establecerCodigoPostal(e.target.value);
        break;
      case "telefono":
        establecerTelefono(e.target.value);
        break;
      case "plataforma":
        establecerPlataforma(e.target.value);
        break;
      case "limitarPedido":
        establecerLimitarPedido(e.target.value);
        break;
      case "limitarPrecio":
        establecerLimitarPrecio(e.target.value);
        break;
      case "envio":
        establecerEnvio(e.target.value);
        break;
      case "costo_envio":
        establecerCosto_envio(e.target.value);
        break;
      case "desde_envio":
        establecerDesde_envio(e.target.value);
        break;
      case "terminos":
        establecerTerminos(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    //validar el formulario
    if (
      nombreTienda === "" ||
      correoRemitente === "" ||
      correoContacto === "" ||
      nombre === "" ||
      calle === "" ||
      colonia === "" ||
      ciudad === "" ||
      estado === "" ||
      codigoPostal === "" ||
      telefono === ""
    ) {
      Toast.fire({
        icon: "error",
        title: "Todos los campos son obligatorios",
      });

      return;
    }

    //aqui quitamos los caracteres _ para hacer el conteo de numeros en la validacion
    if (telefono.replace(/-|_/g, "").length != 18) {
      Toast.fire({
        icon: "error",
        title: "El número telefonico debe ser igual a 10 números",
      });

      return;
    }

    if (validator.isEmail(correoContacto)) {
      console.log("Valid Email");
    } else {
      Toast.fire({
        icon: "error",
        title: "Escribe un correo de contacto válido",
        dangerMode: true,
      });
      return;
    }

    if (validator.isEmail(correoRemitente)) {
      console.log("Valid Email");
    } else {
      Toast.fire({
        icon: "error",
        title: "Escribe un correo remitente válido",
      });
      return;
    }

    if (plataforma === "") {
      Toast.fire({
        icon: "error",
        title: "Elige una plataforma de pago",
      });

      return;
    }

    const userObj = {
      nombreTienda: nombreTienda,
      correoContacto: correoContacto,
      correoRemitente: correoRemitente,
      nombre: nombre,
      rfc: rfc,
      telefono: telefono,
      calle: calle,
      colonia: colonia,
      ciudad: ciudad,
      estado: estado,
      codigoPostal: codigoPostal,
      plataforma: plataforma,
      limitarPedido: limitarPedido,
      limitarPrecio: limitarPrecio,
      envio: envio,
      costo_envio: parseFloat(parseFloat(costo_envio).toFixed(2)),
      desde_envio: parseFloat(parseFloat(desde_envio).toFixed(2)),
      terminos: terminos,
    };

    //console.log(userObj);

    if (editando == false) {
      firebase.db.collection("settings").add(userObj);
    } else {
      firebase.db.collection("settings").doc(datos[0].id).update(userObj);
    }

    Toast.fire({
      title: "<p style='color:#22c55e'>" + "Guardado" + "</p>",
      html: '<p style="color:#fff">Se guardó la configuración de la tienda</p>',
      icon: "success",
      color: "#fff",
      background: "#18181b",
      position: "top-end",
      iconColor: "#22c55e",
      timer: 1500,
      showConfirmButton: false,
    });
  };

  return (
    <div className="md:flex min-h-screen">
      <Sidebar />
      <ReactTooltip />
      {loading === true ? (
        <div className="md:w-4/5 xl:w-4/5 bg-gray-900 flex justify-center items-center h-screen">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="md:w-4/5 xl:w-4/5 p-4 bg-gray-900">
          <header>
            <div className="flex justify-end">
              {/*               <div className="flex text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 mt-1 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <h1 className="text-3xl">Configuración</h1>
              </div> */}
              <div className="flex">
                <div className="mt-2">
                  {isStatic ? <Notifications /> : null}
                </div>
                <div className=" z-50">{isStatic ? <UserMenu /> : null}</div>
              </div>
            </div>
          </header>

          <Prompt
            when={changes}
            message="¿Estás seguro de que deseas abandonar la página? Tus cambios no se guardarán."
          />

          <div className="p-2 pt-2 bg-gray-800 rounded-lg max-w-screen-lg xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-base">
            <p className="text-2xl text-white flex justify-center mt-2">
              Detalles de la tienda
            </p>

            <form onSubmit={handleSubmit} className="mt-5">
              <div className="rounded-lg px-4 py-4 m-1 shadow-md max-w-screen-lg bg-gray-700">
                <div className="md:p-4">
                  <div className="pl-2 pr-2">
                    <div className=" flex mt-4 mb-2">
                      <p className="font-semibold text-sm text-gray-300">
                        Nombre de la tienda
                      </p>

                      <svg
                        data-tip="El nombre de la tienda como la verá el público en general"
                        alt="tooltip"
                        className="ml-2 hover:animate-bounce"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="white"
                        fill="white"
                        strokeWidth=".1"
                      >
                        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
                      </svg>
                    </div>

                    <ReactTooltip place="top" type="dark" effect="float" />

                    <input
                      onChange={handleChange}
                      value={nombreTienda}
                      name="nombreTienda"
                      type="text"
                      placeholder="Nombre de la tienda"
                      className="border border-gray-400 p-3 rounded w-full text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 transition-shadow xs:text-xs md:text-base"
                    />
                  </div>

                  <div className="pl-2 pr-2">
                    <div className="flex mt-4 mb-2 ">
                      <p className=" font-semibold text-sm text-gray-300 ">
                        Correo de contacto
                      </p>

                      <svg
                        data-tip="Será utilizado por default en el sistema para enviar notificaciones al propietario de la tienda"
                        alt="tooltip"
                        className="ml-2 hover:animate-bounce"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="white"
                        fill="white"
                        strokeWidth=".1"
                      >
                        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
                      </svg>
                    </div>

                    <input
                      onChange={handleChange}
                      value={correoContacto}
                      name="correoContacto"
                      type="text"
                      placeholder="Correo de contacto"
                      className="w-full xl:w-1/3 lg:w-1/3 border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 transition-shadow xs:text-xs md:text-base "
                    />
                  </div>

                  <div className="pl-2 pr-2">
                    <div className="flex mt-4 mb-2 ">
                      <p className="font-semibold text-sm text-gray-300">
                        Correo del remitente
                      </p>

                      <svg
                        data-tip="Los clientes verán esta dirección de correo al ser notificados con un correo"
                        className="ml-2 hover:animate-bounce"
                        alt="tooltip"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="white"
                        fill="white"
                        strokeWidth=".1"
                      >
                        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
                      </svg>
                    </div>

                    <input
                      onChange={handleChange}
                      value={correoRemitente}
                      name="correoRemitente"
                      type="text"
                      placeholder="Correo del remitente"
                      className="w-full xl:w-1/3 lg:w-1/3 border border-gray-400 p-3 mb-4 rounded text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 transition-shadow xs:text-xs md:text-base "
                    />
                  </div>
                </div>
              </div>

              <p className="text-2xl text-white flex justify-center mt-4">
                Detalles del negocio
              </p>

              <div className="rounded-lg px-3 py-4 m-1 shadow-md max-w-screen-lg bg-gray-700 mt-4">
                <div className="md:p-4">
                  <div className="pl-2 pr-2">
                    <div className="flex mt-4 mb-2">
                      <p className="font-semibold text-sm text-gray-300">
                        Nombre legal del negocio o empresa
                      </p>

                      <svg
                        data-tip="El nombre del negocio o entidad como se encuentra registrado legalmente"
                        alt="tooltip"
                        className="ml-2 hover:animate-bounce"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke="white"
                        fill="white"
                        strokeWidth=".1"
                      >
                        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z" />
                      </svg>
                    </div>

                    <input
                      onChange={handleChange}
                      value={nombre}
                      name="nombre"
                      type="text"
                      placeholder="Nombre legal"
                      className="border border-gray-400 p-3 rounded w-full text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 transition-shadow text-center xs:text-xs md:text-base"
                    />
                  </div>

                  <div className="sm:flex">
                    <div className="pl-2 pr-2">
                      <p className="mt-4 mb-2 font-semibold text-sm text-gray-300">
                        Teléfono
                      </p>
                      <div className="flex">
                        <img
                          className="block h-10 cursor-pointer self-center mr-2"
                          src={flag}
                          alt="Revdev Store"
                        />
                        <InputMask
                          mask="(+52) 999 999 9999"
                          alwaysShowMask={true}
                          onChange={handleChange}
                          value={telefono}
                          name="telefono"
                          placeholder="Teléfono"
                          className="w-44 xl:w-44 lg:w-44 border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 bg-white text-center transition-shadow xs:text-xs md:text-base "
                        ></InputMask>
                      </div>
                    </div>
                  </div>

                  <div className="pl-2 pr-2">
                    <p className="mt-4 mb-2 font-semibold text-sm text-gray-300">
                      RFC
                    </p>

                    <InputMask
                      onChange={handleChange}
                      mask="aaaa-999999-***"
                      value={rfc}
                      name="rfc"
                      type="text"
                      placeholder="RFC"
                      alwaysShowMask={true}
                      className="w-44 xl:w-44 lg:w-44 border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 text-center transition-shadow xs:text-xs md:text-base"
                    ></InputMask>
                  </div>

                  <div className="md:flex">
                    <div className="pl-2 pr-2">
                      <p className="mt-4 mb-2 font-semibold text-sm text-gray-300">
                        Estado
                      </p>

                      <select
                        name="estado"
                        onChange={handleChange}
                        value={estado}
                        className="w-44 xl:w-44 lg:w-44 border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 bg-white transition-shadow text-center xs:text-xs md:text-base"
                      >
                        <option value="no">Seleccione uno...</option>
                        <option value="Aguascalientes">Aguascalientes</option>
                        <option value="Baja California">Baja California</option>
                        <option value="Baja California Sur">
                          Baja California Sur
                        </option>
                        <option value="Campeche">Campeche</option>
                        <option value="Chiapas">Chiapas</option>
                        <option value="Chihuahua">Chihuahua</option>
                        <option value="CDMX">Ciudad de México</option>
                        <option value="Coahuila">Coahuila</option>
                        <option value="Colima">Colima</option>
                        <option value="Durango">Durango</option>
                        <option value="Estado de México">
                          Estado de México
                        </option>
                        <option value="Guanajuato">Guanajuato</option>
                        <option value="Guerrero">Guerrero</option>
                        <option value="Hidalgo">Hidalgo</option>
                        <option value="Jalisco">Jalisco</option>
                        <option value="Michoacán">Michoacán</option>
                        <option value="Morelos">Morelos</option>
                        <option value="Nayarit">Nayarit</option>
                        <option value="Nuevo León">Nuevo León</option>
                        <option value="Oaxaca">Oaxaca</option>
                        <option value="Puebla">Puebla</option>
                        <option value="Querétaro">Querétaro</option>
                        <option value="Quintana Roo">Quintana Roo</option>
                        <option value="San Luis Potosí">San Luis Potosí</option>
                        <option value="Sinaloa">Sinaloa</option>
                        <option value="Sonora">Sonora</option>
                        <option value="Tabasco">Tabasco</option>
                        <option value="Tamaulipas">Tamaulipas</option>
                        <option value="Tlaxcala">Tlaxcala</option>
                        <option value="Veracruz">Veracruz</option>
                        <option value="Yucatán">Yucatán</option>
                        <option value="Zacatecas">Zacatecas</option>
                      </select>
                    </div>

                    <div className="pl-2 pr-2">
                      <p className="mt-4 mb-2 font-semibold text-sm text-gray-300">
                        Ciudad
                      </p>

                      <input
                        onChange={handleChange}
                        value={ciudad}
                        name="ciudad"
                        type="text"
                        placeholder="Ciudad"
                        className="w-44  xl:w-44 lg:w-44 border border-gray-400 p-3 rounded  text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 transition-shadow text-center xs:text-xs md:text-base"
                      />
                    </div>

                    <div className="pl-2 pr-2">
                      <p className="mt-4 mb-2 font-semibold text-sm text-gray-300">
                        Colonia
                      </p>

                      <input
                        onChange={handleChange}
                        value={colonia}
                        name="colonia"
                        type="text"
                        placeholder="Colonia"
                        className="w-44 xl:w-44 lg:w-44 border border-gray-400 p-3 rounded  text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 transition-shadow text-center xs:text-xs md:text-base"
                      />
                    </div>
                  </div>

                  <div className="md:flex justify-around">
                    <div className="pl-2 w-full">
                      <p className="mt-4 mb-2 font-semibold text-sm text-gray-300">
                        Calle y número
                      </p>

                      <input
                        onChange={handleChange}
                        value={calle}
                        name="calle"
                        type="text"
                        placeholder="Calle y número"
                        className="border border-gray-400 p-3 rounded w-full text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 transition-shadow text-center xs:text-xs md:text-base"
                      />
                    </div>

                    <div className="pl-2 pr-2">
                      <p className="mt-4 mb-2 font-semibold text-sm text-gray-300">
                        Código Postal
                      </p>

                      <input
                        onChange={handleChange}
                        value={codigoPostal}
                        name="codigoPostal"
                        type="number"
                        placeholder="Código Postal"
                        min="0"
                        className="w-24 border border-gray-400 p-3 rounded text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-purple-600 focus:ring-2 bg-white pl-3 transition-shadow text-center xs:text-xs md:text-base"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-2xl text-white flex justify-center mt-4">
                Plataforma de pago
              </p>

              <div className="rounded-lg max-w-screen-lg mt-4">
                <div className="md:p-4">
                  <div className="pl-2 pr-2">
                    <div className="flex justify-center">
                      <div className="border-2 border-purple-600 rounded-lg  bg-gray-300 p-4 ">
                        <div className="flex mb-4">
                          <input
                            className="w-6 h-6 mt-2"
                            onChange={handleChange}
                            value="paypal"
                            type="radio"
                            id="paypal"
                            name="plataforma"
                            checked={plataforma === "paypal"}
                          ></input>
                          <img
                            className="block h-6 cursor-pointer pl-2 mt-2"
                            src={paypal}
                            alt="Revdev Store"
                          />
                          <button
                            className="ml-2 p-2 bg-blue-500 text-white rounded-md text-sm"
                            onClick={() => {navigate('/paypalsettings')}}
                          >
                            Configurar
                          </button>
                        </div>

                        <div className="flex">
                          <input
                            className="w-6 h-6 mt-1"
                            onChange={handleChange}
                            value="stripe"
                            type="radio"
                            id="stripe"
                            name="plataforma"
                            checked={plataforma === "stripe"}
                          ></input>
                          <img
                            className="block h-7 cursor-pointer pl-2 mt-1 "
                            src={stripe}
                            alt="stripe logo"
                          />
                          <button className="ml-2 p-2 bg-blue-500 text-white rounded-md text-sm"
                            onClick={() => {navigate('/stripesettings')}}
                          >
                            Configurar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-2xl text-white flex justify-center mt-4">
                Envíos
              </p>

              <div className="rounded-lg px-3 py-4 m-1 shadow-md max-w-screen-lg bg-gray-700 mt-2">
                <div className="md:p-4">
                  <div className="pl-2 pr-2">
                    {" "}
                    <div className="">
                      <div className=" text-gray-300 mt-4 xs:text-xs md:text-base">
                        <div className="flex mb-2">
                          <input
                            className="w-6 h-6"
                            onChange={handleChange}
                            value="gratis"
                            type="radio"
                            id="gratis"
                            name="envio"
                            checked={envio === "gratis"}
                            onClick={() => {
                              setIsDisabled(true);
                            }}
                          ></input>
                          <p className="ml-2 self-center">Envío gratis</p>
                        </div>
                        <div className="flex mb-2">
                          <input
                            className="w-6 h-6 mt-3"
                            onChange={handleChange}
                            value="costo"
                            type="radio"
                            id="costo"
                            name="envio"
                            checked={envio === "costo"}
                            onClick={() => {
                              setIsDisabled(false);
                            }}
                          ></input>
                          <p className="ml-2 self-center">Costo fijo</p>
                           <input
                            onChange={handleChange}
                            value={costo_envio}
                            name={"costo_envio"}
                            type="number"
                            placeholder="$0.00"
                            className="w-40 border border-gray-400 p-3 rounded  text-base placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 ml-2 transition-shadow"
                            disabled={isDisabled}
                          /> 

                          {/*                       <p className="ml-2 self-center">
                        envíos gratis desde los
                      </p>

                      <input
                        onChange={handleChange}
                        value={desde_envio}
                        name={"desde_envio"}
                        type="text"
                        placeholder="$0.00"
                        className="w-40 border border-gray-400 p-3 rounded  text-base leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 ml-2 transition-shadow"
                        disabled={isDisabled}
                      /> */}
                        </div>
                        <div className="flex mb-2">
                          <input
                            className="w-6 h-6"
                            onChange={handleChange}
                            value="posicionamiento"
                            type="radio"
                            id="posicionamiento"
                            name="envio"
                            checked={envio === "posicionamiento"}
                            onClick={() => {
                              setIsDisabled(true);
                            }}
                          ></input>
                          <p className="ml-2 self-center">
                            Calcular por geoposicionamiento
                          </p>
                        </div>
                        <div className="flex mb-2 mt-5">
                          <input
                            className="w-6 h-6"
                            onChange={handleChange}
                            value="noenvio"
                            type="radio"
                            id="noenvio"
                            name="envio"
                            checked={envio === "noenvio"}
                            onClick={() => {
                              setIsDisabled(true);
                            }}
                          ></input>
                          <p className="ml-2 self-center">
                            Sin envios (recoger en tienda)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-2xl text-white flex justify-center mt-4">
                Legal
              </p>

              <div className="rounded-lg px-3 py-4 m-1 shadow-md max-w-screen-lg bg-gray-700 mt-4">
                <div className="md:p-4">
                  <div className="pl-2 pr-2">
                    {" "}
                    <div className="">
                      <p className="font-semibold text-sm text-gray-300 pt-2 pb-2">
                        Términos y condiciones
                      </p>

                      {/*                       <textarea
                        className="border border-gray-400 p-4 rounded leading-4 placeholder-gray-400 text-gray-600 focus:ring-blue-500 focus:ring-2 w-full transition-shadow h-44 text-xs"
                        id="terminos"
                        name="terminos"
                        placeholder="Escribe los términos y condiciones de la tienda"
                        value={terminos}
                        onChange={handleChange}
                      ></textarea> */}
                      <div className="bg-white ">
                        <ReactQuill
                          value={terminos}
                          onChange={handleChangeEditor}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-4">
                <button className="bg-purple-900 hover:bg-purple-700 w-full p-4 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-200 ease-in-out  transform hover:-translate-x hover:scale-105">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
