import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../../firebase";
import axios from "axios";
import { createServer } from 'http';
import { saveAs } from 'file-saver';
const ReactDomServer = require("react-dom/server");
var xml2js = require('xml2js');
const Sitemap = require('react-router-sitemap').default;
const { Route, Link } = require('react-router-dom');

const GenerateSitemap = () => {

  const [elementos, setElementos] = useState([]);

  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    //host

    //URLS ESTÁTICAS
    const urls = [
      { loc: window.location.origin, lastmod: Date(), priority: 0.5 },
      {
        loc: window.location.origin + "/nosotros",
        lastmod: Date(),
        priority: 0.5,
      },
      {
        loc: window.location.origin + "/contacto",
        lastmod: Date(),
        priority: 0.5,
      },
      {
        loc: window.location.origin + "/login",
        lastmod: Date(),
        priority: 0.5,
      },
      {
        loc: window.location.origin + "/registro",
        lastmod: Date(),
        priority: 0.5,
      },
    ];

    //URLS DE PRODUCTOS

    const obtenerProductos = async () => {
      const productosRef = firebase.db.collection("productos");
      const snapshot = await productosRef.get();
      if (snapshot.empty) {
        console.log("No matching documents.");
        return;
      }

      const arreglo = [...elementos];

      snapshot.forEach((doc) => {
        const objeto = {
          loc: window.location.origin + "/producto/" + doc.id,
         // lastmod: doc.data().lastmod,
         lastmod: Date(),
         priority: 0.5,
        };

        arreglo.push(objeto);

/*         var builder = new xml2js.Builder();
        var xml = builder.buildObject(objeto);

        setElemento(xml) */


      });

      setElementos([...urls, ...arreglo]);


      console.log(elementos)
    };

    obtenerProductos();




/*         axios({
          method: "POST",
          url: "https://us-central1-mystore-d7058.cloudfunctions.net/generateSiteMap",
          data: {
            sitemap:sitemap
          },
          timeout: 5000,
        }).then(()=>{console.log("FUNCION INVOCADA")}).catch((err)=>{console.log("PELAS",err)}) */

/*         var FileSaver = require('file-saver');

        const someHTML=    ReactDomServer.renderToStaticMarkup(        
          <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
            {elementos.map((elemento) => {
              return (
                <url>
                  <loc>{elemento.loc}</loc>
                  <lastmod>{elemento.lastmod}</lastmod>
                  <priority>{elemento.priority}</priority>
                </url>
              );
            })}
          </urlset>
        ) */
           
/*         var file = new File([someHTML], "hello world.xml", {type: "text/xsl;charset=utf-8"});
        FileSaver.saveAs(file) */
  }, []);

  const generarMapa = () => {
    /*     const Animal = (props) => React.createElement("animal", props);
    const Owner = (props) => React.createElement("owner", props);
    const Pets = (props) => React.createElement("pets", props);

    const elementXML = ReactDomServer.renderToStaticMarkup(
      <Pets>
        <Owner name="Luke">
          <Animal type="guinea pig" name="Sparkles" />
        </Owner>
        <owner name="Neal">
          <Animal type="guinea pig" name="Wendy" />
          <Animal type="guinea pig" name="Renel" />
        </owner>
      </Pets>
    ); 
     // console.log(elementXML);
    */
    /* 
    const elementXML1 = ReactDomServer.renderToStaticMarkup(
      <url>
        <loc>https://revdev.mx/</loc>
        <lastmod>2023-03-08T14:12:10+00:00</lastmod>
        <priority>0.80</priority>
      </url>
    );

    console.log(elementXML1);

    setElemento(elementXML1); */
  };

  return (
    <>
    <button
    onClick={(e)=>{
     e.preventDefault();
     try {
      axios({
        method: "POST",
        url: "http://localhost:3001/mapa",
        data: {elementos: elementos},
      })
        .then(function (res) {
          console.log("todo ok")
        })
        .catch(function (error) {
          // An error happened.
          console.log(error);
        });
    } catch (error) {}

    }}
    >Generate</button>
{/*       {ReactDomServer.renderToStaticMarkup(        
        <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
          {elementos.map((elemento) => {
            return (
              <url>
                <loc>{elemento.loc}</loc>
                <lastmod>{elemento.lastmod}</lastmod>
                <priority>{elemento.priority}</priority>
              </url>
            );
          })}
        </urlset>
      )}  */}
    </>
  );
};

export default GenerateSitemap;
