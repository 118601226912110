import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../ui/Sidebar";
import ReactLoading from "react-loading";
import { FirebaseContext } from "../../firebase";
import Usuario from "../paginas/Usuario";
import UserMenu from "../ui/UserMenu";
import ProductosContext from "../../context/ProductosContext";
import CommentAdmin from "./CommentAdmin";
import _ from "lodash";
import Notifications from "../ui/Notifications";
import { useMediaQuery } from "react-responsive";


const CommentsAdmin = () => {
  //state que detecta si la pantalla es movil o de escritorio
  const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

  const [loading, setLoading] = useState(false);

  //state de usuario

  const { usuarioState, setUsuarioState } = React.useContext(ProductosContext);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  const [comentarios, guardarComentarios] = useState([]);

  const [searchterm, setSearchTerm] = useState("");

  useEffect(() => {
    const obtenerComentarios = () => {
      setLoading(true);
      firebase.db
        .collection("comentarios")
        .where("uidUsuario", "==", usuarioState.uidUsuario)
        .onSnapshot(manejarSnapshot);
    };
    obtenerComentarios();
  }, []);

  function manejarSnapshot(snapshot) {
    let comentarios = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    comentarios = _.orderBy(comentarios, "creado", "desc");

    guardarComentarios(comentarios);

    setLoading(false);
  }

  return (
    <div className="md:flex min-h-screen">
      <Sidebar />

      {loading === true ? (
        <div className="md:w-4/5 xl:w-4/5 bg-gray-900 flex justify-center items-center h-screen">
        <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="md:w-4/5 xl:w-4/5 p-6 bg-gray-900 h-screen">
          <header>
            <div className="flex justify-between mb-5">
              <div className="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 mt-1 mr-2 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <h1 className="text-3xl text-white">
                  Comentarios de {usuarioState.nombre}
                </h1>
              </div>
              <div className="flex">
                <div className="mt-2">
                  {isStatic ? <Notifications /> : null}
                </div>
                <div className=" z-50">{isStatic ? <UserMenu /> : null}</div>
              </div>
            </div>
          </header>
          {/*           <header className="pt-1">
            <nav className="flex justify-between w-full text-white p-1">
              <div></div>

              <div className="md:items-center md:w-auto flex">
                <div className="flex text-sm">
                  <div className="inline-flex flex-col justify-center relative text-gray-500 mr-3">
                    <div className="relative">
                      <input
                        type="text"
                        className="p-2 pl-8 rounded border border-blue-500 focus:bg-white focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:border-transparent"
                        placeholder="Busca un comentario"
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                      />
                      <svg
                        className="w-4 h-4 absolute left-2.5 top-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="black"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </div>
                  </div>


                  <UserMenu />
                </div>
              </div>
            </nav>
          </header> */}



          {comentarios
            .filter((comentario) => {
              if (searchterm == "") {
                return comentario;
              } else if (
                comentario.comentario
                  .toLowerCase()
                  .includes(searchterm.toLowerCase())
              ) {
                return comentario;
              }
            })
            .map((comentario, id) => {
              return (
                <div key={comentario.id} className="text-lg mt-4 mb-4">
                  <CommentAdmin key={comentario.id} comentario={comentario} />
                </div>
              );
            })}

          {/*                       <div className="text-lg mt-4 mb-4">                                  
                            {usuarios.map( usuario => (
                                <Usuario
                                    key={usuario.id}
                                    usuario={usuario}
                                />
                            ))}
                      </div>   */}
        </div>
      )}
    </div>
  );
};

export default CommentsAdmin;
