import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../ui/Sidebar";
import { FirebaseContext } from "../../firebase";
import Pedido from "../ui/Pedido";
import _ from "lodash";
import ReactLoading from "react-loading";
import ProductosContext from "../../context/ProductosContext";
import { useAuth } from "../../context/AuthContext";
import FrontHeader from "./FrontHeader";
import FrontFooter from "./FrontFooter";

const FrontPedidos = () => {
  const [loading, setLoading] = useState(false);

  const [searchterm, setSearchTerm] = useState("");

  //extraer el usuario de auth
  const { usuario } = useAuth();

  //state del select de filtro
  const [datos, guardarDatos] = useState({ filtro: "todos" });

  //extraer del state filtro
  const { filtro } = datos;

  //context de firebase
  const { firebase } = useContext(FirebaseContext);

  //state con los pedidos
  const [pedidos, guardarPedidos] = useState([]);

  useEffect(() => {
    const obtenerPedidos = () => {
      setLoading(true);

      firebase.db
        .collection("ordenes")
        .where("uidUsuario", "==", usuario.uid)
        .onSnapshot(manejarSnapshot);
      // firebase.db.collection('ordenes').onSnapshot(manejarSnapshot);
    };
    obtenerPedidos();
  }, []);

  function manejarSnapshot(snapshot) {
    let pedidos = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    //ordenar por categorias con lodash
    pedidos = _.orderBy(pedidos, "creado", "desc");

    guardarPedidos(pedidos);

    setLoading(false);
  }

  //lee la info del select
  const seleccion = (e) => {
    guardarDatos({ ...datos, [e.target.name]: e.target.value });

    if (e.target.value === "completado") {
      firebase.db
        .collection("ordenes")
        .where("completado", "==", true)
        .onSnapshot(manejarSnapshot);
    }
    if (e.target.value === "nocompletado") {
      firebase.db
        .collection("ordenes")
        .where("completado", "==", false)
        .onSnapshot(manejarSnapshot);
    }
    if (e.target.value === "todos") {
      firebase.db.collection("ordenes").onSnapshot(manejarSnapshot);
    }
  };

  return (
    <div className="md:flex min-h-screen">
      {loading === true ? (
        <div className="w-full bg-black flex justify-center items-center h-screen">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="w-full bg-gray-900">
          <FrontHeader />

          {pedidos.length == 0 ? (
            <div className="flex flex-col text-4xl m-80 p-12  bg-gray-900 rounded-lg text-white">
              <p className=" justify-center text-center">No hay pedidos que mostrar</p>
              <p className=" text-base cursor-pointer mt-2 text-center font text-decoration: underline">
                Volver a a la tienda
              </p>
            </div>
          ) : (
            <>
              {pedidos
                .filter((pedido) => {
                  if (searchterm == "") {
                    return pedido;
                  } else if (
                    pedido.nombre
                      .toLowerCase()
                      .includes(searchterm.toLowerCase()) ||
                    pedido.id
                      .toLowerCase()
                      .includes(searchterm.toLowerCase()) ||
                    pedido.calle
                      .toLowerCase()
                      .includes(searchterm.toLowerCase())
                  ) {
                    return pedido;
                  }
                })
                .map((pedido, id) => {
                  return (
                    <div
                      key={pedido.id}
                      className="text-lg xs:m-2 sm:m-2 md:mt-2 lg:m-4 xl:m-4"
                    >
                      <Pedido pedido={pedido} />
                    </div>
                  );
                })}
            </>
          )}

          <FrontFooter />
        </div>
      )}
    </div>
  );
};

export default FrontPedidos;
