import React, { useState, useContext, useRef } from "react";
import { FirebaseContext } from "../../firebase";
import { useNavigate } from "react-router-dom";
import Alerta from "../ui/Alerta";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import cart from "../../images/cart.png";
import toast, { Toaster } from "react-hot-toast";
import PedidoContext from "../../context/pedidos/pedidosContext";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import ReactLoading from "react-loading";
import { set } from "lodash";

const Login = () => {
  const [correo, establecerCorreo] = useState("");
  const [password, establecerPassword] = useState("");
  const [estadoAlerta, cambiarEstadoAlerta] = useState(false);
  const [alerta, cambiarAlerta] = useState({});
  const [captcha, setCaptcha] = useState(false);
  const [loading, setLoading] = useState(false);

  const captchaRef = useRef(null);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //Context del pedido
  const {
    pedido,
    mostrarResumen,
    eliminarProducto,
    pedidoRealizado,
    guardarPedido,
  } = useContext(PedidoContext);

  //hook para redireccionar
  const navigate = useNavigate();

  const handleChange = (e) => {
    switch (e.target.name) {
      case "email":
        establecerCorreo(e.target.value);
        break;
      case "password":
        establecerPassword(e.target.value);
        break;
      default:
        break;
    }
  };

  const resolveCaptcha = async () => {
    const token = captchaRef.current.getValue();

    await axios
      .get(
        `${"https://us-central1-mystore-d7058.cloudfunctions.net/sendRecaptcha"}?token=${token}`
      )
      .then(function (response) {
        //true si es humano, false si es robot

        //Take action here based on score.

        setCaptcha(response.data.score);
      })
      .catch(function (error) {
        console.log("error: ", error);
        return;
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    cambiarEstadoAlerta(false);
    cambiarAlerta({});

    //captcha

    //  const inputVal = await e.target[0].value;
    /*     const token = captchaRef.current.getValue();
    captchaRef.current.reset(); */

    //funcion para un servidor local
    /*     axios({
      method: "POST",
      url: "http://localhost:3001/captcha",
      data: { token: token },
    })
      .then(function (res) {
        console.log(res.data)
      })
      .catch(function (error) {
        // An error happened.
        console.log(error);
      }); */

    setLoading(true);

    /* 
    try {

      const response = await axios.get(
        `${"https://us-central1-mystore-d7058.cloudfunctions.net/sendRecaptcha"}?token=${token}`
      );

      console.log(response.data.score);
      //validar formulario

      if (correo === "" || password === "" || !response.data.score) {
        if (correo === "") {
          toast.error("Correo no válido");
        }

        if (password === "") {
          toast.error("El password no es válido");
        }

        if (!captcha) {
          toast.error("El captcha no es válido");
        }

        cambiarEstadoAlerta(true);
        toast.error("Todos los campos son obligatorios");

        cambiarAlerta({
          titulo: "Todos los campos son obligatorios",
        });

        setLoading(false);
        return;
      }

    } catch (error) {
      // Handle errors
      console.log("HUBO UN ERROR")
    } */

    /*     axios
      .get(
        `${"https://us-central1-mystore-d7058.cloudfunctions.net/sendRecaptcha"}?token=${token}`
      )
      .then(function (response) {

        //true si es humano, false si es robot

        //Take action here based on score.

        console.log(response.data.score)

        setCaptcha(response.data.score)

        console.log(captcha)

      })
      .catch(function (error) {
        console.log("error: ", error);
        return;
      }); */

    if (
      correo === "" ||
      password === "" ||
      captcha === false 
    ) {
      if (correo === "") {
        toast.error("Correo no válido");
      }

      if (password === "") {
        toast.error("El password no es válido");
      }

      if (captcha === false) {
        toast.error("El captcha no es válido");
      }

      cambiarEstadoAlerta(true);
      toast.error("Todos los campos son obligatorios");

      cambiarAlerta({
        titulo: "Todos los campos son obligatorios",
      });

      captchaRef.current.reset();

      setLoading(false);

      return;
    } else {
      try {
        //insertar el registro actividad de usuario en Firebase
        await firebase.auth
          .signInWithEmailAndPassword(correo, password)
          .then((respuesta) => {
            const activityObj = {
              uidUsuario: respuesta.user.uid,
              activity: "El usuario entró en el sistema",
              fecha: Date.now(),
              code: "01",
            };

            firebase.db.collection("usuarios_log").add(activityObj);

            //carga el carrito de compras
            //verifica que esté el usuario auth y su pedido sin items y su carro no tenga items

            //lo ponemos en el state del pedido
            const dbRef = firebase.db
              .collection("cart")
              .doc(respuesta.user.uid)
              .get()
              .then((doc) => {
                //firebase nos entrega un objeto que debe ser convertido a un arreglo
                //console.log("DOC DATA ES", doc.data());

                if (doc.data()) {
                  //convertimos de Objeto a un Arreglo, es que el formto del carrito
                  const newArrayDataOfObject = Object.values(doc.data());

                  //console.log("EL ARREGLO ES", newArrayDataOfObject);

                  //por cada item del pedido
                  newArrayDataOfObject.forEach((item) => {
                    const pedidoSpread = { ...item };
                    //al guardar el pedido se mete item por item al pedido
                    guardarPedido(pedidoSpread);
                  });
                }
              });

            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              title: "<p style='color:#22c55e'>" + "Bienvenido" + "</p>",
              //        html: '<p style="color:#fff">Se guardó la configuración de la tienda</p>',
              icon: "success",
              color: "#fff",
              background: "#18181b",
              position: "top-end",
              iconColor: "#22c55e",
              timer: 1500,
              showConfirmButton: false,
            });

            setLoading(false);

            //redireccionar con useNavigate
            navigate("/");
          });
      } catch (error) {

        setCaptcha(false)
        cambiarEstadoAlerta(true);
        let mensaje;

        //mensajes de error en Firebase
        switch (error.code) {
          case "auth/user-not-found":
            toast.error("El correo no está asociado a una cuenta");
            mensaje = "El correo no está asociado a una cuenta";
            setLoading(false);

            break;

          case "auth/wrong-password":
            toast.error("La contraseña es incorrecta");
            mensaje = "La contraseña es incorrecta";
            setLoading(false);

            break;

          default:
            toast.error("No se pudo acceder al sistema");
            mensaje = "No se pudo acceder al sistema";
            setLoading(false);

            break;
        }

        cambiarAlerta({ titulo: mensaje });
      }
    }
  };

  const loginGoogle = () => {
    //Auth con Google
    var provider = new firebase.authGoogle.GoogleAuthProvider();
    firebase.auth
      .signInWithPopup(provider)
      .then((respuesta) => {
        navigate("/");
      })
      .catch((err) => console.log(err));
  };

  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }

  function onChange(value) {
    //  console.log("Captcha value:", value);
    resolveCaptcha();
  }

  return (
    <>
      <div>
        <Toaster position="bottom-center" />{" "}
        <div className="relative min-h-screen flex flex-col justify-center items-center bg-gray-900 p-10">
          <div className="relative sm:max-w-sm w-full">
            <div className="card bg-yellow-500 shadow-lg w-full h-full rounded-3xl absolute transform -rotate-6"></div>
            <div className="card bg-green-400 shadow-lg w-full h-full rounded-3xl absolute transform rotate-6"></div>
            <div className="relative w-full rounded-3xl px-6 py-4 bg-gray-100 shadow-md ">
              <div className="flex justify-center pt-5">
                <img
                  onClick={() => {
                    navigate("/");
                  }}
                  className="block h-8 w-auto cursor-pointer"
                  src={cart}
                  alt="Revdev Store"
                />
                <p className="text-black mb-5 text-xl pl-2">The Revdev Store</p>
              </div>
              <label className="block text-gray-700 text-center text-3xl font-light mb-6">
                Login
              </label>
              <form onSubmit={handleSubmit} className="mt-5">
                <Alerta
                  titulo={alerta.titulo}
                  mensaje={alerta.mensaje}
                  estadoAlerta={estadoAlerta}
                  cambiarEstadoAlerta={cambiarEstadoAlerta}
                />

                {loading ? (
                  <div className="flex justify-center">
                    <ReactLoading color={"#22C55E"} type={"spin"} />
                  </div>
                ) : (
                  <div>
                    <div>
                      <input
                        onChange={handleChange}
                        value={correo}
                        name="email"
                        type="email"
                        placeholder="Correo"
                        className="w-full mt-1 block  border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-5 ring-1 focus:outline-none xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base"
                      />
                    </div>

                    {/*                 <div className="mt-5">
                  <input
                    onChange={handleChange}
                    value={password}
                    name="password"
                    type="password"
                    placeholder="Contraseña"
                    className="w-full mt-1 block  border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-5 ring-1 focus:outline-none xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base"
                  />
                </div> */}

                    <div className="relative container mx-auto  mt-4">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        onChange={handleChange}
                        value={password}
                        name="password"
                        placeholder="Contraseña"
                        className="w-full mt-1 block  border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:ring-blue-500 focus:ring-2 pl-5 ring-1 focus:outline-none xs:text-xs sm:text-sm md:text-base lg:text-base xl:text-base"
                      />
                      <div
                        className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                        onClick={togglePasswordVisibility}
                      >
                        {isPasswordVisible ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        )}
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onChange={onChange}
                        ref={captchaRef}
                      />
                    </div>
                    <div className="mt-6">
                      <button className="bg-blue-500 w-full py-3 rounded-xl text-white shadow-xl focus:outline-none transition duration-200 ease-in-out  transform hover:-translate-x hover:scale-105">
                        Login
                      </button>
                    </div>
                  </div>
                )}

                <div className="mt-6 flex justify-center items-center">
                  <Link
                    to="/passwordreset"
                    className="text-gray-500 hover:text-gray-900 transition duration-200 ease-in-out  transform hover:-translate-x hover:scale-105 text-sm"
                  >
                    ¿Olvidaste tu contraseña?
                  </Link>
                </div>

                {/*<div className="flex mt-6 items-center text-center">
                  <label className="block font-medium text-sm text-gray-700 w-full">
                    Accede con
                  </label>
                </div> */}
                {/* 
                <div className="flex mt-1 justify-center w-full">
                  <Link
                    to="/login"
                    className="rounded-lg text-white bg-blue-700 hover:bg-blue-800 p-3 mr-5 text-sm mt-3 mb-5 inline-flex items-center"
                  >
                    <svg
                      className="w-5 h-5 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width=""
                      height=""
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 -1 26 26"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
                      />
                    </svg>
                    Facebook
                  </Link>

                  <button
                    onClick={loginGoogle}
                    className="rounded-lg text-white bg-red-600 hover:bg-red-700 p-3 text-sm mt-3 mb-5 inline-flex items-center"
                  >
                    <svg
                      className="w-5 h-5 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width=""
                      height=""
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 48 48"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                      />
                    </svg>
                    Google
                  </button>
                </div>
 */}
                <div className=" flex justify-center mt-4 mb-4">
                  <div className="">
                    <label className="text-sm text-gray-800 mb-3 ">
                      ¿Eres nuevo?
                    </label>
                    <Link
                      to="/registro"
                      className=" text-blue-500 transition duration-200 ease-in-out transform hover:-translate-x hover:scale-105 text-sm ml-4"
                    >
                      Crear cuenta
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
