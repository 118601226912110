import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Transition } from "@headlessui/react";
import cart from "../../images/cart.png";
import UserMenu from "../ui/UserMenu";
import Notifications from "../ui/Notifications";

const Sidebar = () => {
  const navigate = useNavigate();

  const [isClosed, setClosed] = useState(true);

  const isStatic = useMediaQuery({ query: "(min-width: 640px)" });

  return (
    <div className="sm:-1/5 md:w-1/5 xl:w-1/5 bg-gradient-to-tl from-gray-700 via-gray-900 to-black shadow-2xl border-r-2 border-gray-800">
      <div className="flex xs:pt-2 sm:pt-2 lg:pt-4 xs:justify-around  md:justify-center lg:justify-center xl:justify-center pb-3 ">
        <div className="flex mt-1">
          {isClosed ? (
            <button onClick={() => setClosed(false)}>
              <svg
                className="text-yellow-400 w-10 h-10 p-1 lg:hidden xl:hidden outline-none border-solid"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          ) : (
            <button onClick={() => setClosed(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=" text-yellow-400 w-10 h-10 p-1 lg:hidden xl:hidden outline-none"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </div>

        <div className="flex md:mt-2 lg:border-b lg:border-gray-800 lg:pb-2">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="ml-2 w-12 pl-2 xs:w-10 xs:h-8 cursor-pointer self-center"
            src={cart}
            alt="The Revdev Store"
          />
          <p
            onClick={() => {
              navigate("/");
            }}
            className="ml-3 text-white cursor-pointer xs:text-base md:text-2xl self-center"
          >
            The Revdev Store®
          </p>
        </div>

        <div className="flex">
          <div className="mt-2 mr-1">
            {!isStatic ? <Notifications /> : null}
          </div>
          {!isStatic ? <UserMenu /> : null}
        </div>
      </div>
      <Transition
        show={isStatic || !isClosed}
        enter="transition-all duration-400  transform opacity-0"
        enterFrom="-mt-64"
        enterTo="mt-0"
        leave="transition-all duration-200 transform opacity-0 "
        leaveTo="-mt-80"
      >
        <div className="p-6 ">
          <nav>
            <NavLink
              className="rounded-lg p-3 text-white hover:text-white hover:bg-green-500 mb-2 flex transition duration-200 ease-in-out"
              activeClassName="text-white bg-yellow-500"
              exact="true"
              to="/pedidos"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                />
              </svg>
              Pedidos
            </NavLink>
            <NavLink
              className="rounded-lg p-3 text-white hover:text-white  hover:bg-green-500 mb-2 flex transition duration-200 ease-in-out"
              activeClassName="text-white bg-yellow-500"
              exact="true"
              to="/catalogo"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6  mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                />
              </svg>
              Catalogo
            </NavLink>

            <NavLink
              className="rounded-lg p-3 text-white hover:text-white  hover:bg-green-500 mb-2 flex transition duration-200 ease-in-out"
              activeClassName="text-white bg-yellow-500"
              exact="true"
              to="/categorias"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                />
              </svg>
              Categorias
            </NavLink>

            <NavLink
              className="rounded-lg p-3 text-white hover:text-white  hover:bg-green-500 mb-2 flex transition duration-200 ease-in-out"
              activeClassName="text-white bg-yellow-500"
              exact="true"
              to="/reportes"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6  mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
              Reportes
            </NavLink>
            <NavLink
              className="rounded-lg p-3 text-white hover:text-white  hover:bg-green-500 mb-2 flex transition duration-200 ease-in-out"
              activeClassName="text-white bg-yellow-500"
              exact="true"
              to="/usuarios"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6  mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              Usuarios
            </NavLink>

            <NavLink
              className="rounded-lg p-3 text-white  hover:text-white  hover:bg-green-500 mb-2 flex transition duration-200 ease-in-out"
              activeClassName="text-white bg-yellow-500"
              exact="true"
              to="/configuracion"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6  mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              Configuración
            </NavLink>
          </nav>
        </div>
      </Transition>
      <footer className=" font-light text-gray-500 text-xs fixed m-2 inset-x-0 bottom-0">
        Revdev México® {new Date().getUTCFullYear()}{" "}
      </footer>
    </div>
  );
};

export default Sidebar;
