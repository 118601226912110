// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDhyZXnUA9mc94GaErkjo_mKXcWLEtmZP0",

  authDomain: "revdevstorecustomers.firebaseapp.com",

  projectId: "revdevstorecustomers",

  storageBucket: "revdevstorecustomers.appspot.com",

  messagingSenderId: "674856869864",

  appId: "1:674856869864:web:4f3508a12a634913228e5d",

  measurementId: "G-2MGC9HDRQT",
};

export default firebaseConfig;
