import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import cart from "../../images/cart.png";
import { FirebaseContext } from "../../firebase";

const FrontFooter = () => {
  const navigate = useNavigate();

  const handleVer = (categoria) => {
    //   setProductoState(producto)
    const ruta = "/categoria/" + categoria;

    navigate(ruta);
  };

  const [loading, setLoading] = useState(false);

  const [treeData, setTreedata] = useState({
    currentNode: {},
    searchFocusIndex: 0,
    treeData: [
      {
        title: "Default",
        children: [],
      },
    ],
  });

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //State que guarda las categorias
  const [categorias, guardarCategorias] = useState([]);

  useEffect(() => {
    setLoading(true);

    //obtiene las categorias
    const db = firebase.db;
    const collectionRef = db.collection("categorias");

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const categorias = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      guardarCategorias(categorias);

      //SI DATOS CONTIENE CATEGORIAS LAS PONE EN EL STATE TREEDATA

      if (Object.keys(categorias).length > 0) {
        setTreedata({ treeData: categorias[0].treeData });
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <footer className="text-gray-400 bg-gray-900 body-font">
      <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <a className="flex title-font font-medium items-center md:justify-start justify-center text-white">
            <img className=" float-left h-full" width={48} src={cart} />
            <span className="ml-3 text-xl">Revdev Store</span>
          </a>
          <p className="mt-2 text-sm text-gray-500 text-center">
            Tus ventas, nuestro negocio
          </p>
        </div>
        <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h3 className="title-font font-medium text-white tracking-widest text-sm mb-3">
              CATEGORIAS
            </h3>

            {treeData.treeData.map((nodo, id) => {
              const { title } = nodo;
              var categoria = title;
              return (
                <div className="text-white m-2" key={id}>
                  <p
                    className="font-bold cursor-default hover:text-yellow-500"
                    onClick={() => navigate("/categoria/" + title)}
                  >
                    {title}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3 cursor-default">
              DIRECCIÓN
            </h2>
            <nav className="list-none mb-10 cursor-default">
              <li>
                <p className="text-gray-400 hover:text-white">
                  Sara Bertha de la Torre 5506
                </p>
              </li>
              <li>
                <p className="text-gray-400 hover:text-white">Paseos del Sol</p>
              </li>
              <li>
                <p className="text-gray-400 hover:text-white">
                  Zapopan, Jalisco, México
                </p>
              </li>
              <li>
                <p className="text-gray-400 hover:text-white">CP. 45070</p>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3 cursor-default">
              CONTACTO
            </h2>
            <nav className="list-none mb-10 cursor-default">
              <li>
                <p className="text-gray-400 hover:text-white">
                  Teléfono: 333 475 8263
                </p>
              </li>
              <li>
                <p className="text-gray-400 hover:text-white">
                  Correo: contacto@revdev.mx
                </p>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3 cursor-default">
              SOBRE NOSOTROS
            </h2>
            <nav className="list-none mb-10 cursor-default">
              <li>
                <a className="text-gray-400 hover:text-white">
                  Políticas de venta
                </a>
              </li>
              <li>
                <a className="text-gray-400 hover:text-white">
                  Soporte de ventas
                </a>
              </li>
              <li>
                <a className="text-gray-400 hover:text-white">
                  Información de privacidad
                </a>
              </li>
              <li>
                <a className="text-gray-400 hover:text-white">Uso de datos</a>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-gray-800 bg-opacity-75">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-gray-400 text-sm text-center sm:text-left">
            ©Revdev México {new Date().getUTCFullYear()} -
            <a
              href="https://twitter.com/revdevmexico"
              rel="noopener noreferrer"
              className="text-gray-500 ml-1"
              target="_blank"
            >
              @revdevmexico
            </a>
          </p>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
            <a className="text-gray-400">
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a className="ml-3 text-gray-400">
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a className="ml-3 text-gray-400">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
            <a className="ml-3 text-gray-400">
              <svg
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default FrontFooter;
