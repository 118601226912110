import React, { useContext, useState, useEffect } from "react";
import { FirebaseContext } from "../../firebase";
import { useAuth } from "../../context/AuthContext";
import ProductosContext from "../../context/ProductosContext";
import Swal from "sweetalert2";
import FrontHeader from "./FrontHeader";
import FrontFooter from "./FrontFooter";
import PedidoContext from "../../context/pedidos/pedidosContext";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Comments from "./Comments";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import Cart from "../paginas/Cart";
import "react-medium-image-zoom/dist/styles.css";
import ReactImageMagnify from "react-image-magnify";
import SearchAlgoliaFront from "../ui/SearchAlgoliaFront";
import Rating from "../ui/Rating";
import { FirebaseContextRevdev } from "../../firebaseRevdev";
import styled from "styled-components";
import MyRating from "../ui/MyRating";
import cart from "../../images/cart.png";
//import { Helmet } from 'react-helmet-async';
import { Helmet } from 'react-helmet';


const MyReactImageMagnify = styled(ReactImageMagnify).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
})`
  img {
    border-radius: 2%;
  }
`;

const VerProducto = () => {
  //Context con las operaciones de firebase de Revdev
  //diferencia es el número de días que faltan para que expire la subscripción
  const { diferencia } = useContext(FirebaseContextRevdev);

  const [datos, guardarDatos] = useState([]);

  const navigate = useNavigate();

  //obtengo el id del producto desde la URL

  const { id } = useParams();

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const [loading, setLoading] = useState(false);

  //extraer el usuario de auth
  const { usuario } = useAuth();

  const [settings, guardarSettings] = useState([""]);

  //state que guarda si el usuario está habilitado o no

  const [enabled, isEnabled] = useState(true);

  useEffect(() => {
    if (usuario) {
      usuario.getIdTokenResult().then((idTokenResult) => {
        isEnabled(idTokenResult.claims.habilitado);
        //    console.log(enabled);
      });
    }
  }, []);

  useEffect(() => {
    //obtiene los settings
    const db = firebase.db;
    const collectionRef = db.collection("settings");

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const settings = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      guardarSettings(settings);
    });

    return () => unsubscribe();
  }, []);

  //state de la imagen del producto

  const [previewImage, setPreviewImage] = useState("");

  //pedido context

  const {
    pedido,
    eliminarProducto,
    pedidoRealizado,
    guardarPedido,
    pedidoTotal,
  } = useContext(PedidoContext);

  const { totalPedido, guardarTotalPedido } = useContext(ProductosContext);

  //state para cantidades y pedido

  const [cantidad, guardarCantidad] = useState(1);
  const [total, guardarTotal] = useState(0);
  // const [totalPedido, guardarTotalPedido] = useState(0);

  //<--hasta aqui el state lo relacionado a pedidos

  //Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);

  //consulta la base de datos al cargar

  useEffect(() => {
    //se va hacia arriba de la pantalla
    window.scrollTo(0, 0);

    setLoading(true);

    //DATOS DEL PRODUCTO

    const obtenerDatos = () => {
      var docRef = firebase.db.collection("productos").doc(id);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log('DESDE LA CONSULTA DE FIREBASE', doc.id)
            guardarDatos({ ...doc.data(), id: id });
            setLoading(false);
          } else {
            // doc.data() will be undefined in this case
            console.log("No se encontro este producto!");

            navigate("/page404");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    };

    //se ejecuta la funcion para obtener datos

    obtenerDatos();
  }, []);

  useEffect(() => {
    calcularTotal();
  }, [cantidad]);

  useEffect(() => {
    calcularTotal();
    //cambia la imagen de preview
    setPreviewImage(datos.imagen);
  }, [datos]);

  //calcula el total de un articulo individual
  const calcularTotal = () => {
    if (datos.descuento > 0) {
      var totalPagar =
        (
          datos.precio.toFixed(2) -
          datos.precio.toFixed(2) * (datos.descuento / 100)
        ).toFixed(2) * cantidad;
    } else {
      var totalPagar = datos.precio * cantidad;
    }

    guardarTotal(totalPagar);
  };

  //calcula el total del pedido

  const calcularTotalPedido = () => {
    let suma = 0;

    pedido.forEach((item) => {
      if (item.descuento > 0) {
        let precio_final = item.precio - item.precio * (item.descuento / 100);
        suma = suma + precio_final.toFixed(2) * item.cantidad;
      } else {
        suma = suma + item.precio * item.cantidad;
      }
    });

    if (settings[0].envio == "costo" && Object.keys(pedido).length > 0) {
      suma = suma + settings[0].costo_envio;
    }

    guardarTotalPedido(suma);
  };

  //decrementa 1
  const decrementarUno = () => {
    if (cantidad > 1) {
      const nuevaCantidad = parseInt(cantidad) - 1;
      guardarCantidad(nuevaCantidad);
    }
  };

  //incrementa 1
  const incrementarUno = () => {
    if (datos.noAplicaStock) {
      const nuevaCantidad = parseInt(cantidad) + 1;
      guardarCantidad(nuevaCantidad);
    } else {
      if (cantidad < datos.stock) {
        const nuevaCantidad = parseInt(cantidad) + 1;
        guardarCantidad(nuevaCantidad);
      }
    }
  };

  //CHECA SI EL PRODUCTO YA ESTÁ EN EL CARRITO
  const checkProduct = (pedido) => {
    //inicalizacion de alerta

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    ///checa si ya está en el carrito o no

    const check = pedido.find((producto) => producto.id === id);

    if (check !== undefined) {
      //    console.log("found it");

      //en que posición está
      const posicionFn = (producto) => producto.id === id;

      //  console.log(pedido.findIndex(posicionFn));

      const posicion = pedido.findIndex(posicionFn);

      //     console.log(posicion);

      //obtener valor de cantidad en esa posicion

      const cantidadCart = pedido[posicion].cantidad;

      //  console.log(cantidadCart);

      //cantidad actual a añadir
      //   console.log(cantidad);

      //nueva cantidad

      let nuevaCantidad = cantidad + cantidadCart;

      //    console.log(nuevaCantidad);

      pedido[posicion].cantidad = nuevaCantidad;

      console.log(
        "La nueva cantidad en el pedido es de",
        pedido[posicion].cantidad
      );

      //    console.log("pedido", pedido);

      //Recalcula el total del producto
      if (pedido[posicion].descuento > 0) {
        var totalPagar =
          (
            pedido[posicion].precio.toFixed(2) -
            pedido[posicion].precio.toFixed(2) *
              (pedido[posicion].descuento / 100)
          ).toFixed(2) * nuevaCantidad;
      } else {
        var totalPagar = pedido[posicion].precio * nuevaCantidad;
      }

      pedido[posicion].total = totalPagar;

      calcularTotalPedido();

      /* 
       //recalcula el total

      let nuevoTotal = 0;
      nuevoTotal = pedido.reduce(
        (nuevoTotal, articulo) => nuevoTotal + articulo.total,
        0
      );

      console.log("El nuevo total es:", nuevoTotal);

      guardarTotalPedido(nuevoTotal);

      console.log("En el estate", totalPedido); */

      Toast.fire({
        icon: "success",
        title: "<p style='color:#22c55e'>" + "Se añadió" + "</p>",
        text: cantidad + " x " + pedido[posicion].nombre,
        html:
          '<p style="color:#fff">' +
          cantidad +
          " x " +
          pedido[posicion].nombre +
          "</p>",
        color: "#fff",
        background: "#18181b",
        position: "bottom-end",
        iconColor: "#22c55e",
      });

      return;
    } else {
      console.log("not found it");
      calcularTotal();
    }

    const pedidoSpread = { ...datos, cantidad, total };
    guardarPedido(pedidoSpread);

    Toast.fire({
      icon: "success",
      title: "<p style='color:#22c55e'>" + "Se añadió" + "</p>",
      text: cantidad + " x " + pedidoSpread.nombre,
      html:
        '<p style="color:#fff">' +
        cantidad +
        " x " +
        pedidoSpread.nombre +
        "</p>",
      color: "#000",
      background: "#18181b",
      position: "bottom-end",
      iconColor: "#22c55e",
    });

    return;
  };

  //añade al carrito
  const addCart = () => {
    //almacenar el pedido al carrito de compras

    if (Number.isNaN(total) === true) {
      return null;
    }
    ///   const pedidoTemp = [...pedido];
    checkProduct(pedido);
  };

  //cambia la imagen
  const changeImage = () => {
    setPreviewImage(datos.imagen);
  };

  const props = {
    width: 546,
    height: 340,
    zoomPosition: "right",
    zoomWidth: 500,
    img: datos.imagen,
  };

  const titulo = "The Revdev Store - " +  datos.nombre

  return (
    <>
      <Helmet>
        <title>{titulo}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content={datos.descripcion}
        />
        <meta itemprop="image" content={datos.imagen} />

        {/* Open Graph / Facebook  */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={titulo} />
        <meta
          property="og:description"
          content={datos.descripcion}
        />
        <meta property="og:image" content={datos.imagen} />

        {/* Twitter  */}
        <meta property="twitter:card" content={datos.imagen} />
        <meta property="twitter:url" content={window.location.href}/>
        <meta property="twitter:title" content={titulo} />
        <meta
          property="twitter:description"
          content={datos.descripcion}
        />
        <meta property="twitter:image" content={datos.imagen} />
      </Helmet>

      <FrontHeader toggleDrawer={toggleDrawer} />

      <div>
        {" "}
        <SearchAlgoliaFront />
      </div>

      <Drawer size={350} open={isOpen} onClose={toggleDrawer} direction="right">
        <Cart isOpen={isOpen} setIsOpen={setIsOpen} />
      </Drawer>

      {loading === true ? (
        <div className="flex justify-center items-center h-screen">
          <ReactLoading color={"#22C55E"} type={"spin"} />
        </div>
      ) : (
        <div className="p-2 bg-gray-800">
          <div className="mx-auto bg-gray-200 rounded-xl shadow-xl overflow-hidden max-w-screen-2xl  ">
            <div className="md:flex">
              <div className="flex flex-col md:max-w-md lg:max-w-2xl">
                <div className="md:flex-shrink-0">
                  {/*             <img
                  className=" h-full w-full object-cover md:w-96 rounded-md"
                  src={previewImage}
                  alt={datos.nombre}
                /> */}
                  <div className="shadow-md rounded-lg p-2">
                    <MyReactImageMagnify
                      {...{
                        smallImage: {
                          alt: datos.nombre,
                          width: 640,
                          height: 480,
                          src: previewImage,
                          isFluidWidth: true,
                        },
                        largeImage: {
                          src: previewImage,
                          width: 2000,
                          height: 960,
                        },
                        enlargedImageContainerDimensions: {
                          width: "200%",
                          height: "100%",
                        },
                      }}
                    />
                  </div>
                </div>

                <div className="grid grid-flow-row grid-cols-2 gap-3 justify-between sm:grid-cols-2 md:grid-cols-5 m-4 ">
                  <div
                    className="border-green-500 rounded-md border p-1 cursor-pointer hover:border-yellow-600"
                    onMouseOver={changeImage}
                  >
                    <img
                      className=" h-full w-full object-cover md:w-96"
                      src={datos.imagen}
                    />
                  </div>

                  {datos.images !== undefined ? (
                    <>
                      {" "}
                      {datos.images.map((imagen) => {
                        return (
                          <div
                            className="border-green-500 rounded-md border p-1 cursor-pointer hover:border-yellow-600"
                            onMouseOver={() => {
                              setPreviewImage(imagen.urlimagen);
                            }}
                          >
                            <img
                              className=" h-full w-full object-cover md:w-96"
                              src={imagen.urlimagen}
                              alt={imagen.altText}
                            />
                          </div>
                        );
                      })}{" "}
                    </>
                  ) : null}
                </div>
              </div>

              <div className="w-full pl-8 pt-4">
                <Rating id={id} />

                <h1 className="text-3xl text-gray-800 mb-2 mt-4">
                  {datos.nombre}
                </h1>
                <p className="block mt-2 text-base leading-tight font-medium text-gray-700 hover:underline cursor-pointer">
                  {datos.marca}
                </p>
                <div className="flex">
                  <p className="block mt-2 text-sm leading-tight font-medium text-gray-700 hover:underline cursor-pointer">
                    {datos.categoria}
                  </p>
                  {datos.subcategoria !== "" ? (
                    <p className="block mt-2 text-sm pl-1 pr-1 leading-tight font-medium text-gray-700 hover:underline cursor-pointer">
                      /
                    </p>
                  ) : null}

                  <p className="block mt-2 text-sm leading-tight font-medium text-gray-700 hover:underline cursor-pointer">
                    {datos.subcategoria}
                  </p>
                  {datos.subsubcategoria !== "" ? (
                    <p className="block mt-2 text-sm pl-1 pr-1 leading-tight font-medium text-gray-700 hover:underline cursor-pointer">
                      /
                    </p>
                  ) : null}
                  <p className="block mt-2 text-sm leading-tight font-medium text-gray-700 hover:underline cursor-pointer">
                    {datos.subsubcategoria}
                  </p>
                </div>
                <p className="mt-2 text-gray-600 mr-4 text-sm">
                  {datos.descripcion}
                </p>
                <p className="mt-2 text-green-500 mb-4 text-sm">
                  {datos.hashtag}
                </p>

                {diferencia > 0 ? (
                  <div>
                    {datos.descuento > 0 ? (
                      <>
                        <div className="flex">
                          <p className="text-white mb-4 text-sm font-bold bg-green-600 rounded p-2">
                            Descuento:{" "}
                            <span className="text-white font-normal">
                              {datos.descuento}%
                            </span>
                          </p>
                        </div>

                        <div className="flex">
                          <p className="mb-3 text-xl font-bold line-through text-red-500">
                            MXN{" "}
                            <span className="text-red-500 font-bold">
                              {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                              }).format(datos.precio)}
                            </span>
                          </p>
                          <p className="text-gray-700 mb-3 text-xl font-bold pl-5">
                            MXN{" "}
                            <span className="text-gray-700 font-bold">
                              {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                              }).format(
                                datos.precio -
                                  datos.precio * (datos.descuento / 100)
                              )}
                            </span>{" "}
                          </p>
                        </div>
                      </>
                    ) : (
                      <p className="text-gray-700 mb-3 text-xl font-bold">
                        MXN{" "}
                        <span className="text-gray-700 font-bold">
                          {new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                          }).format(datos.precio)}
                        </span>
                      </p>
                    )}
                    {enabled === false ? (
                      <p className="text-base bg-red-500 w-auto text-gray-300 p-2 mt-2 mr-6 rounded-md xl:w-1/2">
                        Tu cuenta está deshabilitada y no puedes hacer compras
                      </p>
                    ) : (
                      <div>
                        {datos.stock > 0 || datos.noAplicaStock ? (
                          <div>
                            {datos.existencia ? (
                              <div>
                                <div className="grid grid-cols-3 pt-2 pb-2 w-56 xs:w-48 sm:w-48 lg:w-38">
                                  <button
                                    onClick={decrementarUno}
                                    className="rounded-md rounded-r-none  text-white text-lg bg-yellow-500 hover:bg-yellow-700 p-2 text-center"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="ml-3 h-6 w-6"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M18 12H6"
                                      />
                                    </svg>
                                  </button>
                                  <input
                                    readOnly
                                    value={cantidad.toString()}
                                    type="number"
                                    className="font-bold focus:outline-none text-center w-full bg-white text-base  hover:text-gray-700 focus:hover:text-gray-700  flex items-center text-gray-700 outline-none border-gray-200 border p-1"
                                  />
                                  <button
                                    onClick={incrementarUno}
                                    className="rounded-md rounded-l-none  text-white text-lg bg-yellow-500 hover:bg-yellow-700 p-1 text-center"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="ml-3 h-6 w-6"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                      />
                                    </svg>
                                  </button>
                                </div>
                                <button
                                  onClick={addCart}
                                  className="text-center ml-15 rounded-xl text-white bg-blue-500 hover:bg-blue-600 p-3 text-sm mt-3 mb-7 inline-flex items-center shadow-lg"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 mr-2"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                                    />
                                  </svg>
                                  AÑADIR AL CARRITO
                                </button>
                              </div>
                            ) : (
                              <div className="text-sm bg-red-500 p-2 mt-4 text-white rounded-lg w-72 flex justify-center">
                                No disponible por el momento
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="text-sm bg-red-500 p-2 mt-4 text-white rounded-lg w-72 flex justify-center">
                            No disponible por el momento
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="text-sm bg-red-500 text-white p-2">
                    Tu susbscripción ha caducado y la tienda está en modo
                    catálogo
                  </div>
                )}
              </div>
            </div>

            {/*             <div className="flex justify-end p-5">
              <span class="px-4 py-2 flex items-center text-base rounded-full text-blue-500 border border-blue-500 undefined ">
                  <svg width="20" fill="currentColor" height="20" class="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                      </path>
                  </svg>
                  Facebook
              </span>
            </div> */}

            <div className="flex justify-end m-4 text-gray-700">
              {datos.facebook ? (
                <a
                  href={"https://facebook.com/" + datos.facebook}
                  target="_blank"
                >
                  {/*                   <img
                    className="h-8 w-8 m-1 xs:h-6 xs:w-6"
                    alt="Facebook"
                    src={facebook}
                  /> */}
                  <div className="text-gray-700 hover:text-blue-600">
                    <svg
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="h-8 w-8 m-1 xs:h-6 xs:w-6"
                      viewBox="0 0 24 24"
                    >
                      <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                    </svg>
                  </div>
                </a>
              ) : null}
              {datos.twitter ? (
                <a
                  href={"https://twitter.com/" + datos.twitter}
                  target="_blank"
                >
                  <div className="text-gray-700 hover:text-blue-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8 m-1 xs:h-6 xs:w-6"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                    </svg>
                  </div>
                </a>
              ) : null}
              {datos.instagram ? (
                <a
                  href={"https://instagram.com/" + datos.instagram}
                  target="_blank"
                >
                  <div className="text-gray-700 hover:text-purple-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      className="h-8 w-8 m-1 xs:h-6 xs:w-6"
                      fill="currentColor"
                    >
                      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                    </svg>
                  </div>
                </a>
              ) : null}
            </div>
          </div>
        </div>
      )}

      {/*       <Zoom>
        <img alt="that wanaka tree" src={datos.imagen} width="500" />
      </Zoom> */}

      <div className="bg-gray-200">
        <div className="pt-6">
          <MyRating id={id} />
        </div>

        <div className="mt-4">
          <Comments />
        </div>
        <FrontFooter />
      </div>
    </>
  );
};

export default VerProducto;
